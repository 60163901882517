import type {BuildTypeId, BuildTypeInternalId, ProjectId, ProjectInternalId} from '../../../types'
import type {WritableKeyValue} from '../../../utils/object'

export type HolderType = 'project' | 'buildType' | 'template'

/** POLICY */
export type PolicyTemplate = {
  name: string
  fullName: string
  projectId: ProjectInternalId
  externalId: BuildTypeId
}
export type PolicyTemplates = PolicyTemplate[]
type BuildTypeTemplates = {
  basedOnInaccessibleTemplates: boolean
  templates: PolicyTemplates
}
type PolicyEntryMeta = {
  internalId: ProjectInternalId | BuildTypeInternalId
  externalId: ProjectId | BuildTypeId
  name: string
  everythingPolicy: boolean | null | undefined
  buildTypeTemplates?: BuildTypeTemplates | null | undefined
}
export type PolicyHolder =
  | {
      holderId: ProjectInternalId
      holderType: 'project'
    }
  | {
      holderId: BuildTypeInternalId
      holderType: 'buildType'
    }
type PolicyEntryOptions = {
  ownPrevent: boolean
  preventDependenciesArtifactsFromCleanup: boolean
  cleanupPoliciesDisabled: boolean
  ownDisabled: boolean
}
export type PolicyType = {
  ownPolicy: boolean
  levelDescription: 'Artifacts' | 'History' | 'Everything'
  policyDescription: string
  policyParameters: {
    'keepDays.count'?: string
    'keepBuilds.count'?: string
    artifactPatterns?: string
  }
}
export type PolicyEntry = PolicyEntryMeta & {
  artifactsPolicy: PolicyType | null | undefined
  historyPolicy: PolicyType | null | undefined
  everythingPolicy: PolicyType | null | undefined
  options: PolicyEntryOptions
}
export type Settings = {
  daysCount: number | null | undefined
  buildsCount: number | null | undefined
  artifactPatterns: string | null | undefined
}
export type UpdatePolicyPayload = {
  holder: HolderType
  holderId: string
  everythingSettings: Settings | null | undefined
  historySettings: Settings | null | undefined
  artifactsSettings: Settings | null | undefined
  preventDependencies: boolean | null | undefined
}
export type DiskUsage = {
  buildType: WritableKeyValue<string, number>
  project: WritableKeyValue<string, number>
}
export type DiskUsageNode = Element & {
  nodeName: keyof DiskUsage
}
export const getEmptyDiskUsage = (): DiskUsage => ({
  buildType: {},
  project: {},
})

/** Action Creators Props */
export type ACPGetDiskUsageProps = ProjectId

/** REST **/
export type Policy = {
  projectInternalId: ProjectInternalId
  projectExternalId: ProjectId
  projectDescription: string
  editPermitted: boolean
  projectPolicies: PolicyEntry
  templatesPolicies: ReadonlyArray<PolicyEntry>
  buildTypesPolicies: ReadonlyArray<PolicyEntry>
}
export type PoliciesResponse = {
  policiesPerProject: ReadonlyArray<Policy>
}
export type CleanupPoliciesRequestActions = string
export type LastAction = {
  action: CleanupPoliciesRequestActions | null
  loading: boolean
  ready: boolean
}
export type Owner = {
  internalId: ProjectInternalId
  externalId: ProjectId
}
export type Entity = {
  policy: PolicyEntry
  type: HolderType
  owner: Owner
}
export type OwnProps = {
  projectInternalId: ProjectInternalId
  projectId: ProjectId
}
export type StateProps = {
  policiesAreLoaded: boolean
  lastAction: LastAction
  policies: PoliciesResponse | null | undefined
}
export type ActionProps = {
  fetchProjectPolicies: (
    arg0: ProjectInternalId,
    arg1: {
      includeSubprojects: boolean
    },
  ) => unknown
  fetchDiskUsage: (arg0: ACPGetDiskUsageProps) => unknown
  resetPolicy: (arg0: PolicyHolder) => unknown
  editPolicy: (arg0: UpdatePolicyPayload) => unknown
  disablePolicy: (arg0: PolicyHolder, arg1: boolean | null | undefined) => unknown
}
export type HooksProps = {
  showSubprojects: boolean
  toggleSubprojects: (arg0: string) => unknown
}
type HandlersProps = {
  fetchPolicies: () => unknown
}
export type Props = OwnProps & StateProps & ActionProps & HooksProps & HandlersProps
