import type {$Values} from 'utility-types'

const CollapsibleBlocks = Object.freeze({
  COLLAPSED_PROJECT_BUILDTYPELINE: 'COLLAPSED_PROJECT_BUILDTYPELINE',
  COLLAPSED_BRANCHES_SECTION: 'COLLAPSED_BRANCHES_SECTION',
  COLLAPSED_PROJECT_BLOCK: 'COLLAPSED_PROJECT_BLOCK',
  COLLAPSED_AGENT_POOLS: 'COLLAPSED_AGENT_POOLS',
  COLLAPSED_SUBPROJECT: 'COLLAPSED_SUBPROJECT',
})
export type CollapsibleBlock = $Values<typeof CollapsibleBlocks>
export default CollapsibleBlocks
