import type {$Keys} from 'utility-types'

import type {FullPath} from '../types'

import type {WritableKeyValue} from './object'

export type FullPathCacheItem = {
  fullPath: FullPath
  isFullyLoaded: boolean
}
type Cached<V> = WritableKeyValue<string, V>
type ValueTypes = {
  fullPath?: FullPathCacheItem
}
type Type = $Keys<ValueTypes>
type Cache = {
  [K in keyof ValueTypes]: Cached<ValueTypes[K]>
}
let cache: Cache = {}
const memoryCache = {
  set<T extends Type>(type: T, key: string, value: ValueTypes[T]): void {
    const cachedType: Cached<ValueTypes[T]> = cache[type]

    if (cachedType) {
      cachedType[key] = value
    } else {
      cache[type] = {
        [key]: value,
      }
    }
  },

  get<T extends Type>(type: T, key: string): ValueTypes[Type] | null | undefined {
    const cachedType = cache[type]
    return cachedType ? cachedType[key] : undefined
  },

  reset(type: Type): void {
    cache[type] = {}
  },

  resetAll() {
    cache = {}
  },
}
export default memoryCache
