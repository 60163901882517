import * as Redux from 'redux'

import {keyValueFetchable} from '../../../../reducers/fetchable'
import {emptyArray} from '../../../../utils/empty'

import {fetchChangeBuildTypes, fetchChangeDeployments} from './ChangeDetailsTabs.actions'
import type {ChangeDetailsTabsState} from './ChangeDetailsTabs.types'

const changeDetailsTabs = Redux.combineReducers<ChangeDetailsTabsState>({
  changeBuildTypes: keyValueFetchable(
    arg => arg,
    fetchChangeBuildTypes,
    emptyArray,
    (_, action) => action.payload,
  ),
  changeDeployments: keyValueFetchable(
    arg => arg,
    fetchChangeDeployments,
    emptyArray,
    (_, action) => action.payload,
  ),
})
export default changeDetailsTabs
