import type {EntityPathProps as APIProps} from '@jetbrains/teamcity-api'
import * as React from 'react'
import {useEffect} from 'react'

import {fetchProjectWithAllParentsData} from '../actions/projects'
import BuildPath from '../components/common/BuildPath/BuildPath'
import type {BuildPathOwnProps} from '../components/common/BuildPath/BuildPath.types'

import {useProjectOrBuildTypeNodeFromUrl} from '../hooks/routes'
import {getBuildTypeArg} from '../rest/buildTypes'
import {getBuildType, getFullPath} from '../selectors'
import {restApi} from '../services/rest'
import {getProjectBuildTypeFilter, toBuildId, toBuildTypeId, toProjectId} from '../types'
import type {BuildId, BuildTypeId, ProjectId, PathItem} from '../types'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'

type OwnProps = BuildPathOwnProps & {
  buildId?: string | BuildId
  // string for API
  projectId?: ProjectId | null
  buildTypeId?: BuildTypeId | null
  path?: ReadonlyArray<PathItem>
}

export const useLoadEntityWithAllParents = ({
  buildTypeId,
  projectId,
}: {
  projectId?: ProjectId | null
  buildTypeId?: BuildTypeId | null
}) => {
  const dispatch = useAppDispatch()
  const [fetchSingleBuildTypeData] =
    restApi.endpoints.getBuildTypeNormalized.useLazyQuerySubscription()
  useEffect(() => {
    dispatch(async (_, getState) => {
      const state = getState()
      let projectIdToLoad = projectId

      if (buildTypeId != null) {
        let buildType = getBuildType(state, buildTypeId)

        if (buildType == null) {
          const arg = getBuildTypeArg(buildTypeId)
          await fetchSingleBuildTypeData(arg, true)
          buildType = getBuildType(getState(), buildTypeId)
        }

        if (buildType != null && projectIdToLoad == null) {
          projectIdToLoad = buildType.projectId
        }
      }

      if (projectIdToLoad != null) {
        return dispatch(fetchProjectWithAllParentsData(projectIdToLoad)).unsubscribe
      }
      return undefined
    })
  }, [buildTypeId, dispatch, fetchSingleBuildTypeData, projectId])
}

export default function EntityPath({
  projectId,
  buildTypeId,
  buildId,
  path,
  ...restProps
}: OwnProps) {
  const fullPath = useAppSelector(
    state =>
      path ??
      getFullPath(
        state,
        getProjectBuildTypeFilter({
          projectId,
          buildTypeId,
        }),
      ),
  )
  useLoadEntityWithAllParents({
    projectId,
    buildTypeId,
  })
  const projectOrBuildTypeNode = useProjectOrBuildTypeNodeFromUrl()
  const buildIdProperlyTyped = typeof buildId === 'string' ? toBuildId(buildId) : buildId
  return (
    <BuildPath
      withLeafStatusIcon
      projectOrBuildTypeNode={projectOrBuildTypeNode}
      buildId={buildIdProperlyTyped}
      {...restProps}
      path={fullPath}
    />
  )
}
export const EntityPathAPI: React.ComponentType<APIProps> = ({
  buildId,
  projectId,
  buildTypeId,
  ...restProps
}) => (
  <EntityPath
    buildId={buildId != null ? toBuildId(buildId) : undefined}
    projectId={projectId != null ? toProjectId(projectId) : undefined}
    buildTypeId={buildTypeId != null ? toBuildTypeId(buildTypeId) : undefined}
    {...restProps}
  />
)
