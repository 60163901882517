export type SubscriptionID = number
function* generatorUID(): Generator<SubscriptionID> {
  let i = 0

  while (true) {
    i += 1
    yield i
  }
}

const generatorUIDInst = generatorUID()
export const generateUID = (): SubscriptionID => generatorUIDInst.next().value
