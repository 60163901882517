import {mergeIfDifferent} from '../reducers/utils'
import type {Entities} from '../rest/schemata'

import {objectEntries, objectFromEntries} from './object'

export const mergeEntities = (
  first: Partial<Entities>,
  second: Partial<Entities>,
): Partial<Entities> => ({
  ...first,
  ...objectFromEntries(
    objectEntries(second).map(([key, value]) => {
      const current = first[key]
      return [key, current != null ? mergeIfDifferent(current, value) : value]
    }),
  ),
})
