import {branch, renderNothing, mapProps} from 'recompose'

import compose from 'lodash/flowRight'
import type {ComponentType} from 'react'
import {connect} from 'react-redux'

import withDialogContainer from '../../../../hocs/withDialogContainer'
import type {DialogOwnProps, DialogProps} from '../../../../hocs/withDialogContainer'
import type {State} from '../../../../reducers/types'
import {getBuildType, getIsSakuraUI, getProject} from '../../../../selectors'
import {DialogType, stringifyId} from '../../../../types'
import type {
  ProblemOccurrenceId,
  BuildId,
  BuildTypeId,
  ProjectId,
  ProblemId,
  ProjectInternalId,
} from '../../../../types'
import {getProblemOccurrencesById} from '../../BuildProblems/BuildProblems.selectors'

import InvestigationHistoryPopup from './InvestigationHistoryPopup'

type OwnProps = {
  buildId: BuildId | null | undefined
  problemOccurrenceId: ProblemOccurrenceId
}
type StateProps = {
  projectId: ProjectId | null | undefined
  projectInternalId: ProjectInternalId | null | undefined
  buildTypeId: BuildTypeId | null | undefined
  problemId: ProblemId | null | undefined
  problemName: string | null | undefined
  isSakuraUI: boolean
}
type BaseOwnPropsDummy = {
  projectId: ProjectId | null | undefined
  projectInternalId: ProjectInternalId | null | undefined
  buildTypeId: BuildTypeId | null | undefined
  problemOccurrenceId: ProblemOccurrenceId
  problemId: ProblemId | null | undefined
  problemName: string | null | undefined
}
type OwnPropsDummy = DialogOwnProps<BaseOwnPropsDummy>

const mapStateToProps: (arg0: State, arg1: OwnProps) => StateProps = (
  state,
  {problemOccurrenceId},
) => {
  const problemOccurrence = getProblemOccurrencesById(state, problemOccurrenceId)
  const buildTypeId = problemOccurrence?.build?.buildTypeId
  const buildType = getBuildType(state, buildTypeId)
  const project = getProject(state, buildType?.projectId)
  return {
    buildTypeId,
    projectId: buildType?.projectId,
    projectInternalId: project?.internalId,
    problemId: problemOccurrence?.problem?.id,
    problemName: problemOccurrence?.details,
    isSakuraUI: getIsSakuraUI(state),
  }
}

export const BuildProblemInvestigationHistoryPopupDummy: ComponentType<OwnPropsDummy> = compose(
  withDialogContainer(DialogType.INVESTIGATION_HISTORY, (state, props: OwnPropsDummy) =>
    stringifyId(props.problemOccurrenceId),
  ),
  mapProps(
    ({
      buildId,
      problemName,
      problemId,
      problemOccurrenceId,
      ...restProps
    }: DialogProps<OwnPropsDummy>) => ({
      ...restProps,
      subTitle: `Problem name – ${problemName ?? 'Unknown'}`,
      locator: `projectId=${stringifyId(restProps.projectId)}&BuiPro${stringifyId(
        problemId,
      )}=${stringifyId(buildId)}`,
    }),
  ),
  branch((props: DialogProps<OwnPropsDummy>) => !props.isOpened, renderNothing),
)(InvestigationHistoryPopup)
export default connect(
  mapStateToProps,
  {},
)(BuildProblemInvestigationHistoryPopupDummy) as ComponentType<OwnProps>
