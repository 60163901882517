import Button from '@jetbrains/ring-ui/components/button/button'
import * as React from 'react'

import type {BuildId} from '../../../../types'

import {useBuildApprove, useTimeoutInfo} from '../BuildApproval.hooks'

import type {BuildApproval} from '../BuildApproval.types'

import BuildApprovalStatus from './BuildApproval.status'

import styles from './BuildApproval.inline.css'

type OwnProps = {
  approvalInfo: BuildApproval
  buildId: BuildId
}

export default function ApprovalControls({approvalInfo, buildId}: OwnProps) {
  const secondsLeft = useTimeoutInfo(approvalInfo?.timeoutTimestamp || null)
  const {approve} = useBuildApprove(buildId)

  const shouldRenderApproveButton = approvalInfo.canBeApprovedByCurrentUser && !!secondsLeft

  return (
    <>
      {shouldRenderApproveButton ? (
        <Button className={styles.approveButton} onClick={approve}>
          {'Approve'}
        </Button>
      ) : null}
      {approvalInfo.timeoutTimestamp ? (
        <BuildApprovalStatus timeout={approvalInfo.timeoutTimestamp} status={approvalInfo.status} />
      ) : null}
    </>
  )
}
