import {fetchBuildTypeTags} from '../../../../../actions/fetch'
import {createFetchAction} from '../../../../../reducers/fetchable'
import {getSingleBuildArg} from '../../../../../rest/builds'
import {getBuildLocator} from '../../../../../rest/locators'
import {restApi} from '../../../../../services/rest'
import type {PinHandlerArgs} from '../../../../../types'
import {BS} from '../../../../../types/BS_types'

export const pinBuild = createFetchAction(
  'pinBuild',
  (
    {buildId, isPinned, hasDependencies, pinComment, tagsContainerId, buildTypeId}: PinHandlerArgs,
    {dispatch},
  ) =>
    BS?.PinBuildDialog?.showPinBuildDialog(
      buildId,
      !isPinned,
      hasDependencies,
      pinComment,
      tagsContainerId,
      true,
      buildTypeId,
    ).then(data => {
      dispatch(
        restApi.endpoints.getBuildNormalizedAsList.initiate(
          getSingleBuildArg(getBuildLocator(buildId)),
          {
            subscribe: false,
            forceRefetch: true,
          },
        ),
      )

      if (buildTypeId) {
        dispatch(fetchBuildTypeTags(buildTypeId))
      }
      return data
    }),
  {condition: () => BS != null},
)
