import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice, isAnyOf} from '@reduxjs/toolkit'

import {restApi} from '../services/rest'
import type {AgentTypeId, ProjectOrBuildTypeNode, ReduxLocatorOptions, AgentId} from '../types'

const initialState: ReduxLocatorOptions = {
  locator: null,
  locatorReady: true,
  projectBuildtype: {
    nodeType: 'all',
  },
  useRawLocator: false,
  agent: null,
  agentPattern: null,
  tag: null,
  updating: false,
}
const buildsFilters = createSlice({
  name: 'buildsFilters',
  initialState,
  reducers: {
    changeProjectBuildtypeFilter(state, action: PayloadAction<ProjectOrBuildTypeNode>) {
      state.projectBuildtype = action.payload
    },
    setTagFilter(state, action: PayloadAction<string | null | undefined>) {
      state.tag = action.payload
    },
    toggleAdvandedMode(state, action: PayloadAction<boolean | undefined>) {
      state.useRawLocator = action.payload != null ? action.payload : !state.useRawLocator
    },
    changeLocator(state, action: PayloadAction<string | null | undefined>) {
      state.locator = action.payload
    },
    setLocatorReady(state, action: PayloadAction<boolean>) {
      state.locatorReady = action.payload
    },
    setAgentIdFilter(state, action: PayloadAction<AgentId | null | undefined>) {
      state.agent =
        action.payload != null
          ? {
              id: action.payload,
            }
          : null
    },
    setAgentTypeIdFilter(state, action: PayloadAction<AgentTypeId | null | undefined>) {
      state.agent =
        action.payload != null
          ? {
              typeId: action.payload,
            }
          : null
    },
    setAgentPatternFilter(state, action: PayloadAction<string | null | undefined>) {
      state.agentPattern = action.payload
    },
    updateResults(state) {
      state.updating = true
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        restApi.endpoints.getAllBuildsNormalized.matchRejected,
      ),
      state => {
        state.updating = false
      },
    )
  },
})

export default buildsFilters
