import {createFetchAction} from '../../../../reducers/fetchable'
import {restRoot} from '../../../../rest/consts'
import type {BuildTypeId, ChangeId} from '../../../../types'

import {requestChangeFiles} from './ChangeFiles.rest'

export const fetchChangesFiles = createFetchAction(
  'fetchChangesFiles',
  ({
    changeId,
    buildTypeId,
    personal,
  }: {
    changeId: ChangeId
    buildTypeId?: BuildTypeId | null | undefined
    personal?: boolean | undefined
  }) => requestChangeFiles(restRoot, changeId, buildTypeId, personal),
)
