import type {AppThunk} from '../../../actions/types'
import {createFetchAction} from '../../../reducers/fetchable'
import {getExtensionEndpoint} from '../../../selectors'
import type {BuildId, UrlExtension} from '../../../types'
import {base_uri} from '../../../types/BS_types'

import {
  requestBuildsForCompare,
  requestCompareBuildsInfo,
  requestCompareBuildsList,
} from './CompareBuildsPage.rest'
import {settings} from './CompareBuildsPage.slices'

export const setShowChangedOnly = () => settings.actions.setShowChangedOnly(true)
export const setShowAll = () => settings.actions.setShowChangedOnly(false)

type FetchCompareBuildsListArg = {
  sourceId: BuildId
  compareWithId: BuildId
  extension?: UrlExtension<any>
}
export const fetchCompareBuildsListAction = createFetchAction(
  'compareBuilds.fetchCompareBuildsList',
  ({sourceId, compareWithId, extension}: FetchCompareBuildsListArg) =>
    requestCompareBuildsList(
      extension?.serverUrl ?? base_uri,
      extension?.endpoint ?? '',
      sourceId,
      compareWithId,
    ),
)
export const fetchCompareBuildsList =
  (sourceId: BuildId, compareWithId: BuildId): AppThunk =>
  (dispatch, getState) => {
    const extension = getExtensionEndpoint(getState(), 'builds-diff')
    if (extension != null && !window.GLOBAL_FETCH_DISABLED) {
      dispatch(fetchCompareBuildsListAction({sourceId, compareWithId, extension}))
    }
  }

type FetchCompareBuildsInfoArg = {
  compareType: string
  sourceId: BuildId
  compareWithId: BuildId | null | undefined
  showChangedOnly?: boolean
  filterText?: string | null | undefined
  extension?: UrlExtension<any>
}
export const fetchCompareBuildsInfoAction = createFetchAction(
  'compareBuilds.fetchCompareBuildsInfo',
  ({
    compareType,
    sourceId,
    compareWithId,
    showChangedOnly,
    filterText,
    extension,
  }: FetchCompareBuildsInfoArg) =>
    requestCompareBuildsInfo(
      extension?.serverUrl ?? base_uri,
      extension?.endpoint ?? '',
      compareType,
      sourceId,
      compareWithId!, // checked in condition
      showChangedOnly ? 'changed' : 'all',
      filterText?.trim(),
    ),
)
export const fetchCompareBuildsInfo =
  (
    compareType: string,
    sourceId: BuildId,
    compareWithId: BuildId | null | undefined,
    showChangedOnly: boolean,
    filterText?: string | null | undefined,
  ): AppThunk =>
  (dispatch, getState) => {
    const extension = getExtensionEndpoint(getState(), 'builds-diff')

    if (extension != null && !window.GLOBAL_FETCH_DISABLED && compareWithId != null) {
      dispatch(
        fetchCompareBuildsInfoAction({
          compareType,
          sourceId,
          compareWithId,
          showChangedOnly,
          filterText,
          extension,
        }),
      )
    }
  }

type FetchBuildsForCompareArg = {
  buildId: BuildId
  extension?: UrlExtension<any>
}
export const fetchBuildsForCompareAction = createFetchAction(
  'compareBuilds.fetchBuildsForCompare',
  ({buildId, extension}: FetchBuildsForCompareArg) =>
    requestBuildsForCompare(extension?.serverUrl ?? base_uri, extension?.endpoint ?? '', buildId),
)
export const fetchBuildsForCompare =
  (buildId: BuildId): AppThunk =>
  (dispatch, getState) => {
    const extension = getExtensionEndpoint(getState(), 'builds-diff')

    if (extension != null && !window.GLOBAL_FETCH_DISABLED) {
      dispatch(fetchBuildsForCompareAction({buildId, extension}))
    }
  }
