/* eslint-disable @typescript-eslint/no-magic-numbers */
type QueueEntry = () => void

export default class AsyncQueue {
  private queue: QueueEntry[] = []
  private timerId: number | null = null
  private frameDuration = 1000 / 60

  public addToQueue(entry: QueueEntry) {
    this.queue.push(entry)
    this.startQueue()
  }

  public stopQueue() {
    if (this.timerId != null) {
      cancelAnimationFrame(this.timerId)
      this.timerId = null
    }
    this.queue = []
  }

  private startQueue() {
    if (this.timerId) {
      return
    }
    this.timerId = requestAnimationFrame(() => {
      this.timerId = null
      const start = Date.now()
      let cb: QueueEntry | undefined
      while ((cb = this.queue.shift())) {
        cb()
        if (Date.now() - start >= this.frameDuration) {
          this.startQueue()
          break
        }
      }
    })
  }
}
