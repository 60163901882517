import AsteriskSmallIcon from '@jetbrains/icons/asterisk-10px.svg'
import AsteriskIcon from '@jetbrains/icons/asterisk.svg'
import DiceIcon from '@jetbrains/icons/dice.svg'
import ExceptionIcon from '@jetbrains/icons/exception.svg'
import IgnoredIcon from '@jetbrains/icons/ignored.svg'
import MutedIcon from '@jetbrains/icons/muted.svg'
import FixedIcon from '@jetbrains/icons/ok.svg'
import SearchIcon from '@jetbrains/icons/search.svg'
import WarningIcon from '@jetbrains/icons/warning.svg'
import classNames from 'classnames'
import * as React from 'react'
import type {$Keys} from 'utility-types'

import SvgIcon from '../../SvgIcon/SvgIcon'

export const Icons = {
  error: ExceptionIcon,
  internal: WarningIcon,
  taken: SearchIcon,
  fixed: FixedIcon,
  muted: MutedIcon,
  'muted-red': MutedIcon,
  ignored: IgnoredIcon,
  flaky: DiceIcon,
  asterisk: AsteriskIcon,
}
type Icon = $Keys<typeof Icons>
const block = 'buildProblemIcon'
type DefaultProps = {
  type: Icon
  isNew: boolean
}
type Props = DefaultProps & {
  className?: string
}

class BuildProblemIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    type: 'error',
    isNew: false,
  }

  render(): React.ReactNode {
    const {type, isNew, className} = this.props
    const classes = classNames(block, className, `${block}_${type}`, 'svg-icon', {
      [`${block}_new`]: isNew,
    })
    const Icon = Icons[type]
    return (
      <span className={classes}>
        <SvgIcon icon={Icon} className={`${block}__icon`} />
        {isNew && <SvgIcon icon={AsteriskSmallIcon} className={`${block}__asterisk-small`} />}
      </span>
    )
  }
}

export default BuildProblemIcon
