import type {State} from '../../../reducers/types'
import {
  getBuild,
  getBuildCanceledInfo,
  getBuildType,
  getIsMine,
  hasPermission,
} from '../../../selectors'
import type {BuildId} from '../../../types'
import {Permission} from '../../../types'

export function getIsCancellable(state: State, buildId: BuildId | null | undefined): boolean {
  const {buildType, personal} = getBuild(state, buildId) || {}
  const {projectId} = getBuildType(state, buildType) || {}
  const isMine = getIsMine(state, buildId)
  const isCancellable = hasPermission(state, Permission.CANCEL_BUILD, projectId)

  if (isCancellable && personal === true && !isMine) {
    return hasPermission(state, Permission.CANCEL_ANY_PERSONAL_BUILD, projectId)
  }

  return isCancellable
}
export const getIsInterrupted = (state: State, buildId: BuildId): boolean =>
  getBuildCanceledInfo(state, buildId) != null
