import type {PopupMenuAttrs} from '@jetbrains/ring-ui/components/popup-menu/popup-menu'
import * as React from 'react'

const PopupMenuLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PopupMenu", webpackPrefetch: true */
      './PopupMenu'
    ),
)

const PopupMenu = (props: PopupMenuAttrs) =>
  props.hidden === true && process.env.NODE_ENV !== 'test' ? null : (
    <React.Suspense fallback="">
      <PopupMenuLazy {...props} />
    </React.Suspense>
  )

export default PopupMenu
