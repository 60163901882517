import type {ComponentType} from 'react'
import ReactIS from 'react-is'

import {queryToObject} from '../../../utils/queryParams'

import type {PluginLocationContext} from './Plugin.types'
// By default ReactIS.isValidElementType considers the string as a Valid React type and tries to create element based on it
export const isValidPluginReactElementType = (
  content: HTMLElement | null | undefined | string | ComponentType<any>,
): boolean => typeof content !== 'string' && ReactIS.isValidElementType(content)
export const getPluginUIContext = (activeEntities: PluginLocationContext): string =>
  JSON.stringify(activeEntities)
export function getActiveEntitiesFromUrl(): PluginLocationContext {
  const {projectId, buildId, buildTypeId, agentId, agentPoolId, agentTypeId} = queryToObject(
    document.location.search,
  )
  return {
    projectId,
    buildId,
    buildTypeId,
    agentId,
    agentPoolId,
    agentTypeId,
  }
}
