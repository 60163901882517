import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import type {PopupAttrs} from '@jetbrains/ring-ui/components/popup/popup'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import * as React from 'react'

import type {BuildId} from '../../../types'
import Popup from '../Popup/Popup.lazy'

import BuildDurationAnchor from './BuildDurationAnchor/BuildDurationAnchor.container'
import BuildDurationDetails from './BuildDurationDetails/BuildDurationDetails.container'

type DefaultProps = {
  exact: boolean
  popupProps: Partial<PopupAttrs>
}
type Props = DefaultProps & {
  buildId: BuildId
  showAgent?: boolean
  className?: string
  started?: string | null | undefined
  finished?: string | null | undefined
  low?: boolean
  withBorder?: boolean
}

class BuildDuration extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    exact: false,
    popupProps: {
      directions: [Directions.LEFT_BOTTOM],
      left: -8,
    },
  }

  render(): React.ReactNode {
    const {buildId, showAgent, started, finished, className, exact, low, popupProps, withBorder} =
      this.props
    const anchor = (
      <BuildDurationAnchor
        buildId={buildId}
        className={className}
        exact={exact}
        low={low}
        withBorder={withBorder}
      />
    )

    if (started == null || (exact && finished != null)) {
      return anchor
    }

    return (
      <Dropdown
        anchor={anchor}
        hoverShowTimeOut={300}
        hoverHideTimeOut={300}
        clickMode={false}
        hoverMode
      >
        <Popup {...popupProps}>
          <BuildDurationDetails buildId={buildId} showAgent={showAgent} />
        </Popup>
      </Dropdown>
    )
  }
}

export default BuildDuration
