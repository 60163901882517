export const REVALIDATION_HEADER_NAME = 'X-TeamCity-Revalidation'
export const UPDATE_MESSAGE_TYPE = 'JSONCheckerUpdate'

// Client commands
export const CHECK_VERSION = 'CHECK_VERSION'
export const SKIP_WAITING = 'SKIP_WAITING'
export const EXPIRE_CACHES = 'EXPIRE_CACHES'
export const DELETE_CACHES = 'DELETE_CACHES'

// Caching-mark headers
export const CACHE_PROJECT_PERMISSION = 'projects-permissions'
export const CACHE_PROJECTS_TREE = 'projects-tree'
export const CACHE_BUILDTYPES = 'build-configurations'
export const CACHE_TABS = 'tabs'
