import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {deleteIfEmpty} from './EditPipelinePage.slices.utils'

export const setParameter: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    name: string
    value: string
  }>
> = (state, action) => {
  const {id, name, value} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.settings.parameters ??= {}
    draft.settings.parameters[name] = value
  }
}

export const renameParameter: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    name: string
    savedName: string
  }>
> = (state, action) => {
  const {id, name, savedName} = action.payload

  if (name !== savedName) {
    state[id] ??= {}
    state[id]!.renamed ??= {}
    state[id]!.renamed!.parameters ??= {}
    state[id]!.renamed!.parameters![savedName] = name
  } else if (state[id]?.renamed?.parameters?.[savedName] != null) {
    delete state[id]!.renamed!.parameters![savedName]
    deleteIfEmpty(state[id]!.renamed, 'parameters') && deleteIfEmpty(state[id], 'renamed')
  }
}

export const deleteParameter: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; name: string}>
> = (state, action) => {
  const {id, name} = action.payload

  state[id] ??= {}
  state[id]!.deleted ??= {}
  state[id]!.deleted!.parameters ??= []
  state[id]!.deleted!.parameters!.push(name)
}

export const restoreParameter: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; name: string}>
> = (state, action) => {
  const {id, name} = action.payload
  const {deleted} = state[id] ?? {}
  if (deleted?.parameters?.includes(name)) {
    deleted.parameters = deleted.parameters.filter(item => item !== name)
    deleteIfEmpty(deleted, 'parameters') && deleteIfEmpty(state[id], 'deleted')
  }
}
