import type {Draft, PayloadAction} from '@reduxjs/toolkit'
import {createSlice, isAnyOf} from '@reduxjs/toolkit'

import {restApi} from '../../../../services/rest'
import {showQueuedBuildsInBuildsList} from '../../../../slices'
import buildsFilters from '../../../../slices/buildsFilters'
import {submitPager} from '../Pager.actions.base'
import type {Pager} from '../Pager.types'
import {PagerGroup} from '../Pager.types'
import {hasItemsOnPage, needsLoadMore} from '../Pager.utils'

const defaultPager: Pager = {
  show: false,
  currentPage: 1,
  precountedPages: 4,
  pageSize: 50,
  total: 0,
  openTotal: false,
  hasMore: true,
  lookupLimit: 50000,
  lookupLimitWarning: false,
  lookupDelta: 50000,
  loadedLessThanRequested: null,
}

const pager = createSlice({
  name: 'buildPager',
  initialState: defaultPager,
  reducers: {
    changeCurrentPage(state, action: PayloadAction<number>) {
      const {lookupLimit = 0, lookupDelta = 0, lookupLimitWarning: warning, hasMore: more} = state
      const updateLimit = warning === true && needsLoadMore(action.payload, state)
      state.currentPage = action.payload
      state.lookupLimit = updateLimit ? lookupLimit + lookupDelta : lookupLimit
      state.show = hasItemsOnPage(action.payload, state) || !more
    },
    incrementLookupLimit(state) {
      const {lookupLimit = 0, lookupDelta = 0} = state
      state.lookupLimit = lookupLimit + lookupDelta
    },
  },
  extraReducers: builder => {
    function reset(state: Draft<Pager>) {
      state.total = 0
      state.openTotal = false
      state.lookupLimitWarning = false
      state.show = false
      state.isLoading = false
    }
    builder.addCase(submitPager, (state, action) => {
      if (action.meta.group === PagerGroup.BUILD) {
        Object.assign(state, action.payload)
      }
    })
    builder.addMatcher(restApi.endpoints.getAllBuilds.matchPending, (state, action) => {
      const {isCountRequest, inBackground} = action.meta.arg.originalArgs
      if (isCountRequest && !inBackground) {
        state.show = false
        state.isLoading = true
      }
    })
    builder.addMatcher(restApi.endpoints.getAllBuilds.matchFulfilled, (state, action) => {
      const {isCountRequest, count = 0} = action.meta.arg.originalArgs
      if (!isCountRequest) {
        return
      }

      const actionData = action.payload

      if (actionData == null) {
        reset(state)
        return
      }

      const hasMore = actionData.nextHref != null
      const total = actionData.count ?? 0
      const loadedLessThanRequested = total < count
      const lookupLimitWarning = loadedLessThanRequested && hasMore
      state.total = total
      state.lookupLimitWarning = lookupLimitWarning
      const hasItems = state.currentPage != null && hasItemsOnPage(state.currentPage, state)
      state.hasMore = hasMore
      state.openTotal = hasMore && !loadedLessThanRequested
      state.show = (loadedLessThanRequested && total > 0) || hasItems
      state.isLoading = false
    })
    builder.addMatcher(restApi.endpoints.getAllBuilds.matchRejected, (state, action) => {
      if (action.meta.arg.originalArgs.isCountRequest) {
        reset(state)
      }
    })
    builder.addMatcher(
      isAnyOf(
        buildsFilters.actions.changeProjectBuildtypeFilter,
        buildsFilters.actions.setTagFilter,
        buildsFilters.actions.toggleAdvandedMode,
        showQueuedBuildsInBuildsList.actions.toggle,
      ),
      state => {
        state.currentPage = 1
        state.show = false
      },
    )
  },
})

export default pager
