import * as React from 'react'

type Lazy<T> =
  | {
      isInited: false
    }
  | {
      isInited: true
      value: T
    }
export default function useLazy<T>(init: () => T, dependencies: ReadonlyArray<unknown>): () => T {
  const ref = React.useRef<Lazy<T>>({
    isInited: false,
  })
  return React.useCallback(() => {
    const {current} = ref

    if (!current.isInited) {
      const value = init()
      ref.current = {
        isInited: true,
        value,
      }
      return value
    }

    return current.value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
