export type Refetchable<T = unknown> = Promise<T> & {
  refetch: () => unknown
  unsubscribe: () => void
}

export const combineRefetchables = <T>(refetchables: Refetchable<T>[]): Refetchable<T[]> =>
  Object.assign(Promise.all(refetchables), {
    refetch: () => refetchables.forEach(item => item.refetch()),
    unsubscribe: () => refetchables.forEach(item => item.unsubscribe()),
  })

export const createRefetchableSubscription = (
  initiate: () => Refetchable,
  subscribeEvent: (handler: () => unknown) => () => unknown,
) => {
  let result: Refetchable | undefined
  const unsubscribeEvent = subscribeEvent(() => {
    if (result == null) {
      result = initiate()
    } else {
      result.refetch()
    }
  })
  return () => {
    unsubscribeEvent()
    result?.unsubscribe()
  }
}

export const unsubscribeOnSuccess = <T>(refetchable: Refetchable<T>): Promise<T> =>
  refetchable.then(data => {
    refetchable.unsubscribe()
    return data
  })
