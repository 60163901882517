import {useCallback, useLayoutEffect, useRef} from 'react' // https://github.com/facebook/react/issues/14099#issuecomment-440013892

export default function useEventCallback<I extends unknown[], O>(
  fn: (...args: I) => O,
): (...args: I) => O {
  const ref = useRef<(...args: I) => O>()
  useLayoutEffect(() => {
    ref.current = fn
  })
  return useCallback((...args) => {
    const {current} = ref

    if (current == null) {
      throw new Error('callback created in useEventCallback can only be called from event handlers')
    }

    return current(...args)
  }, [])
}
