import type {BuildId, Group, InexactCountable, User} from '../../../types'

export enum ApprovalStatuses {
  WAITING_FOR_APPROVAL = 'waitingForApproval',
  APPROVED = 'approved',
  TIMED_OUT = 'timedOut',
  CANCELED = 'canceled',
}

export type BuildApproval = {
  status: ApprovalStatuses
  canBeApprovedByCurrentUser: boolean
  configurationValid: boolean
  timeoutTimestamp: string
  groupApprovals?: InexactCountable & {
    groupApproval?: ReadonlyArray<GroupApprovalRuleStatus>
  }
  userApprovals?: InexactCountable & {
    userApproval?: ReadonlyArray<UserApprovalRuleStatus>
  }
}

export type UserApprovalRuleStatus = {
  user: User
  approved: boolean
}

export type GroupApprovalRuleStatus = {
  group: Group
  requiredApprovalsCount: number
  currentlyApprovedBy: InexactCountable & {
    user: ReadonlyArray<User>
  }
}

export enum BuildApprovalViewMode {
  INLINE = 'inline',
  CLASSIC_UI = 'classic_ui',
  POPUP = 'popup',
}

export type OwnProps = {
  buildId: BuildId
  className?: string
  view?: BuildApprovalViewMode
}

export type StateProps = {
  shouldRender: boolean
}

export type Props = OwnProps & StateProps

export type ViewProps = {
  buildId: BuildId
  approvalInfo: BuildApproval | null | undefined
}
