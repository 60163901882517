import type {Ref} from 'react'
import {useCallback, useContext, useEffect, useRef, useState} from 'react'

import {VisibilityContext} from './Visible.context'
import {observeVisibility} from './Visible.utils'

export const useParentVisibility = (): boolean | null | undefined => useContext(VisibilityContext)

export function useVisibility(
  ref: {
    current: HTMLElement | null | undefined
  },
  id?: string | null | undefined,
  defaultVisible: boolean = false,
  disable: boolean = false,
): boolean {
  const [isVisible, setIsVisible] = useState(defaultVisible)
  useEffect(() => {
    if (!disable && ref.current != null) {
      return observeVisibility(ref.current, setIsVisible, id)
    }

    return undefined
  }, [id, ref, disable])
  return isVisible
}

export function useVisibilityRef(
  id?: string | null | undefined,
  defaultVisible: boolean = false,
  disable: boolean = false,
): [Ref<HTMLDivElement>, boolean] {
  const [isVisible, setIsVisible] = useState(defaultVisible)
  const [storedNode, setStoredNode] = useState<HTMLDivElement>()
  // Both refs are needed.
  // First is to always access real current value, second is to ensure rendering on the value update.
  const visibilityRevObject = useRef<HTMLDivElement | null>(null)
  const visibilityRefCallback = useCallback((node: HTMLDivElement) => {
    if (node != null) {
      visibilityRevObject.current = node
      setStoredNode(node)
    }
  }, [])

  const setVisibility = useCallback(
    (visible: boolean) => {
      // We should switch the visibility based only on the real current node
      if (storedNode === visibilityRevObject.current) {
        setIsVisible(visible)
      }
    },
    [storedNode],
  )

  useEffect(() => {
    if (!disable && storedNode != null) {
      return observeVisibility(storedNode, setVisibility, id)
    }

    return undefined
  }, [id, storedNode, disable, setVisibility])

  return [visibilityRefCallback, isVisible]
}
