import {BitbucketIcon as PlainBitBucketIcon} from '@atlaskit/logo'
import * as React from 'react'

import cssVariables from '../../../utils/cssVariables'

export const mainColor = (disabled: boolean): string => (disabled ? '#e5e5e5' : '#000' ?? '')

const secondaryColor = () => cssVariables['--ring-icon-secondary-color']

class BitbucketIcon extends React.PureComponent<{
  disabled: boolean
}> {
  render(): React.ReactNode {
    const {disabled} = this.props
    return (
      <PlainBitBucketIcon
        iconColor={mainColor(disabled)}
        iconGradientStart={secondaryColor()}
        iconGradientStop={mainColor(disabled)}
      />
    )
  }
}

export default BitbucketIcon
