// Copy from TrackerEventType.java
export const BUILD_STARTED = 'a'
export const BUILD_CHANGES_LOADED = 'b'
export const BUILD_FINISHED = 'c'
export const BUILD_CHANGED_STATUS = 'd'
export const BUILD_INTERRUPTED = 'e'
export const BUILD_REMOVED = 'f'
export const BUILD_TAGS_CHANGED = 'N'
export const PERSONAL_BUILD_STARTED = 'g'
export const PERSONAL_BUILD_FINISHED = 'h'
export const PERSONAL_BUILD_CHANGED_STATUS = 'i'
export const PERSONAL_BUILD_INTERRUPTED = 'j'
export const PERSONAL_BUILD_ADDED_TO_QUEUE = 'k'
export const PROJECT_RESTORED = 'l'
export const PROJECT_PERSISTED = 'm'
export const PROJECT_REMOVED = 'n'
export const PROJECT_CREATED = 'o'
export const PROJECT_ARCHIVED = 'p'
export const PROJECT_DEARCHIVED = 'q'
export const PROJECT_UPDATED = 'V'
export const BUILD_TYPE_REGISTERED = 'r'
export const BUILD_TYPE_UNREGISTERED = 's'
export const BUILD_TYPE_ADDED_TO_QUEUE = 't'
export const BUILD_TYPE_REMOVED_FROM_QUEUE = 'u'
export const BUILD_TYPE_ACTIVE_STATUS_CHANGED = 'v'
export const BUILD_TYPE_RESPONSIBILITY_CHANGES = 'w'
export const BUILD_TYPE_PERSISTED = 'L'
export const BUILD_TYPE_TEMPLATE_PERSISTED = 'M'
export const BUILD_TYPE_BRANCHES_CHANGED = 'X'
export const BUILD_TYPE_UPDATED = 'U'
export const CHANGE_ADDED = 'x'
export const BUILD_QUEUE_ORDER_CHANGED = 'y'
export const AGENT_REGISTERED = 'z'
export const AGENT_UNREGISTERED = 'A'
export const AGENT_REMOVED = 'B'
export const AGENT_STATUS_CHANGED = 'C'
export const USER_ACCOUNT_CREATED = 'D'
export const USER_ACCOUNT_REMOVED = 'E'
export const USER_ACCOUNT_CHANGED = 'F'
export const NOTIFICATION_RULES_CHANGED = 'G'
export const SERVER_SHUTDOWN = 'H'
export const TEST_RESPONSIBILITY_CHANGED = 'I'
export const AGENT_PARAMETERS_UPDATED = 'J'
export const TEST_MUTE_UPDATED = 'K'
export const USER_PERMISSIONS_CHANGED = 'O'
export const PROBLEMS_UPDATED = 'P'
export const PROBLEM_MUTE_UPDATED = 'R'
export const PROBLEM_RESPONSIBILITY_CHANGED = 'S'
export const AGENT_POOL_TOUCHED = 'T'
