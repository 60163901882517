import type {ElementType, ReactNode} from 'react'
import {memo, useRef} from 'react'

import type {SeqProps} from '../SequenceLoader/SequenceLoader'

import {VisibilityContext} from './Visible.context'
import {useVisibility} from './Visible.hooks'

export type Props = SeqProps & {
  id?: string
  children: (isVisible: boolean) => ReactNode
  TagName?: ElementType
  defaultVisible?: boolean
  className?: string
}

function Visible({id, children, TagName = 'div', className, defaultVisible}: Props) {
  const ref = useRef()
  const isVisible = useVisibility(ref, id, defaultVisible)
  return (
    <TagName key={id} ref={ref} className={className}>
      <VisibilityContext.Provider value={isVisible}>
        {children(isVisible)}
      </VisibilityContext.Provider>
    </TagName>
  )
}

export default memo<Props>(Visible)
