import {createSelector} from 'reselect'

import type {State} from '../../../reducers/types'
import {getBooleanUserProperty} from '../../../selectors'
import {UserProperties} from '../../../utils/userProperties'

import type {HintCategory, HintsState, HintId, Hint} from './Hints.types'

export const getAvailableHints = (state: State): ReadonlyArray<Hint> => state.hints.available
export const getSelectedHints = (state: State): ReadonlyArray<HintId> => state.hints.selected
export const getSelectedCategory = (state: State): HintCategory | null =>
  state.hints.selectedCategory
export const getHasSeenTipsReminder = (state: State): boolean =>
  getBooleanUserProperty(state, UserProperties.HAS_SEEN_TIPS_REMINDER)
const searchParams = new URLSearchParams(location.search)
export const embedded = searchParams.get('embedded') === 'true'
export const getTipsModeEnabled = (state: State): boolean =>
  !embedded && getBooleanUserProperty(state, UserProperties.TIPS_MODE, false)
export const getSkipObligatoryHints = (state: State): boolean => state.hints.skipObligatoryHints
const getViewedObligatoryHints = (state: State): ReadonlyArray<HintId> =>
  state.hints.viewedObligatoryHints
export const getCurrentObligatoryHintId = createSelector(
  [getAvailableHints, getViewedObligatoryHints],
  (availableHints, viewedObligatoryHints) => {
    const viewedHintsSet = new Set(viewedObligatoryHints)
    return (
      availableHints.filter(hint => hint.obligatory === true && !viewedHintsSet.has(hint.id))[0]
        ?.id ?? null
    )
  },
)

export const getHintsStateToPersist: (state: State) => HintsState = createSelector(
  [getViewedObligatoryHints, getSkipObligatoryHints],
  (viewedObligatoryHints, skipObligatoryHints) => ({
    registrations: {},
    available: [],
    selected: [],
    selectedCategory: null,
    viewedObligatoryHints,
    skipObligatoryHints,
  }),
)
