import type {AppThunk} from '../../../../../actions/types'
import {createFetchAction} from '../../../../../reducers/fetchable'
import {restRoot} from '../../../../../rest/consts'
import {stringifyId} from '../../../../../types'
import type {ChangeId} from '../../../../../types'

import {requestChange} from './BuildStatusTooltip.rest'
import {getChangeData} from './BuildStatusTooltip.selectors'

export const fetchPersonalChangeAction = createFetchAction(
  'fetchPersonalChange',
  (changeId: ChangeId) =>
    requestChange(restRoot, `id:${stringifyId(changeId)},personal:true`).then(data => data),
)
export const fetchPersonalChange =
  (changeId: ChangeId): AppThunk =>
  (dispatch, getState) => {
    const state = getState()
    if (getChangeData(state, changeId).inited) {
      return
    }
    dispatch(fetchPersonalChangeAction(changeId))
  }
