import {createSlice} from '@reduxjs/toolkit'

import type {BuildId} from '../../../types'

import type {KeyValue} from '../../../utils/object'

import {fetchApproval} from './BuildApproval.actions'
import type {BuildApproval} from './BuildApproval.types'

const buildApprovalSlice = createSlice({
  name: 'buildApprovals',
  initialState: {} as KeyValue<BuildId, BuildApproval>,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchApproval.fulfilled, (state, action) => {
      Object.assign(state, {[action.meta.arg.buildId]: action.payload.approvalInfo})
    })
  },
})

export default buildApprovalSlice
