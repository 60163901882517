import type {SerializedError} from '@reduxjs/toolkit'
import type {FetchBaseQueryError} from '@reduxjs/toolkit/src/query/fetchBaseQuery'

export function extractError(
  error: SerializedError | FetchBaseQueryError | undefined,
): Error | string | null | undefined {
  if (error == null) {
    return null
  } else if ('status' in error) {
    if (error.data instanceof Error || typeof error.data === 'string') {
      return error.data
    } else if ('error' in error) {
      return error.error
    } else {
      return null
    }
  } else {
    return error.message
  }
}
