import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'

export const changeTrigger: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    index: number
    parameters: Record<string, string>
  }>
> = (state, action) => {
  const {id, index, parameters} = action.payload
  const {triggers} = state[id]?.draft ?? {}

  if (triggers) {
    const trigger = triggers[index]

    triggers[index] = {
      ...trigger,
      parameters,
    }
  }
}

export const toggleDisabledTrigger: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    index: number
    value: boolean
  }>
> = (state, action) => {
  const {id, index, value} = action.payload
  const {triggers} = state[id]?.draft ?? {}

  if (triggers) {
    triggers[index].disabled = value
  }
}

export const addTrigger: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    type: string
    parameters?: Record<string, string>
    disabled?: boolean
  }>
> = (state, action) => {
  const {id, ...trigger} = action.payload

  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.triggers ??= []

    draft.triggers.push(trigger)
  }
}

export const deleteTrigger: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; index: number}>
> = (state, action) => {
  const {id, index} = action.payload

  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.triggers ??= []

    draft.triggers.splice(index, 1)
  }
}
