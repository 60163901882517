import * as React from 'react'

import {mergeIfDifferent} from '../reducers/utils'
import type {Enhancer} from '../types'

import withHook from './withHook'

export function useDerivedState<S>(initialState: S, deriveState: (arg0: S) => S): S {
  const [state, updateState] = React.useState(initialState)
  const derivedState = deriveState(state)

  if (derivedState !== state) {
    updateState(derivedState)
  }

  return state
}
// https://github.com/facebook/flow/issues/8052
type DeriveState<S> = (state: S) => Partial<S> | null
type DeriveStateFromProps<P, S> = (props: P, state: S) => Partial<S> | null

function bindProps<P, S extends any>(derive: DeriveStateFromProps<P, S>, props: P): DeriveState<S> {
  return state => derive(props, state)
}

function useDerivedPartialState<S extends {}>(initialState: S, deriveState: DeriveState<S>): S {
  return useDerivedState(initialState, (state: S) => {
    const derivedState = deriveState(state)
    return derivedState != null ? mergeIfDifferent(state, derivedState) : state
  })
}

export default function withDerivedState<P, S extends {}>(
  initialState: S,
  deriveState: DeriveStateFromProps<P, S>,
): Enhancer<S, P> {
  return withHook<S, P>(props =>
    useDerivedPartialState<S>(initialState, bindProps(deriveState, props)),
  )
}
