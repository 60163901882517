import type {MeasureProps, MeasurementType} from 'react-measure'

import withHook from '../hocs/withHook'
import useMeasure from '../hooks/useMeasure'

const emptyArray: readonly MeasurementType[] = []
export function withContentRect<P extends {}>(
  types: MeasurementType | readonly MeasurementType[] = [],
) {
  return withHook((props: P) =>
    useMeasure({
      ...Object.fromEntries(emptyArray.concat(types).map(type => [type, true])),
      ...props,
    }),
  )
}

type MeasureComponentProps = MeasureProps & {
  children: NonNullable<MeasureProps['children']>
}

const Measure = ({children, ...restProps}: MeasureComponentProps) => children(useMeasure(restProps))

export default Measure
