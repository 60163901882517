import {createOrUpdateDarkTheme, removeDarkTheme} from './adapter'
import * as iframeManager from './adapter/iframes'
import {DEFAULT_THEME} from './defaults'
import type {Theme} from './types'

export const disable = () => removeDarkTheme()
export const enable = (themeOptions: Partial<Theme> | null = {}) =>
  createOrUpdateDarkTheme({...DEFAULT_THEME, ...themeOptions})

export {iframeManager}
