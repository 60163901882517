import {createFetchAction} from '../../../../reducers/fetchable'
import {restRoot} from '../../../../rest/consts'

import {requestChangeBuildTypes, requestChangeDeployments} from './ChangeDetailsTabs.rest'

export const fetchChangeBuildTypes = createFetchAction('fetchChangeBuildTypes', (locator: string) =>
  requestChangeBuildTypes(restRoot, locator),
)

export const fetchChangeDeployments = createFetchAction(
  'fetchChangeDeployments',
  (locator: string) => requestChangeDeployments(restRoot, locator),
)
