import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {ExpandState, ProjectId} from '../../../types'
import type {KeyValue} from '../../../utils/object'

export const allProjectsExpandState = createSlice({
  name: 'allProjectsExpandState',
  initialState: {} as KeyValue<ProjectId, ExpandState>,
  reducers: {
    toggle(state, action: PayloadAction<ExpandState[]>) {
      action.payload.forEach(item => {
        state[item.id!] = item
      })
    },
  },
})

export const sidebar = createSlice({
  name: 'sidebar',
  initialState: {
    searchQuery: '',
    searchActive: false,
    allProjectsCollapsed: false,
    showArchivedProjects: false,
  },
  reducers: {
    changeSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload
    },
    changeSearchActive(state, action: PayloadAction<boolean>) {
      state.searchActive = action.payload
      if (!action.payload) {
        state.searchQuery = ''
        state.showArchivedProjects = false
      }
    },
    changeAllProjectsCollapsed(state, action: PayloadAction<boolean>) {
      state.allProjectsCollapsed = action.payload
    },
    toggleArchivedProjectsInSidebar(state) {
      state.showArchivedProjects = !state.showArchivedProjects
    },
  },
})

export const searchProjectsExpandState = createSlice({
  name: 'searchProjectsExpandState',
  initialState: {} as KeyValue<ProjectId, ExpandState>,
  reducers: {
    toggle(state, action: PayloadAction<ExpandState[]>) {
      action.payload.forEach(item => {
        state[item.id!] = item
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(sidebar.actions.changeSearchQuery, () => ({}))
  },
})
