import Theme from '@jetbrains/ring-ui/components/global/theme'
import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import {fetchCurrentUserDataAction, setUserPropertyAction} from '../actions'
import {getPropertyFromList} from '../utils/getProperty'
import {UserProperties} from '../utils/userProperties'

export const theme = createSlice({
  name: 'theme',
  initialState: Theme.AUTO,
  reducers: {
    set: (_, action: PayloadAction<Theme>) => action.payload,
  },
  extraReducers: builder => {
    builder.addCase(
      fetchCurrentUserDataAction.fulfilled,
      (state, action) =>
        (getPropertyFromList(UserProperties.THEME, action.payload?.properties) as
          | Theme
          | ''
          | undefined) || state,
    )
    builder.addCase(setUserPropertyAction.pending, (state, action) => {
      const {name, value} = action.meta.arg
      return name === UserProperties.THEME ? (value as Theme) : state
    })
  },
})
