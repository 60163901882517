import type {BuildId, Fetchable} from '../../../types'
import {emptyArrayFetchable} from '../../../utils/empty'

import type {BuildQueueInfoState, WaitReasons} from './BuildQueueInfo.types'

export const getWaitReasonsFetchable: (
  state: BuildQueueInfoState,
  buildId: BuildId,
) => Fetchable<WaitReasons> = ({waitReasons}, buildId) =>
  waitReasons[buildId] ?? emptyArrayFetchable
