import type {CurrentUser} from '../types'

import processResponse from './processResponse'
import request from './request'

export default (serverUrl: string): Promise<CurrentUser> =>
  request(serverUrl, 'users/current?fields=id,name,username,properties(property),avatars', {
    essential: true,
  }).then<CurrentUser>(processResponse)
export const setProperty = async (
  serverUrl: string,
  name: string,
  value: string,
): Promise<string> => {
  const response = await request(serverUrl, `users/current/properties/${name}`, {
    method: 'PUT',
    body: value,
    headers: {
      Accept: 'text/plain',
      'Content-Type': 'text/plain',
    },
  })

  if (!response.ok) {
    const message = await response.text()
    throw new Error(message)
  }

  return response.text()
}
