import type {State} from '../../../../../../reducers/types'
import {getBranchesLocator, Policy} from '../../../../../../rest/branches'
import {getProjectOverviewLocator} from '../../../../../../rest/locators'
import {getProjectBranches, getBuildType} from '../../../../../../selectors'
import {getProjectBuildTypeFilter} from '../../../../../../types'

import type {FetcherOwnProps} from './BuildTypeLine.types'

const MIN_AMOUNT_OF_FINISHED_BUILDS = 50
const MULTIPLE_FACTOR_FOR_QUEUED_COUNT = 2
export const getBuildTypeLineLocator = (state: State, {buildTypeId, branch}: FetcherOwnProps) => {
  const buildType = getBuildType(state, buildTypeId)
  const projectId = buildType?.projectId ?? null
  const projectOrBuildTypeNode = getProjectBuildTypeFilter({
    projectId,
  })
  const locator = getBranchesLocator({
    node: projectOrBuildTypeNode,
    policy: Policy.ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES,
    includeSubprojects: true,
  })
  const branches = projectId && getProjectBranches(state, projectId, locator)
  const branchesCount = branches?.length ?? 1
  const finishedCount =
    branchesCount < MIN_AMOUNT_OF_FINISHED_BUILDS ? MIN_AMOUNT_OF_FINISHED_BUILDS : branchesCount
  const runningCount = finishedCount * MULTIPLE_FACTOR_FOR_QUEUED_COUNT
  const queuedCount = finishedCount * MULTIPLE_FACTOR_FOR_QUEUED_COUNT
  return getProjectOverviewLocator({
    runningCount,
    queuedCount,
    finishedCount,
    branch,
    projectBuildtype: getProjectBuildTypeFilter({
      buildTypeId,
    }),
  })
}
