import {fetchFederationServers} from '../../../actions/federation'
import {fetchArchivedProjects} from '../../../actions/projects'
import type {AppThunk} from '../../../actions/types'
import {getFederationProjectsArg} from '../../../rest/projects'
import {restApi} from '../../../services/rest'
import {sidebar} from '../../App/ProjectsSidebar/ProjectsSidebar.slices'

export const fetchProjectsForAllFederationServers =
  (force?: boolean): AppThunk<any> =>
  async (dispatch, getState) => {
    let state = getState()

    if (!state.federationServers.inited && !state.federationServers.loading) {
      await dispatch(fetchFederationServers(force))
    }

    state = getState()
    for (let i = 0; i < state.federationServers.data.length; i++) {
      const federationServerId = state.federationServers.data[i]
      dispatch(
        restApi.endpoints.getFederationProjectsNormalized.initiate(
          getFederationProjectsArg(state, federationServerId),
        ),
      )
    }
  }
export const toggleArchivedProjectsInSidebar = (): AppThunk<any> => (dispatch, getState) => {
  dispatch(sidebar.actions.toggleArchivedProjectsInSidebar())
  const state = getState()

  if (state.sidebar.showArchivedProjects) {
    dispatch(fetchArchivedProjects())
    dispatch(fetchProjectsForAllFederationServers(true))
  }
}
