import type {$Values} from 'utility-types'

import type {BuildTypeId, TypeOfBuildType, ChangeId, Fetchable} from '../../../../types'
import type {KeyValue} from '../../../../utils/object'

export type ChangeTab = {
  id: string
  title: string
}

export const ChangePageTabNamesEnum = {
  PROBLEMS_TESTS: 'problemsTests',
  BUILDS: 'builds',
  DEPLOYMENTS: 'deployments',
  FILES: 'files',
}

export const ChangePageTabTitlesEnum = {
  [ChangePageTabNamesEnum.PROBLEMS_TESTS]: 'Problems & Tests',
  [ChangePageTabNamesEnum.BUILDS]: 'Builds',
  [ChangePageTabNamesEnum.DEPLOYMENTS]: 'Deployments',
  [ChangePageTabNamesEnum.FILES]: 'Files',
}

export type ChangePageTabNamesEnumType = $Values<typeof ChangePageTabNamesEnum>

export const OldChangePageTabNamesEnum = {
  PROBLEMS_TESTS: 'vcsModificationTests',
  BUILDS: 'vcsModificationBuilds',
  DEPLOYMENTS: 'vcsModificationDeployments',
  FILES: 'vcsModificationFiles',
}
export type OldChangePageTabNamesEnumType = $Values<typeof OldChangePageTabNamesEnum>

export type ChangeDetailsTabsProps = {
  className?: string
  changeId: ChangeId
  buildTypeId?: BuildTypeId | undefined | null
  currentTab: ChangePageTabNamesEnumType
  onSelectTab: (tab: ChangePageTabNamesEnumType) => void
  withHref?: boolean
}

export type ChangeBuild = {
  id: BuildTypeId
  type: TypeOfBuildType
}

export type ChangeDeployment = {
  id: BuildTypeId
}

export type ChangeDetailsTabsState = {
  changeBuildTypes: KeyValue<string, Fetchable<ReadonlyArray<ChangeBuild>>>
  changeDeployments: KeyValue<string, Fetchable<ReadonlyArray<ChangeDeployment>>>
}
