import {queryToObject} from './queryParams'
import {parseURL} from './url'

enum LogKeys {
  Performance = 'performanceLog',
}

enum PerformanceLogKeys {
  Actions = 'actions',
  Reducers = 'reducers',
  All = 'all',
}

function getLogLevel(key: LogKeys): string | null | undefined {
  const search = parseURL(window.location.href).search
  const urlParams = queryToObject(search)

  return urlParams[key]
}

export const hasPerformanceActionLog = (() => {
  const logLevel = getLogLevel(LogKeys.Performance)
  if (logLevel == null) {
    return false
  }
  return logLevel === PerformanceLogKeys.Actions || logLevel === PerformanceLogKeys.All
})()

export const hasPerformanceReducerLog = (() => {
  const logLevel = getLogLevel(LogKeys.Performance)
  if (logLevel == null) {
    return false
  }
  return logLevel === PerformanceLogKeys.Reducers || logLevel === PerformanceLogKeys.All
})()
