import {createAction} from '@reduxjs/toolkit'

import {createFetchAction} from '../../../reducers/fetchable'
import {base_uri} from '../../../types/BS_types'

import {downloadFile} from '../../../utils/download'

import {requestAgentToken, requestAgentConfig} from './AgentAuth.rest'

export const fetchAgentToken = createFetchAction('fetchAgentToken', () =>
  requestAgentToken(base_uri),
)
export const fetchAgentConfig = createFetchAction(
  'fetchAgentConfig',
  async (agentName: string | null | undefined) => {
    const data = await requestAgentConfig(base_uri, agentName)
    downloadFile(data, 'buildAgent.properties', 'text/plain')
    return data
  },
)
export const clearAgentAuth = createAction('clearAgentAuth')
