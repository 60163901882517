import type {
  Branch,
  CheckIfBranchExistsApiArg,
  CheckIfBranchExistsInBuildTypeApiArg,
  GetAllBranchesApiArg,
  GetAllBranchesOfBuildTypeApiArg,
} from '../services/rest'
import type {ProjectOrBuildTypeNode} from '../types'
import {getBuildTypeFilter, stringifyId} from '../types'

import {getBranchLocatorPart} from './locators'

export enum Policy {
  ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES = 'ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES',
  ALL_BRANCHES = 'ALL_BRANCHES',
  HISTORY_BRANCHES = 'HISTORY_BRANCHES',
}

type FetchBranchesParams = {
  node?: ProjectOrBuildTypeNode | null | undefined
  policy?: Policy | null | undefined
  includeSnapshots?: boolean | null | undefined
  includeSubprojects?: boolean | null | undefined
  excludeGroups?: boolean | null | undefined
  excludeDefault?: boolean
  branch?: Branch
  inPath?: boolean
}

export const getBranchesLocator = ({
  node,
  includeSubprojects,
  includeSnapshots,
  policy,
  excludeGroups,
  excludeDefault = false,
  branch,
  inPath,
}: FetchBranchesParams) => {
  const {nodeType, id} = node ?? {}
  const isProject = nodeType === 'project'
  const defaultPolicy = isProject ? 'ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES' : 'ALL_BRANCHES'
  const isGroup = branch != null && branch.groupFlag === true && branch.internalName != null

  return [
    isProject &&
      includeSubprojects !== false &&
      `buildType:(affectedProject:(id:${stringifyId(id)}))`,
    !isGroup && `policy:${policy ?? defaultPolicy}`,
    !isGroup && includeSnapshots != null && `changesFromDependencies:${String(includeSnapshots)}`,
    excludeGroups !== true && 'includeGroups:true',
    excludeDefault && 'default:false',
    branch != null && getBranchLocatorPart(branch, inPath),
  ]
    .filter(Boolean)
    .join(',')
}

const branchesFields = 'branch(internalName,name,default,active,groupFlag)'

export const getProjectBranchesArg = (
  projectId: string,
  locator: string,
): GetAllBranchesApiArg => ({projectLocator: `id:${projectId}`, locator, fields: branchesFields})

export const getBuildTypeBranchesArg = (
  buildTypeId: string,
  locator: string,
): GetAllBranchesOfBuildTypeApiArg => ({
  btLocator: `id:${buildTypeId}`,
  locator,
  fields: branchesFields,
})

export const getIsBranchPresentInProjectArg = (
  projectId: string,
  branchLocator: string,
): CheckIfBranchExistsApiArg => ({
  projectLocator: `id:${projectId}`,
  branchLocator,
})

export const getIsBranchPresentInBuildTypeArg = (
  buildTypeId: string,
  branchLocator: string,
  essential?: boolean,
): CheckIfBranchExistsInBuildTypeApiArg => ({
  btLocator: `id:${buildTypeId}`,
  branchLocator,
  essential,
})

export const getBuildTypeHasNonDefaultBranchesArg = (buildTypeId: string) =>
  getIsBranchPresentInBuildTypeArg(
    buildTypeId,
    getBranchesLocator({
      node: getBuildTypeFilter(buildTypeId),
      excludeGroups: true,
      excludeDefault: true,
      inPath: true,
    }),
    true,
  )
