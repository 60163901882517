import type {PayloadAction} from '@reduxjs/toolkit'
import {createReducer} from '@reduxjs/toolkit'
import type * as Redux from 'redux'

import {keyValueFetchable} from '../../../../../../reducers/fetchable'
import {createCleanupRuleAction, updateCleanupRuleAction} from '../Rules.actions'
import type {Form} from '../Rules.types'
import {getKey} from '../Rules.utils'

const cleanupCreateRulesReducer = keyValueFetchable(
  arg => getKey(arg.holderNode),
  createCleanupRuleAction,
  null,
  () => 'create',
)
const cleanupUpdateRulesReducer = keyValueFetchable(
  arg => getKey(arg.holderNode),
  updateCleanupRuleAction,
  null,
  () => 'update',
)
const reducer: Redux.Reducer<Form, PayloadAction<unknown>> = createReducer({}, builder => {
  builder.addMatcher(
    action => action.type.startsWith(createCleanupRuleAction.typePrefix),
    cleanupCreateRulesReducer,
  )
  builder.addMatcher(
    action => action.type.startsWith(updateCleanupRuleAction.typePrefix),
    cleanupUpdateRulesReducer,
  )
})
export default reducer
