import type {$Values} from 'utility-types'

import {stringifyId} from '../types'
import type {Id} from '../types'

export const Namespaces = {
  CHANGES: 'changes' as 'changes',
  ARTIFACTS: 'artifacts' as 'artifacts',
  AGENTS: 'agents' as 'agents',
  HAS_DEPENDANTS: 'has-dependants' as 'has-dependants',
  TEST_OCCURRENCES: 'test-occurrences' as 'test-occurrences',
  HTML: 'html' as 'html',
  CHANGE_STATUS: 'change-status' as 'change-status',
  CHANGE_BRANCHES: 'change-branches' as 'change-branches',
}
export type NS = $Values<typeof Namespaces>
export const getEntityId = (ns: NS, id: Id): string => `${ns}:${stringifyId(id)}`
