import {useEffect} from 'react'

import {fetchCurrentUserData} from '../actions'
import {getCurrentUserId} from '../selectors'
import type {UserId} from '../types'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'

export default function useMyId(): UserId | null | undefined {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchCurrentUserData())
  }, [dispatch])
  return useAppSelector(getCurrentUserId)
}
