import type {GetProjectPermissionsApiArg} from '../services/rest'
import {ALL_PROJECTS, stringifyId} from '../types'
import type {AgentPoolId, Permission, PoolPermissions, ProjectId} from '../types'
import {keyValue} from '../utils/object'

import processResponse from './processResponse'
import request from './request'
import type {RestRequestOptions} from './request'

export const getProjectPermissionsArg = (
  permission: Permission,
  projectId: ProjectId,
): GetProjectPermissionsApiArg => ({
  locator: `${
    projectId !== ALL_PROJECTS ? `id:${stringifyId(projectId)},` : ''
  }userPermission:(permission:${permission},user:current)`,
  fields: 'project(id)',
  projectId,
  permission,
})

type PoolPermission = Readonly<{
  poolId: AgentPoolId
  canChangeStatus: boolean
  canAuthorized: boolean
}>

export const requestPoolPermissions: (
  arg0: string,
  arg1: RestRequestOptions | null | undefined,
) => Promise<{
  canChangeStatus: PoolPermissions
  canAuthorize: PoolPermissions
}> = (serverUrl, options) =>
  request(serverUrl, '?poolPermissions=true', options)
    .then<ReadonlyArray<PoolPermission>>(processResponse)
    .then(data =>
      data.reduce(
        (result, item) => ({
          canChangeStatus: {
            ...result.canChangeStatus,
            ...keyValue(item.poolId, item.canChangeStatus),
          },
          canAuthorize: {...result.canAuthorize, ...keyValue(item.poolId, item.canAuthorized)},
        }),
        {canChangeStatus: {}, canAuthorize: {}},
      ),
    )
