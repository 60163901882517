import type {ComponentType} from 'react'
import {connect} from 'react-redux'

import type {State} from '../../../reducers/types'
import {getBuildComment, getUserDisplayName} from '../../../selectors'
import type {BuildId} from '../../../types'

import BuildCommentByIcon from './BuildCommentByIcon'

type Props = {
  buildId: BuildId
  className?: string
  inline?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
  const {text, buildTypeId, user, timestamp} = getBuildComment(state, props.buildId) || {}
  return {
    user: getUserDisplayName(state, user, true),
    text,
    timestamp,
    buildTypeId,
  }
}

export default connect(mapStateToProps)(BuildCommentByIcon) as ComponentType<Props>
