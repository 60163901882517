import {parameterGroups} from '../Parameters.slice'

export const toParametersGroupState = (
  persistentState?: ReturnType<typeof parameterGroups.getInitialState>,
) => ({
  expanded: {
    ...parameterGroups.getInitialState().expanded,
    ...persistentState?.expanded,
  },
})
