import {createSelector} from 'reselect'

import type {State} from '../../../../reducers/types'
import type {Project} from '../../../../services/rest'
import {hasPipelineHeadParameter} from '../EditPipelinePage/EditPipelinePage.utils'

export const getPipelineHead = (data: Project | undefined) =>
  data?.buildTypes?.buildType?.find(hasPipelineHeadParameter)

export const getPipelineHeadId = (data: Project | undefined) => getPipelineHead(data)?.id

export const getPipelinesStateToPersist = createSelector(
  (state: State) => state.pipelines.pipelineDraft,
  (state: State) => state.pipelines.collapsedBlocks,
  (pipelineDraft, collapsedBlocks) => ({
    pipelineDraft,
    collapsedBlocks,
  }),
)
