import {castDraft} from 'immer'

import {createReducer} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {restApi} from '../../../services/rest'

import type {KeyValue} from '../../../utils/object'

import type {BuildProblemsState, ProblemOccurrencesSubtree} from './BuildProblems.types'

const problemsReducer = Redux.combineReducers<BuildProblemsState>({
  problemOccurrencesSubtree: createReducer<
    KeyValue<string, KeyValue<string, ProblemOccurrencesSubtree>>
  >({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getProblemOccurrenceTree.matchFulfilled,
      (state, action) => {
        const {treeLocator, subTreeKey, depth} = action.meta.arg.originalArgs
        if (treeLocator != null && subTreeKey != null) {
          state[treeLocator] ??= {}
          state[treeLocator]![subTreeKey] = {depth, data: castDraft(action.payload.tree)}
        }
      },
    )
  }),
})
export default problemsReducer
