import {matchPath} from 'react-router-dom'

import Routes, {getAgentsOverviewHref, getBaseRoute} from '../../../routes'
import {getLastAgentsPage} from '../../../utils/lastPage'
import {objectValues} from '../../../utils/object'
import Redirect from '../../App/Redirect/Redirect'

export default function AgentsPages() {
  const lastAgentsPage = getLastAgentsPage()

  if (
    lastAgentsPage == null ||
    objectValues(Routes).find(route => route !== Routes.BASE && matchPath(route, lastAgentsPage)) ==
      null
  ) {
    return <Redirect to={getAgentsOverviewHref()} replace />
  }

  return <Redirect to={getBaseRoute(lastAgentsPage)} replace />
}
