import {createAction} from '@reduxjs/toolkit'

import type HTTPError from '../../../rest/errors/HTTPError'

import {filedErrors} from './ErrorAlerts.slices'

export const errorAction = createAction('errorAction', (error: HTTPError) => ({
  payload: null,
  error,
}))
export const closeErrorAlert = filedErrors.actions.close
