import memoize from '@jetbrains/ring-ui/components/global/memoize'

import type {Branch} from '../services/rest'
import {stringifyId} from '../types'
import type {ProjectId} from '../types'
import {BS} from '../types/BS_types'

export const DEFAULT_NAME = '<default>'
export const defaultBranch: Branch = {
  default: true,
  name: DEFAULT_NAME,
}
export const stringifyBranch = (branch: Branch | null | undefined): string => {
  if (!branch) {
    return ''
  }

  return BS?.Branch
    ? BS.Branch.stringifyBranch(branch)
    : branch.default === true
    ? DEFAULT_NAME
    : branch.name ?? ''
}

const memoizedParse: (arg0: string | null | undefined) => Branch | null = memoize(
  (name: string | null | undefined) =>
    BS?.Branch
      ? BS.Branch.parseBranch(name)
      : name != null
      ? {
          name,
          default: name === DEFAULT_NAME,
        }
      : null,
)
export const parseBranch = (name: string | null | undefined): Branch | null =>
  name != null ? memoizedParse(name) : null
export const getBranchParam = (projectId: ProjectId | null | undefined): string =>
  projectId ? `branch_${stringifyId(projectId)}` : 'branch'
