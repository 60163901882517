import {createSelector} from 'reselect'

import type {State} from '../../../reducers/types'
import {stringifyId} from '../../../types'
import type {BuildId, Fetchable} from '../../../types'
import {emptyArray} from '../../../utils/empty'

import type {
  CompareBuildsList,
  CompareBuildsInfo,
  CompareBuildsLine,
  CompareBuildsInfoGroup,
  BuildForCompareData,
  CompareBuildsState,
} from './CompareBuildsPage.types'

export const getShowChangedOnly: (arg0: State) => boolean = state =>
  state.compareBuilds.settings.showChangedOnly
export const getCompareBuildsList: (
  arg0: State,
  arg1: BuildId | null | undefined,
  arg2: BuildId | null | undefined,
) => Fetchable<CompareBuildsList> | null | undefined = (state, sourceId, compareWithId) =>
  sourceId != null && compareWithId != null
    ? state.compareBuilds.lists[`${stringifyId(sourceId)}:${stringifyId(compareWithId)}`]
    : null
export const getCompareBuildsInfo: (
  arg0: State,
  arg1: string,
  arg2: BuildId | null | undefined,
  arg3: BuildId | null | undefined,
) => Fetchable<CompareBuildsInfo> | null | undefined = (
  state,
  compareType,
  sourceId,
  compareWithId,
) =>
  sourceId != null && compareWithId != null
    ? state.compareBuilds.info[
        `${stringifyId(sourceId)}:${stringifyId(compareWithId)}:${compareType}`
      ]
    : null

const getCompareBuildsInfoData: (
  arg0: State,
  arg1: string,
  arg2: BuildId | null | undefined,
  arg3: BuildId | null | undefined,
) => CompareBuildsInfo | null | undefined = (state, compareType, sourceId, compareWithId) => {
  const info = getCompareBuildsInfo(state, compareType, sourceId, compareWithId)
  return info?.data
}

const processGroups: (
  arg0: ReadonlyArray<CompareBuildsInfoGroup>,
  arg1: number,
) => ReadonlyArray<CompareBuildsLine> = (groups, level) =>
  groups.reduce((acc: Array<CompareBuildsLine>, group: CompareBuildsInfoGroup) => {
    if (group.name != null && group.name.length > 0) {
      if (groups.length > 1 || level > 0 || (group.groups != null && group.groups.length > 0)) {
        acc.push({
          type: 'title',
          name: group.name,
          hasChildren:
            (group.groups != null && group.groups.length > 0) ||
            (group.rows != null && group.rows.length > 0),
          level,
        })
      }
    }

    if (group.groups != null && group.groups.length > 0) {
      acc.push(...processGroups(group.groups, level + 1))
    }

    if (group.rows != null && group.rows.length > 0) {
      acc.push(...group.rows)
    }

    return acc
  }, [])

export const getCompareBuildsInfoLines: (
  arg0: State,
  arg1: string,
  arg2: BuildId | null | undefined,
  arg3: BuildId | null | undefined,
) => ReadonlyArray<CompareBuildsLine> = createSelector(
  getCompareBuildsInfoData,
  (info: CompareBuildsInfo | null | undefined) =>
    info == null || info.groups == null || info.groups.length === 0
      ? emptyArray
      : processGroups(info.groups, 0),
)
export const getBuildsForCompareData = (
  state: State,
  buildId: BuildId,
): Fetchable<BuildForCompareData> | null | undefined => state.compareBuilds.builds[buildId]
export const getCompareBuildsStateToPersist: (arg0: State) => CompareBuildsState = createSelector(
  (state: State) => state.compareBuilds.settings,
  settings => ({
    lists: {},
    info: {},
    builds: {},
    settings,
  }),
)
