import type {State} from '../../../reducers/types'

import {PagerGroup} from './Pager.types'
import type {Pager} from './Pager.types'

export const getPager: (state: State, group: PagerGroup) => Pager = (state, group) =>
  state.pager[group]
export const getCurrentPage: (arg0: State, arg1: PagerGroup) => number = (state, group) =>
  getPager(state, group).currentPage || 1
export const getCurrentPageForBuildGroup: (arg0: State) => number = state =>
  getCurrentPage(state, PagerGroup.BUILD) //TODO remove it when the QuerySyncContainer is reworked.
