import ordinal from 'ordinal'

import type {State} from '../../../reducers/types'
import {getBuild} from '../../../selectors'
import type {BuildId} from '../../../types'

import {useAppSelector} from 'src/hooks/react-redux'

type FormatBuildNumberInput = {
  number: string | null | undefined
  isQueued?: boolean
  queuePosition?: number | null | undefined
}
type FormatBuildNumberOutput = {
  text: string
  title?: string
}
export function formatBuildNumber({
  number,
  isQueued,
  queuePosition,
}: FormatBuildNumberInput): FormatBuildNumberOutput {
  if (number != null) {
    return {
      text: `${number !== 'N/A' ? '#' : ''}${number}`,
      title: `Build number: ${number}`,
    }
  } else if (isQueued && queuePosition != null) {
    return {
      text: ordinal(queuePosition),
      title: `Queue position: ${queuePosition}`,
    }
  } else {
    return {
      text: 'Not defined',
    }
  }
}

function getBuildNumber(state: State, id: BuildId | null | undefined) {
  const {number, queuePosition, state: buildState} = getBuild(state, id) ?? {}
  return formatBuildNumber({
    number,
    isQueued: buildState === 'queued',
    queuePosition,
  })
}

export const useBuildNumberText = (id: BuildId | null | undefined): string =>
  useAppSelector(state => getBuildNumber(state, id).text)
