import processResponse from '../../../../../../rest/processResponse'
import request from '../../../../../../rest/request'
import type {BuildTypeHierarchy} from '../../../../../../types'

export const requestChangeBuildTypeHierarchy = (
  serverUrl: string,
  locator: string,
): Promise<BuildTypeHierarchy> =>
  request(serverUrl, `app/changeFilesByBuildTypeHierarchy?${locator}`).then<BuildTypeHierarchy>(
    processResponse,
  )
