import {createAction} from '@reduxjs/toolkit'

import type {Pager} from './Pager.types'
import {PagerGroup} from './Pager.types'

export const submitPager = createAction(
  'submitPager',
  (pager: Partial<Pager>, group: PagerGroup = PagerGroup.BUILD) => ({
    payload: pager,
    meta: {group},
  }),
)
