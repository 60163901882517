import {castDraft} from 'immer'

import {createReducer} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {keyValueFetchable} from '../../../reducers/fetchable'
import {emptyArray, emptyArrayFetchable} from '../../../utils/empty'
import type {KeyValue} from '../../../utils/object'
import stableSort from '../../../utils/stableSort'

import {fetchBuildLogMessagesAction, fetchBuildLogTimelineAction} from './BuildLog.actions'
import {settings} from './BuildLog.slices'
import type {
  BuildLogKey,
  BuildLogTimeline,
  FetchMessagesParams,
  LastMessageIncludedState,
  MessagesLoadState,
  MessagesState,
  TestAnchorsState,
  BuildLogMessage,
  BuildLogState,
} from './BuildLog.types'
import {processMessage} from './BuildLog.utils'
import {searchStates} from './FullBuildLog/BuildLogHeader/BuildLogSearch/BuildLogSearch.reducers'
import {updateFullLogState} from './FullBuildLog/FullBuildLog.actions'
import {fullLogStates} from './FullBuildLog/FullBuildLog.reducers'

const buildLogMessagesReducer = keyValueFetchable(
  arg => arg.buildLogKey,
  fetchBuildLogMessagesAction,
  emptyArray,
  (state, action) => {
    const newMessages: ReadonlyArray<BuildLogMessage> | null | undefined =
      action.payload.data.messages

    if (action.meta.arg.mergeData !== true) {
      return newMessages != null
        ? newMessages.filter((message: BuildLogMessage, index: number) => {
            processMessage(message)

            if (
              index === newMessages.length - 1 &&
              action.payload.data.lastMessageIncluded === true
            ) {
              message.isLast = true
            }

            return message.isBroken !== true
          })
        : emptyArray
    }

    // TODO: need to extract this function to util and write unit tests
    if (newMessages == null || newMessages.length === 0) {
      return state
    }

    // TODO: possibly can be optimized to use one loop for filter existing and sorting
    const firstInsertIndex: number = newMessages[0].id
    const lastInsertIndex: number = newMessages[newMessages.length - 1].id
    const firstExistIndex: number | null | undefined = state[0]?.id
    const lastExistIndex: number | null | undefined = state[state.length - 1]?.id
    let holeExists =
      firstExistIndex != null &&
      lastExistIndex != null &&
      firstInsertIndex > firstExistIndex &&
      lastInsertIndex < lastExistIndex
    const exist = new Set()
    const result = newMessages.concat(state).filter((message: BuildLogMessage, index: number) => {
      processMessage(message)

      if (message.isBroken === true) {
        return false
      }

      if (index === newMessages.length - 1 && action.payload.data.lastMessageIncluded === true) {
        message.isLast = true
      }

      if (exist.has(message.id)) {
        if (holeExists && message.id === lastInsertIndex) {
          holeExists = false
        }

        return false
      }

      exist.add(message.id)
      return true
    })

    if (holeExists) {
      newMessages[newMessages.length - 1].nextIsHole = true
    }

    return stableSort(result, (a: BuildLogMessage, b: BuildLogMessage) => a.id - b.id)
  },
)

export const buildLogReducers = Redux.combineReducers<BuildLogState>({
  messages: createReducer<MessagesState>({}, builder => {
    builder.addCase(updateFullLogState, (state, action) => {
      const {target, buildId} = action.payload
      // eslint-disable-next-line eqeqeq
      if (buildId === null) {
        // reset messages on close full build log
        state[target] = castDraft(emptyArrayFetchable)
      }
    })
    builder.addMatcher(
      action => action.type.startsWith(fetchBuildLogMessagesAction.typePrefix),
      buildLogMessagesReducer,
    )
  }),
  messagesLoadStates: createReducer<KeyValue<BuildLogKey, MessagesLoadState>>({}, builder => {
    const getKey = (arg: FetchMessagesParams) =>
      arg.options?.target ?? arg.options?.logAnchor?.toString()
    builder.addCase(fetchBuildLogMessagesAction.pending, (state, action) => {
      const key = getKey(action.meta.arg)
      if (key == null) {
        return
      }
      state[action.meta.arg.buildLogKey] ??= {}
      state[action.meta.arg.buildLogKey]![key] = {
        loading: true,
        lastLoadedTime:
          action.meta.invalidate === true
            ? null
            : state[action.meta.arg.buildLogKey]![key]?.lastLoadedTime,
      }
    })
    builder.addCase(fetchBuildLogMessagesAction.fulfilled, (state, action) => {
      const key = getKey(action.meta.arg)
      if (key == null) {
        return
      }
      state[action.meta.arg.buildLogKey] ??= {}
      state[action.meta.arg.buildLogKey]![key] = {
        loading: false,
        lastLoadedTime: Date.now(),
      }
    })
    builder.addCase(fetchBuildLogMessagesAction.rejected, (state, action) => {
      const key = getKey(action.meta.arg)
      if (key == null) {
        return
      }
      state[action.meta.arg.buildLogKey] ??= {}
      state[action.meta.arg.buildLogKey]![key] = {
        loading: false,
        lastLoadedTime: Date.now(),
      }
    })
  }),
  timelines: keyValueFetchable(
    arg => String(arg.buildId),
    fetchBuildLogTimelineAction,
    null as BuildLogTimeline | null,
    (_, action) => action.payload,
  ),
  testAnchors: createReducer<TestAnchorsState>({}, builder => {
    builder.addCase(fetchBuildLogMessagesAction.fulfilled, (state, action) => {
      const {testTarget} = action.payload.data
      const current = state[action.meta.arg.buildLogKey]
      if (current != null) {
        Object.assign(current, testTarget)
      } else if (testTarget != null) {
        state[action.meta.arg.buildLogKey] = castDraft(testTarget)
      }
    })
  }),
  lastMessageIncluded: createReducer<LastMessageIncludedState>({}, builder => {
    builder.addCase(fetchBuildLogMessagesAction.fulfilled, (state, action) => {
      const {lastMessageIncluded} = action.payload.data
      if (lastMessageIncluded != null) {
        state[action.meta.arg.buildLogKey] = lastMessageIncluded
      }
    })
  }),
  fullLogStates,
  settings: settings.reducer,
  searchStates,
})
