import type {PayloadAction} from '@reduxjs/toolkit'
import {createAction, createSlice, prepareAutoBatched} from '@reduxjs/toolkit'

import type {StartQueryActionCreatorOptions} from '@reduxjs/toolkit/dist/query/core/buildInitiate'

import {getPager} from '../components/common/Pager/Pager.selectors'
import type {Pager} from '../components/common/Pager/Pager.types'
import {PagerGroup} from '../components/common/Pager/Pager.types'
import {getLastPageToCount} from '../components/common/Pager/Pager.utils'
import {createFetchAction} from '../reducers/fetchable'
import {
  getBuildsArg,
  getBuildsCountArg,
  getBuildsDetailsArg,
  requestHasBuilds,
} from '../rest/builds'
import {restRoot} from '../rest/consts'
import {getBuildLocator} from '../rest/locators'
import type {RestRequestOptions} from '../rest/request'
import type {NormalizedBuilds} from '../rest/schemata'
import {
  normalizeBuild,
  normalizeBuildChanges,
  normalizeBuilds,
  normalizeCompatibleAgents,
} from '../rest/schemata'
import {getBuildsLocatorWithPage} from '../selectors'
import type {Build} from '../services/rest'
import {restApi} from '../services/rest'
import type {BuildId, NormalizedBuild, RequestOptionsParams} from '../types'
import {
  ActionThrottlerWithArrayCollect,
  ActionThrottlerWithObjectCollect,
} from '../utils/actionThrottler'
import type {KeyValue} from '../utils/object'
import {keyValue} from '../utils/object'
import type {Refetchable} from '../utils/refetchable'
import {combineRefetchables} from '../utils/refetchable'
import type {Unsubscribe} from '../utils/subscriber'
import {subscribeOnRunningBuild} from '../utils/subscriber'

import {buildDetailsQueue} from './buildDetailsQueue'
import type {AppThunk} from './types'

type ReceiveBuildThrottledParams = {
  withBuildTypes?: boolean
  request?: Promise<unknown>
}

export const receiveBuildAction = createAction(
  'receiveBuild',
  (data: ReadonlyArray<Build>, locators: string[], params?: ReceiveBuildThrottledParams) => ({
    payload: normalizeBuilds(data),
    meta: {arg: {originalArgs: {locators, ...params}}},
  }),
)

export const receiveBuild = (data: Build, locator: string = getBuildLocator(data.id)) =>
  receiveBuildAction([data], [locator])

const receiveBuildThrottled =
  (receiveBuildData: ReadonlyArray<Build>, params?: ReceiveBuildThrottledParams): AppThunk<any> =>
  dispatch => {
    if (receiveBuildData.length) {
      dispatch(
        receiveBuildAction(
          receiveBuildData,
          receiveBuildData.map(item => getBuildLocator(item.id)),
          params,
        ),
      )
    }
  }

const receiveBuildWithThrottle = new ActionThrottlerWithArrayCollect(
  builds =>
    receiveBuildThrottled(builds, {
      withBuildTypes: true,
    }),
  [],
)
export const multiplyReceiveBuild = (data: Build): AppThunk<any> =>
  receiveBuildWithThrottle.fetch([data])

export const receiveBuildWSDataAction =
  createAction<KeyValue<BuildId, NormalizedBuild | null>>('receiveBuildWSData')

const receiveBuildWSDataWithThrottle = new ActionThrottlerWithObjectCollect(
  receiveBuildWSDataAction,
  {},
)
export const receiveBuildWSData = (data: NormalizedBuild): AppThunk<any> =>
  receiveBuildWSDataWithThrottle.fetch(keyValue(data.id, data))
export const subscribeOnBuild =
  (buildId: BuildId): AppThunk<Unsubscribe> =>
  dispatch =>
    subscribeOnRunningBuild(buildId, data => {
      if (data == null) {
        return
      }

      const buildsWSData: KeyValue<BuildId, NormalizedBuild | null> | null | undefined =
        normalizeBuild(data).entities.builds

      if (buildsWSData != null) {
        dispatch(receiveBuildWSDataWithThrottle.fetch(buildsWSData))
      }
    })
export const compatibleAgents = createSlice({
  name: 'compatibleAgents',
  initialState: {},
  reducers: {
    receive: {
      reducer(state, action: PayloadAction<NormalizedBuilds>) {
        Object.assign(state, action.payload.entities.compatibleAgents)
      },
      prepare: prepareAutoBatched<NormalizedBuilds>(),
    },
  },
  extraReducers: builder => {
    builder.addCase(receiveBuildWSDataAction, (state, action) => {
      Object.assign(state, action.payload)
    })
  },
})
export const receiveCompatibleAgents = (data: ReadonlyArray<Build>) =>
  compatibleAgents.actions.receive(normalizeCompatibleAgents(data))
export const receiveBuildChanges = createAction(
  'receiveBuildChanges',
  (data: ReadonlyArray<Build>) =>
    prepareAutoBatched<NormalizedBuilds>()(normalizeBuildChanges(data)),
)
export const receiveBuildsDetails =
  (data: ReadonlyArray<Build>): AppThunk<any> =>
  dispatch => {
    dispatch(receiveBuildChanges(data))
    dispatch(receiveCompatibleAgents(data))
  }
export const stopFetching = (queueEntryId: string): void => buildDetailsQueue.cancel(queueEntryId)

const fetchBuildsDetails =
  (
    locatorToStore: string,
    locatorToFetch: string,
    initiateOptions: StartQueryActionCreatorOptions,
    requestOptions: RequestOptionsParams | null | undefined,
  ): AppThunk<Refetchable> =>
  dispatch => {
    const throttler = new ActionThrottlerWithArrayCollect(receiveBuildsDetails, [])
    return dispatch(
      restApi.endpoints.getAllBuildsDetailsNormalized.initiate(
        getBuildsDetailsArg(locatorToStore, locatorToFetch, requestOptions, build =>
          dispatch(throttler.fetch([build])),
        ),
        initiateOptions,
      ),
    )
  }

type FetchBuildsPageDataParams = {
  locator: string
  pager: Pager
  requestOptions?: RequestOptionsParams
  inBackground?: boolean
}

export const DEFAULT_LOOKUP_LIMIT = 10000

type FetchBuildDataArg = {
  locator: string
  requestOptions?: RequestOptionsParams
  onProgress?: (build: Build) => unknown
}
const fetchBuildsAction = (
  args: FetchBuildDataArg,
  initiateOptions: StartQueryActionCreatorOptions,
) => {
  const {locator, requestOptions, onProgress} = args
  return restApi.endpoints.getAllBuildsNormalized.initiate(
    getBuildsArg(locator, requestOptions, onProgress),
    initiateOptions,
  )
}

const fetchBuildsAllData =
  (
    locator: string,
    requestOptions: RequestOptionsParams = {},
    inBackground: boolean = false,
    initiateOptions: StartQueryActionCreatorOptions,
  ): AppThunk<Refetchable> =>
  (dispatch, getState) => {
    const {fetchCount} = requestOptions
    const state = getState()
    const refetchables: Refetchable[] = []
    if (fetchCount != null) {
      const pager = getPager(state, PagerGroup.BUILD)
      refetchables.push(
        dispatch(
          restApi.endpoints.getAllBuilds.initiate(
            getBuildsCountArg(
              locator,
              fetchCount,
              true,
              pager.lookupLimit ?? DEFAULT_LOOKUP_LIMIT,
              requestOptions,
              inBackground,
            ),
            initiateOptions,
          ),
        ),
      )
    }
    refetchables.push(
      dispatch(
        fetchBuildsDetails(locator, locator, initiateOptions, {
          ...requestOptions,
          essential: false,
        }),
      ),
    )
    const throttler = new ActionThrottlerWithArrayCollect(
      builds =>
        receiveBuildThrottled(builds, {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          request,
        }),
      [],
    )
    const request = dispatch(
      fetchBuildsAction(
        {
          locator,
          requestOptions,
          onProgress: build => {
            if (!inBackground && requestOptions.withProgress !== false) {
              dispatch(throttler.fetch([build]))
            }
          },
        },
        initiateOptions,
      ),
    )
    refetchables.push(request)
    return combineRefetchables(refetchables)
  }

const fetchBuildsPageData =
  (
    params: FetchBuildsPageDataParams,
    initiateOptions: StartQueryActionCreatorOptions,
  ): AppThunk<Refetchable> =>
  (dispatch, getState) => {
    const {locator, pager, requestOptions, inBackground = false} = params
    const {pageSize} = pager
    const locatorToStore = locator
    const state = getState()
    const lastPageToCount = getLastPageToCount(pager)
    const upperLimit = lastPageToCount * pageSize + 1
    const locatorToQuery = getBuildsLocatorWithPage(state, locator)

    return combineRefetchables([
      dispatch(
        restApi.endpoints.getAllBuilds.initiate(
          getBuildsCountArg(
            locator,
            upperLimit,
            false,
            pager.lookupLimit ?? DEFAULT_LOOKUP_LIMIT,
            requestOptions,
            inBackground,
          ),
          initiateOptions,
        ),
      ),
      dispatch(
        fetchBuildsDetails(locatorToStore, locatorToQuery, initiateOptions, {
          ...requestOptions,
          essential: false,
        }),
      ),
      dispatch(
        fetchBuildsAction(
          {
            locator: locatorToQuery,
            requestOptions,
          },
          initiateOptions,
        ),
      ),
    ])
  }

export type FetchBuildDataParams = FetchBuildDataArg & {
  inBackground?: boolean
  forceRefetch?: boolean
  withPager?: boolean
}
export const fetchBuildsData =
  ({
    locator,
    withPager = false,
    requestOptions,
    inBackground = false,
    forceRefetch = false,
  }: FetchBuildDataParams): AppThunk<Refetchable> =>
  (dispatch, getState) => {
    const state = getState()
    const pager = getPager(state, PagerGroup.BUILD)
    const initiateOptions = {forceRefetch}
    return withPager
      ? dispatch(
          fetchBuildsPageData(
            {
              locator,
              pager,
              requestOptions,
              inBackground,
            },
            initiateOptions,
          ),
        )
      : dispatch(fetchBuildsAllData(locator, requestOptions, inBackground, initiateOptions))
  }
type FetchHasBuildsArg = {
  locator: string
  restOptions?: RestRequestOptions
}
export const fetchHasBuildsAction = createFetchAction(
  'fetchHasBuilds',
  ({locator, restOptions}: FetchHasBuildsArg) => requestHasBuilds(restRoot, locator, restOptions),
)
export const fetchHasBuilds = (locator: string, restOptions?: RestRequestOptions) =>
  fetchHasBuildsAction({locator, restOptions})
