export function performanceCallback<A extends unknown[], R>(mark: string, fn: (...args: A) => R) {
  return (...args: A) => {
    const startMark = `${mark}-start`
    const endMark = `${mark}-end`

    performance.mark(startMark)
    const result = fn(...args)
    performance.mark(endMark)

    performance.measure(mark, {
      start: startMark,
      end: endMark,
    })

    return result
  }
}
