import type {SWGenerator, EmptySWResponse, SWGeneratorReturn} from '../types/generators'

function noop() {}

type ProcessServiceWorkerResponseProps<T, F> = {
  iterator: SWGenerator<T, F>
  onStart?: () => unknown
  onSuccess: (arg0: T) => unknown
  onError?: (arg0: Error) => unknown
  onFinal?: () => unknown
  skipWaiting?: boolean
}

export const checkIfNonValidResponse = <K>(event: SWGeneratorReturn<K>): event is EmptySWResponse =>
  event &&
  (event as EmptySWResponse).meta &&
  ((event as EmptySWResponse).meta.NO_NEW_DATA === true ||
    (event as EmptySWResponse).meta.timeout === true)

export async function processServiceWorkerResponse<T, F>({
  iterator,
  onStart = noop,
  onSuccess = noop,
  onError = noop,
  onFinal = noop,
}: ProcessServiceWorkerResponseProps<T, F>) {
  onStart !== noop && onStart()

  try {
    const cached = await iterator.next()

    // The first response is always fullfilled either by Cache or by Request
    {
      const {value, done} = cached

      if (!checkIfNonValidResponse<T>(value)) {
        onSuccess(value.payload)
      }

      if (done) {
        return
      }
    }

    // The second request can be received from the CacheUpdates
    {
      const {value, done} = await iterator.next()

      if (done && !checkIfNonValidResponse<T>(value)) {
        onSuccess(value.payload)
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      onError(error)
    }
  } finally {
    onFinal !== noop && onFinal()
  }
}
