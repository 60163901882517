import {createAction} from '@reduxjs/toolkit'

import {fetchTabs} from '../../../actions'
import {removeAgent} from '../../../actions/fetch'
import type {AppThunk} from '../../../actions/types'
import {getAgentPoolsArg} from '../../../rest/agentPools'
import {getAgentPreviewsArg, getSingleAgentArg, requestAgentExists} from '../../../rest/agents'
import {restRoot} from '../../../rest/consts'
import {restApi} from '../../../services/rest'
import type {AgentId, AgentRequestOptions} from '../../../types'
import {internalProps} from '../../../types/BS_types'
import {createRefetchableSubscription} from '../../../utils/refetchable'
import type {Unsubscribe} from '../../../utils/subscriber'
import {
  getTopics,
  subscribeOnAgentEvents,
  subscribeOnOverallEvents,
} from '../../../utils/subscriber'
import * as SubscriptionEvents from '../../../utils/subscriptionEvents'
import {getTabParamsKey} from '../../../utils/tabs'

export const fetchAgentPoolsData = () =>
  restApi.endpoints.getAllAgentPoolsNormalized.initiate(getAgentPoolsArg())
const FREQUENT_AGENT_EVENT_TYPES = [
  SubscriptionEvents.AGENT_PARAMETERS_UPDATED,
  SubscriptionEvents.BUILD_STARTED,
  SubscriptionEvents.BUILD_FINISHED,
]
const AGENT_TYPE_EVENT_TYPES = [
  SubscriptionEvents.AGENT_REGISTERED,
  SubscriptionEvents.AGENT_REMOVED,
  SubscriptionEvents.AGENT_UNREGISTERED,
  SubscriptionEvents.AGENT_POOL_TOUCHED,
]
const REGULAR_AGENT_EVENT_TYPES = [
  ...AGENT_TYPE_EVENT_TYPES,
  SubscriptionEvents.AGENT_STATUS_CHANGED,
]
const AGENT_EVENT_TYPES = [...FREQUENT_AGENT_EVENT_TYPES, ...REGULAR_AGENT_EVENT_TYPES]
const FREQUENT_OVERALL_AGENT_EVENT_TOPICS = getTopics('', FREQUENT_AGENT_EVENT_TYPES)
export const REGULAR_OVERALL_AGENT_EVENT_TOPICS = getTopics('', REGULAR_AGENT_EVENT_TYPES)
export const OVERALL_AGENT_POOL_EVENT_TOPICS = getTopics('', [
  SubscriptionEvents.AGENT_POOL_TOUCHED,
])
export const OVERALL_PROJECT_EVENT_TOPICS = getTopics('', [
  SubscriptionEvents.PROJECT_RESTORED,
  SubscriptionEvents.PROJECT_PERSISTED,
  SubscriptionEvents.PROJECT_REMOVED,
  SubscriptionEvents.PROJECT_CREATED,
  SubscriptionEvents.PROJECT_ARCHIVED,
  SubscriptionEvents.PROJECT_DEARCHIVED,
  SubscriptionEvents.PROJECT_UPDATED,
])

const AGENT_PREVIEWS_UPDATE_TIMEOUT = Number(
  internalProps['teamcity.ui.agentPreviewsUpdateTimeout'],
)
const AGENT_DATA_UPDATE_TIMEOUT = Number(internalProps['teamcity.ui.agentDataUpdateTimeout'])
export const subscribeOnAgents = (): AppThunk<any> => dispatch => {
  const fetchAgentPreviewsData = () =>
    dispatch(restApi.endpoints.getAllAgentPreviewsNormalized.initiate(getAgentPreviewsArg()))
  const unsubscribeFromAgents = createRefetchableSubscription(fetchAgentPreviewsData, handler =>
    subscribeOnOverallEvents(
      REGULAR_OVERALL_AGENT_EVENT_TOPICS,
      handler,
      AGENT_DATA_UPDATE_TIMEOUT,
    ),
  )
  const unsubscribeFromFrequentAgentEvents = createRefetchableSubscription(
    fetchAgentPreviewsData,
    handler =>
      subscribeOnOverallEvents(
        FREQUENT_OVERALL_AGENT_EVENT_TOPICS,
        handler,
        AGENT_PREVIEWS_UPDATE_TIMEOUT,
      ),
  )
  return () => {
    unsubscribeFromAgents()
    unsubscribeFromFrequentAgentEvents()
  }
}

export const subscribeOnAgentPools = (): AppThunk<any> => dispatch =>
  createRefetchableSubscription(
    () => dispatch(fetchAgentPoolsData()),
    handler => subscribeOnOverallEvents(OVERALL_AGENT_POOL_EVENT_TOPICS, handler),
  )

export const subscribeOnAgent =
  (agentId: AgentId, requestOptions: AgentRequestOptions = {}): AppThunk<Unsubscribe> =>
  dispatch =>
    createRefetchableSubscription(
      () =>
        dispatch(
          restApi.endpoints.getAgentNormalized.initiate(getSingleAgentArg(agentId, requestOptions)),
        ),
      handler =>
        subscribeOnAgentEvents(
          agentId,
          AGENT_EVENT_TYPES.filter(
            eventType =>
              ![SubscriptionEvents.AGENT_REMOVED, SubscriptionEvents.AGENT_UNREGISTERED].includes(
                eventType,
              ),
          ),
          () => {
            handler()
            if (requestOptions?.tabs) {
              dispatch(fetchTabs(getTabParamsKey({agentId}), {essential: true}, false))
            }
          },
        ),
    )

export const markAgentDeleted = createAction<AgentId>('markAgentDeleted')
export const subscribeOnAgentRemoval =
  (agentId: AgentId, preserveAfterDelete: boolean = false): AppThunk<Unsubscribe> =>
  dispatch =>
    subscribeOnAgentEvents(agentId, [SubscriptionEvents.AGENT_REMOVED], () => {
      requestAgentExists(restRoot, agentId).catch(() => {
        if (preserveAfterDelete) {
          dispatch(markAgentDeleted(agentId))
        } else {
          dispatch(removeAgent(agentId))
        }
      })
    })
