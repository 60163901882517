import {combineReducers} from 'redux'

import {keyValueFetchable} from '../../../../../reducers/fetchable'
import type {NormalizedChange} from '../../../../../types'
import {stringifyId} from '../../../../../types'

import {fetchPersonalChangeAction} from './BuildStatusTooltip.actions'
import type {BuildStatusTooltipState} from './BuildStatusTooltip.types'

const buildStatusTooltip = combineReducers<BuildStatusTooltipState>({
  changes: keyValueFetchable(
    changeId => stringifyId(changeId),
    fetchPersonalChangeAction,
    null as NormalizedChange | null,
    (state, action) => action.payload,
  ),
})
export default buildStatusTooltip
