import {createSelector} from 'reselect'

import type {State} from '../../../reducers/types'
import type {NormalizedSingleBuild} from '../../../rest/schemata'
import type {
  BuildId,
  ChangeId,
  NormalizedChange,
  NormalizedVcsRootInstance,
  VcsRootId,
  VcsRootInstanceId,
  VcsRoot,
} from '../../../types'
import {emptyArray} from '../../../utils/empty'
import {notNull} from '../../../utils/guards'
import type {KeyValue} from '../../../utils/object'

const getChangesHash: (state: State) => KeyValue<ChangeId, NormalizedChange> = state =>
  state.entities.changes

export const getChange: (
  state: State,
  changeId: ChangeId | null | undefined,
) => NormalizedChange | null | undefined = (state, changeId) =>
  changeId != null ? getChangesHash(state)[changeId] : null

const getBuildFromNormalized = (data?: NormalizedSingleBuild) =>
  data?.entities.builds?.[data?.result]

export const getFirstBuildIdWithSameChanges = (data?: NormalizedSingleBuild) =>
  getBuildFromNormalized(data)?.firstBuildWithSameChanges

export const getBuildChangesCount = (data?: NormalizedSingleBuild) =>
  getBuildFromNormalized(data)?.changes?.change?.length ?? 0

export const getArtifactChangesCount = (data?: NormalizedSingleBuild) =>
  getBuildFromNormalized(data)?.artifactDependencyChanges?.count ?? 0

export const getBuildChangesIdsByBuildId: (
  state: State,
  buildId: BuildId,
) => ReadonlyArray<ChangeId> | null | undefined = (state, buildId) =>
  state.changesDropdown.buildChangesByBuildId[buildId]

export const getBuildChanges: (state: State, buildId: BuildId) => ReadonlyArray<NormalizedChange> =
  createSelector(
    [getBuildChangesIdsByBuildId, getChangesHash],
    (
      ids: ReadonlyArray<ChangeId> | null | undefined,
      changes: KeyValue<ChangeId, NormalizedChange>,
    ) => (ids != null ? ids.map((id: ChangeId) => changes[id]).filter(Boolean) : emptyArray),
  )

const getVcsRoots: (state: State) => KeyValue<VcsRootId, VcsRoot> = state => state.entities.vcsRoots

const getVcsRootInstances: (
  state: State,
) => KeyValue<VcsRootInstanceId, NormalizedVcsRootInstance> = state =>
  state.entities.vcsRootInstances

export const getVcsRoot: (
  state: State,
  vcsRootId: VcsRootId | null | undefined,
) => VcsRoot | null | undefined = (state, vcsRootId) =>
  vcsRootId != null ? getVcsRoots(state)[vcsRootId] : null

export const getVcsRootInstance: (
  state: State,
  vcsRootInstanceId: VcsRootInstanceId | null | undefined,
) => NormalizedVcsRootInstance | null | undefined = (state, vcsRootInstanceId) =>
  vcsRootInstanceId != null ? state.entities.vcsRootInstances[vcsRootInstanceId] : null

const getModificationsOfChange: (
  state: State,
  changeId: ChangeId | null | undefined,
) => ReadonlyArray<ChangeId> = (state, changeId) =>
  (changeId != null
    ? state.entities.modificationsOfChanges[changeId]?.mergedInfo?.changes?.change
    : null) ?? emptyArray

export const getUniqueVcsRootIds: (
  state: State,
  changeId: ChangeId | null | undefined,
) => ReadonlyArray<VcsRootId> = createSelector(
  [getModificationsOfChange, getChangesHash, getVcsRootInstances],
  (
    ids: ReadonlyArray<ChangeId> | null | undefined,
    changesHash: KeyValue<ChangeId, NormalizedChange>,
    vcsRootInstancesHash: KeyValue<VcsRootInstanceId, NormalizedVcsRootInstance>,
  ) => {
    if (ids == null) {
      return emptyArray
    }
    const changes = ids.map((id: ChangeId) => changesHash[id]).filter(notNull)
    const vcsRootIds = changes
      .map(({vcsRootInstance}: NormalizedChange) =>
        vcsRootInstance != null ? vcsRootInstancesHash[vcsRootInstance]?.['vcs-root'] : null,
      )
      .filter(notNull)

    return Array.from(new Set(vcsRootIds))
  },
)
