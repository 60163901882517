import * as React from 'react'

import {displayDate} from '../../../../utils/dateTimeUtils'

import {useTimeoutInfo} from '../BuildApproval.hooks'
import {ApprovalStatuses} from '../BuildApproval.types'

type OwnProps = {
  timeout: string
  status: ApprovalStatuses
}

export default function BuildApprovalStatus({timeout, status}: OwnProps) {
  const {secondsLeft, timeLeft} = useTimeoutInfo(timeout)
  const timeoutDate = displayDate(timeout, true, true)

  if (status === ApprovalStatuses.CANCELED) {
    return <span>{'Approval request is canceled'}</span>
  }

  if (secondsLeft == null || IS_STORYBOOK || status === ApprovalStatuses.APPROVED) {
    return null
  }

  if (secondsLeft <= 0) {
    return <span>{`Approval request timed out at ${timeoutDate}`}</span>
  }

  return <span>{`Request expires on ${timeoutDate} ${timeLeft ? `(${timeLeft})` : ''}`}</span>
}
