import {getChangesFields} from '../../../rest/arguments'
import type {GetAllChangesApiArg, GetChangeApiArg} from '../../../services/rest'

export const getModificationsOfChangeArg = (changeLocator: string): GetChangeApiArg => ({
  changeLocator,
  fields: `id,mergedInfo(changes(${getChangesFields()}))`,
})

export const getChangeStatusArg = (locator: string): GetAllChangesApiArg => ({
  locator,
  fields:
    'change(id,status(cancelledBuilds,pendingBuildTypes,runningSuccessfullyBuilds,successfulBuilds,compilationErrorBuilds(count),criticalBuilds(count),newTestsFailedBuilds(count),notCriticalBuilds(count)))',
  essential: true,
})

export const getChangeStatusBuildsArg = (locator: string): GetAllChangesApiArg => ({
  locator,
  fields:
    'change(id,status(criticalBuilds(build:(id,buildTypeId)),newTestsFailedBuilds(build:(id,buildTypeId))))',
})

export const getChangeBranchesArg = (locator: string): GetAllChangesApiArg => ({
  locator,
  fields: 'change(id,mergedInfo(branches(branch(name,default))))',
})
