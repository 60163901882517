import {requestBuildTypeIds} from '../../../../../rest/buildTypes'
import {getBranchLocator} from '../../../../../rest/locators'
import {stringifyId} from '../../../../../types'
import type {BuildId, BuildTypeId} from '../../../../../types'

import type {PossibleDeployment} from './Deployments.state.types'
import type {LastDeployedBuildConfig} from './Deployments.types'

const deployLocator = 'type:deployment,project(archived:false)'
export const getDeploymentsLocator = (buildId: BuildId): string =>
  `buildType(${deployLocator}),` +
  `item(defaultFilter:false,snapshotDependency(from:(id:${stringifyId(buildId)}))),` +
  `item(defaultFilter:false,artifactDependency(from:(id:${stringifyId(buildId)}),recursive:false))`
export async function requestPossibleDeployments(
  serverUrl: string,
  buildTypeId: BuildTypeId,
): Promise<ReadonlyArray<PossibleDeployment>> {
  const [snapshotDependantIds, artifactDependantIds] = await Promise.all([
    requestBuildTypeIds(
      serverUrl,
      `${deployLocator},snapshotDependency(from:(id:${stringifyId(buildTypeId)}))`,
    ),
    requestBuildTypeIds(
      serverUrl,
      `${deployLocator},artifactDependency(from:(id:${stringifyId(buildTypeId)}),recursive:false)`,
    ),
  ])
  return [
    ...snapshotDependantIds.map(id => ({
      id,
      artifactOnly: false,
    })),
    ...artifactDependantIds
      .filter(id => !snapshotDependantIds.includes(id))
      .map(id => ({
        id,
        artifactOnly: true,
      })),
  ]
}
export function getLastDeployedBuildLocator({
  buildTypeId,
  branch,
  deployTypeId,
}: LastDeployedBuildConfig): string {
  const deploymentLocator = [
    'defaultFilter:false',
    `buildType:(id:${stringifyId(deployTypeId)})`,
    getBranchLocator(branch),
  ]
    .filter(Boolean)
    .join(',')
  return (
    `count:1,defaultFilter:false,buildType:(id:${stringifyId(buildTypeId)}),` +
    `item(snapshotDependency:(to:(${deploymentLocator}))),` +
    `item(artifactDependency:(to:(${deploymentLocator}),recursive:false))`
  )
}
