import {getBooleanUserProperty, getIsSakuraSelected} from '../../../selectors'
import {internalProps} from '../../../types/BS_types'
import {isEmbedded} from '../../../utils/isEmbedded'

import {UserProperties} from '../../../utils/userProperties'

import {useAppSelector} from 'src/hooks/react-redux'

const isEnabledServerWide = internalProps['teamcity.ui.sakura.releaseBanner.enabled'] === true

export const useSakuraReleaseBanner = () => {
  const sakuraSelected = useAppSelector(getIsSakuraSelected)
  const isEnabledForUser = useAppSelector(state =>
    getBooleanUserProperty(state, UserProperties.SHOW_SAKURA_RELEASE_BANNER, true),
  )

  if (!isEnabledForUser || !isEnabledServerWide) {
    return false
  }

  return !isEmbedded && !sakuraSelected
}
