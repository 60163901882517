import type {State} from '../reducers/types'
import type {
  GetAllProjectsNormalizedApiArg,
  GetFederationProjectsNormalizedApiArg,
  GetProjectNormalizedApiArg,
} from '../services/rest'
import {ROOT_PROJECT_ID, stringifyId} from '../types'
import type {ProjectId, RequestOptionsParams} from '../types'

import {buildTypeFields, linksField, parametersField} from './buildTypes'

const basicProjectFields = ['id', 'internalId', 'name', 'parentProjectId', 'archived', 'readOnlyUI']
const descriptionField = 'description'
const archivedProjectsIdField = 'projects(project(id),count,$locator(archived:true))'
const projectFields: (
  arg0: RequestOptionsParams | null | undefined,
) => ReadonlyArray<string> = options =>
  basicProjectFields
    .concat(
      options?.withBuildTypes === true
        ? `buildTypes(buildType(${buildTypeFields(options).join(',')}))`
        : [],
    )
    .concat(options?.withTemplates === true ? 'templates(buildType(id,name))' : [])
    .concat(options?.withVirtual === true ? 'virtual' : [])
    .concat(options?.withLinks === true ? linksField : [])
    .concat(options?.withParameters === true ? parametersField : [])
    .concat(options?.withDescription === true ? descriptionField : [])
    .concat(options?.withArchivedSubprojectsIds === true ? archivedProjectsIdField : [])
    .concat(
      options?.withAncestorProjects === true
        ? `ancestorProjects(project(${projectFields({}).join(',')}))`
        : [],
    )

export const getProjectsArg = (
  projectId: ProjectId,
  options?: RequestOptionsParams,
): GetAllProjectsNormalizedApiArg => {
  const childrenLocator = `archived:${options?.archived ? options.archived : 'false'},${
    options?.withLowNesting === true ? 'project' : 'affectedProject'
  }:${stringifyId(projectId)}`
  return {
    ...options,
    locator:
      options?.includeRoot === true
        ? `item(id:${stringifyId(projectId)}),item(${childrenLocator})`
        : childrenLocator,
    fields: `project(${projectFields(options).join(',')})`,
    projectId,
  }
}

export const archivedProjectsOptions: RequestOptionsParams = {
  withBuildTypes: true,
  withDescription: true,
  archived: 'any',
}

export const sidebarArg = getProjectsArg(ROOT_PROJECT_ID, {
  withBuildTypes: true,
  withAncestorProjects: true,
  cache: true,
  projectReceiveMeta: {
    sidebarProjectsLoaded: true,
  },
})

export const overviewArg: GetAllProjectsNormalizedApiArg = {
  isOverview: true,
  cache: true,
  locator: 'archived:false,selectedByUser:(user:(current),mode:selected)',
  fields:
    `project(${projectFields({archived: 'any'}).join(',')},` +
    `buildTypes(buildType(${buildTypeFields().join(',')}),` +
    '$locator(selectedByUser:(user:current))))',
}

export const agentsProjectsArg = getProjectsArg(ROOT_PROJECT_ID, {
  withBuildTypes: false,
  withLinks: true,
})

export const getFederationProjectsArg = (
  state: State,
  federationServerUrl: string,
): GetFederationProjectsNormalizedApiArg => {
  const withArchived = state.sidebar.showArchivedProjects
  return {
    ...getProjectsArg(ROOT_PROJECT_ID, {
      withBuildTypes: true,
      archived: withArchived ? 'any' : 'false',
    }),
    federationServerUrl,
  }
}

export const getSingleProjectArg = (
  projectId: ProjectId,
  options: RequestOptionsParams = {
    withBuildTypes: true,
    withLinks: true,
    withParameters: true,
    withArchivedSubprojectsIds: true,
    withDescription: true,
    withPauseComment: true,
  },
): GetProjectNormalizedApiArg => ({
  ...options,
  projectLocator: `id:${stringifyId(projectId)}`,
  fields: projectFields({...options, withVirtual: true}).join(','),
  essential: true,
})
