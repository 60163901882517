import {stringifyId} from '../types'
import type {BuildTypeId, ChangeId} from '../types'

type Params = {
  text: string | null | undefined
  buildTypeId?: BuildTypeId | null | undefined
  changeId?: ChangeId | null | undefined
  personal?: boolean
}
export default ({text, buildTypeId, changeId, personal}: Params): string | null | undefined => {
  if (text == null) {
    return null
  }

  const params = new URLSearchParams()
  params.set('content', text)

  if (buildTypeId != null) {
    params.set('buildTypeId', stringifyId(buildTypeId))
  } else if (changeId != null && personal != null) {
    params.set('modId', stringifyId(changeId))
    params.set('personal', String(personal || false))
  }

  return `bsout.html?${params.toString()}`
}
