import {setDisplayName} from 'recompose'

import * as React from 'react'

import type {Enhancer} from '../types'
import {getDisplayName, wrapDisplayName} from '../utils/getDisplayName'

const isStatelessFunctionalComponent = (
  Component: React.ElementType,
): Component is React.FunctionComponent =>
  typeof Component === 'function' && !Component.prototype?.isReactComponent

const createFactoryIfNeeded: <P>(
  Component: React.ElementType,
) => React.FunctionComponent<P> = Component =>
  isStatelessFunctionalComponent(Component) // eslint-disable-next-line new-cap
    ? props => Component({...Component.defaultProps, ...props})
    : props => <Component {...props} />

export default function withHook<AddProps, OwnProps>(
  useHook: (arg0: OwnProps) => AddProps,
  wrapperName: string = 'withHook',
): Enhancer<AddProps, OwnProps> {
  return BaseComponent => {
    const factory = createFactoryIfNeeded(BaseComponent)

    const WithHook = (props: OwnProps) => {
      const addProps = useHook(props)
      return factory({...props, ...addProps})
    }

    if (process.env.NODE_ENV !== 'production') {
      return setDisplayName(
        isStatelessFunctionalComponent(BaseComponent)
          ? getDisplayName(BaseComponent)
          : wrapDisplayName(BaseComponent, wrapperName),
      )(WithHook)
    }

    return WithHook
  }
}
