import memoize from '@jetbrains/ring-ui/components/global/memoize'
import type {$Values} from 'utility-types'

import type {BuildType} from '../../../../../services/rest'
import type {
  BuildTypeId,
  BuildTypeInternalId,
  Fetchable,
  NormalizedProject,
  ProjectId,
  ProjectInternalId,
} from '../../../../../types'
import type {KeyValue} from '../../../../../utils/object'

const FilterBranchStatus = Object.freeze({
  active: 'active',
  inactive: 'inactive',
  allBranches: 'allBranches',
})
const FilterBuildStatus = Object.freeze({
  any: 'any',
  successful: 'successful',
  failed: 'failed',
  failed_to_start: 'failed_to_start',
})
const FilterPersonal = Object.freeze({
  any: 'any',
  personal: 'personal',
  not_personal: 'not_personal',
})
export type FilterBranchStatusType = $Values<typeof FilterBranchStatus>
export type FilterBuildStatusType = $Values<typeof FilterBuildStatus>
export type FilterPersonalType = $Values<typeof FilterPersonal>
type EmptyObject = {} | null | undefined
export type FilterBranchActivity = {
  activity?: FilterBranchStatusType
}
export type FilterPersonalForm = {
  personal: FilterPersonalType
}
export type FilterBranchPattern = {
  pattern?: string
}
type Filter = {
  activeBranch?: FilterBranchActivity | null | undefined
  branchPattern?: FilterBranchPattern | null | undefined
  buildStatus?:
    | {
        status: FilterBuildStatusType
      }
    | null
    | undefined
  personalBuild?: FilterPersonalForm | null | undefined
  tags?:
    | {
        tagsList: string
      }
    | null
    | undefined
}
export type KeepBuildParts = {
  artifacts?:
    | {
        artifactPatterns?: string
      }
    | null
    | undefined
  everything?: EmptyObject
  logs?: EmptyObject
  history?: EmptyObject
  statistics?: EmptyObject
}
export type Partitions = {
  perBranch?:
    | {
        active?: boolean
      }
    | null
    | undefined
  perPersonal?:
    | {
        active?: boolean
      }
    | null
    | undefined
  perBranchAndActivity?:
    | {
        active?: boolean
      }
    | null
    | undefined
}
export type RuleId = string

/** LIMITS */
type LimitAllName = 'all'
type LimitDaysNames =
  | 'keepNDaysSinceLastBuild'
  | 'keepNDaysSinceLastSuccessfulBuild'
  | 'keepNDaysSinceToday'
type LimitBuildsName = 'keepLastNBuilds'
export type LimitNames = LimitDaysNames | LimitBuildsName | LimitAllName
export type LimitValue = 'daysCount' | 'buildsCount'
export type Limit = KeyValue<LimitNames, KeyValue<LimitValue, number> | null | undefined>
type Params = {}

/** CLEANUP HOLDER CALCULATION */
export type CleanupHolderNode =
  | {
      nodeType: 'project'
      id: ProjectId
      internalId: ProjectInternalId | null | undefined
      name?: string
    }
  | {
      nodeType: 'bt'
      id?: BuildTypeId
      internalId: BuildTypeInternalId | null | undefined
      name?: string
    }
export const getCleanupBuildTypeNode = memoize(
  ({id, internalId, name}: Partial<BuildType>): CleanupHolderNode => ({
    nodeType: 'bt',
    id,
    internalId,
    name,
  }),
)
export const getCleanupProjectNode = memoize(
  ({
    id,
    internalId,
    name,
  }: Partial<NormalizedProject> & {
    id: ProjectId
  }): CleanupHolderNode => ({
    nodeType: 'project',
    id,
    internalId,
    name,
  }),
)

/** RULE */
type CleanupHierarchyTypes = 'own' | 'effective'
export type FetchRulesOptons = {
  hierarchy?: CleanupHierarchyTypes
  getChildrensRules?: ('withAllInheritors' | 'withOwnBuildTypes') | null | undefined
}
export type RuleCommon = {
  ruleId?: RuleId
  ruleDisabled: boolean
}
export type RuleParameters = {
  keepBuildParts: KeepBuildParts
  filters: Filter
  partitions: Partitions
  limit: Limit
  params: Params
  preserveArtifacts: boolean | null | undefined
}
type RuleMeta = {
  source: 'own' | 'inherited' | 'enforced'
  holder: ('buildType' | 'project') | null | undefined
  holderExternalId: (BuildTypeId | ProjectId) | null | undefined
  overridesOwn: boolean | null | undefined
}
export type Rule = RuleCommon & RuleMeta & RuleParameters

/** REST **/
const AnswerStatuses = {
  success: 'success' as 'success',
  error: 'error' as 'error',
}
export type ErrorAnswer = {
  status: typeof AnswerStatuses.error
  code?: string
  errorId: string
  errorType?: string
  message: string
}
type SuccessAnswer<T> = {
  status: typeof AnswerStatuses.success
  data: T
}
type JSendResponse<T> = SuccessAnswer<T>
export type HolderRule =
  | {
      holder: 'project'
      holderExternalId: ProjectId
      rules: ReadonlyArray<Rule>
    }
  | {
      holder: 'buildType' | 'template'
      holderExternalId: BuildTypeId
      rules: ReadonlyArray<Rule>
    }
export type GetRulesResponse = JSendResponse<{
  holdersWithRules: ReadonlyArray<HolderRule>
}>
export type CreateRuleAnswer = JSendResponse<{
  ruleId: RuleId
}>
export type CleanupReceiveActions = string
export type LastAction = {
  action: CleanupReceiveActions | null
  loading: boolean
  ready: boolean
}

/** STATE **/
type FormAction = 'create' | 'update' | 'disable'
export type Form = KeyValue<string, Fetchable<FormAction | null>>

/** PROPS **/
export type OwnProps = {
  buildTypeId: BuildTypeId | null | undefined
  projectId: ProjectId
  isTemplate: boolean
  onRuleEdit?: () => unknown
  inheritedVisible?: boolean
  embedded?: boolean
  hideOwn?: boolean
  hideInherited?: boolean
  hideEnforced?: boolean
  holderNode: CleanupHolderNode
}
type VisibilityProps = {
  isVisible: boolean | null | undefined
}
export type ReduxOwnProps = OwnProps & VisibilityProps
export type StateProps = {
  lastAction: LastAction
  buildTypeTagsReady: boolean
}
export type ActionProps = {
  fetchTags: (arg0: BuildTypeId) => unknown
}
export type Props = ReduxOwnProps & StateProps & ActionProps
