import * as React from 'react'

import withHook from '../hocs/withHook'
import type {Enhancer} from '../types'

export const withContextAsProp = <T>(
  context: React.Context<T>,
  propName: string,
): Enhancer<any, any> =>
  withHook(() => ({
    [propName]: React.useContext(context),
  }))
