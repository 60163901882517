export function getScripts(content: string | null | undefined): string | null {
  const div = document.createElement('div')
  const result = document.createElement('div')
  div.innerHTML = content ?? ''
  const scripts = [...div.getElementsByTagName('script')]

  for (let i = 0; i < scripts.length; i++) {
    result.appendChild(scripts[i])
  }

  return result.innerHTML ? result.innerHTML : null
}
export function withoutScripts(content: string | null | undefined): string {
  const div = document.createElement('div')
  div.innerHTML = content ?? ''
  const scripts = div.getElementsByTagName('script')
  let i = scripts.length

  while (i--) {
    scripts[i].parentNode?.removeChild(scripts[i])
  }

  return div.innerHTML
}
