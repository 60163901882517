import Theme, {ThemeProvider} from '@jetbrains/ring-ui/components/global/theme'
import type {FC} from 'react'
import * as React from 'react'

import styles from './HeaderBanner.css'

type OwnProps = {
  content: React.ReactNode
  actions?: React.ReactNode
}

const HeaderBanner: FC<OwnProps> = ({content, actions}) => (
  <ThemeProvider theme={Theme.DARK}>
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.description}>{content}</div>
        <div className={styles.actions}>{actions}</div>
      </div>
    </div>
  </ThemeProvider>
)

export default HeaderBanner
