import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {NewIntegrationDto} from '../../services/pipelinesApi.types'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {getJob, deleteIfEmpty} from './EditPipelinePage.slices.utils'

export const deleteIntegration: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    pipelineId: string
    id: string
    name: string
  }>
> = (state, action) => {
  const {pipelineId, id, name} = action.payload
  state[pipelineId] ??= {}
  const jobs = state[pipelineId]!.draft?.settings.jobs

  Object.keys(jobs ?? []).forEach(key => {
    const integrations = jobs![key].integrations
    const hasIntegration = integrations?.includes(name)

    if (hasIntegration) {
      jobs![key].integrations = integrations?.filter(integrationsId => integrationsId !== name)

      if (!jobs![key].integrations?.length) {
        deleteIfEmpty(jobs![key], 'integrations')
      }
    }
  })

  state[pipelineId]!.deleted ??= {}
  state[pipelineId]!.deleted!.integrations ??= []
  state[pipelineId]!.deleted!.integrations!.push(id)
}

export const restoreIntegration: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    pipelineId: string
    id: string
  }>
> = (state, action) => {
  const {pipelineId, id} = action.payload
  const {deleted} = state[pipelineId] ?? {}
  if (deleted?.integrations?.includes(id)) {
    deleted.integrations = deleted.integrations.filter(featureId => id !== featureId)
    deleteIfEmpty(deleted, 'integrations') && deleteIfEmpty(state[pipelineId], 'deleted')
  }
}

export const addIntegration: CaseReducer<
  PipelineDraftState,
  PayloadAction<
    NewIntegrationDto & {
      pipelineId: string
      jobId: string | null
    }
  >
> = (state, action) => {
  const {pipelineId, jobId, type, parameters} = action.payload
  const id = parameters.displayName

  const {draft} = state[pipelineId] ?? {}
  if (draft != null) {
    draft.integrations ??= []
    draft.integrations!.push({
      id,
      type,
      parameters,
    })
    if (jobId != null) {
      const job = getJob(state, pipelineId, jobId)
      if (job != null && id != null) {
        job.integrations ??= []
        job.integrations!.push(id)
      }
    }
  }
}

export const editIntegration: CaseReducer<
  PipelineDraftState,
  PayloadAction<
    Partial<NewIntegrationDto> & {
      pipelineId: string
    }
  >
> = (state, action) => {
  const {pipelineId, id, parameters} = action.payload
  const integration = state[pipelineId]?.draft?.integrations?.find(item => item.id === id)
  if (integration != null) {
    const {displayName, ...restParameters} = parameters ?? {}
    Object.assign(integration.parameters, restParameters)
    if (displayName != null) {
      const originalName = integration.parameters.displayName
      if (displayName !== originalName) {
        state[pipelineId] ??= {}
        state[pipelineId]!.renamed ??= {}
        state[pipelineId]!.renamed!.integrations ??= {}
        state[pipelineId]!.renamed!.integrations![originalName] = displayName
      } else if (state[pipelineId]?.renamed?.integrations?.[originalName] != null) {
        delete state[pipelineId]!.renamed!.integrations![originalName]
        deleteIfEmpty(state[pipelineId]!.renamed, 'integrations') &&
          deleteIfEmpty(state[pipelineId], 'renamed')
      }
    }
  }
}

export const toggleIntegration: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    pipelineId: string
    jobId: string
    id: string
    disabled: boolean
  }>
> = (state, action) => {
  const {pipelineId, jobId, id, disabled} = action.payload
  const job = getJob(state, pipelineId, jobId)
  if (job != null) {
    if (disabled) {
      if (job.integrations?.includes(id)) {
        job.integrations = job.integrations.filter(item => item !== id)

        if (!job.integrations?.length) {
          deleteIfEmpty(job, 'integrations')
        }
      }
    } else {
      job.integrations ??= []
      if (!job.integrations?.includes(id)) {
        job.integrations.push(id)
      }
    }
  }
}
