import {connect} from 'react-redux'

import type {State} from '../../../reducers/types'
import {getBuildAgent, getIsSakuraUI} from '../../../selectors'

import AgentLink from './AgentLink'
import {mapAgentToProps} from './AgentLink.container'
import type {BuildAgentLinkOwnProps, BuildAgentLinkStateProps} from './AgentLink.types'

const mapStateToProps = (state: State, props: BuildAgentLinkOwnProps): BuildAgentLinkStateProps => {
  const agent = getBuildAgent(state, props.buildId)
  return {
    ...mapAgentToProps(agent),
    isSakuraUI: getIsSakuraUI(state),
    secondary: props.secondary ?? true,
  }
}

export default connect(mapStateToProps)(AgentLink)
