import type {AppThunk} from '../../../actions/types'
import {createFetchAction} from '../../../reducers/fetchable'
import {restRoot} from '../../../rest/consts'
import type {BuildId, BuildTypeHierarchy, BuildTypeId, ProjectId, TestId} from '../../../types'
import {stringifyId} from '../../../types'
import {base_uri, BS} from '../../../types/BS_types'
import {ActionThrottler} from '../../../utils/actionThrottler'
import type {WritableKeyValue} from '../../../utils/object'

import {requestTestBuildTypeHierarchy, requestTestOccurrencesCounts} from './Tests.rest'
import {getTestOccurrencesCountsFetchable} from './Tests.selectors'
import type {TestOccurrencesCounts} from './Tests.types'

const THROTTLER_TIMEOUT = 5000

export const fetchTestOccurrencesCounts = createFetchAction(
  'fetchTestOccurrencesCounts',
  (locator: string) => requestTestOccurrencesCounts(restRoot, locator),
)

export const receiveTestOccurrencesCounts = (data: TestOccurrencesCounts, locator: string) =>
  fetchTestOccurrencesCounts.fulfilled(data, '', locator)

const fetchTestOccurrencesCountsThrottler = new ActionThrottler(
  fetchTestOccurrencesCounts,
  THROTTLER_TIMEOUT,
  true,
)

export const fetchTestOccurrencesCountsWithThrottle =
  (locator: string, isBackground?: boolean): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {loading} = getTestOccurrencesCountsFetchable(state, locator)

    if (loading && isBackground === true) {
      return
    }

    dispatch(fetchTestOccurrencesCountsThrottler.fetch(locator, !isBackground))
  }

export const showTestMetadataGraph =
  (
    anchor: EventTarget,
    testNameId: TestId,
    buildId: BuildId,
    escapedName: string,
    buildTypeId: BuildTypeId | null | undefined,
  ): AppThunk<any> =>
  () =>
    buildTypeId != null &&
    BS?.TestMetadata?.showGraph(anchor, testNameId, buildId, escapedName, buildTypeId)

export const assignTestInvestigations =
  (
    projectId: ProjectId,
    tests: ReadonlyArray<TestId>,
    flakyTests: ReadonlyArray<TestId>,
    buildIds: ReadonlyArray<BuildId>,
    fixMode: boolean = false,
    submitHandler?: () => unknown,
  ): AppThunk<any> =>
  () => {
    const testsData: WritableKeyValue<string, string> = {
      projectId: stringifyId(projectId),
    }
    const flakyTestIds: WritableKeyValue<string, boolean> = {}
    tests.forEach(item => {
      const key = stringifyId(item)
      testsData[key] = `${buildIds.join(',')},`
      flakyTestIds[key] = flakyTests.includes(item)
    })
    BS?.BulkInvestigateMuteTestDialog?.show(testsData, fixMode, flakyTestIds, true, submitHandler)
  }

export const fetchTestBuildTypeHierarchy = createFetchAction(
  'fetchTestBuildTypeHierarchy',
  (locator: string) => requestTestBuildTypeHierarchy(base_uri, locator),
)

export const receiveTestBuildTypeHierarchy = (data: BuildTypeHierarchy, locator: string) =>
  fetchTestBuildTypeHierarchy.fulfilled(data, '', locator)
