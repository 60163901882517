import {useEffect, useRef, useState} from 'react'

import useEventCallback from './useEventCallback'

export type EqualityFn<T> = (arg0: T, arg1: T) => boolean
export default function useEventSelector<I, O>(
  selector: (input?: I) => O,
  equalityFn: EqualityFn<O> = (a, b) => a === b,
): [O, (input: I) => void] {
  const [value, setValue] = useState(selector)
  const isMounted = useRef(true)
  useEffect(
    () => () => {
      isMounted.current = false
    },
    [],
  )
  const handler = useEventCallback((input: I) => {
    const newValue = selector(input)

    if (isMounted.current && !equalityFn(newValue, value)) {
      setValue(newValue)
    }
  })
  return [value, handler]
}
