/* eslint-disable @typescript-eslint/no-magic-numbers */
import {darkThemeAdapterServicePrefix} from '../defaults'
import type {FilterConfig} from '../types'
import AsyncQueue from '../utils/asyncQueue'
import {getSRGBLightness} from '../utils/color'
import {loadAsDataURL} from '../utils/network'

import {getSVGFilterMatrixValue} from './svgFilter'

export interface ImageDetails {
  src: string
  dataURL: string
  width: number
  height: number
  isDark: boolean
  isLight: boolean
  isTransparent: boolean
  isLarge: boolean
  isTooLarge: boolean
}

const imageManager = new AsyncQueue()

export function getImageDetails(url: string) {
  return new Promise<ImageDetails>(async (resolve, reject) => {
    let dataURL: string | undefined
    if (url.startsWith('data:')) {
      dataURL = url
    } else {
      try {
        const result = await getImageDataURL(url)
        if (typeof result === 'string') {
          dataURL = result
        }
      } catch (error) {
        reject(error)
        return
      }
    }

    try {
      if (dataURL) {
        const image = await urlToImage(dataURL)
        imageManager.addToQueue(() => {
          resolve({
            src: url,
            dataURL: dataURL as string,
            width: image.naturalWidth,
            height: image.naturalHeight,
            ...analyzeImage(image),
          })
        })
      } else {
        throw Error('dataURL is empty')
      }
    } catch (error) {
      reject(error)
    }
  })
}

async function getImageDataURL(url: string): Promise<string | null> {
  const parsedURL = new URL(url)
  if (parsedURL.origin === location.origin) {
    return await loadAsDataURL(url)
  }

  return null
}

function urlToImage(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.onload = () => resolve(image)
    image.onerror = () => reject(`Unable to load image ${url}`)
    image.src = url
  })
}

const MAX_ANALIZE_PIXELS_COUNT = 32 * 32
let canvas: HTMLCanvasElement | OffscreenCanvas | null
let context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D | null

function createCanvas() {
  const maxWidth = MAX_ANALIZE_PIXELS_COUNT
  const maxHeight = MAX_ANALIZE_PIXELS_COUNT
  canvas = document.createElement('canvas')
  canvas.width = maxWidth
  canvas.height = maxHeight
  context = canvas.getContext('2d', {willReadFrequently: true})!
  context.imageSmoothingEnabled = false
}

function removeCanvas() {
  canvas = null
  context = null
}

// 5MB
const MAX_IMAGE_SIZE = 5 * 1024 * 1024

function analyzeImage(image: HTMLImageElement) {
  if (!canvas) {
    createCanvas()
  }
  const {naturalWidth, naturalHeight} = image
  if (naturalHeight === 0 || naturalWidth === 0) {
    return {
      isDark: false,
      isLight: false,
      isTransparent: false,
      isLarge: false,
      isTooLarge: false,
    }
  }

  const size = naturalWidth * naturalHeight * 4
  if (size > MAX_IMAGE_SIZE) {
    return {
      isDark: false,
      isLight: false,
      isTransparent: false,
      isLarge: false,
      isTooLarge: true,
    }
  }

  const naturalPixelsCount = naturalWidth * naturalHeight
  const k = Math.min(1, Math.sqrt(MAX_ANALIZE_PIXELS_COUNT / naturalPixelsCount))
  const width = Math.ceil(naturalWidth * k)
  const height = Math.ceil(naturalHeight * k)
  context!.clearRect(0, 0, width, height)

  context!.drawImage(image, 0, 0, naturalWidth, naturalHeight, 0, 0, width, height)
  const imageData = context!.getImageData(0, 0, width, height)
  const d = imageData.data

  const TRANSPARENT_ALPHA_THRESHOLD = 0.05
  const DARK_LIGHTNESS_THRESHOLD = 0.4
  const LIGHT_LIGHTNESS_THRESHOLD = 0.7

  let transparentPixelsCount = 0
  let darkPixelsCount = 0
  let lightPixelsCount = 0

  let i: number
  let x: number
  let y: number
  let r: number
  let g: number
  let b: number
  let a: number
  let l: number
  for (y = 0; y < height; y++) {
    for (x = 0; x < width; x++) {
      i = 4 * (y * width + x)
      r = d[i + 0]
      g = d[i + 1]
      b = d[i + 2]
      a = d[i + 3]

      if (a / 255 < TRANSPARENT_ALPHA_THRESHOLD) {
        transparentPixelsCount++
      } else {
        l = getSRGBLightness(r, g, b)
        if (l < DARK_LIGHTNESS_THRESHOLD) {
          darkPixelsCount++
        }
        if (l > LIGHT_LIGHTNESS_THRESHOLD) {
          lightPixelsCount++
        }
      }
    }
  }

  const totalPixelsCount = width * height
  const opaquePixelsCount = totalPixelsCount - transparentPixelsCount

  const DARK_IMAGE_THRESHOLD = 0.7
  const LIGHT_IMAGE_THRESHOLD = 0.7
  const TRANSPARENT_IMAGE_THRESHOLD = 0.1
  const LARGE_IMAGE_PIXELS_COUNT = 800 * 600

  return {
    isDark: darkPixelsCount / opaquePixelsCount >= DARK_IMAGE_THRESHOLD,
    isLight: lightPixelsCount / opaquePixelsCount >= LIGHT_IMAGE_THRESHOLD,
    isTransparent: transparentPixelsCount / totalPixelsCount >= TRANSPARENT_IMAGE_THRESHOLD,
    isLarge: naturalPixelsCount >= LARGE_IMAGE_PIXELS_COUNT,
    isTooLarge: false,
  }
}

export function getFilteredImageDataURL(
  {dataURL, width, height}: ImageDetails,
  theme: FilterConfig,
): string {
  const matrix = getSVGFilterMatrixValue(theme)
  const svg = [
    `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" height="${height}">`,
    '<defs>',
    `<filter id="${darkThemeAdapterServicePrefix}-image-filter">`,
    `<feColorMatrix type="matrix" values="${matrix}" />`,
    '</filter>',
    '</defs>',
    `<image width="${width}" height="${height}" filter="url(#${darkThemeAdapterServicePrefix}-image-filter)" xlink:href="${dataURL}" />`,
    '</svg>',
  ].join('')
  return `data:image/svg+xml;base64,${btoa(svg)}`
}

export function cleanImageProcessingCache() {
  imageManager?.stopQueue()
  removeCanvas()
}
