import {restApi} from '../../../../services/rest'
import type {BuildId} from '../../../../types'
import {getPropertyFromList} from '../../../../utils/getProperty'

const STORAGE_FEATURE_ID = 'teamcity.storage.feature.id'

export function useArtifactStorage(buildId: BuildId) {
  const {featureId} = restApi.endpoints.getBuild.useQuery(
    {
      buildLocator: `id:${buildId}`,
      fields: `resultingProperties(property(name,value),$locator:(name:${STORAGE_FEATURE_ID}))`,
    },
    {
      selectFromResult: ({data}) => ({
        featureId: getPropertyFromList(STORAGE_FEATURE_ID, data?.resultingProperties),
      }),
    },
  )

  return restApi.endpoints.getFeature.useQuery(
    {
      projectLocator: `projectFeature:id:${featureId}`,
      featureLocator: `id:${featureId}`,
      fields: 'properties(property(name,value))',
    },
    {
      skip: featureId == null,
      selectFromResult: ({data}) => ({
        name: getPropertyFromList('storage.name', data?.properties) ?? '',
        type: getPropertyFromList('storage.type', data?.properties) ?? '',
      }),
    },
  )
}
