import {fetchBuildTypeTags} from '../../../../../actions/fetch'
import {createAppAsyncThunk, createFetchAction} from '../../../../../reducers/fetchable'
import {getSingleBuildArg} from '../../../../../rest/builds'
import {getBuildLocator} from '../../../../../rest/locators'
import {getIsStarred} from '../../../../../selectors'
import {restApi} from '../../../../../services/rest'
import {STAR_TAG} from '../../../../../types'
import type {CommentHandlerArgs, TagsHandlerArgs} from '../../../../../types'
import {BS} from '../../../../../types/BS_types'

export const commentBuild = createFetchAction(
  'commentBuild',
  ({buildId, comment: text}: CommentHandlerArgs, {dispatch}) =>
    BS?.BuildCommentDialog?.showBuildCommentDialog(buildId, text, true).then(data => {
      dispatch(
        restApi.endpoints.getBuildNormalizedAsList.initiate(
          getSingleBuildArg(getBuildLocator(buildId)),
          {
            subscribe: false,
            forceRefetch: true,
          },
        ),
      )
      return data
    }),
  {condition: () => BS != null},
)
export const tagBuild = createAppAsyncThunk(
  'tagBuild',
  (
    {buildId, joinedTags, hasDependencies, tagsContainerId, buildTypeId}: TagsHandlerArgs,
    {dispatch, getState},
  ) =>
    BS?.Tags?.showEditDialog(
      buildId,
      joinedTags,
      '',
      hasDependencies,
      tagsContainerId,
      true,
      buildTypeId,
    ).then(data => {
      dispatch(
        restApi.endpoints.getBuildNormalizedAsList.initiate(
          getSingleBuildArg(getBuildLocator(buildId)),
          {
            subscribe: false,
            forceRefetch: true,
          },
        ),
      )

      if (buildTypeId) {
        dispatch(fetchBuildTypeTags(buildTypeId))
      }
      const tags = {
        tag: data.map(_ => ({
          name: _,
          private: false,
        })),
      }

      if (getIsStarred(getState(), buildId)) {
        tags.tag.push({
          name: STAR_TAG,
          private: true,
        })
      }

      return tags
    }),
  {condition: () => BS != null},
)
