import * as Redux from 'redux'

import {keyValueFetchable} from '../../../reducers/fetchable'
import {stringifyId} from '../../../types'
import {emptyArray} from '../../../utils/empty'

import {
  fetchBuildsForCompareAction,
  fetchCompareBuildsInfoAction,
  fetchCompareBuildsListAction,
} from './CompareBuildsPage.actions'
import {settings} from './CompareBuildsPage.slices'
import type {CompareBuildsState} from './CompareBuildsPage.types'

export const compareBuildsPageReducers = Redux.combineReducers<CompareBuildsState>({
  settings: settings.reducer,

  lists: keyValueFetchable(
    arg => `${stringifyId(arg.sourceId)}:${stringifyId(arg.compareWithId)}`,
    fetchCompareBuildsListAction,
    emptyArray,
    (_, action) => action.payload,
  ),

  info: keyValueFetchable(
    arg => `${stringifyId(arg.sourceId)}:${stringifyId(arg.compareWithId)}:${arg.compareType}`,
    fetchCompareBuildsInfoAction,
    Object.freeze({}),
    (_, action) => action.payload,
  ),
  builds: keyValueFetchable(
    arg => String(arg.buildId),
    fetchBuildsForCompareAction,
    Object.freeze({}),
    (_, action) => action.payload,
  ),
})
