import React, {useEffect} from 'react'
import {shallowEqual} from 'react-redux'

import {addHint, removeHint, selectHintCategory} from './Hints.actions'

import {
  getAvailableHints,
  getSelectedCategory,
  getSelectedHints,
  getSkipObligatoryHints,
  getTipsModeEnabled,
} from './Hints.selectors'

import type {HintCategory, HintDataHook, Hint} from './Hints.types'
import {HINT_CATEGORY} from './Hints.types'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'

import {getIsGuestOrRoot} from 'src/selectors'

export const useIsHintsModeEnabled = () => {
  const isEnabled = useAppSelector(getTipsModeEnabled)
  const isGuestOrRoot = useAppSelector(getIsGuestOrRoot)

  return {isEnabled, isGuestOrRoot}
}

export const useHint = (hint?: (Hint | null) | boolean) => {
  const dispatch = useAppDispatch()
  const {isEnabled, isGuestOrRoot} = useIsHintsModeEnabled()
  const skipObligatoryHints = useAppSelector(getSkipObligatoryHints)

  useEffect(() => {
    if (
      hint == null ||
      isGuestOrRoot ||
      typeof hint === 'boolean' ||
      (!isEnabled && (!hint.obligatory || skipObligatoryHints))
    ) {
      return () => {}
    }

    dispatch(addHint(hint))
    return () => dispatch(removeHint(hint.id))
  }, [isEnabled, dispatch, hint, isGuestOrRoot, skipObligatoryHints])
}

export const useHintData = (): HintDataHook => {
  const dispatch = useAppDispatch()
  const selectedCategory = useAppSelector(state => getSelectedCategory(state))
  const availableHints = useAppSelector(state => getAvailableHints(state), shallowEqual)
  const selectedHints = useAppSelector(state => getSelectedHints(state), shallowEqual)
  const setSelectedCategory = React.useCallback(
    (category: HintCategory) => dispatch(selectHintCategory(category)),
    [dispatch],
  )
  const registeredCategories: HintCategory[] = React.useMemo(
    () => [
      ...new Set(
        availableHints
          .filter(hint => hint.category !== HINT_CATEGORY.guide)
          .map(hint => hint.category ?? HINT_CATEGORY.miscellaneous),
      ),
    ],
    [availableHints],
  )
  const onSelectNext = React.useCallback(() => {
    const currentCategoryIndex =
      selectedCategory != null ? registeredCategories.indexOf(selectedCategory) : -1
    const lastPage = currentCategoryIndex === registeredCategories.length
    const nextCategoryIndex = lastPage ? 0 : currentCategoryIndex + 1
    setSelectedCategory(registeredCategories[nextCategoryIndex])
  }, [registeredCategories, selectedCategory, setSelectedCategory])
  const onSelectPrevious = React.useCallback(() => {
    const currentCategoryIndex =
      selectedCategory != null ? registeredCategories.indexOf(selectedCategory) : -1
    const firstPage = currentCategoryIndex === 0
    const nextCategoryIndex = firstPage ? registeredCategories.length - 1 : currentCategoryIndex - 1
    setSelectedCategory(registeredCategories[nextCategoryIndex])
  }, [registeredCategories, selectedCategory, setSelectedCategory])
  React.useEffect(() => {
    if (
      registeredCategories.length > 0 &&
      (selectedCategory == null || !registeredCategories.includes(selectedCategory))
    ) {
      setSelectedCategory(registeredCategories[0])
    }
  }, [registeredCategories, selectedCategory, setSelectedCategory])
  const currentCategoryHints = React.useMemo(
    () => ({
      category: selectedCategory,
      collection: availableHints.filter(item => item.category === selectedCategory),
    }),
    [availableHints, selectedCategory],
  )
  const onSelectCategory = React.useCallback(
    (id: HintCategory) => {
      const nextCategoryIndex = registeredCategories.indexOf(id)
      setSelectedCategory(registeredCategories[nextCategoryIndex])
    },
    [registeredCategories, setSelectedCategory],
  )
  return {
    onSelectNext,
    onSelectPrevious,
    onSelectCategory,
    currentCategoryHints,
    registeredCategories,
    selectedHints,
  }
}
