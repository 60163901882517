import memoize from '@jetbrains/ring-ui/components/global/memoize'
import RingIcon from '@jetbrains/ring-ui/components/icon/icon'
import type {IconType, IconAttrs} from '@jetbrains/ring-ui/components/icon/icon'
import {pascalCase} from 'change-case'
import classNames from 'classnames'
import {Suspense, lazy, memo} from 'react'

import styles from './SvgIcon.css'

type Props = IconAttrs & {
  icon: IconType | string
}
const sizeRE = /-(\d+)px$/
const DEFAULT_SIZE = 16

function getSize(icon: string) {
  const [_, size] = icon.match(sizeRE) ?? []
  return size != null ? Number(size) : DEFAULT_SIZE
}

const getGlyph = memoize((icon: IconType | string): IconType | string => {
  if (typeof icon !== 'string' || /^<svg/.test(icon)) {
    return icon
  }

  const size = getSize(icon)
  const LazySvg = lazy(() =>
    import(
      /* webpackChunkName: "[request]", webpackPrefetch: true */
      `../../../svg/${icon}.svg`
    ).catch(
      () =>
        import(
          /* webpackChunkName: "[request]", webpackPrefetch: true */
          `@jetbrains/icons/${icon}.svg`
        ),
    ),
  )
  const LoadableSvg = memo(props => (
    <Suspense fallback={<svg {...props} data-suspense-fallback width={size} height={size} />}>
      <LazySvg {...props} />
    </Suspense>
  ))
  LoadableSvg.displayName = `LoadableSvg${pascalCase(icon)}`
  return LoadableSvg
})
export default memo(function SvgIcon({icon, className, ...restProps}: Props) {
  const classes = classNames(styles.icon, className)
  return <RingIcon {...restProps} className={classes} glyph={getGlyph(icon)} />
})
