import type {ComponentEnhancer} from 'recompose'
import {branch, withProps} from 'recompose'

import compose from 'lodash/flowRight'
import type {ElementType} from 'react'

import type {Enhancer} from '../../../types'
import {withContextAsProp} from '../../../utils/contexts'
import fromRenderProps from '../../../utils/fromRenderProps'

import type {Props} from './Visible'
import Visible from './Visible'
import {VisibilityContext} from './Visible.context'

export type VisibleProps = {
  isVisible?: boolean | null
}

const withVisibilityContext = withContextAsProp(VisibilityContext, 'isVisible')

export const withVisibility = (
  detect?: boolean | null,
  TagName: ElementType = 'div',
  ignoreProp: boolean = false,
  className?: string,
): Enhancer<VisibleProps, any> => {
  const addProps: Partial<Props> = {
    TagName,
    className,
  }
  const withVisibilityDetect = fromRenderProps(withProps(addProps)(Visible), isVisible =>
    ignoreProp
      ? Object.freeze({})
      : {
          isVisible,
        },
  )

  switch (detect) {
    case true:
      return withVisibilityDetect

    case false:
      return withVisibilityContext

    default:
      // try context, fall back to detect
      return compose(
        withVisibilityContext,
        branch(
          props => props.isVisible == null,
          withVisibilityDetect as ComponentEnhancer<VisibleProps, VisibleProps>,
        ),
      )
  }
}
