import Button from '@jetbrains/ring-ui/components/button/button'
import * as React from 'react'
import {createPortal} from 'react-dom'

import {setUserProperty} from '../../../actions'
import {getBooleanUserProperty, getCurrentUserLoaded, getUserProperty} from '../../../selectors'
import {UserProperties} from '../../../utils/userProperties'

import version from '../../../utils/version'
import HeaderBanner from '../../App/Header/HeaderBanner/HeaderBanner'
import HeaderBannerFeedback from '../../App/Header/HeaderBanner/HeaderBanner.feedback'

import {useSakuraReleaseBanner} from './LegacySakuraRelease.hooks'
import {LegacySakuraReleasePopup} from './LegacySakuraRelease.popup'

import styles from './LegacySakuraRelease.css'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'

const feedbackURL = `https://teamcity-support.jetbrains.com/hc/en-us/requests/new?ticket_form_id=360001686659&tcv=${version.full}`

type SakuraReleaseBannerProps = {
  href: string
}

export const BannerContent = ({onShow, href}: {onShow: () => void; href: string}) => (
  <div className={styles.content}>
    <span>{'The Sakura UI is here: a fresh look, improved usability, and new features'}</span>
    <div className={styles.controls}>
      <Button primary className={styles.more} onClick={onShow}>
        {'Read more'}
      </Button>
      <Button className={styles.switch} href={href}>
        {'Switch now'}
      </Button>
    </div>
  </div>
)

const LegacySakuraReleaseBanner = React.memo(({href}: SakuraReleaseBannerProps) => {
  const portal = document.getElementById('sakura-release-banner-portal')
  const dispatch = useAppDispatch()
  const [show, setShowMore] = React.useState(false)
  const onShow = React.useCallback(() => setShowMore(true), [])
  const onHide = React.useCallback(() => setShowMore(false), [])

  const showReleaseBanner = useSakuraReleaseBanner()
  const isCurrentUserLoaded = useAppSelector(getCurrentUserLoaded)

  const hasSeenBannerBefore = useAppSelector(state =>
    isCurrentUserLoaded
      ? getBooleanUserProperty(state, UserProperties.HAS_SEEN_SAKURA_RELEASE_POPUP)
      : true,
  )

  const hasSeenSakuraUIAfterRelease = useAppSelector(state =>
    isCurrentUserLoaded ? getUserProperty(state, UserProperties.LAST_SEEN_SAKURA_UI_VERSION) : true,
  )

  React.useEffect(() => {
    if (!hasSeenBannerBefore && !hasSeenSakuraUIAfterRelease) {
      onShow()
      dispatch(setUserProperty(UserProperties.HAS_SEEN_SAKURA_RELEASE_POPUP, 'true'))
    }
  }, [dispatch, hasSeenBannerBefore, hasSeenSakuraUIAfterRelease, onShow])

  const node = (
    <React.Suspense fallback={<div data-suspense-fallback className={styles.placeholder} />}>
      <HeaderBanner
        content={<BannerContent onShow={onShow} href={href} />}
        actions={<HeaderBannerFeedback href={feedbackURL} />}
      />
      <LegacySakuraReleasePopup show={show} onCloseAttempt={onHide} href={href} />
    </React.Suspense>
  )

  return portal && showReleaseBanner ? createPortal(node, portal) : null
})
LegacySakuraReleaseBanner.displayName = 'LegacySakuraReleaseBanner'
export default LegacySakuraReleaseBanner
