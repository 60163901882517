import compose from 'lodash/flowRight'
import {connect} from 'react-redux'

import {moveBuildToTop} from '../../../actions'

import {useUpdateResults} from '../../../contexts/update'
import withUserPermissions from '../../../hocs/withUserPermissions'

import type {State} from '../../../reducers/types'
import {getBuild, getBuildType, hasPermission} from '../../../selectors'
import type {BuildId, ProjectId} from '../../../types'

import {Permission} from '../../../types'

import type {MoveToTopProps} from './MoveToTop'
import MoveToTop from './MoveToTop'

type Props = {
  buildId: BuildId
}

type StateProps = {
  projectId: ProjectId | undefined
  isDraggable: boolean
}

const mapStateToProps = (state: State, props: Props): StateProps => {
  const {buildType, queuePosition = 0} = getBuild(state, props.buildId) || {}
  const {projectId} = getBuildType(state, buildType) || {}
  const isDraggable =
    queuePosition > 1 && hasPermission(state, Permission.REORDER_BUILD_QUEUE, projectId)
  return {
    projectId,
    isDraggable,
  }
}

const actionCreators = {
  clickHandler: moveBuildToTop,
}

const MoveToTopContainer = (props: MoveToTopProps) => (
  <MoveToTop {...props} updateBuilds={useUpdateResults()} />
)

export default compose(
  connect(mapStateToProps, actionCreators),
  withUserPermissions(
    [Permission.REORDER_BUILD_QUEUE],
    (props: MoveToTopProps & StateProps) => props.projectId,
  ),
)(MoveToTopContainer)
