import {createAction} from '@reduxjs/toolkit'

import {createFetchAction} from '../reducers/fetchable'
import {deleteCloudInstance} from '../rest/agents'
import requestBuildTypeTags from '../rest/buildTypeTags'
import {restRoot} from '../rest/consts'

import requestDslOptions from '../rest/dsl'
import {restApi} from '../services/rest'
import type {AgentId, BuildTypeId, CloudInstanceId} from '../types'

import type {AppThunk} from './types'

export const removeAgentAction = createAction<AgentId>('removeAgent')
export const removeAgent =
  (id: AgentId): AppThunk =>
  dispatch => {
    dispatch(removeAgentAction(id))
    dispatch(restApi.util.invalidateTags([{type: 'Agent', id}]))
  }

export const stopCloudInstance = createFetchAction(
  'stopCloudInstance',
  (cloudInstanceId: CloudInstanceId) => deleteCloudInstance(restRoot, cloudInstanceId),
)

type FetchDslOptionsArg = {
  serverUrl: string
  force?: boolean
}
export const fetchDslOptionsAction = createFetchAction(
  'fetchDslOptions',
  ({serverUrl}: FetchDslOptionsArg) => requestDslOptions(serverUrl),
)

export const fetchDslOptions =
  (serverUrl: string, force?: boolean): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const {dslOptions} = getState()
    if (force || !dslOptions.inited) {
      await fetchDslOptionsAction({serverUrl, force})
    }
  }

export const fetchBuildTypeTags = createFetchAction(
  'fetchBuildTypeTags',
  (buildTypeId: BuildTypeId) => requestBuildTypeTags(restRoot, buildTypeId),
)
