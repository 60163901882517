import type {ComponentType, NamedExoticComponent} from 'react'
import {
  isFragment,
  isPortal,
  isProfiler,
  isStrictMode,
  isSuspense,
  isContextConsumer,
  isContextProvider,
  isForwardRef,
  isMemo,
  isLazy,
} from 'react-is'

export function getDisplayName(
  Type: string | ComponentType<any> | NamedExoticComponent<any>,
): string {
  if (typeof Type === 'string') {
    return Type
  }

  if (typeof Type.displayName === 'string') {
    return Type.displayName
  }

  const propTypes = 'propTypes' in Type ? Type.propTypes : undefined
  if ('propTypes' in Type) {
    Type.propTypes = undefined
  }
  const element = <Type />

  const result = (() => {
    if (isFragment(element)) {
      return 'Fragment'
    }

    if (isPortal(element)) {
      return 'Portal'
    }

    if (isProfiler(element)) {
      return `Profiler`
    }

    if (isStrictMode(element)) {
      return 'StrictMode'
    }

    if (isSuspense(element)) {
      return 'Suspense'
    }

    if (isContextConsumer(element)) {
      return 'Context.Consumer'
    }

    if (isContextProvider(element)) {
      return 'Context.Provider'
    }

    if (isForwardRef(element)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return wrapDisplayName((Type as any).render, 'ForwardRef')
    }

    if (isMemo(element)) {
      return getDisplayName((Type as any).type)
    }

    if (isLazy(element)) {
      const resolvedThenable = (Type as any)._status === 1 ? (Type as any)._result : null

      if (resolvedThenable) {
        return getDisplayName(resolvedThenable)
      }
    }

    return Type.name ?? 'Anonymous'
  })()

  if ('propTypes' in Type) {
    Type.propTypes = propTypes
  }
  return result
}
export const wrapDisplayName = (
  component: string | ComponentType<any> | NamedExoticComponent<any>,
  hocName: string,
): string => `${hocName}(${getDisplayName(component)})`
