import {fetchHasBuilds} from '../../../actions/builds'
import type {AppThunk} from '../../../actions/types'
import {getChangeTagsArg} from '../../../rest/builds'
import {getAllTags, getIsSakuraUI, getTags} from '../../../selectors'
import {restApi} from '../../../services/rest'
import {STAR_TAG, STARRED_LOCATOR_WITHOUT_DEFAULT_FILTER} from '../../../types'
import type {BuildId} from '../../../types'

export const starBuild =
  (buildId: BuildId, starred: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const tags = getTags(state, buildId)
    await dispatch(
      restApi.endpoints.setBuildTags.initiate(
        getChangeTagsArg(
          buildId,
          starred
            ? tags.concat({
                name: STAR_TAG,
                private: true,
              })
            : tags,
          getAllTags(state, buildId),
        ),
      ),
    )
    if (getIsSakuraUI(state)) {
      dispatch(fetchHasBuilds(STARRED_LOCATOR_WITHOUT_DEFAULT_FILTER))
    }
  }
