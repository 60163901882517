import {createAction} from '@reduxjs/toolkit'

import type {AppThunk} from '../../../../actions/types'
import {getBuild} from '../../../../selectors'
import type {BuildId} from '../../../../types'
import {fetchBuildLogMessages} from '../BuildLog.actions'
import {getFullLogState, getFullBuildLog} from '../BuildLog.selectors'
import type {
  FullLogTarget,
  LogFilter,
  FullLogScrollBarInfo,
  BuildLogMessage,
} from '../BuildLog.types'
import {
  getKeyForBuildLogData,
  INITIAL_NEXT_MESSAGES_COUNT,
  INITIAL_PREVIOUS_MESSAGES_COUNT,
} from '../BuildLog.utils'

export const updateFullLogState = createAction<{
  target: FullLogTarget
  buildId?: BuildId | null | undefined
  focusLine?: number | null | undefined
  expandState?: ReadonlyArray<number> | null | undefined
  expandAll?: boolean
}>('updateFullLogState')
export const updateFullLogShowed = createAction<{
  target: FullLogTarget
  firstId: number | null | undefined
  firstDate: string | null | undefined
  lastDate: string | null | undefined
  lastId: number | null | undefined
}>('updateFullLogShowed')
export const setFullLogScrollBarInfoAction = createAction<{
  target: FullLogTarget
  info: FullLogScrollBarInfo
}>('setFullLogScrollBarInfo')
export const setFullLogScrollBarInfo = (target: FullLogTarget, info: FullLogScrollBarInfo) =>
  setFullLogScrollBarInfoAction({
    info,
    target,
  })
export const navigateToFirstMessage =
  (target: FullLogTarget): AppThunk<any> =>
  (dispatch, getState) => {
    const {buildId, expandState, expandAll, filter, logView} = getFullLogState(getState(), target)

    if (buildId != null) {
      dispatch(
        fetchBuildLogMessages({
          buildLogKey: getKeyForBuildLogData({
            type: 'full',
            target,
          }),
          invalidate: true,
          buildId,
          target,
          options: {
            logView,
            logAnchor: 0,
            count: INITIAL_NEXT_MESSAGES_COUNT,
            expandState,
            expandAll,
            filter,
          },
        }),
      )
    }
  }
export const navigateToLastMessage =
  (target: FullLogTarget): AppThunk<any> =>
  (dispatch, getState) => {
    const {buildId, expandState, expandAll, filter, logView} = getFullLogState(getState(), target)

    if (buildId != null) {
      dispatch(
        fetchBuildLogMessages({
          buildLogKey: getKeyForBuildLogData({
            type: 'full',
            target,
          }),
          invalidate: true,
          buildId,
          target,
          options: {
            logView,
            logAnchor: -1,
            count: [0, -INITIAL_NEXT_MESSAGES_COUNT],
            expandState,
            expandAll,
            filter,
          },
        }),
      )
    }
  }
export const collapseAllMessages =
  (target: FullLogTarget): AppThunk<any> =>
  (dispatch, getState) => {
    const {buildId, expandState, expandAll, filter, logView} = getFullLogState(getState(), target)

    if (
      (expandAll === true || (expandState != null && expandState.length > 0)) &&
      buildId != null
    ) {
      dispatch(
        fetchBuildLogMessages({
          buildLogKey: getKeyForBuildLogData({
            type: 'full',
            target,
          }),
          invalidate: true,
          buildId,
          target,
          options: {
            logView,
            count: INITIAL_NEXT_MESSAGES_COUNT,
            expandState: null,
            expandAll: false,
            filter,
          },
        }),
      )
    }
  }
export const expandAllMessages =
  (target: FullLogTarget): AppThunk<any> =>
  (dispatch, getState) => {
    const {buildId, expandState, expandAll, showedFirstId, filter, logView} = getFullLogState(
      getState(),
      target,
    )

    if (
      showedFirstId != null &&
      (expandAll !== true || (expandState != null && expandState.length > 0)) &&
      buildId != null
    ) {
      dispatch(
        fetchBuildLogMessages({
          buildLogKey: getKeyForBuildLogData({
            type: 'full',
            target,
          }),
          invalidate: true,
          buildId,
          target,
          options: {
            logView,
            count: [INITIAL_NEXT_MESSAGES_COUNT, INITIAL_PREVIOUS_MESSAGES_COUNT],
            logAnchor: showedFirstId,
            expandState: [],
            expandAll: true,
            filter,
          },
        }),
      )
    }
  }
export const setLogFilter = createAction<LogFilter | null | undefined>('setLogFilter')
export const resetLogView = createAction('resetLogView')
export const changeLogFilterAndFetchMessages =
  (target: FullLogTarget, value: LogFilter | null | undefined): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {
      buildId,
      expandState,
      expandAll: savedExpandAll,
      showedFirstId,
      logView,
    } = getFullLogState(state, target)
    const expandAll =
      ((expandState == null || expandState.length === 0) &&
        (value === 'important' || value === 'err')) ||
      savedExpandAll

    if (buildId != null) {
      dispatch(
        fetchBuildLogMessages({
          buildLogKey: getKeyForBuildLogData({
            type: 'full',
            target,
          }),
          invalidate: true,
          buildId,
          target,
          options: {
            logView,
            filter: value,
            count: [INITIAL_NEXT_MESSAGES_COUNT, INITIAL_PREVIOUS_MESSAGES_COUNT],
            logAnchor: showedFirstId ?? 0,
            expandState,
            expandAll,
          },
        }),
      )
    }
  }
export const navigateToMessage =
  (target: FullLogTarget, messageId: number): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState()
    const buildLogKey = getKeyForBuildLogData({
      type: 'full',
      target,
    })
    const {buildId, expandState, expandAll, filter, logView} = getFullLogState(getState(), target)
    const build = getBuild(state, buildId)
    const {messages} = getFullBuildLog(state, buildLogKey, target, build?.state === 'running')
    const targetMessage: BuildLogMessage | null | undefined = messages.find(
      (message: BuildLogMessage | null | undefined) => message?.id === messageId,
    )

    if (targetMessage != null) {
      dispatch(
        updateFullLogState({
          focusLine: messageId,
          target,
        }),
      )
    } else if (buildId != null) {
      dispatch(
        fetchBuildLogMessages({
          invalidate: true,
          buildLogKey,
          buildId,
          target,
          options: {
            logView,
            logAnchor: messageId,
            count: [INITIAL_NEXT_MESSAGES_COUNT, INITIAL_PREVIOUS_MESSAGES_COUNT],
            expandState,
            expandAll,
            filter,
          },
        }),
      )
    }
  }
