export function notNull<T>(value: T): value is NonNullable<T> {
  return value != null
}

type Truthy<T> = T extends null | undefined | false | 0 | '' ? never : T

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value
}

export const isKey = <T extends object>(obj: T, key: PropertyKey): key is keyof T => key in obj
export const isValue = <T extends Record<string, unknown>>(
  obj: T,
  key: unknown,
): key is T[keyof T] => Object.values(obj).includes(key)
export const isArray = (arg: unknown): arg is readonly unknown[] => Array.isArray(arg)
