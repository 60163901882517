import {castDraft} from 'immer'

import {createReducer, isAnyOf} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {restApi} from '../../../../services/rest'
import type {ChangeFile, ChangeId} from '../../../../types'
import {toChangeId} from '../../../../types'
import type {KeyValue} from '../../../../utils/object'

import {fetchChangesFiles} from './ChangeFiles.actions'
import type {ChangeFilesState} from './ChangeFiles.types'

const changeFiles = Redux.combineReducers<ChangeFilesState>({
  changesFiles: createReducer<KeyValue<ChangeId, ReadonlyArray<ChangeFile>>>({}, builder => {
    builder.addCase(fetchChangesFiles.fulfilled, (state, action) => {
      const files = action.payload?.file

      if (files == null) {
        return
      }

      state[action.meta.arg.changeId] = castDraft(files)
    })
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllChanges.matchFulfilled,
        restApi.endpoints.getChangeLog.matchFulfilled,
      ),
      (state, action) => {
        const {changes} = action.payload.changes?.entities ?? {}

        if (changes == null) {
          return
        }

        for (const [changeId, change] of Object.entries(changes)) {
          if (change?.files?.file != null) {
            state[toChangeId(changeId)] = change.files.file
          }
        }
      },
    )
  }),
})

export default changeFiles
