import {createAppAsyncThunk} from '../reducers/fetchable'
import makeRequest from '../rest/request'
import {getBuildType, getIsProjectOnOverview, getOwnerProjectId, getProject} from '../selectors'
import {restApi} from '../services/rest'
import type {ProjectId, ProjectOrBuildTypeNode} from '../types'
import {stringifyId} from '../types'
import {base_uri} from '../types/BS_types'
import {objectToQuery} from '../utils/queryParams'

import type {AppThunk} from './types'

const requestToggleProject =
  (
    node: ProjectOrBuildTypeNode,
    on: boolean,
    skipSubprojects: boolean | null | undefined,
  ): AppThunk<any> =>
  async (_, getState) => {
    if (!node.id || node.nodeType !== 'project') {
      return
    }

    const state = getState()
    const projectInternalId = getProject(state, node.id)?.internalId

    if (!projectInternalId) {
      throw new Error(
        `Project with id ${stringifyId(
          node.id,
        )} not found in store, or internalId field is missing`,
      )
    }

    await makeRequest(
      base_uri,
      `ajax.html?${on ? 'addProject' : 'hideProject'}=${stringifyId(projectInternalId)}${
        skipSubprojects === true ? '&skipSubprojects=true' : ''
      }`,
      {
        method: 'POST',
      },
    )
  }

const requestAddOrRemoveBuildTypeFromFavorite =
  (
    internalId: string | null | undefined,
    projectId: ProjectId | null | undefined,
    on: boolean,
  ): AppThunk<any> =>
  async () => {
    if (internalId == null || internalId.length === 0 || !projectId) {
      return
    }

    await makeRequest(base_uri, 'visibleBuildTypes.html', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: objectToQuery({
        projectId: stringifyId(projectId),
        bt_visible: internalId,
        ...(on
          ? {
              showOne: 'true',
            }
          : {
              hideOne: 'true',
            }),
      }),
    })
  }

const requestAddBuildTypesToFavorite =
  (ids?: ReadonlyArray<string | null | undefined>, projectId?: ProjectId): AppThunk<any> =>
  async () => {
    if (!ids || !projectId) {
      return
    }

    await makeRequest(base_uri, 'visibleBuildTypes.html', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: objectToQuery({
        projectId: stringifyId(projectId),
        bt_visible: ids.join(','),
        bt_order: ids.join(','),
      }),
    })
  }

type ToggleOverviewArg = {
  node: ProjectOrBuildTypeNode
  on: boolean
  skipSubprojects?: boolean | null | undefined
}
export const toggleOverviewAction = createAppAsyncThunk(
  'toggleOverview',
  async ({node, on, skipSubprojects}: ToggleOverviewArg, {dispatch, getState}) => {
    if (!node.id) {
      return
    }

    const state = getState()
    const projectInternalId =
      node.nodeType === 'project' ? getProject(state, node.id)?.internalId : null
    const buildTypeInternalId =
      node.nodeType === 'project' ? null : getBuildType(state, node.id)?.internalId

    if (!projectInternalId && !buildTypeInternalId) {
      throw new Error(
        `${node.nodeType === 'project' ? 'Project' : 'Build Configuration'} with id ${stringifyId(
          node.id,
        )} not found in store, or internalId field is missing`,
      )
    }

    if (node.nodeType === 'project') {
      await dispatch(requestToggleProject(node, on, skipSubprojects))
    } else if (!on) {
      await dispatch(
        requestAddOrRemoveBuildTypeFromFavorite(
          stringifyId(buildTypeInternalId),
          getOwnerProjectId(state, node.id),
          on,
        ),
      )
    } else {
      const projectId = getOwnerProjectId(state, node.id)

      if (projectId && !getIsProjectOnOverview(state, projectId)) {
        await dispatch(
          requestToggleProject(
            {
              nodeType: 'project',
              id: projectId,
            },
            true,
            true,
          ),
        )
        await dispatch(
          requestAddBuildTypesToFavorite([stringifyId(buildTypeInternalId)], projectId),
        )
      } else if (projectId) {
        await dispatch(
          requestAddOrRemoveBuildTypeFromFavorite(stringifyId(buildTypeInternalId), projectId, on),
        )
      }
    }

    await dispatch(restApi.util.invalidateTags(['Overview']))
  },
)

export const toggleOverview = (
  node: ProjectOrBuildTypeNode,
  on: boolean,
  skipSubprojects?: boolean | null | undefined,
) => toggleOverviewAction({node, on, skipSubprojects})
