import classNames from 'classnames'
import * as React from 'react'

import {stringifyId} from '../../../../types'
import type {BuildId, BuildTypeId} from '../../../../types'
import {resolveRelative} from '../../../../utils/url'
import IconButton from '../../IconButton/IconButton'

import styles from './ArtifactsDownloadAll.css'

export default class ArtifactsDownloadAll extends React.PureComponent<{
  buildId?: BuildId
  buildType?: BuildTypeId | null | undefined
  showHidden?: boolean | null | undefined
  className?: string
}> {
  render(): React.ReactNode {
    const {buildId, buildType, showHidden, className} = this.props
    return (
      <IconButton
        href={resolveRelative(
          `/repository/downloadAll/${stringifyId(buildType)}/${stringifyId(
            buildId,
          )}:id/artifacts.zip${showHidden === true ? '?showAll=true' : ''}`,
        )}
        icon="download"
        className={classNames(styles.downloadLink, className)}
        iconClassName={styles.downloadIcon}
      >
        {'Download all (.zip)'}
      </IconButton>
    )
  }
}
