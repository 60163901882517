export enum GlobalSearchKind {
  Sidebar = 'Sidebar',
  Parameters = 'Parameters',
}

interface GlobarSearchState {
  count: number
}

export interface ShortcutState {
  globalSearch: Record<GlobalSearchKind, GlobarSearchState>
}
