import rawFastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'
import fastdomSandbox from 'fastdom/extensions/fastdom-sandbox'
import {useCallback, useEffect, useMemo, useRef} from 'react'

const fastdom = rawFastdom.extend(fastdomPromised).extend(fastdomSandbox)
const Sandboxes = new WeakMap()
export const getSandbox = (elem?: Element) => {
  if (elem == null) {
    return fastdom.sandbox()
  }

  let sandbox = Sandboxes.get(elem)

  if (!sandbox) {
    sandbox = fastdom.sandbox()
    Sandboxes.set(elem, sandbox)
  }

  return sandbox
}
export const removeSandbox = (elem: Element) => {
  const sandbox = Sandboxes.get(elem)

  if (sandbox) {
    sandbox.clear()
    Sandboxes.delete(elem)
  }
}
export const useSandbox = (elem?: Element) => {
  const sandbox = useMemo(() => getSandbox(elem), [elem])
  const lastElem = useRef<Element | null | undefined>(elem)
  useEffect(() => {
    lastElem.current = elem
    return () => {
      lastElem.current = null
      sandbox.mutate(() => {
        if (lastElem.current !== elem) {
          sandbox.clear()
          if (elem != null && Sandboxes.has(elem)) {
            Sandboxes.delete(elem)
          }
        }
      })
    }
  }, [elem, sandbox])
  return sandbox
}
export const useMutate = (): ((arg0: () => unknown) => unknown) => {
  const sandbox = useSandbox()
  const mutationScheduled = useRef(false)
  const callbackRef = useRef(() => {})
  useEffect(
    () => () => {
      callbackRef.current = () => {}
    },
    [],
  )
  return useCallback(
    callback => {
      callbackRef.current = callback

      if (!mutationScheduled.current) {
        mutationScheduled.current = true
        sandbox.mutate(() => {
          mutationScheduled.current = false
          callbackRef.current()
        })
      }
    },
    [sandbox],
  )
}
