import {processTextResponse} from '../../../rest/processResponse'
import request from '../../../rest/request'

const ENDPOINT = 'plugins/hosted-plugin/agentsAuth.html'
export const requestAgentToken = (serverUrl: string): Promise<string> =>
  request(serverUrl, `${ENDPOINT}?generate=token`, {
    method: 'POST',
  }).then(processTextResponse)
export const requestAgentConfig = (
  serverUrl: string,
  agentName: string | null | undefined,
): Promise<string> => {
  const params = new URLSearchParams()
  params.set('generate', 'config')

  if (agentName != null && agentName.length > 0) {
    params.set('agentName', agentName)
  }

  return request(serverUrl, `${ENDPOINT}?${params.toString()}`, {
    method: 'POST',
  }).then(processTextResponse)
}
