import {castDraft} from 'immer'

import {createReducer} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {keyValueFetchable} from '../../../reducers/fetchable'
import {restApi} from '../../../services/rest'
import type {BuildTypeHierarchy} from '../../../types'

import {fetchTestBuildTypeHierarchy, fetchTestOccurrencesCounts} from './Tests.actions'
import type {TestOccurrencesCounts, TestOccurrencesTree} from './Tests.types'

const testOccurrencesCounts = keyValueFetchable(
  arg => arg,
  fetchTestOccurrencesCounts,
  null as TestOccurrencesCounts | null,
  (_, action) => action.payload,
)
const testBuildTypeHierarchy = keyValueFetchable(
  arg => arg,
  fetchTestBuildTypeHierarchy,
  null as BuildTypeHierarchy | null,
  (_, action) => action.payload,
)

export type TestOccurrencesSubtree = {
  depth?: number
  data: TestOccurrencesTree
}

const testsReducer = Redux.combineReducers({
  testOccurrencesCounts,
  testBuildTypeHierarchy,
  testOccurrencesSubtree: createReducer<Record<string, Record<string, TestOccurrencesSubtree>>>(
    {},
    builder => {
      builder.addMatcher(
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
        (state, action) => {
          const {treeLocator, subTreeKey, depth} = action.meta.arg.originalArgs
          if (treeLocator != null && subTreeKey != null) {
            state[treeLocator] ??= {}
            state[treeLocator]![subTreeKey] = {depth, data: castDraft(action.payload.tree)}
          }
        },
      )
    },
  ),
})
export default testsReducer
