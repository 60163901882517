import type {
  PluginConstructorArguments,
  PluginCallback,
  PlaceId,
  PluginContext,
  PluginCallbacks as PluginCallbacksInterface,
  UnsubscribeFromLifecycle,
} from '@jetbrains/teamcity-api'
import type * as React from 'react'
import type {$Values} from 'utility-types'

import {isValidPluginReactElementType} from '../components/common/Plugin/Plugin.utils'

import {objectKeys} from '../utils/object'

import {PlaceIdList, PluginLifecycleEnum} from './plugins.types'

type CallbacksStore = Record<$Values<typeof PluginLifecycleEnum>, Array<PluginCallback>>
type State = null | 'mounted' | 'deleted' | 'unmounted'
export abstract class PluginCallbacks implements PluginCallbacksInterface {
  debug: boolean = false
  name: string = ''
  placeId: PlaceId = PlaceIdList.SAKURA_BEFORE_CONTENT
  state: State = null
  context: PluginContext = {location: {}}
  content: string | HTMLElement | React.ComponentType<any> = ''
  callbacks: CallbacksStore = {
    ON_CREATE: [],
    ON_MOUNT: [],
    ON_CONTENT_UPDATE: [],
    ON_CONTEXT_UPDATE: [],
    ON_UNMOUNT: [],
    ON_DELETE: [],
  }

  constructor(plugin: PluginConstructorArguments) {
    plugin.onCreate && this.onCreate(plugin.onCreate)
    plugin.onMount && this.onMount(plugin.onMount)
    plugin.onContentUpdate && this.onContentUpdate(plugin.onContentUpdate)
    plugin.onContextUpdate && this.onContextUpdate(plugin.onContextUpdate)
    plugin.onUnmount && this.onUnmount(plugin.onUnmount)
    plugin.onDelete && this.onDelete(plugin.onDelete)
  }

  isControlled(): boolean {
    return (
      this.callbacks.ON_CONTEXT_UPDATE.length > 0 || isValidPluginReactElementType(this.content)
    )
  }

  setState(state: State) {
    if (this.state === 'deleted') {
      return
    }

    this.state = state
  }

  cleanCallbacks() {
    objectKeys(this.callbacks).forEach((lifecycle: $Values<typeof PluginLifecycleEnum>) => {
      if (this.callbacks.hasOwnProperty(lifecycle)) {
        this.callbacks[lifecycle] = []
      }
    })
  }

  subscribe(
    stage: $Values<typeof PluginLifecycleEnum>,
    callback: PluginCallback,
  ): UnsubscribeFromLifecycle {
    this.debug && // eslint-disable-next-line no-console
      console.debug(
        `Plugin debugging. ${this.name} / ${this.placeId}. Subscribe to Lifecycle. `,
        stage,
        callback,
      )
    this.callbacks[stage].push(callback)
    return () => {
      this.callbacks[stage] = this.callbacks[stage].filter(item => item !== callback)
    }
  }

  invokeCallbacks(stage: $Values<typeof PluginLifecycleEnum>) {
    if (this.state === 'deleted') {
      return
    }

    this.debug && // eslint-disable-next-line no-console
      console.debug(
        `Plugin debugging. ${this.name} / ${this.placeId}. Invoke callbacks. `,
        stage,
        this,
      )
    this.callbacks[stage].forEach(
      callback => typeof callback === 'function' && callback.call(this, this.context),
    )
  }

  onCreate(callback: () => unknown): UnsubscribeFromLifecycle {
    return this.subscribe(PluginLifecycleEnum.ON_CREATE, callback)
  }

  onMount(callback: PluginCallback): UnsubscribeFromLifecycle {
    if (this.state === 'mounted') {
      callback.call(this, this.context)
    }

    return this.subscribe(PluginLifecycleEnum.ON_MOUNT, callback)
  }

  onContentUpdate(callback: PluginCallback): UnsubscribeFromLifecycle {
    return this.subscribe(PluginLifecycleEnum.ON_CONTENT_UPDATE, callback)
  }

  onContextUpdate(callback: PluginCallback): UnsubscribeFromLifecycle {
    return this.subscribe(PluginLifecycleEnum.ON_CONTEXT_UPDATE, callback)
  }

  onDelete(callback: PluginCallback): UnsubscribeFromLifecycle {
    return this.subscribe(PluginLifecycleEnum.ON_DELETE, callback)
  }

  onUnmount(callback: PluginCallback): UnsubscribeFromLifecycle {
    return this.subscribe(PluginLifecycleEnum.ON_UNMOUNT, callback)
  }

  create() {
    this.invokeCallbacks(PluginLifecycleEnum.ON_CREATE)
  }

  mount() {
    this.setState('mounted')
    this.invokeCallbacks(PluginLifecycleEnum.ON_MOUNT)
  }

  updateContext(context: PluginContext) {
    this.context = context
    this.invokeCallbacks(PluginLifecycleEnum.ON_CONTEXT_UPDATE)
  }

  updateContent() {
    this.invokeCallbacks(PluginLifecycleEnum.ON_CONTENT_UPDATE)
  }

  unmount() {
    this.setState('unmounted')
    this.invokeCallbacks(PluginLifecycleEnum.ON_UNMOUNT)
  }

  delete() {
    window.ReactUI.removePlugin(this.placeId, this.name)
    this.invokeCallbacks(PluginLifecycleEnum.ON_DELETE)
    this.cleanCallbacks()
    this.setState('deleted')
  }
}
