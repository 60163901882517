import * as Redux from 'redux'

import {keyValueFetchable, fetchable} from '../../../reducers/fetchable'
import {emptyArray} from '../../../utils/empty'

import {fetchAllUsers, fetchChangesCommiters} from './UserSelect.actions'
import type {UserSelectState} from './UserSelect.types'

const userSelect = Redux.combineReducers<UserSelectState>({
  allUsers: fetchable(fetchAllUsers, emptyArray, (state, action) => action.payload),
  changesCommiters: keyValueFetchable(
    locator => locator,
    fetchChangesCommiters,
    emptyArray,
    (state, action) => action.payload.data,
  ),
})
export default userSelect
