import {createReducer, isAnyOf} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {fetchable} from '../../../reducers/fetchable'
import type {Fetchable} from '../../../types'
import {emptyNullFetchable} from '../../../utils/empty'

import {clearAgentAuth, fetchAgentConfig, fetchAgentToken} from './AgentAuth.actions'
import type {AgentAuthState} from './AgentAuth.types'

const tokenReducer = fetchable(
  fetchAgentToken,
  null as string | null,
  (_, action) => action.payload,
)
const configReducer = fetchable(
  fetchAgentConfig,
  null as string | null,
  (_, action) => action.payload,
)
const agentAuth = Redux.combineReducers<AgentAuthState>({
  token: createReducer<Fetchable<string | null>>(emptyNullFetchable, builder => {
    builder.addCase(clearAgentAuth, () => emptyNullFetchable)
    builder.addMatcher(
      isAnyOf(fetchAgentToken.pending, fetchAgentToken.fulfilled, fetchAgentToken.rejected),
      tokenReducer,
    )
  }),
  config: createReducer<Fetchable<string | null>>(emptyNullFetchable, builder => {
    builder.addCase(clearAgentAuth, () => emptyNullFetchable)
    builder.addMatcher(
      isAnyOf(fetchAgentConfig.pending, fetchAgentConfig.fulfilled, fetchAgentConfig.rejected),
      configReducer,
    )
  }),
})
export default agentAuth
