export function getMatches(regex: RegExp, input: string, group = 0) {
  const matches: string[] = []
  let m: RegExpMatchArray | null
  while ((m = regex.exec(input))) {
    matches.push(m[group])
  }
  return matches
}

export function getParenthesesRange(input: string, searchStartIndex = 0) {
  const length = input.length
  let depth = 0
  let firstOpenIndex = -1
  for (let i = searchStartIndex; i < length; i++) {
    if (depth === 0) {
      const openIndex = input.indexOf('(', i)
      if (openIndex < 0) {
        break
      }
      firstOpenIndex = openIndex
      depth++
      i = openIndex
    } else {
      const closingIndex = input.indexOf(')', i)
      if (closingIndex < 0) {
        break
      }
      const openIndex = input.indexOf('(', i)
      if (openIndex < 0 || closingIndex < openIndex) {
        depth--
        if (depth === 0) {
          return {start: firstOpenIndex, end: closingIndex + 1}
        }
        i = closingIndex
      } else {
        depth++
        i = openIndex
      }
    }
  }
  return null
}
