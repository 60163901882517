import type {AgentId, AgentTypeId, AgentPoolId, OSType, BuildId} from '../../../../types'

type AgentNodeType = 'agent'
export type AgentsTreeAgentNode = {
  id: AgentId
  poolId?: AgentPoolId
  type: AgentNodeType
  title: string
  name: string
  authorized: boolean
  osType: OSType | null | undefined
  busy: boolean
  connected: boolean
  enabled: boolean
  buildId?: BuildId | null | undefined
  ip: string
  cloud: boolean
  matching?: boolean
  containsMatching?: never
  removable?: boolean
}
type CloudImageNodeType = 'cloud-image'
export type AgentsTreeCloudImageNode = {
  type: CloudImageNodeType
  agentTypeId: AgentTypeId
  agentTypeRawId: string
  title: string
  children: Array<AgentsTreeAgentNode>
  count: number
  busyCount: number
  disconnectedCount: number
  matching?: boolean
  containsMatching?: boolean
  osType: OSType | null | undefined
}
type AgentTypeNodeType = 'agent-type'
export type AgentsTreeAgentTypeNode = {
  type: AgentTypeNodeType
  id: AgentTypeId
  rawId: string
  title: string
  children: Array<AgentsTreeAgentNode>
  count: number
  busyCount: number
  disconnectedCount: number
  matching?: boolean
  containsMatching?: boolean
  osType: OSType | null | undefined
}
export const UNAUTHORIZED_AGENTS_SELECTION_ID = 'unauthorized-agents'
export const DETACHED_AGENTS_SELECTION_ID = 'detached-agents'
export type AgentSelectionId = 'unauthorized-agents' | 'detached-agents'
export type AgentSelectionNodeType = 'agent-selection'
export type AgentsTreeRootNodeChild = AgentsTreeAgentTypeNode | AgentsTreeAgentNode
export const isAgentTypeNode = (node: AgentsTreeRootNodeChild): node is AgentsTreeAgentTypeNode =>
  node.type === 'agent-type'
export const isAgentNode = (node: AgentsTreeRootNodeChild): node is AgentsTreeAgentNode =>
  node.type === 'agent'
export type AgentsTreeAgentSelectionNode = {
  id: AgentSelectionId
  type: AgentSelectionNodeType
  title: string
  children: Array<AgentsTreeRootNodeChild>
  count: number
  containsMatching?: boolean
  disconnectedCount: number
}
export type AgentPoolNodeType = 'agent-pool'
export type AgentsTreeAgentPoolNode = {
  id?: AgentPoolId
  type: AgentPoolNodeType
  title?: string
  children: Array<AgentsTreeRootNodeChild>
  count: number
  busyCount: number
  disconnectedCount: number
  matching?: boolean
  containsMatching?: boolean
}
export type AgentsTreeRootNode = AgentsTreeAgentSelectionNode | AgentsTreeAgentPoolNode
export type AgentsTree = ReadonlyArray<AgentsTreeRootNode>
export type AgentBranchId = AgentPoolId | AgentSelectionId | AgentTypeId
export type AgentBranch = AgentPoolNodeType | AgentSelectionNodeType | AgentTypeNodeType
export type AgentsTreeNode = AgentsTreeRootNode | AgentsTreeRootNodeChild
type AgentsTreeItem = {
  title?: string
  level: number
}
type AgentsTreeSelectableItem = AgentsTreeItem & {
  active: boolean
}
export type AgentsTreeItemTitle = AgentsTreeItem & {
  id: string
  type: 'agents-tree-title'
}
export type AgentsTreeItemLink = AgentsTreeSelectableItem & {
  id: string
  type: 'agents-tree-link'
  to: string
  disconnectedCount?: number
  focusable: boolean
}
export type AgentsTreeItemPool = AgentsTreeSelectableItem & {
  id?: AgentPoolId
  type: AgentPoolNodeType
  containsActive: boolean
  count: number
  busyCount: number
  disconnectedCount: number
  expanded?: boolean
  matching?: boolean
  focusable: boolean
}
export type AgentsTreeItemSelection = AgentsTreeSelectableItem & {
  id: AgentSelectionId
  type: AgentSelectionNodeType
  count: number
  disconnectedCount: number
  containsActive: boolean
  expanded?: boolean
  focusable: boolean
}
export type AgentsTreeItemAgentType = AgentsTreeSelectableItem & {
  type: AgentTypeNodeType
  id: AgentTypeId
  count: number
  busyCount: number
  disconnectedCount: number
  containsActive: boolean
  expanded?: boolean
  osType: OSType | null | undefined
  matching?: boolean
  focusable: boolean
}
export type AgentsTreeItemAgent = AgentsTreeSelectableItem & {
  name: string
  id: AgentId
  type: AgentNodeType
  authorized: boolean
  osType: OSType | null | undefined
  busy?: boolean
  connected?: boolean
  enabled?: boolean
  cloud: boolean
  matching?: boolean
  focusable: boolean
}
export type AgentsTreeItemType =
  | AgentsTreeItemTitle
  | AgentsTreeItemLink
  | AgentsTreeItemAgent
  | AgentsTreeItemAgentType
  | AgentsTreeItemPool
  | AgentsTreeItemSelection
export type FlatAgentsTree = ReadonlyArray<AgentsTreeItemType>
export type ActiveItemFilter =
  | {
      id: AgentId
      type: AgentNodeType
    }
  | {
      id?: AgentPoolId
      type: AgentPoolNodeType
    }
  | {
      id: AgentTypeId
      type: AgentTypeNodeType
    }
  | {
      id: AgentSelectionId
      type: AgentSelectionNodeType
    }
  | {
      id: string
      type: 'agents-tree-link'
    }
export type AgentsRouteProps = {
  agentId?: AgentId | null | undefined
  agentTypeId?: AgentTypeId | null | undefined
  agentPoolId?: AgentPoolId | null | undefined
}
