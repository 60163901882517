import {getBuildTypeDsl, getDslRequestEndpoint, getFragmentDsl} from '../Dsl.selectors'

import {useAppSelector} from 'src/hooks/react-redux'
import type {BuildTypeId} from 'src/types'

export const useIsDslLoaded = (
  controlId: string,
  buildTypeOrTemplateId?: BuildTypeId,
  isFragment?: boolean,
) => {
  const dslEndpoint = useAppSelector(state =>
    !isFragment && buildTypeOrTemplateId != null
      ? getDslRequestEndpoint(state, buildTypeOrTemplateId, controlId)
      : null,
  )
  const data = useAppSelector(
    state =>
      (dslEndpoint != null ? getBuildTypeDsl(state, dslEndpoint) : getFragmentDsl(state)) ?? null,
  )

  return data != null
}
