import {darkThemeAdapterServicePrefix} from '../defaults'
import type {FilterConfig} from '../types'
import {forEach, push} from '../utils/array'
import {
  iterateShadowHosts,
  createOptimizedTreeObserver,
  isReadyStateComplete,
  addReadyStateCompleteListener,
  checkIfParentIgnoresAdapter,
} from '../utils/dom'
import {throttle} from '../utils/throttle'
import {getDuration} from '../utils/time'

import {iterateCSSDeclarations} from './cssRules'
import {getModifiableCSSDeclaration} from './modifyCss'
import type {CSSVariableModifier} from './variables'
import {variablesStore} from './variables'

type Overrides = Record<
  string,
  {
    customProp: string
    cssProp: string
    dataAttr: string
  }
>

const overrides: Overrides = {
  'background-color': {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-bgcolor`,
    cssProp: 'background-color',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-bgcolor`,
  },
  'background-image': {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-bgimage`,
    cssProp: 'background-image',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-bgimage`,
  },
  'border-color': {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-border`,
    cssProp: 'border-color',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-border`,
  },
  'border-bottom-color': {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-border-bottom`,
    cssProp: 'border-bottom-color',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-border-bottom`,
  },
  'border-left-color': {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-border-left`,
    cssProp: 'border-left-color',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-border-left`,
  },
  'border-right-color': {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-border-right`,
    cssProp: 'border-right-color',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-border-right`,
  },
  'border-top-color': {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-border-top`,
    cssProp: 'border-top-color',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-border-top`,
  },
  'box-shadow': {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-boxshadow`,
    cssProp: 'box-shadow',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-boxshadow`,
  },
  color: {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-color`,
    cssProp: 'color',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-color`,
  },
  fill: {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-fill`,
    cssProp: 'fill',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-fill`,
  },
  stroke: {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-stroke`,
    cssProp: 'stroke',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-stroke`,
  },
  'outline-color': {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-outline`,
    cssProp: 'outline-color',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-outline`,
  },
  'stop-color': {
    customProp: `--${darkThemeAdapterServicePrefix}-inline-stopcolor`,
    cssProp: 'stop-color',
    dataAttr: `data-${darkThemeAdapterServicePrefix}-inline-stopcolor`,
  },
}

const overridesList = Object.values(overrides)
const normalizedPropList: Record<string, string> = {}
overridesList.forEach(({cssProp, customProp}) => (normalizedPropList[customProp] = cssProp))

const INLINE_STYLE_ATTRS = ['style', 'fill', 'stop-color', 'stroke', 'bgcolor', 'color']
export const INLINE_STYLE_SELECTOR = INLINE_STYLE_ATTRS.map(attr => `[${attr}]`).join(', ')

export function getInlineOverrideStyle() {
  return overridesList
    .map(({dataAttr, customProp, cssProp}) =>
      [`[${dataAttr}] {`, `  ${cssProp}: var(${customProp}) !important;`, '}'].join('\n'),
    )
    .join('\n')
}

function getInlineStyleElements(root: Node) {
  const results: Element[] = []
  if (root instanceof Element && root.matches(INLINE_STYLE_SELECTOR)) {
    results.push(root)
  }
  if (root instanceof Element || root instanceof ShadowRoot || root instanceof Document) {
    push(results, root.querySelectorAll(INLINE_STYLE_SELECTOR))
  }
  return results
}

const treeObservers = new Map<Node, {disconnect(): void}>()
const attrObservers = new Map<Node, MutationObserver>()

export function watchForInlineStyles(
  elementStyleDidChange: (element: HTMLElement) => void,
  shadowRootDiscovered: (root: ShadowRoot) => void,
) {
  deepWatchForInlineStyles(document, elementStyleDidChange, shadowRootDiscovered)
  iterateShadowHosts(document.documentElement, host => {
    deepWatchForInlineStyles(host.shadowRoot!, elementStyleDidChange, shadowRootDiscovered)
  })
}

function deepWatchForInlineStyles(
  root: Document | ShadowRoot,
  elementStyleDidChange: (element: HTMLElement) => void,
  shadowRootDiscovered: (root: ShadowRoot) => void,
) {
  if (treeObservers.has(root)) {
    treeObservers.get(root)!.disconnect()
    attrObservers.get(root)!.disconnect()
  }

  const discoveredNodes = new WeakSet<Node>()

  function discoverNodes(node: Node) {
    getInlineStyleElements(node).forEach(el => {
      if (discoveredNodes.has(el)) {
        return
      }
      discoveredNodes.add(el)
      elementStyleDidChange(el as HTMLElement)
    })
    iterateShadowHosts(node, n => {
      if (discoveredNodes.has(node)) {
        return
      }
      discoveredNodes.add(node)
      shadowRootDiscovered(n.shadowRoot!)
      deepWatchForInlineStyles(n.shadowRoot!, elementStyleDidChange, shadowRootDiscovered)
    })
  }

  const treeObserver = createOptimizedTreeObserver(root, {
    onMinorMutations: ({additions}) => {
      additions.forEach(added => discoverNodes(added))
    },
    onHugeMutations: () => {
      discoverNodes(root)
    },
  })
  treeObservers.set(root, treeObserver)

  let attemptCount = 0
  let start: number | null = null
  const ATTEMPTS_INTERVAL = getDuration({seconds: 10})
  const RETRY_TIMEOUT = getDuration({seconds: 2})
  const MAX_ATTEMPTS_COUNT = 50
  let cache: MutationRecord[] = []
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  const handleAttributeMutations = throttle((mutations: MutationRecord[]) => {
    mutations.forEach(m => {
      if (INLINE_STYLE_ATTRS.includes(m.attributeName!)) {
        elementStyleDidChange(m.target as HTMLElement)
      }
    })
  })
  const attrObserver = new MutationObserver(mutations => {
    if (timeoutId) {
      cache.push(...mutations)
      return
    }
    attemptCount++
    const now = Date.now()
    if (start == null) {
      start = now
    } else if (attemptCount >= MAX_ATTEMPTS_COUNT) {
      if (now - start < ATTEMPTS_INTERVAL) {
        timeoutId = setTimeout(() => {
          start = null
          attemptCount = 0
          timeoutId = null
          const attributeCache = cache
          cache = []
          handleAttributeMutations(attributeCache)
        }, RETRY_TIMEOUT)
        cache.push(...mutations)
        return
      }
      start = now
      attemptCount = 1
    }
    handleAttributeMutations(mutations)
  })
  attrObserver.observe(root, {
    attributes: true,
    attributeFilter: INLINE_STYLE_ATTRS.concat(overridesList.map(({dataAttr}) => dataAttr)),
    subtree: true,
  })
  attrObservers.set(root, attrObserver)
}

export function stopWatchingForInlineStyles() {
  treeObservers.forEach(o => o.disconnect())
  attrObservers.forEach(o => o.disconnect())
  treeObservers.clear()
  attrObservers.clear()
}

const inlineStyleCache = new WeakMap<HTMLElement, string>()
const filterProps: Array<keyof FilterConfig> = [
  'brightness',
  'contrast',
  'grayscale',
  'sepia',
  'mode',
]

function getInlineStyleCacheKey(el: HTMLElement, theme: FilterConfig) {
  return INLINE_STYLE_ATTRS.map(attr => `${attr}="${el.getAttribute(attr)}"`)
    .concat(filterProps.map(prop => `${prop}="${theme[prop]}"`))
    .join(' ')
}

export function overrideInlineStyle(element: HTMLElement, theme: FilterConfig) {
  const cacheKey = getInlineStyleCacheKey(element, theme)
  if (checkIfParentIgnoresAdapter(element)) {
    return
  }

  if (cacheKey === inlineStyleCache.get(element)) {
    return
  }

  const unsetProps = new Set(Object.keys(overrides))

  function setCustomProp(targetCSSProp: string, modifierCSSProp: string, cssVal: string) {
    const isPropertyVariable = targetCSSProp.startsWith('--')
    const {customProp, dataAttr} = isPropertyVariable
      ? ({} as Overrides[''])
      : overrides[targetCSSProp]

    const mod = getModifiableCSSDeclaration(
      modifierCSSProp,
      cssVal,
      {style: element.style} as CSSStyleRule,
      variablesStore,
    )
    if (!mod) {
      return
    }
    let value = mod.value
    if (typeof value === 'function') {
      value = value(theme) as string
    }

    if (isPropertyVariable && typeof value === 'object') {
      const typedValue = value as ReturnType<CSSVariableModifier>
      typedValue.declarations.forEach(({property, value: valueL}) => {
        !(valueL instanceof Promise) && element.style.setProperty(property, valueL)
      })
    } else {
      element.style.setProperty(customProp, value)
      if (!element.hasAttribute(dataAttr)) {
        element.setAttribute(dataAttr, '')
      }
      unsetProps.delete(targetCSSProp)
    }
  }

  if (element.hasAttribute('bgcolor')) {
    let value = element.getAttribute('bgcolor')!
    if (value.match(/^[0-9a-f]{3}$/i) || value.match(/^[0-9a-f]{6}$/i)) {
      value = `#${value}`
    }
    setCustomProp('background-color', 'background-color', value)
  }

  if (element.hasAttribute('color') && (element as HTMLLinkElement).rel !== 'mask-icon') {
    let value = element.getAttribute('color')!
    if (value.match(/^[0-9a-f]{3}$/i) || value.match(/^[0-9a-f]{6}$/i)) {
      value = `#${value}`
    }
    setCustomProp('color', 'color', value)
  }
  if (element instanceof SVGElement) {
    if (element.hasAttribute('fill')) {
      const SMALL_SVG_LIMIT = 32
      const value = element.getAttribute('fill')!
      if (value !== 'none') {
        if (!(element instanceof SVGTextElement)) {
          const handleSVGElement = () => {
            const {width, height} = element.getBoundingClientRect()
            const isBg = width > SMALL_SVG_LIMIT || height > SMALL_SVG_LIMIT
            setCustomProp('fill', isBg ? 'background-color' : 'color', value)
          }

          if (isReadyStateComplete()) {
            handleSVGElement()
          } else {
            addReadyStateCompleteListener(handleSVGElement)
          }
        } else {
          setCustomProp('fill', 'color', value)
        }
      }
    }
    if (element.hasAttribute('stop-color')) {
      setCustomProp('stop-color', 'background-color', element.getAttribute('stop-color')!)
    }
  }
  if (element.hasAttribute('stroke')) {
    const value = element.getAttribute('stroke')!
    setCustomProp(
      'stroke',
      element instanceof SVGLineElement || element instanceof SVGTextElement
        ? 'border-color'
        : 'color',
      value,
    )
  }
  element.style &&
    iterateCSSDeclarations(element.style, (property, value) => {
      if (property === 'background-image' && value.includes('url')) {
        return
      }
      if (
        overrides.hasOwnProperty(property) ||
        (property.startsWith('--') && !normalizedPropList[property])
      ) {
        setCustomProp(property, property, value)
      } else {
        const overridenProp = normalizedPropList[property]
        if (
          overridenProp &&
          !element.style.getPropertyValue(overridenProp) &&
          !element.hasAttribute(overridenProp)
        ) {
          if (overridenProp === 'background-color' && element.hasAttribute('bgcolor')) {
            return
          }
          element.style.setProperty(property, '')
        }
      }
    })
  if (element.style && element instanceof SVGTextElement && element.style.fill) {
    setCustomProp('fill', 'color', element.style.getPropertyValue('fill'))
  }

  forEach(unsetProps, cssProp => {
    element.removeAttribute(overrides[cssProp].dataAttr)
  })
  inlineStyleCache.set(element, getInlineStyleCacheKey(element, theme))
}
