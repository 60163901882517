import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice, prepareAutoBatched} from '@reduxjs/toolkit'

import type {ShortcutState} from './Shortcuts.types'
import {GlobalSearchKind} from './Shortcuts.types'

const defaultState: ShortcutState = {
  globalSearch: {
    [GlobalSearchKind.Sidebar]: {
      count: 0,
    },
    [GlobalSearchKind.Parameters]: {
      count: 0,
    },
  },
}

export const shortcuts = createSlice({
  name: 'shortcuts',
  initialState: defaultState,
  reducers: {
    addSearch: {
      reducer(state, action: PayloadAction<GlobalSearchKind>) {
        state.globalSearch[action.payload].count += 1
      },
      prepare: prepareAutoBatched<GlobalSearchKind>(),
    },
    removeSearch: {
      reducer(state, action: PayloadAction<GlobalSearchKind>) {
        state.globalSearch[action.payload].count -= 1
      },
      prepare: prepareAutoBatched<GlobalSearchKind>(),
    },
  },
})
