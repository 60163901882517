import {forEach} from '../utils/array'
import {parseURL, getAbsoluteURL} from '../utils/url'

export function iterateCSSRules(
  rules: CSSRuleList,
  iterate: (rule: CSSStyleRule) => void,
  onMediaRuleError?: () => void,
) {
  forEach(rules, rule => {
    if ((rule as CSSStyleRule).selectorText) {
      iterate(rule as CSSStyleRule)
    } else if ((rule as CSSImportRule).href) {
      try {
        iterateCSSRules((rule as CSSImportRule).styleSheet.cssRules, iterate, onMediaRuleError)
      } catch (err) {
        onMediaRuleError?.()
      }
    } else if ((rule as CSSMediaRule).media) {
      const media = Array.from((rule as CSSMediaRule).media)
      const isScreenOrAllOrQuery = media.some(
        m => m.startsWith('screen') || m.startsWith('all') || m.startsWith('('),
      )
      const isPrintOrSpeech = media.some(m => m.startsWith('print') || m.startsWith('speech'))

      if (isScreenOrAllOrQuery || !isPrintOrSpeech) {
        iterateCSSRules((rule as CSSMediaRule).cssRules, iterate, onMediaRuleError)
      }
    } else if ((rule as CSSSupportsRule).conditionText) {
      if (CSS.supports((rule as CSSSupportsRule).conditionText)) {
        iterateCSSRules((rule as CSSSupportsRule).cssRules, iterate, onMediaRuleError)
      }
    }
  })
}

const shorthandVarDependantProperties = [
  'background',
  'border',
  'border-color',
  'border-bottom',
  'border-left',
  'border-right',
  'border-top',
  'outline',
  'outline-color',
]

export function iterateCSSDeclarations(
  style: CSSStyleDeclaration,
  iterate: (property: string, value: string) => void,
) {
  forEach(style, property => {
    const value = style.getPropertyValue(property).trim()
    if (!value) {
      return
    }
    iterate(property, value)
  })

  const cssText = style.cssText
  if (cssText.includes('var(')) {
    shorthandVarDependantProperties.forEach(prop => {
      const val = style.getPropertyValue(prop)
      if (val?.includes('var(')) {
        iterate(prop, val)
      }
    })
  }
}

export const cssURLRegex = /url\((('.*?')|(".*?")|([^\)]*?))\)/g
export const cssImportRegex = /@import\s*(url\()?(('.+?')|(".+?")|([^\)]*?))\)? ?(screen)?;?/gi

export function getCSSURLValue(cssURL: string) {
  return cssURL
    .trim()
    .replace(/[\n\r\\]+/g, '')
    .replace(/^url\((.*)\)$/, '$1')
    .trim()
    .replace(/^"(.*)"$/, '$1')
    .replace(/^'(.*)'$/, '$1')
    .replace(/(?:\\(.))/g, '$1')
}

export function getCSSBaseBath(url: string) {
  const cssURL = parseURL(url)
  return `${cssURL.origin}${cssURL.pathname.replace(/\?.*$/, '').replace(/(\/)([^\/]+)$/i, '$1')}`
}

export function replaceCSSRelativeURLsWithAbsolute($css: string, cssBasePath: string) {
  return $css.replace(cssURLRegex, match => {
    const pathValue = getCSSURLValue(match)
    try {
      return `url('${getAbsoluteURL(cssBasePath, pathValue)}')`
    } catch (err) {
      return match
    }
  })
}

const cssCommentsRegex = /\/\*[\s\S]*?\*\//g

export function removeCSSComments($css: string) {
  return $css.replace(cssCommentsRegex, '')
}

const fontFaceRegex = /@font-face\s*{[^}]*}/g

export function replaceCSSFontFace($css: string) {
  return $css.replace(fontFaceRegex, '')
}
