import type {Middleware, PayloadAction} from '@reduxjs/toolkit'
import {
  configureStore,
  isFulfilled,
  isPending,
  isRejected,
  autoBatchEnhancer,
} from '@reduxjs/toolkit'
import type {QueryThunkArg} from '@reduxjs/toolkit/dist/query/core/buildThunks'
import {setupListeners} from '@reduxjs/toolkit/query'

import {toParametersGroupState} from '../components/packages/Parameters/utils/toPreloadedState'
import reducer from '../reducers'
import type {State} from '../reducers/types'
import {getStateToPersist} from '../selectors/toPersist'
import {restApi} from '../services/rest'
import statistics from '../statistics/middleware'

import {emptyNullFetchable} from '../utils/empty'

import {hasPerformanceActionLog} from '../utils/logger'

import {autobatchMiddleware} from './autobatchMiddleware'
import {collectActionsMiddleware} from './collectActionsMiddleware'
import {meassureActionPerformaceMiddleware} from './meassureActionPerformaceMiddleware'
import {loadState, saveState} from './persistState'

const logMe: Middleware<{}, State> = store => next => action => {
  if (process.env.NODE_ENV === 'development') {
    const castedAction = action as PayloadAction<unknown, string, {arg: QueryThunkArg}, unknown>
    const {meta, payload, error} = castedAction
    if (meta?.arg?.endpointName != null) {
      const {endpointName, originalArgs} = meta.arg
      let type = ''
      if (isPending(castedAction)) {
        type = 'pending'
      } else if (isFulfilled(castedAction)) {
        type = 'fulfilled'
      } else if (isRejected(castedAction)) {
        type = 'rejected'
      }
      // eslint-disable-next-line no-console
      console.log(`${type}:`, endpointName, originalArgs, payload, error)
    }
    // eslint-disable-next-line no-console
    console.log('dispatching:', action, store.getState())
  }

  return next(action)
}

const persistentState = loadState()
export const createStore = (
  logicMiddleWare = IS_STORYBOOK && require('./getLogicMiddleware').getLogicMiddleware(),
) =>
  configureStore({
    reducer,
    preloadedState: persistentState && {
      ...persistentState,
      parameterGroups: toParametersGroupState(persistentState?.parameterGroups),
      currentUser: {
        ...emptyNullFetchable,
        ...persistentState.currentUser,
        // don't prevent request for up-to-date user info
        inited: false,
      },
    },
    devTools: process.env.NODE_ENV === 'development',
    middleware: getDefaultMiddleware => {
      const defaultMiddleware = getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
      return defaultMiddleware.concat(
        [
          restApi.middleware,
          logicMiddleWare,
          logMe,
          autobatchMiddleware(),
          ...(hasPerformanceActionLog
            ? [collectActionsMiddleware(), meassureActionPerformaceMiddleware()]
            : []),
          statistics,
        ].filter(Boolean),
      )
    },
    enhancers: existingEnhancers =>
      IS_STORYBOOK ? [...existingEnhancers] : [...existingEnhancers, autoBatchEnhancer()],
  })
const store = createStore()
store.subscribe(() => {
  saveState(getStateToPersist(store.getState()))
})
setupListeners(store.dispatch)
export default store
export type Store = typeof store
