import {createFetchAction} from '../../../../../reducers/fetchable'
import type {BuildId} from '../../../../../types'
import {base_uri} from '../../../../../types/BS_types'

import {requestBuildSnippets} from './BuildSnippets.requests'

export const fetchBuildSnippetList = createFetchAction(
  'fetchBuildSnippetList',
  (buildId: BuildId) => requestBuildSnippets(base_uri, buildId),
)
