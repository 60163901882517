import alert from '@jetbrains/ring-ui/components/alert-service/alert-service'

import {fetchStatusesWithThrottle} from '../../../actions/statuses'
import type {AppThunk} from '../../../actions/types'
import {createAppAsyncThunk} from '../../../reducers/fetchable'
import {getPromoteOptions, requestBuildCanBeRun, runBuild} from '../../../rest/builds'
import type {TriggerBuildOptions, RunBuildResult} from '../../../rest/builds'
import HTTPError, {HTTPCodesEnum} from '../../../rest/errors/HTTPError'
import {getBuildLocator} from '../../../rest/locators'
import {getStatusKey} from '../../../rest/schemata'
import {shouldAutoExpandQueued} from '../../../selectors'
import type {Branch} from '../../../services/rest'
import {restApi} from '../../../services/rest'
import {getBuildTypeStatusRequest, stringifyId} from '../../../types'
import type {BuildId, BuildTypeId} from '../../../types'
import {internalProps, BS} from '../../../types/BS_types'
import {unsubscribeOnSuccess} from '../../../utils/refetchable'
import {resolveRelative} from '../../../utils/url'
import {errorAction} from '../ErrorAlerts/ErrorAlerts.actions'

const WARNING_TIMEOUT = 5000

const warnIfNoAgents =
  (buildId: BuildId): AppThunk<any> =>
  async dispatch => {
    if (internalProps['teamcity.ui.showNoCompatibleAgentsPopup'] === false) {
      return
    }

    const {data} = await unsubscribeOnSuccess(
      dispatch(
        restApi.endpoints.getBuild.initiate({
          buildLocator: getBuildLocator(buildId),
          fields: 'composite',
        }),
      ),
    )

    if (data?.composite) {
      return
    }

    try {
      const canBeRun = await requestBuildCanBeRun(resolveRelative('/overview'), buildId)

      if (!canBeRun) {
        alert.warning(
          'No enabled compatible agents for this build configuration.\nPlease register a build agent or tweak build configuration requirements.',
          WARNING_TIMEOUT,
        )
      }
    } catch (error) {
      // Don't handle NOT_FOUND error because build can be optimized and removed or already has runned.
      if (error instanceof HTTPError && error.statusCode !== HTTPCodesEnum.NOT_FOUND) {
        dispatch(errorAction(error))
      }
    }
  }

type TriggerBuildArg = {
  buildTypeId: BuildTypeId
  branch: Branch | null | undefined
  options: TriggerBuildOptions
  isCustom?: boolean
}
export function getTriggerBuildKey({buildTypeId, branch}: TriggerBuildArg) {
  const statusRequest = getBuildTypeStatusRequest(buildTypeId, branch)
  return getStatusKey(statusRequest)
}
export const triggerBuildAction = createAppAsyncThunk(
  'triggerBuild',
  async ({buildTypeId, branch, options}: TriggerBuildArg, {dispatch, getState}) => {
    const statusRequest = getBuildTypeStatusRequest(buildTypeId, branch)
    const key = getStatusKey(statusRequest)
    const defaultBranch = branch?.default === true || branch?.groupFlag === true
    const branchName = defaultBranch ? null : branch?.name

    const result = await runBuild(buildTypeId, branchName, options)
    const {queuedBuildId} = result

    if (queuedBuildId != null) {
      dispatch(warnIfNoAgents(queuedBuildId))
      dispatch(fetchStatusesWithThrottle([statusRequest]))
    }

    return {
      ...result,
      autoExpandQueued: queuedBuildId != null && shouldAutoExpandQueued(getState(), key),
    }
  },
)
export const triggerBuild =
  (
    buildTypeId: BuildTypeId,
    branch: Branch | null | undefined,
    options: TriggerBuildOptions,
  ): AppThunk<Promise<RunBuildResult>> =>
  dispatch =>
    dispatch(triggerBuildAction({buildTypeId, branch, options})).unwrap()

export const runCustomBuild =
  (
    buildTypeId: BuildTypeId,
    branch: Branch | null | undefined,
    onTriggerBuild: (buildId: BuildId | null) => void,
    openTab: string | null | undefined,
    options: TriggerBuildOptions,
  ): AppThunk<any> =>
  (dispatch, getState) => {
    const {promoteId, stateKey, init, initFromBuild} = options
    const statusRequest = getBuildTypeStatusRequest(buildTypeId, branch)
    const key = getStatusKey(statusRequest)
    const defaultBranch: boolean = branch?.default === true || branch?.groupFlag === true
    const branchName = defaultBranch ? null : branch?.name
    const arg = {buildTypeId, branch, options, isCustom: true}
    BS?.RunBuild?.runCustomBuild(buildTypeId, {
      branchName,

      beforeTrigger() {
        dispatch(triggerBuildAction.pending('', arg))
      },

      onError(error: Error) {
        dispatch(triggerBuildAction.rejected(error, '', arg))
        BS?.Log?.error('Something went wrong: ', error)
      },

      isCustomRunDialogForRunButton: openTab != null,
      afterShowDialog:
        openTab != null
          ? () => {
              BS?.RunBuildDialog?.showTab(openTab)
            }
          : undefined,
      init: init === true ? 'true' : null,
      initFromBuild: stringifyId(initFromBuild),
      stateKey,
      ...getPromoteOptions(promoteId),
      onSuccess(itemId) {
        onTriggerBuild(itemId)
        dispatch(
          triggerBuildAction.fulfilled(
            {
              queuedBuildId: itemId,
              showDialog: false,
              autoExpandQueued: itemId != null && shouldAutoExpandQueued(getState(), key),
            },
            '',
            arg,
          ),
        )

        if (itemId != null) {
          dispatch(warnIfNoAgents(itemId))
          dispatch(fetchStatusesWithThrottle([statusRequest]))
        }
      },
    })
  }
