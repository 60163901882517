import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'

export const setUrl: CaseReducer<PipelineDraftState, PayloadAction<{id: string; url: string}>> = (
  state,
  action,
) => {
  const {id, url} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.vcsRoot.url = url
  }
}

export const setDefaultBranch: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; branch: string}>
> = (state, action) => {
  const {id, branch} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.vcsRoot.branch = branch
  }
}

export const setBranchSpec: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; branchSpecification: string}>
> = (state, action) => {
  const {id, branchSpecification} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.vcsRoot.branchSpecification = branchSpecification
  }
}
