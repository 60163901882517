import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {getJob} from './EditPipelinePage.slices.utils'

export const setAgent: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    agent?: string
  }>
> = (state, action) => {
  const {id, jobId, agent} = action.payload
  const job = getJob(state, id, jobId)

  if (job != null) {
    job['runs-on'] = agent
  }
}
