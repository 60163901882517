/* eslint-disable @typescript-eslint/no-magic-numbers */
interface Duration {
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}

export function getDuration(time: Duration) {
  let duration = 0
  if (time.seconds) {
    duration += time.seconds * 1000
  }
  if (time.minutes) {
    duration += time.minutes * 60 * 1000
  }
  if (time.hours) {
    duration += time.hours * 60 * 60 * 1000
  }
  if (time.days) {
    duration += time.days * 24 * 60 * 60 * 1000
  }
  return duration
}
