import {createFetchAction} from '../../../../../reducers/fetchable'

import {fetchRules, deleteRule, createRule, updateRule} from './Rules.requests'
import type {CleanupHolderNode, FetchRulesOptons, RuleId, Rule} from './Rules.types'

type FetchCleanupRulesArg = {
  holderNode: CleanupHolderNode
  options?: FetchRulesOptons | null | undefined
}
export const fetchCleanupRulesAction = createFetchAction(
  'fetchCleanupRules',
  async ({holderNode, options}: FetchCleanupRulesArg) => {
    const response = await fetchRules(holderNode, options)
    return response.data.holdersWithRules
  },
)
export const fetchCleanupRules = (
  holderNode: CleanupHolderNode,
  options?: FetchRulesOptons | null | undefined,
) => fetchCleanupRulesAction({holderNode, options})
type DeleteCleanupRuleArg = {
  holderNode: CleanupHolderNode
  ruleId: string
}
export const deleteCleanupRuleAction = createFetchAction(
  'deleteCleanupRule',
  ({holderNode, ruleId}: DeleteCleanupRuleArg) => deleteRule(holderNode, ruleId),
)
export const deleteCleanupRule = (holderNode: CleanupHolderNode, ruleId: string) =>
  deleteCleanupRuleAction({holderNode, ruleId})
type UpdateCleanupRuleArg = {
  holderNode: CleanupHolderNode
  rule: Rule
}
export const updateCleanupRuleAction = createFetchAction(
  'updateCleanupRule',
  ({holderNode, rule}: UpdateCleanupRuleArg) => updateRule(holderNode, rule),
)
export const updateCleanupRule = (holderNode: CleanupHolderNode, rule: Rule) =>
  updateCleanupRuleAction({holderNode, rule})
type CreateCleanupRuleArg = {
  holderNode: CleanupHolderNode
  rule: Rule
  ruleId?: RuleId
}
export const createCleanupRuleAction = createFetchAction(
  'createCleanupRule',
  async ({holderNode, rule, ruleId}: CreateCleanupRuleArg) => {
    const response = await createRule(holderNode, rule, ruleId)
    return response.data.ruleId
  },
)
export const createCleanupRule = (holderNode: CleanupHolderNode, rule: Rule, ruleId?: RuleId) =>
  createCleanupRuleAction({holderNode, rule, ruleId})
