import {createFetchAction} from '../../../reducers/fetchable'
import {restRoot} from '../../../rest/consts'

import {requestAllUsers, requestChangesCommiters} from './UserSelect.rest'

export const fetchAllUsers = createFetchAction('fetchAllUsers', () =>
  requestAllUsers(restRoot).then(data => data),
)

export const fetchChangesCommiters = createFetchAction('fetchChangesCommiters', (locator: string) =>
  requestChangesCommiters(restRoot, locator).then(data => ({locator, data})),
)
