import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

export const settings = createSlice({
  name: 'compareBuilds.settings',
  initialState: {
    showChangedOnly: true,
  },
  reducers: {
    setShowChangedOnly(state, action: PayloadAction<boolean>) {
      state.showChangedOnly = action.payload
    },
  },
})
