import {createReducer, isAnyOf} from '@reduxjs/toolkit'

import {restApi} from '../../../../services/rest'
import {submitPager} from '../Pager.actions.base'
import type {Pager} from '../Pager.types'
import {PagerGroup} from '../Pager.types'

const defaultPager = {
  hasMore: true,
  loadedLessThanRequested: null,
  openTotal: false,
  pageSize: 100,
  total: 0,
  lookupLimitWarning: false,
}

const pagerReducer = createReducer<Pager>(defaultPager, builder => {
  builder.addCase(submitPager, (state, action) => {
    if (action.meta.group === PagerGroup.CHANGE) {
      Object.assign(state, action.payload)
    }
  })
  builder.addMatcher(
    isAnyOf(
      restApi.endpoints.getAllChanges.matchFulfilled,
      restApi.endpoints.getChangeLog.matchFulfilled,
    ),
    (state, action) => {
      if (action.meta.arg.originalArgs.withUpdatePager) {
        const actionData = action.payload.changes

        if (actionData == null) {
          state.total = 0
          state.lookupLimitWarning = false
          state.openTotal = false
          return
        }

        const data = actionData.result
        const {nextHref} = action.payload
        const hasMore = nextHref != null
        const loadedLessThanRequested = data.length < state.pageSize
        const lookupLimitWarning = loadedLessThanRequested && hasMore
        state.total = data.length
        state.hasMore = hasMore
        state.openTotal = hasMore && !loadedLessThanRequested
        state.lookupLimitWarning = lookupLimitWarning
        state.loadedLessThanRequested = loadedLessThanRequested
      }
    },
  )
})

export default pagerReducer
