import type {AppThunk} from '../../../actions/types'
import {createFetchAction} from '../../../reducers/fetchable'
import {base_uri} from '../../../types/BS_types'

import requestInvestigationHistory from './InvestigationHistory.rest'

type FetchInvestigationHistoryArg = {
  locator: string
  isBackground?: boolean
}

export const fetchInvestigationHistoryAction = createFetchAction(
  'fetchInvestigationHistory',
  ({locator}: FetchInvestigationHistoryArg) => requestInvestigationHistory(base_uri, locator),
  {
    getPendingMeta: ({arg}) => ({isBackground: arg.isBackground}),
  },
)

export const fetchInvestigationHistory = (locator: string, isBackground?: boolean): AppThunk<any> =>
  fetchInvestigationHistoryAction({locator, isBackground})
