import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import type {BuildId} from '../../../types'
import {stringifyId} from '../../../types'

import type {BuildApproval} from './BuildApproval.types'

const userFields = 'id,username,email,name,avatars'
const approvalInfo =
  'approvalInfo(' +
  'status,' +
  'canBeApprovedByCurrentUser,' +
  'configurationValid,' +
  'timeoutTimestamp,' +
  `groupApprovals(groupApproval(requiredApprovalsCount,currentlyApprovedBy(user(${userFields})),group(key,name,users(count)))),` +
  `userApprovals(userApproval(user(${userFields}),approved)))`

type BuildApprovalResponse = {
  approvalInfo: BuildApproval
}

export const fetchBuildApprovalData = (
  serverUrl: string,
  buildId: BuildId,
): Promise<BuildApprovalResponse> =>
  request(
    serverUrl,
    `builds/id:${stringifyId(buildId)}?fields=${approvalInfo}`,
  ).then<BuildApprovalResponse>(processResponse)

export const requestApproveBuild = (
  serverUrl: string,
  buildId: BuildId,
): Promise<BuildApprovalResponse> =>
  request(serverUrl, `buildQueue/id:${stringifyId(buildId)}/approve`, {
    method: 'POST',
  }).then<BuildApprovalResponse>(processResponse)
