import type {WritableKeyValue} from './object'
import {safeLocalStorage} from './safeStorages'

export const LAST_AGENTS_PAGE_KEY = 'lastVisitedAgentsPath'
const cache: WritableKeyValue<string, string | null | undefined> = {
  [LAST_AGENTS_PAGE_KEY]: safeLocalStorage.getItem(LAST_AGENTS_PAGE_KEY),
}
export const getLastPage = (key: string): string | null | undefined =>
  IS_STORYBOOK ? null : cache[key]
export const setLastPage = (key: string, page: string | null | undefined) => {
  if (IS_STORYBOOK) {
    return
  }

  cache[key] = page
  page != null ? safeLocalStorage.setItem(key, page) : safeLocalStorage.removeItem(key)
}
export const getLastAgentsPage = (): string | null | undefined => getLastPage(LAST_AGENTS_PAGE_KEY)
