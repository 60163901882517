export enum HTTPCodes {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
}

export interface HTTPError {
  statusCode: number | undefined
  url: string | null | undefined
  response: Response | undefined
}

export function instanceOf<T>(object: any, member: string): object is T {
  return member in object
}

export const ROOT_PROJECT_ID = '_Root'

export enum ParameterNames {
  PipelineHead = 'teamcity.internal.pipelines.isPipelineHead',
}

export enum StepTypes {
  CLI = 'script',
  Maven = 'maven',
  Node = 'node-js',
  Gradle = 'gradle',
}

export type Step = Record<string, string>

type DependencySettings = {
  files?: string[]
}

export type Dependency = string | Record<string, DependencySettings>

export type Job = {
  steps?: Step[]
  dependencies?: Dependency[]
  integrations?: string[]
  'files-publication'?: string[]
  'runs-on'?: string
  'checkout-working-directories-only'?: boolean
}

export type PipelineSettings = {
  name: string
  jobs?: Record<string, Job>
  parameters?: Record<string, string>
  secrets?: Record<string, string>
}
