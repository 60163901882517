import moment from 'moment'

if (process.env.NODE_ENV !== 'test') {
  require('moment/locale/en-gb')
}

const LOCALES = {
  GB: 'en-GB',
  US: 'en-US',
  EN: 'en',
}
type Props = {
  locales: ReadonlyArray<string>
}
export default function setupMoment(
  {locales}: Props = {
    locales: [],
  },
): void {
  let locale = LOCALES.GB

  if (locales.length > 0) {
    const usIndex = locales.indexOf(LOCALES.US)

    if (usIndex > -1) {
      const gbIndex = locales.indexOf(LOCALES.GB)
      const enIndex = locales.indexOf(LOCALES.EN)

      if ((gbIndex === -1 || gbIndex > usIndex) && (enIndex === -1 || enIndex > usIndex)) {
        locale = LOCALES.US
      }
    }
  }

  moment.locale(locale)
}
