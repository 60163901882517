import type {ReducersMapObject} from 'redux'
import {combineReducers as reduxCombineReducers} from 'redux'

import {hasPerformanceReducerLog} from '../utils/logger'
import {performanceCallback} from '../utils/performanceCallback'

export const combineReducers: typeof reduxCombineReducers = (reducers: ReducersMapObject) => {
  if (hasPerformanceReducerLog) {
    const perfReducers = Object.fromEntries(
      Object.entries(reducers).map(([key, reducer]) => [key, performanceCallback(key, reducer)]),
    )
    return reduxCombineReducers(perfReducers)
  } else {
    return reduxCombineReducers(reducers)
  }
}
