// workaround for issue with webpack library option: https://github.com/webpack/webpack/issues/12993
// eslint-disable-next-line import/no-commonjs
const API = require('./TeamCityAPI')

declare global {
  interface Window {
    TeamCityAPI: typeof API
  }
}
window.TeamCityAPI = API
export {}
