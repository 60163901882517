import {stringifyId} from '../../../types'
import type {AgentPoolId} from '../../../types'

export function getQueuePageLocator(
  activeAgentPoolId: AgentPoolId | null | undefined,
  onlyMyPersonal: boolean,
): string {
  let locator = onlyMyPersonal ? 'personal:true,user:current' : 'personal:any'

  if (activeAgentPoolId != null) {
    locator += `,pool(id:${stringifyId(activeAgentPoolId)})`
  }

  return locator
}
