import type {ComponentEnhancer} from 'recompose'
import {branch} from 'recompose'

import classnames from 'classnames'
import compose from 'lodash/flowRight'
import * as React from 'react'

import SvgIcon from '../SvgIcon/SvgIcon'
import {withVisibility} from '../Visible/Visible.hocs'

import {allIconNames, getIconAndClassNames} from './automaton'

import iconStyles from './Icon.css'

export const allIcons = allIconNames
type Size = 'S' | 'M'
type OwnProps = {
  name: string
  className?: string
  isVisible?: boolean
  size?: Size
  title?: string
  'aria-label'?: string
}
type Props = OwnProps & {
  size: Size
}

class Icon extends React.PureComponent<Props> {
  static defaultProps = {
    size: 'S',
  }

  render() {
    const {name, className, isVisible, size, ...restProps} = this.props
    const [myIcon, classes] = getIconAndClassNames(name, size === 'M')
    const allClasses = classnames(iconStyles.tcIcon, className, classes, {
      [iconStyles.animate]: isVisible,
    })
    const iconName = size === 'M' ? `${name}-20px` : name
    const icon = myIcon ?? iconName
    const iconProps = {...restProps, icon, 'data-test-icon': name}
    const withPerson = name.match('personal_running') || name.match('my_running')
    const detached = name.match('detached')

    if (withPerson || detached) {
      return (
        <div className={classnames(allClasses, iconStyles.wrapper)}>
          <SvgIcon {...iconProps} className={classnames(withPerson && iconStyles.staticPerson)} />
          <SvgIcon
            {...iconProps}
            className={classnames(withPerson ? iconStyles.spinningPerson : iconStyles.detached, {
              [iconStyles.animate]: isVisible,
            })}
          />
        </div>
      )
    }

    switch (name) {
      case 'build_chain':
      case 'dropdownTrigger':
      case 'dropdownTriggerSpecial':
        return <span {...restProps} className={allClasses} />

      default:
        return <SvgIcon {...iconProps} className={allClasses} />
    }
  }
}

export default compose(
  React.memo,
  branch(
    (props: Props) => props.name.includes('running'),
    withVisibility(null, 'span') as ComponentEnhancer<Props, Props>,
  ),
)(Icon)
