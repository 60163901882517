import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {deleteIfEmpty} from './EditPipelinePage.slices.utils'

export const setSecret: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    name: string
    value: string
  }>
> = (state, action) => {
  const {id, name, value} = action.payload

  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.settings.secrets ??= {}
    draft.settings.secrets[name] = value
  }
}

export const renameSecret: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    name: string
    savedName: string
  }>
> = (state, action) => {
  const {id, name, savedName} = action.payload

  if (name !== savedName) {
    state[id] ??= {}
    state[id]!.renamed ??= {}
    state[id]!.renamed!.secrets ??= {}
    state[id]!.renamed!.secrets![savedName] = name
  } else if (state[id]?.renamed?.secrets?.[savedName] != null) {
    delete state[id]!.renamed!.secrets![savedName]
    deleteIfEmpty(state[id]!.renamed, 'secrets') && deleteIfEmpty(state[id], 'renamed')
  }
}

export const deleteSecret: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    name: string
  }>
> = (state, action) => {
  const {id, name} = action.payload

  state[id] ??= {}
  state[id]!.deleted ??= {}
  state[id]!.deleted!.secrets ??= []
  state[id]!.deleted!.secrets!.push(name)
}

export const restoreSecret: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    name: string
  }>
> = (state, action) => {
  const {id, name} = action.payload
  const {deleted} = state[id] ?? {}

  if (deleted?.secrets?.includes(name)) {
    deleted.secrets = deleted.secrets.filter(item => item !== name)
    deleteIfEmpty(deleted, 'secrets') && deleteIfEmpty(state[id], 'deleted')
  }
}
