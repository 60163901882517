import type {Middleware} from '@reduxjs/toolkit'
import memoize from 'memoize-one'

import type {State} from '../reducers/types'

const loadStatisticsChunk = memoize(
  () =>
    import(
      /* webpackChunkName: "statistics", webpackPrefetch: true */
      '.'
    ),
)
const middleware: Middleware<{}, State> = store => next => action => {
  const state = store.getState()
  loadStatisticsChunk().then(({track}) => track(state, action))
  return next(action)
}

export default middleware
