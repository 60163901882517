import {useEffect, useContext} from 'react'

import {subscribeOnPermission} from '../actions'
import type {AppDispatch} from '../actions/types'
import {BuildLineLayoutContext} from '../contexts/buildLineLayout'
import useMyId from '../hooks/useMyId'
import {hasPermission} from '../selectors'

import {ALL_PROJECTS, ROOT_PROJECT_ID} from '../types'
import type {Permission, ProjectId} from '../types'

import withHook from './withHook'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'

export function usePermissionSubscription(permission: Permission, projectId?: ProjectId | null) {
  const dispatch: AppDispatch = useAppDispatch()
  const myId = useMyId()
  const {showPath = false} = useContext(BuildLineLayoutContext)
  const requestedProjectId = projectId === ROOT_PROJECT_ID || !showPath ? projectId : ALL_PROJECTS
  useEffect(
    () =>
      myId != null && requestedProjectId != null
        ? dispatch(subscribeOnPermission(permission, requestedProjectId, myId))
        : undefined,
    [permission, dispatch, myId, requestedProjectId],
  )
}
export function usePermission(
  permission: Permission,
  projectId: ProjectId | null | undefined,
  nullable: true,
): boolean | null
export function usePermission(
  permission: Permission,
  projectId?: ProjectId | null,
  nullable?: false,
): boolean
export function usePermission(
  permission: Permission,
  projectId?: ProjectId | null,
  nullable: boolean = false,
): boolean | null {
  usePermissionSubscription(permission, projectId)
  return useAppSelector(
    state =>
      (projectId != null ? hasPermission(state, permission, projectId, true) : null) ??
      (nullable ? null : false),
  )
}
export default <P>(
  permissions: ReadonlyArray<Permission>,
  getProjectId: (props: P) => ProjectId | null | undefined,
) =>
  withHook((props: P) => {
    const projectId = getProjectId(props)

    for (const permission of permissions) {
      // permissions array never changes
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePermissionSubscription(permission, projectId)
    }
  })
