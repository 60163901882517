export enum ParameterGroup {
  Configuration = 'Configuration',
  System = 'System',
  Environment = 'Environment',
  Dependencies = 'Dependencies',
}

export enum ItemType {
  CategoryHeader = 'CategoryHeader',
  GroupHeader = 'GroupHeader',
  GroupDescription = 'GroupDescription',
  TableHeader = 'TableHeader',
  Parameter = 'Parameter',
  AddedParameter = 'AddedParameter',
  ModifiedParameter = 'ModifiedParameter',
  Empty = 'Empty',
}

export enum ParameterCategory {
  Agent = 'Agent',
  UserDefined = 'UserDefined',
  Actual = 'Actual',

  ActualOnAgent = 'ActualOnAgent',
}

export interface CategoryItem {
  type: ItemType.CategoryHeader
  key: string

  category: ParameterCategory
}

export interface GroupItem {
  type: ItemType.GroupHeader
  key: string

  group: ParameterGroup
  category: ParameterCategory

  matches?: number
}

export interface GroupDescriptionItem {
  type: ItemType.GroupDescription
  key: string

  group: ParameterGroup
}

export interface TableHeaderItem {
  type: ItemType.TableHeader
  key: string

  name?: string
  value?: string
}

export interface ParameterItem {
  type: ItemType.Parameter
  key: string

  name: string
  value: string

  defaultValue?: string
  isAddedParameter?: boolean
  highlight: string
}

export interface ModifiedParameterItem {
  type: ItemType.ModifiedParameter
  key: string

  name: string
  value: string

  defaultValue: string

  highlight: string
}

export interface AddedParameterItem {
  type: ItemType.AddedParameter
  key: string

  name: string
  value: string

  highlight: string
}

interface EmptyItem {
  type: ItemType.Empty
  key: string
}

export type VirtualizerItem =
  | CategoryItem
  | GroupItem
  | GroupDescriptionItem
  | TableHeaderItem
  | EmptyItem
  | ParameterItem
  | ModifiedParameterItem
  | AddedParameterItem
