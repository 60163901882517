import * as React from 'react'

import {fetchApproval} from './BuildApproval.actions'
import {BuildApprovalViewContext} from './BuildApproval.context'
import {getBuildApprovalDetails} from './BuildApproval.selectors'

import type {Props} from './BuildApproval.types'
import {BuildApprovalViewMode} from './BuildApproval.types'
import ApprovalClassic from './Classic/BuildApproval.classic'
import ApprovalInline from './Inline/BuildApproval'
import ApprovalPopup from './Popup/DetailsApproval'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'

export default function BuildApprovalLayout(props: Props) {
  const {buildId, view} = props
  const approvalInfo = useAppSelector(state => getBuildApprovalDetails(state, buildId))
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(fetchApproval({buildId}))
  }, [buildId, dispatch])

  let Tag
  let contextValue

  switch (view) {
    case BuildApprovalViewMode.CLASSIC_UI:
      Tag = ApprovalClassic
      contextValue = BuildApprovalViewMode.INLINE
      break
    case BuildApprovalViewMode.POPUP:
      Tag = ApprovalPopup
      contextValue = BuildApprovalViewMode.POPUP
      break
    default:
      contextValue = BuildApprovalViewMode.INLINE
      Tag = ApprovalInline
  }

  return (
    <BuildApprovalViewContext.Provider value={contextValue}>
      <Tag approvalInfo={approvalInfo} buildId={buildId} />
    </BuildApprovalViewContext.Provider>
  )
}
