import type {AppThunk} from '../../../actions/types'
import {createFetchAction} from '../../../reducers/fetchable'
import {getExtensionEndpoint} from '../../../selectors'
import type {BuildId, UrlExtension} from '../../../types'
import {base_uri} from '../../../types/BS_types'
import {ActionThrottlerWithArrayCollect} from '../../../utils/actionThrottler'

import requestBuildLogMessages, {requestTimeline} from './BuildLog.rest'
import {getBuildLogTimeline, getFullLogState, getMessagesLoadStates} from './BuildLog.selectors'
import {settings} from './BuildLog.slices'
import type {
  LogView,
  BuildLogMessagesResponse,
  BuildLogMessagesResponseAnchor,
  FetchMessagesParams,
} from './BuildLog.types'
import {getKeyForBuildLogData, getLogViewUrlParam} from './BuildLog.utils'

type FetchBuildLogMessagesPayload = {
  data: BuildLogMessagesResponse
  focusLine?: number | null
  logView?: LogView
}

type FetchBuildLogMessagesArg = FetchMessagesParams & {
  extension?: UrlExtension<any>
}
export const fetchBuildLogMessagesAction = createFetchAction(
  'fetchBuildLogMessages',
  async ({
    buildId,
    options = {},
    invalidate,
    extension,
  }: FetchBuildLogMessagesArg): Promise<FetchBuildLogMessagesPayload> => {
    const response: BuildLogMessagesResponse = await requestBuildLogMessages(
      extension?.serverUrl ?? base_uri,
      extension?.endpoint ?? '',
      buildId,
      options,
    )
    let focusLine: number | null | undefined
    let logView: LogView

    if (
      options.stageKey != null &&
      response.stageTarget != null &&
      response.stageTarget.key === options.stageKey
    ) {
      const anchor: BuildLogMessagesResponseAnchor | null | undefined =
        response.stageTarget.anchors.find(item => item.type === 'BUILD_STAGE_START')

      if (anchor != null) {
        focusLine = anchor.position
      }
    } else if (invalidate === true && options.logAnchor === 0) {
      const firstVisible = response.messages.find(message => message.level > 0)

      if (firstVisible != null) {
        focusLine = firstVisible.id
      }
    }

    if (options.logAnchor === -1) {
      const last = response.messages[response.messages.length - 1]

      if (last != null) {
        focusLine = last.id
      }
    }

    if (response.view != null) {
      logView = response.view
    }
    if (response.focusIndex != null) {
      focusLine = response.focusIndex
    }

    return {
      data: response,
      focusLine,
      logView,
    }
  },
  {
    getPendingMeta: ({arg}) => ({invalidate: arg.invalidate}),
  },
)

export const fetchBuildLogMessages =
  (params: FetchMessagesParams): AppThunk<any> =>
  (dispatch, getState) => {
    const {target, options} = params
    const filter = target != null ? getFullLogState(getState(), target).filter : null
    const defaultOptions = {
      filter,
    }
    const allOptions = {...defaultOptions, ...options}
    const extension = getExtensionEndpoint(getState(), 'fetchExpandedBuildLogMessage')
    if (extension && !window.GLOBAL_FETCH_DISABLED) {
      dispatch(fetchBuildLogMessagesAction({...params, options: allOptions, extension}))
    }
  }

type FetchBuildLogTimelineArg = {
  buildId: BuildId
  extension?: UrlExtension<any>
}
export const fetchBuildLogTimelineAction = createFetchAction(
  'fetchBuildLogTimeline',
  ({buildId, extension}: FetchBuildLogTimelineArg) =>
    requestTimeline(
      extension?.serverUrl ?? base_uri,
      extension?.endpoint ?? '',
      buildId,
      getLogViewUrlParam(),
    ),
)
const fetchBuildLogTimeline =
  (buildId: BuildId): AppThunk =>
  (dispatch, getState) => {
    const state = getState()
    const extension = getExtensionEndpoint(state, 'fetchBuildLogTimeline')
    const timeline = getBuildLogTimeline(state, buildId)

    if (extension != null && !window.GLOBAL_FETCH_DISABLED && !timeline?.loading) {
      dispatch(fetchBuildLogTimelineAction({buildId, extension}))
    }
  }

const fetchBuildLogTimelines =
  (buildIds: ReadonlyArray<BuildId>): AppThunk<any> =>
  dispatch =>
    [...new Set(buildIds)].forEach(buildId => dispatch(fetchBuildLogTimeline(buildId)))

const fetchTimelineThrottleTime = 200
const fetchBuildLogTimelineThrottler = new ActionThrottlerWithArrayCollect(
  fetchBuildLogTimelines,
  [],
  fetchTimelineThrottleTime,
)
export const fetchBuildLogTimelineWithThrottle = (buildId: BuildId): AppThunk<void> =>
  fetchBuildLogTimelineThrottler.fetch([buildId])
export const fetchBuildLogPreview =
  (buildId: BuildId, messagesCount: number): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState()
    const buildLogKey = getKeyForBuildLogData({
      type: 'preview',
      id: buildId,
    })
    const loadState = getMessagesLoadStates(state, buildLogKey)?.tail

    if (loadState?.loading === true) {
      return
    }

    dispatch(
      fetchBuildLogMessages({
        options: {
          target: 'tail',
          count: [0, -messagesCount * 2],
          //Load more lines because some may be empty
          expandAll: true,
        },
        buildLogKey,
        buildId,
      }),
    )
  }
export const setSoftWrapLinesInBuildlog = settings.actions.setSoftWrapLines
