import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import {receiveBuildWSDataAction} from '../../../actions/builds'
import type {NormalizedBuilds} from '../../../rest/schemata'

export const buildArtifacts = createSlice({
  name: 'buildArtifacts',
  initialState: {},
  reducers: {
    receive(state, action: PayloadAction<NormalizedBuilds>) {
      Object.assign(state, action.payload.entities.buildArtifacts)
    },
  },
  extraReducers: builder => {
    builder.addCase(receiveBuildWSDataAction, (state, action) => {
      Object.assign(state, action.payload)
    })
  },
})
