/*
 * Copy-paste from https://github.com/jquery/jquery/blob/e185aa3f06a0da69dc001b81425354b9c05e40fd/src/css.js + fastdom
 */
import {getSandbox} from './fastdom'
import type {WritableKeyValue} from './object'

const jQuery: JQueryStatic = window.$j

function getDisplay(elem: Element) {
  return getSandbox(elem).measure(() => jQuery.css(elem, 'display'))
}

function setDisplay(elem: HTMLElement, display: string) {
  return getSandbox(elem).mutate(() => {
    elem.style.display = display || ''
    return elem
  })
}

async function isHidden(elem: Element) {
  return (
    !jQuery.contains(elem.ownerDocument.documentElement, elem) ||
    (await getDisplay(elem)) === 'none'
  )
}

const elemdisplay: WritableKeyValue<keyof HTMLElementTagNameMap, string> = {
  // Support: Firefox
  // We have to pre-define these values for FF (#10227)
  html: 'block',
  body: 'block',
}

/**
 * Retrieve the actual display of a element
 * @param {String} name nodeName of the element
 * @param {Object} doc Document object
 */
// Called only from within defaultDisplay
async function actualDisplay(name: keyof HTMLElementTagNameMap, doc: Document): Promise<string> {
  const {body} = doc
  const sandbox = getSandbox()
  const elem = await sandbox.mutate(() => jQuery(doc.createElement(name)).appendTo(body))
  const display = await getDisplay(elem[0])
  sandbox.mutate(() => {
    // We don't have any data stored on the element,
    // so use "detach" method as fast way to get rid of the element
    elem.detach()
  })
  return display
}

async function iframeDisplay(nodeName: keyof HTMLElementTagNameMap, doc: Document) {
  const sandbox = getSandbox()
  const iframe = await sandbox.mutate(() =>
    // Use the already-created iframe if possible
    (iframe || jQuery("<iframe frameborder='0' width='0' height='0'/>")).appendTo(
      doc.documentElement,
    ),
  )
  // Always write a new HTML skeleton so Webkit and Firefox don't choke on reuse
  const iframeDoc = iframe[0].contentDocument
  // Support: IE
  iframeDoc.write()
  iframeDoc.close()
  const display = await actualDisplay(nodeName, iframeDoc)
  iframe.detach()
  return display
}

/**
 * Try to determine the default display value of an element
 * @param {String} nodeName
 */
async function defaultDisplay(nodeName: keyof HTMLElementTagNameMap) {
  const doc = document
  let display = elemdisplay[nodeName]

  if (!display) {
    display = await actualDisplay(nodeName, doc)

    // If the simple way fails, read from inside an iframe
    if (display === 'none' || display == null) {
      display = await iframeDisplay(nodeName, doc)
    }

    // Store the correct default display
    elemdisplay[nodeName] = display
  }

  return display
}

async function setDefaultDisplayIfNeeded(elem: HTMLElement) {
  // Set elements which have been overridden with display: none
  // in a stylesheet to whatever the default browser style is
  // for such an element
  if (elem.style.display === '' && (await isHidden(elem))) {
    const display = await defaultDisplay(elem.nodeName.toLowerCase() as keyof HTMLElementTagNameMap)

    jQuery.data<string>(elem, 'olddisplay', display)

    return setDisplay(elem, display)
  }

  return elem
}

async function showHideElem(elem: HTMLElement, show: boolean) {
  getSandbox(elem).clear()

  if (!elem.style) {
    return elem
  }

  const nextDisplay = jQuery.data(elem, 'olddisplay')

  const display = elem.style.display

  if (show) {
    if (nextDisplay) {
      return setDisplay(elem, nextDisplay)
    }

    if (display === 'none') {
      // Reset the inline display of this element to learn if it is
      // being hidden by cascaded rules or not
      await setDisplay(elem, '')
    }

    return setDefaultDisplayIfNeeded(elem)
  } else {
    const hidden = await isHidden(elem)

    if ((display && display !== 'none') || !hidden) {
      jQuery.data(elem, 'olddisplay', hidden ? display : await getDisplay(elem))
    }

    return setDisplay(elem, 'none')
  }
}

export default function showHide(
  elements: ReadonlyArray<HTMLElement>,
  show: boolean,
): Promise<ReadonlyArray<HTMLElement>> {
  return Promise.all(elements.map(elem => showHideElem(elem, show)))
}
