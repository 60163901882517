import type {FC} from 'react'
import * as React from 'react'
import {Suspense} from 'react'

import {useIsPipelinesSelected} from '../../../../../../hooks/routes'
import HeaderBanner from '../../../../../App/Header/HeaderBanner/HeaderBanner'

import {isPipelinesEnabledInExclusiveMode} from '../../../utils/featureToggles'

import styles from './PipelinesBetaBanner.css'

type OwnProps = {
  isAdmin: 'true' | string
}

const PipelinesBetaBanner: FC<OwnProps> = ({isAdmin}) => {
  const isPipelinesPage = useIsPipelinesSelected()
  const shouldShow = isPipelinesPage || (isAdmin === 'true' && isPipelinesEnabledInExclusiveMode)

  if (!shouldShow && !IS_STORYBOOK) {
    return null
  }

  return (
    <Suspense fallback={<div data-suspense-fallback className={styles.placeholder} />}>
      <HeaderBanner
        content={
          "You're browsing the early pre-release version of TeamCity Pipelines that may contain deficiencies"
        }
      />
    </Suspense>
  )
}

export default PipelinesBetaBanner
