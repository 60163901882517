import {produce} from 'immer'

import {restBaseApi} from '../../../../services/restBase'
import {GITHUB_REPOSITORIES_ENDPOINT} from '../CreatePipelinePage/CreatePipelinePage.constants'
import {
  getPreformattingIntegrations,
  getPreformattingSettings,
  parsePipeline,
  stringifyPipeline,
} from '../EditPipelinePage/EditPipelinePage.utils'

import type {
  NewPipelineDto,
  RepositoryGroup,
  PipelineDto,
  Pipeline,
  UpdatePipelineArg,
  NewPipeline,
} from './pipelinesApi.types'

const url = '/app/pipeline'

const processNewPipeline = ({settings, integrations, ...rest}: NewPipeline): NewPipelineDto => ({
  ...rest,
  integrations: produce(integrations, getPreformattingIntegrations),
  yaml: stringifyPipeline(settings, {
    replacer: getPreformattingSettings,
    noRefs: true,
  }),
})

const processPipeline = ({yaml, ...rest}: PipelineDto): Pipeline => ({
  ...rest,
  settings: parsePipeline(yaml),
})

export const pipelinesApi = restBaseApi.injectEndpoints({
  endpoints: build => ({
    getGitHubRepositories: build.query<RepositoryGroup[], void>({
      query: () => ({url: GITHUB_REPOSITORIES_ENDPOINT}),
    }),
    getAllPipelines: build.query<Pipeline[], void>({
      query: () => ({url}),
      transformResponse: (data: PipelineDto[]) => data.map(processPipeline),
      providesTags: data => [
        ...(data?.map(({id}) => ({type: 'Pipeline' as const, id})) ?? []),
        {type: 'Pipeline', id: 'LIST'},
      ],
    }),
    getPipelineById: build.query<Pipeline, string>({
      query: id => ({url: `${url}/${id}`}),
      transformResponse: processPipeline,
      providesTags: data => [{type: 'Pipeline', id: `${data?.id}-SINGLE`}],
    }),
    createNewPipeline: build.mutation<Pipeline, NewPipeline>({
      query: body => ({url, method: 'POST', body: processNewPipeline(body)}),
      transformResponse: processPipeline,
      invalidatesTags: [{type: 'Pipeline', id: 'LIST'}],
    }),
    updatePipeline: build.mutation<Pipeline, UpdatePipelineArg>({
      query: ({id, body}) => ({
        url: `${url}/${id}`,
        method: 'POST',
        body: processNewPipeline(body),
      }),
      transformResponse: processPipeline,
      invalidatesTags: (_, __, {id}) => [{type: 'Pipeline', id}],
      async onQueryStarted({id}, {dispatch, queryFulfilled}) {
        const {data} = await queryFulfilled
        dispatch(pipelinesApi.util.updateQueryData('getPipelineById', id, () => data))
      },
    }),
    deletePipeline: build.mutation<void, string>({
      query: id => ({url: `${url}/${id}`, method: 'DELETE'}),
      invalidatesTags: (_, __, id) => [
        {type: 'Pipeline', id},
        {type: 'Pipeline', id: `${id}-SINGLE`},
      ],
    }),
  }),
})
