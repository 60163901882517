import {keyValueFetchable} from '../../../../../../reducers/fetchable'
import type {BuildTypeHierarchy} from '../../../../../../types'

import {fetchChangeBuildTypeHierarchy} from './ChangeBuildTypeSelect.actions'

const changeProjectsSelectReducer = keyValueFetchable(
  arg => arg,
  fetchChangeBuildTypeHierarchy,
  null as BuildTypeHierarchy | null,
  (_, action) => action.payload,
)

export default changeProjectsSelectReducer
