import {objectEntries} from './object'
import type {KeyValue} from './object'

export default function invertDictionary<K extends PropertyKey, V extends PropertyKey>(
  src: KeyValue<K, V>,
): KeyValue<V, K> {
  return objectEntries(src).reduce(
    (dest, [key, value]: [K, V | undefined]) => (value != null ? {...dest, [value]: key} : dest),
    Object.freeze({}),
  )
}
