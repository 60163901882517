import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {getJob} from './EditPipelinePage.slices.utils'

export const setFilesPublication: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    files: undefined | string[]
  }>
> = (state, action) => {
  const {id, jobId, files} = action.payload
  const job = getJob(state, id, jobId)

  if (job != null) {
    job['files-publication'] = files
  }
}
