import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {Pipeline} from '../../services/pipelinesApi.types'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'

export const set: CaseReducer<PipelineDraftState, PayloadAction<Pipeline>> = (state, action) => {
  const data = action.payload
  state[data.id] = {draft: data, original: data}
}

export const reset: CaseReducer<PipelineDraftState, PayloadAction<string>> = (state, action) => {
  const {original} = state[action.payload] ?? {}
  state[action.payload] = {original, draft: original}
}

export const setPipelineName: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; name: string}>
> = (state, action) => {
  const {id, name} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.settings.name = name
  }
}
