/* eslint-disable @typescript-eslint/no-magic-numbers */
import type {FilterConfig} from '../types'
import {createFilterMatrix} from '../utils/matrix'

function toSVGMatrix(matrix: number[][]) {
  return matrix
    .slice(0, 4)
    .map(m => m.map(item => item.toFixed(3)).join(' '))
    .join(' ')
}

export function getSVGFilterMatrixValue(config: FilterConfig) {
  return toSVGMatrix(createFilterMatrix(config))
}
