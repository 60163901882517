import type {PayloadAction} from '@reduxjs/toolkit'
import {SHOULD_AUTOBATCH} from '@reduxjs/toolkit'

const defaultPeriod = 2000
const defaultThreshold = 30

export const collectActionsMiddleware = (period = defaultPeriod, threshold = defaultThreshold) => {
  let actions: PayloadAction<unknown>[] = []
  let batchedActions: PayloadAction<unknown>[] = []

  function logActions() {
    setTimeout(() => {
      if (actions.length > threshold) {
        // eslint-disable-next-line no-console
        console.warn(
          `not batched ${actions.length} action sequences in ${period} happened `,
          actions,
          batchedActions,
        )
      }
      batchedActions = []
      actions = []
      logActions()
    }, period)
  }

  logActions()
  return () =>
    (next: (action: PayloadAction<unknown>) => PayloadAction<unknown>) =>
    (action: PayloadAction<unknown, string, {[SHOULD_AUTOBATCH]?: boolean}>) => {
      if (action?.meta?.[SHOULD_AUTOBATCH]) {
        batchedActions.push(action)
      } else {
        actions.push(action)
      }

      return next(action)
    }
}
