export enum FilterMode {
  LIGHT = 0,
  DARK = 1,
}

export interface Theme {
  mode: FilterMode
  brightness: number
  contrast: number
  grayscale: number
  sepia: number
  stylesheet: string
  darkSchemeBackgroundColor: string
  darkSchemeTextColor: string
  darkSchemeSelectionColors: {bg: string; fg: string}
  lightSchemeBackgroundColor: string
  lightSchemeTextColor: string
  lightSchemeSelectionColors: {bg: string; fg: string}
}

export type FilterConfig = Theme
