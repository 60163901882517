import processResponse from '../../../../rest/processResponse'
import request from '../../../../rest/request'

import type {ChangeBuild, ChangeDeployment} from './ChangeDetailsTabs.types'

type BuildTypeIdsData = {
  buildType: ReadonlyArray<ChangeBuild>
}
export const requestChangeBuildTypes = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeBuild>> =>
  request(serverUrl, `changes/${encodeURIComponent(locator)}/buildTypes?fields=buildType(id,type)`)
    .then<BuildTypeIdsData>(processResponse)
    .then(data => {
      const buildTypes = data.buildType
      return buildTypes.filter(
        (buildType, index) => !buildTypes.slice(0, index).some(other => other.id === buildType.id),
      )
    })

type DeploymentsIdsData = {
  buildType: ReadonlyArray<ChangeDeployment>
}
export const requestChangeDeployments = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeDeployment>> =>
  request(
    serverUrl,
    `changes/${encodeURIComponent(locator)}/deploymentConfigurations?fields=buildType(id)`,
  )
    .then<DeploymentsIdsData>(processResponse)
    .then(data => data?.buildType)
