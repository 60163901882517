import * as Redux from 'redux'

import {keyValueFetchable} from '../../../reducers/fetchable'
import {emptyArray} from '../../../utils/empty'

import {fetchWaitReasonsAction} from './BuildQueueInfo.actions'
import type {BuildQueueInfoState} from './BuildQueueInfo.types'

const waitReasonsReducer = keyValueFetchable(
  String,
  fetchWaitReasonsAction,
  emptyArray,
  (_, action) => action.payload,
)
const queueInfoReducer = Redux.combineReducers<BuildQueueInfoState>({
  waitReasons: waitReasonsReducer,
})
export default queueInfoReducer
