import {getSandbox} from './fastdom'

export function closestUpTo(
  el: EventTarget | null | undefined,
  stopAt: Element | null | undefined,
  selector: string,
): Element | null | undefined {
  if (!el || !(el instanceof Element) || el === stopAt) {
    return null
  }

  if (el.matches(selector)) {
    return el
  }

  return closestUpTo(el.parentElement, stopAt, selector)
}
export function closest(
  el: EventTarget | null | undefined,
  selector: string,
): Element | null | undefined {
  return closestUpTo(el, null, selector)
}
export function contains(parent: EventTarget | null | undefined, child: EventTarget): boolean {
  if (parent instanceof Node && child instanceof Node) {
    return parent.contains(child)
  }

  return false
}
export function matches(el: EventTarget | null | undefined, selector: string): boolean {
  return el != null && el instanceof Element && el.matches(selector)
}
export async function scrollIntoViewIfNeeded(
  element: Element,
  options?: boolean | ScrollIntoViewOptions,
) {
  const sandbox = getSandbox(element)
  const needsScroll = await sandbox.measure(() => {
    const {top, right, bottom, left} = element.getBoundingClientRect()
    return top < 0 || right > window.innerWidth || bottom > window.innerHeight || left < 0
  })

  if (needsScroll) {
    await sandbox.mutate(() => element.scrollIntoView(options))
  }
}
export function focusSelfOrChildLink(element: Element | null | undefined) {
  if (element == null || !(element instanceof HTMLElement)) {
    return
  }

  const elementToFocus = element.tabIndex >= 0 ? element : element.querySelector('a')

  if (elementToFocus == null) {
    return
  }

  elementToFocus.focus()
}
