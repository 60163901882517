import type {PayloadAction} from '@reduxjs/toolkit'

import {isElementVisible} from '../components/common/Visible/Visible.utils'
import type {Id} from '../types'
import {getEntityId} from '../utils/namespaces'
import type {NS} from '../utils/namespaces'

import type {RequestsPool} from '../utils/requestQueue'

import type {AppDispatch, AppThunk} from './types'

const maxBatchTime = 1500 //ms

type Options<T> = {
  queue: RequestsPool
  namespace: NS
  request: (id: Id, dispatch: AppDispatch) => Promise<ReadonlyArray<T>>
  action: (data: ReadonlyArray<T>) => PayloadAction<unknown>
}
export default function batchVisible<T>({
  queue,
  namespace,
  request,
  action,
}: Options<T>): (id: Id) => AppThunk<void> {
  let batch: ReadonlyArray<T> = []
  let flushTO: number | null | undefined
  let hadMoreVisibleInQueue: boolean

  const flush = (): AppThunk<any> => dispatch => {
    if (flushTO != null) {
      window.clearTimeout(flushTO)
      flushTO = null
    }

    dispatch(action(batch))
    batch = []
  }

  const addToBatch =
    (data: ReadonlyArray<T>): AppThunk<any> =>
    dispatch => {
      batch = batch.concat(data)

      if (flushTO == null) {
        flushTO = window.setTimeout(() => dispatch(flush()), maxBatchTime)
      }
    }

  return id => dispatch => {
    const entityId = getEntityId(namespace, id)
    queue.add(entityId, async () => {
      const data = await request(id, dispatch)
      dispatch(addToBatch(data))
      const hasMoreVisibleInQueue = queue.queue.some(
        entry =>
          entry.id !== entityId &&
          entry.id.startsWith(`${namespace}:`) &&
          isElementVisible(entry.id),
      )

      if (hadMoreVisibleInQueue && !hasMoreVisibleInQueue) {
        dispatch(flush())
      }

      hadMoreVisibleInQueue = hasMoreVisibleInQueue
    })
  }
}
