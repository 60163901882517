import throttle from 'lodash/throttle'

import type {ActionCreator, AppDispatch, AppThunk} from '../actions/types'

import type {KeyValue} from './object'

const defaultThrottleTime = 100 //ms

type AggregationTypeArray<V> = ReadonlyArray<V>
export class ActionThrottler<V> {
  action: (arg0: AppDispatch) => unknown
  throttledAction: (arg0: AppDispatch) => unknown
  lastData: V | null = null
  constructor(action: ActionCreator<V>, throttleTime?: number, callFirst?: boolean) {
    this.action = (dispatch: AppDispatch) => {
      if (this.lastData != null) {
        dispatch(action(this.lastData))
      }
    }

    this.throttledAction = throttle(this.action, throttleTime ?? defaultThrottleTime, {
      leading: callFirst,
      trailing: true,
    })
  }

  fetch(data: V, force?: boolean): AppThunk<any> {
    this.lastData = data
    return force === true ? this.action : this.throttledAction
  }
}
export class ActionThrottlerWithArrayCollect {
  action: AppThunk<void>
  throttledAction: AppThunk<void>
  aggregation: AggregationTypeArray<any> = []
  constructor(
    action: ActionCreator<AggregationTypeArray<any>>,
    defaultAggregation: AggregationTypeArray<any>,
    throttleTime?: number,
  ) {
    this.action = (dispatch: AppDispatch) => {
      dispatch(action(this.aggregation))
      this.aggregation = []
    }

    this.throttledAction = throttle(this.action, throttleTime ?? defaultThrottleTime)
    this.aggregation = defaultAggregation
  }

  fetch(data: AggregationTypeArray<any>, force?: boolean): AppThunk<void> {
    this.aggregation = [...this.aggregation, ...data]
    return force === true ? this.action : this.throttledAction
  }
}
export class ActionThrottlerWithObjectCollect<V> {
  action: (arg0: AppDispatch) => unknown
  throttledAction: (arg0: AppDispatch) => unknown
  aggregation: KeyValue<string | number, V> = {}
  constructor(
    action: ActionCreator<KeyValue<string | number, V>>,
    defaultAggregation: KeyValue<string | number, V>,
    throttleTime?: number,
    throttleOptions?: {leading?: boolean | undefined; trailing?: boolean | undefined},
  ) {
    this.action = (dispatch: AppDispatch) => {
      dispatch(action(this.aggregation))
      this.aggregation = {}
    }

    this.throttledAction = throttle(
      this.action,
      throttleTime ?? defaultThrottleTime,
      throttleOptions,
    )
    this.aggregation = defaultAggregation
  }

  fetch(data: KeyValue<string | number, V>, force?: boolean): AppThunk<any> {
    this.aggregation = {...this.aggregation, ...data}
    return force === true ? this.action : this.throttledAction
  }

  cancel(key: string | number) {
    const {[key]: canceled, ...rest} = this.aggregation
    this.aggregation = rest
  }
}
