import {createFetchAction} from '../../../reducers/fetchable'
import HTTPError from '../../../rest/errors/HTTPError'
import type {ProjectInternalId} from '../../../types'

import {
  fetchPolicies,
  resetPolicy as resetPolicyRequest,
  getDiskUsage as getDiskUsageRequest,
  updatePolicy,
  setPolicyDisable,
} from './CleanupProjectPage.requests'
import type {PolicyHolder} from './CleanupProjectPage.types'

type FetchProjectPoliciesArg = {
  projectInternalId: ProjectInternalId
  includeSubprojects?: boolean
}
export const fetchProjectPoliciesAction = createFetchAction(
  'fetchProjectPolicies',
  async (
    {projectInternalId, includeSubprojects = false}: FetchProjectPoliciesArg,
    {rejectWithValue},
  ) => {
    try {
      const response = await fetchPolicies(projectInternalId, {
        includeSubprojects,
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error instanceof HTTPError ? await error.response?.json() : null)
    }
  },
)
export const fetchProjectPolicies = (
  projectInternalId: ProjectInternalId,
  {
    includeSubprojects,
  }: {
    includeSubprojects: boolean
  },
) => fetchProjectPoliciesAction({projectInternalId, includeSubprojects})
type DisableProjectPolicyArg = {
  holder: PolicyHolder
  cleanupPoliciesDisabled: boolean | null | undefined
}
export const disableProjectPolicyAction = createFetchAction(
  'disableProjectPolicy',
  ({holder, cleanupPoliciesDisabled}: DisableProjectPolicyArg) =>
    setPolicyDisable(holder, cleanupPoliciesDisabled),
)
export const disableProjectPolicy = (
  holder: PolicyHolder,
  cleanupPoliciesDisabled: boolean | null | undefined,
) => disableProjectPolicyAction({holder, cleanupPoliciesDisabled})
export const resetPolicy = createFetchAction(
  'resetPolicy',
  async (holder: PolicyHolder, {rejectWithValue}) => {
    try {
      return await resetPolicyRequest(holder)
    } catch (error) {
      return rejectWithValue(error instanceof HTTPError ? await error.response?.json() : null)
    }
  },
)
export const editPolicy = createFetchAction('editPolicy', updatePolicy)
export const getDiskUsage = createFetchAction('getDiskUsage', getDiskUsageRequest)
