import type {State} from '../../../../../reducers/types'
import {getStatusKey} from '../../../../../rest/schemata'
import {
  getBuildsData,
  getBuildType,
  getHasBuilds,
  getHasBuildsLoading,
  getHistoryLocator,
  getLocatorIfReady,
  getShowQueuedBuildsInBuildsList,
  getAutoExpandQueuedBuilds,
  getStatusByKey,
  getIsSakuraUI,
} from '../../../../../selectors'
import {getBuildTypeStatusRequest, getProjectBuildTypeFilter} from '../../../../../types'

import type {OwnProps, StateProps} from './BuildTypeHistory.types'

export const mapStateToBuildTypeHistoryProps = (
  state: State,
  {buildTypeId, branch, withCollapsedQueued, withRunningAndQueued, buildState = 'all'}: OwnProps,
): StateProps => {
  const shouldLoadRunningAndQueued =
    withRunningAndQueued === true && !['successful', 'failed'].includes(buildState)
  const shouldShowCollapsibleQueuedBuilds = withCollapsedQueued === true && buildState === 'all'
  const statusKey = getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))
  const status = getStatusByKey(state, statusKey)
  const hasQueued = Boolean(status?.queued)
  const commonLocatorOptions = {
    projectBuildtype: getProjectBuildTypeFilter({
      buildTypeId,
    }),
    withRunningAndQueued: shouldLoadRunningAndQueued,
    branch,
    buildState,
  }
  const allBuildsLocator = getLocatorIfReady(state, commonLocatorOptions)
  const startedBuildsLocator = getLocatorIfReady(state, {
    ...commonLocatorOptions,
    runningAndFinished: true,
  })
  const {inited, loading, data, ready} = getBuildsData(state, startedBuildsLocator)
  const noStartedBuilds = inited && (!loading || ready) && data.length === 0
  const hasQueuedOnly = hasQueued && noStartedBuilds
  const showQueuedBuilds = shouldShowCollapsibleQueuedBuilds
    ? getShowQueuedBuildsInBuildsList(state) ||
      getAutoExpandQueuedBuilds(state, statusKey) ||
      hasQueuedOnly
    : true
  const monitorLocator = getHistoryLocator(state, {
    buildTypeId,
    branch,
    buildState: withRunningAndQueued === true ? null : 'finished',
  })
  const locator = showQueuedBuilds ? allBuildsLocator : startedBuildsLocator
  const buildType = getBuildType(state, buildTypeId)
  return {
    withCollapsedQueued: shouldShowCollapsibleQueuedBuilds,
    shouldLoadRunningAndQueued,
    showQueuedBuilds,
    hasQueuedOnly,
    locator,
    internalId: buildType?.internalId,
    monitorLocator,
    hasBuilds: getHasBuilds(state, monitorLocator),
    hasBuildsLoading: getHasBuildsLoading(state, monitorLocator),
    isSakuraUI: getIsSakuraUI(state),
    composite: buildType?.type === 'composite',
  }
}
