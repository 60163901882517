import compose from 'lodash/flowRight'
import type {ConnectedProps} from 'react-redux'
import {connect} from 'react-redux'

import type {AppDispatch} from '../../../actions/types'
import withUserPermissions from '../../../hocs/withUserPermissions'
import type {State} from '../../../reducers/types'
import {getBuild, getBuildType, getIsStarred, hasPermission} from '../../../selectors'
import type {BuildType} from '../../../services/rest'
import {Permission, ROOT_PROJECT_ID} from '../../../types'
import type {BuildId, NormalizedBuild} from '../../../types'

import StarBuild from './StarBuild'
import {starBuild} from './StarBuild.actions'

type Props = {
  buildId: BuildId
  showEmpty?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
  const build: Partial<NormalizedBuild> = getBuild(state, props.buildId) || {}
  const buildType: Partial<BuildType> = getBuildType(state, build.buildType) || {}
  const {projectId} = buildType
  const isStarred = getIsStarred(state, props.buildId)
  return {
    projectId,
    isStarred,
    isStarrable:
      hasPermission(state, Permission.TAG_BUILD, projectId) &&
      hasPermission(state, Permission.CHANGE_OWN_PROFILE),
  }
}

const mapDispatchToProps = (dispatch: AppDispatch, props: Props) => ({
  onStar: (starred: boolean) => dispatch(starBuild(props.buildId, starred)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  connector,
  withUserPermissions(
    [Permission.TAG_BUILD],
    (props: Props & ConnectedProps<typeof connector>) => props.projectId,
  ),
  withUserPermissions([Permission.CHANGE_OWN_PROFILE], () => ROOT_PROJECT_ID),
)(StarBuild)
