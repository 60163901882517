import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import {ParameterCategory, ParameterGroup} from './Parameters.types'

export const parameterGroups = createSlice({
  name: 'parameters',
  initialState: {
    expanded: {
      [ParameterCategory.ActualOnAgent]: {
        [ParameterGroup.Dependencies]: true,
        [ParameterGroup.Configuration]: true,
        [ParameterGroup.System]: true,
        [ParameterGroup.Environment]: true,
      },
      [ParameterCategory.Actual]: {
        [ParameterGroup.Dependencies]: true,
        [ParameterGroup.Configuration]: true,
        [ParameterGroup.System]: true,
        [ParameterGroup.Environment]: true,
      },
      [ParameterCategory.UserDefined]: {
        [ParameterGroup.Dependencies]: true,
        [ParameterGroup.Configuration]: true,
        [ParameterGroup.System]: true,
        [ParameterGroup.Environment]: true,
      },
      [ParameterCategory.Agent]: {
        [ParameterGroup.Dependencies]: true,
        [ParameterGroup.Configuration]: true,
        [ParameterGroup.System]: true,
        [ParameterGroup.Environment]: true,
      },
    },
  },
  reducers: {
    toggleGroup: (
      state,
      action: PayloadAction<{category: ParameterCategory; group: ParameterGroup}>,
    ) => {
      const {category, group} = action.payload
      state.expanded[category][group] = !state.expanded[category][group]
    },
    collapseAll: state => {
      state.expanded = {
        [ParameterCategory.Actual]: {
          [ParameterGroup.Dependencies]: false,
          [ParameterGroup.Configuration]: false,
          [ParameterGroup.System]: false,
          [ParameterGroup.Environment]: false,
        },
        [ParameterCategory.ActualOnAgent]: {
          [ParameterGroup.Dependencies]: false,
          [ParameterGroup.Configuration]: false,
          [ParameterGroup.System]: false,
          [ParameterGroup.Environment]: false,
        },
        [ParameterCategory.UserDefined]: {
          [ParameterGroup.Dependencies]: false,
          [ParameterGroup.Configuration]: false,
          [ParameterGroup.System]: false,
          [ParameterGroup.Environment]: false,
        },
        [ParameterCategory.Agent]: {
          [ParameterGroup.Dependencies]: false,
          [ParameterGroup.Configuration]: false,
          [ParameterGroup.System]: false,
          [ParameterGroup.Environment]: false,
        },
      }
    },
    expandAll: state => {
      state.expanded = {
        [ParameterCategory.Actual]: {
          [ParameterGroup.Dependencies]: true,
          [ParameterGroup.Configuration]: true,
          [ParameterGroup.System]: true,
          [ParameterGroup.Environment]: true,
        },
        [ParameterCategory.ActualOnAgent]: {
          [ParameterGroup.Dependencies]: true,
          [ParameterGroup.Configuration]: true,
          [ParameterGroup.System]: true,
          [ParameterGroup.Environment]: true,
        },
        [ParameterCategory.UserDefined]: {
          [ParameterGroup.Dependencies]: true,
          [ParameterGroup.Configuration]: true,
          [ParameterGroup.System]: true,
          [ParameterGroup.Environment]: true,
        },
        [ParameterCategory.Agent]: {
          [ParameterGroup.Dependencies]: true,
          [ParameterGroup.Configuration]: true,
          [ParameterGroup.System]: true,
          [ParameterGroup.Environment]: true,
        },
      }
    },
  },
})
