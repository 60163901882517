import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import {stringifyId} from '../../../types'
import type {BuildId} from '../../../types'

import type {
  BuildLogMessagesResponse,
  BuildLogOption,
  BuildLogTimeline,
  LogView,
} from './BuildLog.types'

export default (
  serverUrl: string,
  endpoint: string,
  buildId: BuildId,
  options?: BuildLogOption | null | undefined,
): Promise<BuildLogMessagesResponse> => {
  const params = new URLSearchParams()
  params.set('buildId', stringifyId(buildId))

  if (options != null) {
    const {
      count,
      logAnchor,
      expandState,
      testOccurrenceId,
      problemOccurrenceId,
      stageKey,
      target,
      expandAll,
      filter,
      searchQuery,
      logView,
      expandSubtree,
      expandFailures,
    } = options

    if (count != null) {
      params.set('messagesCount', count.toString())
    }

    if (filter != null) {
      params.set('filter', filter === 'debug' ? 'verbose' : filter)
    }

    if (logView != null) {
      params.set('view', logView)
    }

    if (searchQuery != null) {
      params.set('query', searchQuery)

      if (logAnchor != null && logAnchor !== 0) {
        params.set('messageId', logAnchor.toString())
      }
    } else {
      let expandStateCorrected = expandState ?? []

      if (expandAll === true) {
        expandStateCorrected = expandStateCorrected.filter(id => id !== 0)
      } else {
        expandStateCorrected = [0, ...expandStateCorrected]
      }

      if (testOccurrenceId != null) {
        params.set('testOccurrenceId', stringifyId(testOccurrenceId))
      }

      if (problemOccurrenceId != null) {
        params.set('problemOccurrenceId', stringifyId(problemOccurrenceId))
      }

      if (expandSubtree != null) {
        params.set('expandSubtree', stringifyId(expandSubtree))
      }

      if (logAnchor != null) {
        if (logAnchor !== 0) {
          params.set('messageId', logAnchor.toString())
          params.set('_focus', `${logAnchor}#_state=${expandStateCorrected.join(',')}`)
        } else if (expandState != null) {
          params.set('_focus', `0#_state=${expandStateCorrected.join(',')}`)
        }
      } else if (stageKey != null) {
        params.set('stage', stageKey)
      } else if (target != null) {
        params.set('target', target)

        if (expandState != null) {
          params.set('_focus', `0#_state=${expandStateCorrected.join(',')}`)
        }
      }

      if (expandAll === true) {
        params.set('expandAll', 'true')
      } else if (expandFailures === true) {
        params.set('expandFailures', 'true')
      }
    }
  }

  return request(serverUrl, `${endpoint}?${params.toString()}`).then<BuildLogMessagesResponse>(
    processResponse,
  )
}
export const requestTimeline = (
  serverUrl: string,
  endpoint: string,
  buildId: BuildId,
  logView?: LogView,
): Promise<BuildLogTimeline> => {
  const params = new URLSearchParams()
  params.set('buildId', stringifyId(buildId))
  if (logView != null) {
    params.set('view', logView)
  }
  return request(serverUrl, `${endpoint}?${params.toString()}`).then<BuildLogTimeline>(
    processResponse,
  )
}
