import type {
  GetBuildNormalizedAsListApiArg,
  GetAllBuildsNormalizedApiArg,
  Build,
  GetAllBuildsApiArg,
  SetMultipleBuildCommentsApiArg,
  AddTagsToMultipleBuildsApiArg,
  SetBuildTagsApiArg,
  PinMultipleBuildsApiArg,
  GetBuildApiArg,
  Branch,
} from '../services/rest'
import {stringifyId, toBuildId} from '../types'
import type {
  BuildId,
  BuildStats,
  BuildTypeId,
  NormalizedBuild,
  RequestOptionsParams,
  Tag,
} from '../types'
import {internalProps} from '../types/BS_types'
import type {RunCustomBuildStringParams} from '../types/BS_types'
import buildStats from '../utils/buildStats'
import {objectToQuery} from '../utils/queryParams'
import stableSort from '../utils/stableSort'

import {compatibleAgentsFields} from './buildCompatibleAgents'
import {buildTypeFields} from './buildTypes'
import {getBranchLocator} from './locators'
import processResponse from './processResponse'
import request from './request'
import type {RestRequestOptions} from './request'

const CHANGES_LIMIT: number = internalProps['teamcity.internal.buildChangesPopupLimit']
const approvalWarming = 'approvalInfo(status),'
export const getBuildFields = (options?: RequestOptionsParams | null | undefined): string => {
  const withBuildTypesDetails = options?.withBuildTypeDetails
  let fields =
    'id,number,branchName,defaultBranch,queuedDate,startDate,finishDate,history,composite,' +
    'parallelized,' +
    'links(link(type,relativeUrl)),' +
    'comment(text,timestamp,user(id,name,username)),' +
    'statusChangeComment(text,timestamp,user(id,name,username)),' +
    'statusText,status,state,failedToStart,personal,detachedFromAgent,finishOnAgentDate,' +
    'pinned,pinInfo(text,timestamp,user(id,name,username)),' +
    'user(id,name,username),' +
    'customization,' +
    'canceledInfo(text,user(id,name,username)),' +
    `${approvalWarming}` +
    'agent(name,id,links(link(type,relativeUrl)),environment(osType),typeId,connected,pool(id,name)),' +
    'tags(tag(name,private),$locator(private:any,owner:current)),' +
    'artifacts($locator(count:1),count:($optional)),' +
    'limitedChangesCount($optional),' +
    `buildType(${
      withBuildTypesDetails === true
        ? buildTypeFields({
            withShortProjectDetails: options?.withShortProjectDetails,
            withLinks: true,
          }).join(',')
        : 'id'
    })`

  if (options?.withSnapshotDependencies === true) {
    fields += ',snapshot-dependencies(count,build(id))'
  } else {
    fields += ',snapshot-dependencies(count:(1))'
  }

  if (options?.withDownloadedArtifactsFrom != null) {
    fields += `,downloadedArtifacts($locator(id:${options.withDownloadedArtifactsFrom}),downloadInfo(artifactInfo(path)))`
  }

  if (options?.withRunningInfo === true) {
    // prettier-ignore
    fields +=
      ',running-info(' +
        'percentageComplete,' +
        'elapsedSeconds,' +
        'estimatedTotalSeconds,' +
        'leftSeconds,' +
        'probablyHanging,' +
        'lastActivityTime,' +
        'outdated,' +
        'outdatedReasonBuild(number,links(link(type,relativeUrl)))' +
      ')'
  }

  if (options?.withTestOccurrencesCount === true) {
    fields += ',testOccurrences(count)'
  }

  if (options?.withQueuedInfo === true) {
    // prettier-ignore
    fields +=
      ',waitReason,queuePosition,startEstimate,finishEstimate,' +
      'plannedAgent(name,id,environment(osType),typeId,pool(id,name)),' +
      'delayedByBuild(id,number,status,state,failedToStart,personal,canceledInfo,buildType(id)),' +
      'triggered(' +
        'date,' +
        'displayText,' +
        `buildType(${withBuildTypesDetails === true ? buildTypeFields({withLinks: true}).join(',') : 'id'})` +
      ')'
  }

  return fields
}

export const getBuildsArg = (
  locator: string = 'state:any',
  options?: RequestOptionsParams,
  onProgress: (build: Build) => void = () => {},
): GetAllBuildsNormalizedApiArg => ({
  ...options,
  locator,
  fields: `count,build(${getBuildFields(options)})`,
  onProgress,
})

export const getBuildsDetailsArg = (
  locatorToStore: string,
  locatorToFetch: string = 'state:any',
  options: RequestOptionsParams | null | undefined,
  onProgress: (arg0: Build) => unknown = () => {},
): GetAllBuildsNormalizedApiArg => ({
  ...options,
  detailsLocatorToStore: locatorToStore,
  locator: locatorToFetch,
  fields: `build(${[
    'id',
    `changes($locator(count:${CHANGES_LIMIT}),change(id,username,commiter(users(user(id,name,username,avatars))))),artifactDependencyChanges(count)`,
    options?.withQueuedInfo === true ? compatibleAgentsFields : null,
  ]
    .filter(Boolean)
    .join(',')})`,
  onProgress,
})

export const getBuildsCountArg = (
  locator: string,
  count: number,
  countInLocator: boolean,
  lookupLimit: number,
  options?: RequestOptionsParams | null | undefined,
  inBackground?: boolean,
): GetAllBuildsApiArg => ({
  locator: `${locator},${countInLocator ? '' : `count:${count},`}lookupLimit:${lookupLimit}`,
  fields: 'nextHref,count',
  isCountRequest: true,
  inBackground,
  count,
  ...options,
})

type HasBuildsData = {
  count: number
}
export const requestHasBuilds = (
  serverUrl: string,
  locator: string,
  restOptions?: RestRequestOptions | null | undefined,
): Promise<boolean> =>
  request(
    serverUrl,
    `builds?locator=${encodeURIComponent(locator)},count:1&fields=count`,
    restOptions,
  )
    .then<HasBuildsData>(processResponse)
    .then(data => data.count > 0)
export const getSingleBuildArg = (
  buildLocator: string,
  options?: RequestOptionsParams,
): GetBuildNormalizedAsListApiArg => {
  const optionsWithDefaults = {
    withRunningInfo: true,
    withQueuedInfo: true,
    withBuildTypeDetails: false,
    withSnapshotDependencies: false,
    withTestOccurrencesCount: true,
    essential: true,
    ...options,
  }
  return {
    buildLocator,
    fields: getBuildFields(optionsWithDefaults),
    ...optionsWithDefaults,
    locators: [buildLocator],
  }
}

export const getBuildTriggerBuildArg = (buildId: BuildId): GetBuildApiArg => ({
  buildLocator: `id:${stringifyId(buildId)}`,
  fields: 'triggered(build(id,number,links(link(type,relativeUrl))))',
})

const getMultipleLocator = (buildIds: ReadonlyArray<BuildId>, withDependencies?: boolean) =>
  buildIds
    .map(
      id =>
        `item(id:${id})${
          withDependencies ? `,item(defaultFilter:false,snapshotDependency:(to:(id:${id})))` : ''
        }`,
    )
    .join(',')

export const getSaveBuildsCommentArg = (
  buildIds: ReadonlyArray<BuildId>,
  text: string,
): SetMultipleBuildCommentsApiArg => ({
  buildLocator: getMultipleLocator(buildIds),
  fields: 'operationResult(related(build(id,comment(text,timestamp,user(id,name,username)))))',
  body: text,
})

export const getArtifactDependencyExistsArg = (
  buildId: BuildId,
  isDownloaded: boolean,
): GetAllBuildsApiArg => {
  const state = isDownloaded ? 'state:(running:true,finished:true)' : 'state:finished'
  const direction = isDownloaded ? 'to' : 'from'

  return {
    locator: `defaultFilter:false,artifactDependency:(${direction}:(id:${stringifyId(
      buildId,
    )},${state}),recursive:false)`,
    fields: 'count',
  }
}

export const getPinBuildsArg = (
  buildIds: ReadonlyArray<BuildId>,
  status: boolean,
  commentText: string | undefined,
  withDependencies: boolean,
): PinMultipleBuildsApiArg => ({
  buildLocator: getMultipleLocator(buildIds, withDependencies),
  fields:
    'operationResult(related(build(id,pinned,pinInfo(text,timestamp,user(id,name,username)))))',
  pinInfo: {
    status,
    comment: {
      text: commentText,
    },
  },
})

export const getChangeTagsArg = (
  buildId: BuildId,
  tags: ReadonlyArray<Tag>,
  prevTags: ReadonlyArray<Tag>,
): SetBuildTagsApiArg => ({
  buildLocator: `id:${stringifyId(buildId)}`,
  locator: 'private:any,owner:current',
  tags: {tag: [...tags]},
  prevTags: {tag: [...prevTags]},
})

const getAddTagsArgByLocator = (
  locator: string,
  tags: ReadonlyArray<Tag>,
): AddTagsToMultipleBuildsApiArg => ({
  buildLocator: locator,
  fields:
    'operationResult(related(build(id,tags(tag(name,private),$locator(private:any,owner:current)))))',
  tags: {tag: [...tags]},
})

export const getAddTagsToDependenciesArg = (buildId: BuildId, tags: ReadonlyArray<Tag>) =>
  getAddTagsArgByLocator(
    `defaultFilter:false,snapshotDependency:(to:(id:${stringifyId(buildId)}))`,
    tags,
  )

export const getAddTagsArg = (
  buildIds: ReadonlyArray<BuildId>,
  tags: ReadonlyArray<Tag>,
  withDependencies?: boolean,
) => getAddTagsArgByLocator(getMultipleLocator(buildIds, withDependencies), tags)

export const reorderInQueue = async (
  serverUrl: string,
  buildId: BuildId,
  afterBuild: number,
): Promise<boolean> => {
  const endpoint = `buildQueue/order/after:${afterBuild}`
  const res = await request(serverUrl, endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: buildId,
    }),
  })
  return res.ok
}

export const moveToTop = async (serverUrl: string, buildId: BuildId): Promise<boolean> => {
  const endpoint = `buildQueue/order/1`
  const res = await request(serverUrl, endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: buildId,
    }),
  })
  return res.ok
}
export type RunBuildResult = {
  queuedBuildId: BuildId | null | undefined
  showDialog: boolean
}
export type TriggerBuildOptions = {
  promoteId: BuildId | null | undefined
  stateKey?: string
  init?: boolean
  initFromBuild?: BuildId
}
export const getPromoteOptions = (
  promoteId: BuildId | null | undefined,
): RunCustomBuildStringParams =>
  promoteId != null
    ? {
        dependOnPromotionIds: stringifyId(promoteId),
        init: 'true',
        stateKey: 'promote',
        redirectTo: '',
        modificationId: 'auto',
      }
    : Object.freeze({})
export const runBuild = async (
  buildTypeId: BuildTypeId,
  branchName: string | null | undefined,
  {promoteId}: TriggerBuildOptions,
): Promise<RunBuildResult> => {
  const res = await request(window.base_uri, 'ajax.html', {
    method: 'POST',
    headers: {
      Accept: 'text/xml',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: objectToQuery({
      add2Queue: stringifyId(buildTypeId),
      validate: 'true',
      branchName,
      ...getPromoteOptions(promoteId),
    }),
  })
  const xml = new DOMParser().parseFromString(await res.text(), 'text/xml')
  const queuedBuild = xml.querySelector('queuedBuild')
  const queuedBuildIdAttr = queuedBuild ? queuedBuild.getAttribute('itemId') : null
  const queuedBuildId = queuedBuildIdAttr != null ? toBuildId(queuedBuildIdAttr) : null
  return {
    queuedBuildId,
    showDialog: xml.getElementById('action_showRunCustomBuildDialog') != null,
  }
}
export const requestBuildsStats = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<BuildStats>> =>
  request(
    serverUrl,
    `builds?locator=${encodeURIComponent(
      locator,
    )}&fields=build(id,queuedDate,startDate,finishDate,running-info(elapsedSeconds),status,state)`,
  )
    .then<{build: ReadonlyArray<NormalizedBuild>}>(processResponse)
    .then(data => data.build.map(buildStats))
export const requestBuildsStatsAroundBuild = (
  serverUrl: string,
  locator: string,
  buildId: BuildId,
  statCount: number,
): Promise<ReadonlyArray<BuildStats>> =>
  requestBuildsStats(serverUrl, locator)
    .then((stats: ReadonlyArray<BuildStats>) =>
      stableSort(stats, (a, b) => {
        if (a.startDate == null || b.startDate == null || a.startDate === b.startDate) {
          return 0
        }

        return a.startDate < b.startDate ? 1 : -1
      }),
    )
    .then((stats: ReadonlyArray<BuildStats>) => {
      const wantedBuildIndex = stats.findIndex(stat => stat.id === buildId)
      const result = []

      if (stats.length > 0 && wantedBuildIndex !== -1) {
        result.push(stats[wantedBuildIndex])

        for (let i = 1; i < statCount; i++) {
          const previousElement = stats[wantedBuildIndex - i]
          const nextElement = stats[wantedBuildIndex + i]

          if (previousElement != null && result.length < statCount) {
            result.unshift(previousElement)
          }

          if (nextElement != null && result.length < statCount) {
            result.push(nextElement)
          }
        }
      }

      return result
    })
export const requestBuildCanBeRun = (serverUrl: string, buildId: BuildId): Promise<boolean> =>
  request(serverUrl, `?buildCanBeRun&buildId=${stringifyId(buildId)}`)
    .then<Readonly<{canRun: boolean}>>(processResponse)
    .then(({canRun}) => canRun)

export const getLastSuccessfulBuildLocator = (props: {
  buildTypeId: BuildTypeId
  buildId: BuildId
  branch: Branch
  inPath?: boolean
}) => {
  const {buildTypeId, buildId, branch, inPath} = props

  return [
    `buildType:(id:${stringifyId(buildTypeId)})`,
    `startDate:(build:(id:${stringifyId(buildId)}),condition:before)`,
    getBranchLocator(branch, inPath),
    'state:finished,status:SUCCESS',
    'defaultFilter:false',
    'count:1',
  ]
    .filter(Boolean)
    .join(',')
}
