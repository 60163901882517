import {darkThemeAdapterServicePrefix} from '../../defaults'
import type {Theme} from '../../types'
import {addReadyStateCompleteListener, removeNode, watchForNodePosition} from '../../utils/dom'
import {isSafari} from '../../utils/platform'
import {getStyleSheetJSSelector} from '../../utils/selectors'
import {isRelativeHrefOnAbsolutePath} from '../../utils/url'
import {getCSSBaseBath} from '../cssRules'
import {createStyleSheetModifier} from '../stylesheetModifier'

import type {detailsArgument, Index, StyleElement} from './styleManager.types'
import {
  containsCSSImport,
  corsStyleSet,
  createCORSCopy,
  hasImports,
  linkLoading,
  loadText,
  rejectorsForLoadingLinks,
  replaceCSSImports,
  syncStyleSet,
} from './styleManager.utils'

let loadingLinkCounter = 0
let canOptimizeUsingProxy = false
document.addEventListener(`${darkThemeAdapterServicePrefix}__inlineScriptsAllowed`, () => {
  canOptimizeUsingProxy = true
})

export function manageStyle(
  element: StyleElement,
  {
    update,
    loadingStart,
    loadingEnd,
  }: {update: () => void; loadingStart: () => void; loadingEnd: () => void},
): Index {
  const maxMoveCount = 10
  const loadingLinkId = ++loadingLinkCounter

  let moveCount = 0
  let rulesChangeKey: number | null = null
  let rulesCheckFrameId: number | null = null
  let isLoadingRules = false
  let wasLoadingError = false
  let forceRenderStyle = false
  let areSheetChangesPending = false

  const prevStyles: HTMLStyleElement[] = []
  let next: Element | null = element
  while ((next = next.nextElementSibling) && next.matches(`.${darkThemeAdapterServicePrefix}`)) {
    prevStyles.push(next as HTMLStyleElement)
  }

  let corsCopy: HTMLStyleElement | null = null
  let syncStyle: HTMLStyleElement | SVGStyleElement | null = null

  for (const item of prevStyles) {
    if (corsCopy && syncStyle) {
      break
    }

    if (
      !corsCopy &&
      item.matches(`.${getStyleSheetJSSelector('cors')}`) &&
      !corsStyleSet.has(item)
    ) {
      corsCopy = item
    }
    if (
      !syncStyle &&
      item.matches(`.${getStyleSheetJSSelector('sync')}`) &&
      !syncStyleSet.has(item)
    ) {
      syncStyle = item
    }
  }

  let corsCopyPositionWatcher: ReturnType<typeof watchForNodePosition> | null = null
  let syncStylePositionWatcher: ReturnType<typeof watchForNodePosition> | null = null

  let cancelAsyncOperations = false
  let isOverrideEmpty = true

  const sheetModifier = createStyleSheetModifier()

  const observer = new MutationObserver(() => update())
  const observerOptions: MutationObserverInit = {
    attributes: true,
    childList: true,
    subtree: true,
    characterData: true,
  }

  function getRulesSync(): CSSRuleList | null {
    if (corsCopy) {
      return corsCopy.sheet!.cssRules
    }
    if (containsCSSImport(element)) {
      return null
    }

    const cssRules = safeGetSheetRules()
    if (
      (element instanceof HTMLLinkElement &&
        !isRelativeHrefOnAbsolutePath(element.href) &&
        hasImports(cssRules, false)) ||
      hasImports(cssRules, true)
    ) {
      return null
    }

    return cssRules
  }

  function insertStyle() {
    if (corsCopy) {
      if (element.nextSibling !== corsCopy) {
        element.parentNode!.insertBefore(corsCopy, element.nextSibling)
      }
      if (corsCopy.nextSibling !== syncStyle) {
        element.parentNode!.insertBefore(syncStyle!, corsCopy.nextSibling)
      }
    } else if (element.nextSibling !== syncStyle) {
      element.parentNode!.insertBefore(syncStyle!, element.nextSibling)
    }
  }

  function createSyncStyle() {
    syncStyle =
      element instanceof SVGStyleElement
        ? document.createElementNS('http://www.w3.org/2000/svg', 'style')
        : document.createElement('style')
    syncStyle.classList.add(darkThemeAdapterServicePrefix)
    syncStyle.classList.add(getStyleSheetJSSelector('sync'))
    syncStyle.media = 'screen'
    if (element.title) {
      syncStyle.title = element.title
    }
    syncStyleSet.add(syncStyle)
  }

  async function getRulesAsync(): Promise<CSSRuleList | null> {
    let cssText: string
    let cssBasePath: string

    if (element instanceof HTMLLinkElement) {
      let [cssRules, accessError] = getRulesOrError()

      if (
        (!cssRules && !accessError && !isSafari) ||
        (isSafari && !element.sheet) ||
        isStillLoadingError(accessError!)
      ) {
        try {
          await linkLoading(element, loadingLinkId)
        } catch (err) {
          wasLoadingError = true
        }
        if (cancelAsyncOperations) {
          return null
        }

        ;[cssRules, accessError] = getRulesOrError()
      }

      if (cssRules && !hasImports(cssRules, false)) {
        return cssRules
      }

      cssText = await loadText(element.href)
      cssBasePath = getCSSBaseBath(element.href)
      if (cancelAsyncOperations) {
        return null
      }
    } else if (containsCSSImport(element)) {
      cssText = element.textContent!.trim()
      cssBasePath = getCSSBaseBath(location.href)
    } else {
      return null
    }

    if (cssText) {
      try {
        const fullCSSText = await replaceCSSImports(cssText, cssBasePath)
        corsCopy = createCORSCopy(element, fullCSSText)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err)
      }
      if (corsCopy) {
        corsCopyPositionWatcher = watchForNodePosition(corsCopy, 'prev-sibling')
        return corsCopy.sheet!.cssRules
      }
    }

    return null
  }

  function details(options: detailsArgument) {
    const rules = getRulesSync()
    if (!rules) {
      if (options.secondRound || isLoadingRules || wasLoadingError) {
        return null
      }
      isLoadingRules = true
      loadingStart()

      getRulesAsync()
        .then(results => Boolean(results) && update())
        .finally(() => {
          isLoadingRules = false
          loadingEnd()
        })
      return null
    }
    return {rules}
  }

  function render(theme: Theme) {
    const rules = getRulesSync()
    if (!rules) {
      return
    }

    cancelAsyncOperations = false

    function removeCSSRulesFromSheet(sheet: CSSStyleSheet) {
      if (!sheet) {
        return
      }
      for (let i = sheet.cssRules.length - 1; i >= 0; i--) {
        sheet.deleteRule(i)
      }
    }

    function prepareOverridesSheet(): CSSStyleSheet {
      if (!syncStyle) {
        createSyncStyle()
      }

      syncStylePositionWatcher?.stop()
      insertStyle()

      if (syncStyle!.sheet == null) {
        syncStyle!.textContent = ''
      }

      const sheet = syncStyle!.sheet

      removeCSSRulesFromSheet(sheet!)

      if (syncStylePositionWatcher) {
        syncStylePositionWatcher.run()
      } else {
        syncStylePositionWatcher = watchForNodePosition(syncStyle!, 'prev-sibling', () => {
          forceRenderStyle = true
          buildOverrides()
        })
      }

      return syncStyle!.sheet!
    }

    function buildOverrides() {
      const force = forceRenderStyle
      forceRenderStyle = false
      sheetModifier.modifySheet({
        prepareSheet: prepareOverridesSheet,
        sourceCSSRules: rules!,
        theme,
        force,
        isAsyncCancelled: () => cancelAsyncOperations,
      })
      isOverrideEmpty = syncStyle!.sheet!.cssRules.length === 0
      if (sheetModifier.shouldRebuildStyle()) {
        addReadyStateCompleteListener(() => update())
      }
    }

    buildOverrides()
  }

  function getRulesOrError(): [CSSRuleList | null, Error | null] {
    try {
      if (element.sheet == null) {
        return [null, null]
      }
      return [element.sheet.cssRules, null]
    } catch (err) {
      if (err instanceof Error) {
        return [null, err]
      }
      return [null, null]
    }
  }

  function isStillLoadingError(error: Error) {
    return error?.message?.includes('loading')
  }

  function safeGetSheetRules() {
    const [cssRules, err] = getRulesOrError()
    if (err) {
      return null
    }
    return cssRules
  }

  function watchForSheetChanges() {
    watchForSheetChangesUsingProxy()
    if (!(canOptimizeUsingProxy && element.sheet)) {
      watchForSheetChangesUsingRAF()
    }
  }

  function getRulesChangeKey() {
    const rules = safeGetSheetRules()
    return rules ? rules.length : null
  }

  function didRulesKeyChange() {
    return getRulesChangeKey() !== rulesChangeKey
  }

  function watchForSheetChangesUsingRAF() {
    rulesChangeKey = getRulesChangeKey()
    stopWatchingForSheetChangesUsingRAF()
    const checkForUpdate = () => {
      if (didRulesKeyChange()) {
        rulesChangeKey = getRulesChangeKey()
        update()
      }
      if (canOptimizeUsingProxy && element.sheet) {
        stopWatchingForSheetChangesUsingRAF()
        return
      }
      rulesCheckFrameId = requestAnimationFrame(checkForUpdate)
    }

    checkForUpdate()
  }

  function stopWatchingForSheetChangesUsingRAF() {
    cancelAnimationFrame(rulesCheckFrameId as number)
  }

  function onSheetChange() {
    canOptimizeUsingProxy = true
    stopWatchingForSheetChangesUsingRAF()
    if (areSheetChangesPending) {
      return
    }

    function handleSheetChanges() {
      areSheetChangesPending = false
      if (cancelAsyncOperations) {
        return
      }
      update()
    }

    areSheetChangesPending = true
    if (typeof queueMicrotask === 'function') {
      queueMicrotask(handleSheetChanges)
    } else {
      requestAnimationFrame(handleSheetChanges)
    }
  }

  const updateSheetEventName = `${darkThemeAdapterServicePrefix}__updateSheet`
  function watchForSheetChangesUsingProxy() {
    element.addEventListener(updateSheetEventName, onSheetChange)
  }

  function stopWatchingForSheetChangesUsingProxy() {
    element.removeEventListener(updateSheetEventName, onSheetChange)
  }

  function stopWatchingForSheetChanges() {
    stopWatchingForSheetChangesUsingProxy()
    stopWatchingForSheetChangesUsingRAF()
  }

  function pause() {
    observer.disconnect()
    cancelAsyncOperations = true
    corsCopyPositionWatcher?.stop()
    syncStylePositionWatcher?.stop()
    stopWatchingForSheetChanges()
  }

  function destroy() {
    pause()
    removeNode(corsCopy)
    removeNode(syncStyle)
    loadingEnd()
    if (rejectorsForLoadingLinks.has(loadingLinkId)) {
      const reject = rejectorsForLoadingLinks.get(loadingLinkId)
      rejectorsForLoadingLinks.delete(loadingLinkId)
      reject?.()
    }
  }

  function watch() {
    observer.observe(element, observerOptions)
    if (element instanceof HTMLStyleElement) {
      watchForSheetChanges()
    }
  }

  function restore() {
    if (!syncStyle) {
      return
    }

    moveCount++
    if (moveCount > maxMoveCount) {
      return
    }

    insertStyle()
    corsCopyPositionWatcher?.skip()
    syncStylePositionWatcher?.skip()
    if (!isOverrideEmpty) {
      forceRenderStyle = true
      update()
    }
  }

  return {
    details,
    render,
    pause,
    destroy,
    watch,
    restore,
  }
}
