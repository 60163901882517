const fallback = {
  full: 'SNAPSHOT',
  major: '',
  minor: '',
}

function parseVersion() {
  return {
    ...fallback,
    ...TEAMCITY_VERSION,
  }
}

export default parseVersion()
