import classnames from 'classnames'
import * as React from 'react'

import type {SyntheticEvent} from 'react'

import EntityLink from '../../../containers/EntityLink'
import {BuildTypePageTabNamesEnum, stringifyId} from '../../../types'
import {Modes} from '../../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeOverviewTab.modes'
import Link from '../Link/Link'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'
import RouterLink from '../RouterLink/RouterLink'
import SvgIcon from '../SvgIcon/SvgIcon'
import {hasSelection} from '../Tree/Tree.utils'

import type {Props} from './BranchLabel.types'

import styles from './BranchLabel.css'

const BranchLabel = (props: Props) => {
  const {
    className,
    name,
    main,
    noLink,
    autoTrim,
    defaultTrim,
    withBorder,
    low,
    withIcon,
    tailLength,
    href,
    to,
    buildId,
    branchNameInHref,
    isSakuraUI,
    buildTypeId,
    ...restProps
  } = props

  const onClick = React.useCallback((e: SyntheticEvent) => {
    if (hasSelection()) {
      e.stopPropagation()
      e.preventDefault()
    }
  }, [])

  if (name == null) {
    return null
  }

  const classes = classnames(styles.buildBranch, className, {
    [styles.link]: !noLink,
    [styles.main]: main,
    [styles.autoTrim]: autoTrim,
    [styles.defaultTrim]: defaultTrim,
    [styles.withBorder]: withBorder,
    [styles.withIcon]: withIcon,
    [styles.low]: low,
  })
  const commonProps = {
    ...restProps,
    className: classes,
    'data-test-branch-link': true,
    children: (
      <>
        {withIcon === true ? <SvgIcon icon="branches" className={styles.icon} /> : null}
        <MiddleEllipsis tailLength={tailLength}>{name}</MiddleEllipsis>
      </>
    ),
  }

  if (noLink === true) {
    return <span {...commonProps} />
  }

  if (to != null) {
    return <RouterLink to={to} {...commonProps} innerClassName={styles.innerLink} />
  }

  if (isSakuraUI === true) {
    return (
      <EntityLink
        {...commonProps}
        buildTypeId={buildTypeId}
        params={() => ({
          branch: branchNameInHref,
          buildTypeTab: stringifyId(BuildTypePageTabNamesEnum.OVERVIEW),
          mode: Modes.BUILDS,
        })}
        innerClassName={styles.innerLink}
        draggable="false"
        onClick={onClick}
      />
    )
  }

  return <Link {...commonProps} href={href} innerClassName={styles.innerLink} />
}

export default BranchLabel
