import * as React from 'react'

import type {BuildActionsPopupProps} from './BuildActions.popup'

const BuildActionsPopupLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BuildActionsPopup", webpackPrefetch: true */
      './BuildActions.popup'
    ),
)

const BuildActionsPopup = (props: BuildActionsPopupProps) =>
  props.hidden === true && process.env.NODE_ENV !== 'test' ? null : (
    <React.Suspense fallback="">
      <BuildActionsPopupLazy {...props} />
    </React.Suspense>
  )

export default BuildActionsPopup
