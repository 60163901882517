import * as Redux from 'redux'

import {keyValueFetchable} from '../../../../../reducers/fetchable'
import {emptyArray} from '../../../../../utils/empty'

import {fetchPossibleDeployments} from './Deployments.actions'
import type {DeploymentsState} from './Deployments.state.types'

const possibleDeploymentsReducer = keyValueFetchable(
  arg => arg,
  fetchPossibleDeployments,
  emptyArray,
  (_, action) => action.payload,
)
const deploymentsReducer = Redux.combineReducers<DeploymentsState>({
  possibleDeployments: possibleDeploymentsReducer,
})
export default deploymentsReducer
