import {castDraft} from 'immer'

import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice, prepareAutoBatched} from '@reduxjs/toolkit'

import {emptyArray, getEmptyHash} from '../../../utils/empty'

import {HINT_CATEGORY, stringifyHintId} from './Hints.types'
import type {HintsState, HintCategory, HintId, Hint} from './Hints.types'

const defaultState: HintsState = {
  registrations: getEmptyHash(),
  available: emptyArray,
  selected: emptyArray,
  selectedCategory: null,
  viewedObligatoryHints: emptyArray,
  skipObligatoryHints: false,
}

export const hints = createSlice({
  name: 'hints',
  initialState: defaultState,
  reducers: {
    add: {
      reducer(state, action: PayloadAction<Hint>) {
        const hint = action.payload
        const registrations = state.registrations[hint.id]
        const hintExists = registrations != null && registrations > 0

        if (hintExists) {
          state.registrations[stringifyHintId(hint.id)] = registrations + 1
        } else {
          state.available.push(
            castDraft(hint.category ? hint : {...hint, category: HINT_CATEGORY.miscellaneous}),
          )
          state.registrations[stringifyHintId(hint.id)] = 1
        }
      },
      prepare: prepareAutoBatched<Hint>(),
    },
    remove: {
      reducer(state, action: PayloadAction<HintId>) {
        const hintId = action.payload
        const currentRegistrations = state.registrations[hintId] ?? 0
        state.registrations[stringifyHintId(hintId)] = currentRegistrations - 1
        if (currentRegistrations === 1 && state.available.some(item => item.id === hintId)) {
          state.available = state.available.filter(item => item.id !== hintId)
        }
      },
      prepare: prepareAutoBatched<HintId>(),
    },
    show(state, action: PayloadAction<HintId>) {
      if (!state.selected.includes(action.payload)) {
        state.selected.push(action.payload)
      }
    },
    hide(state, action: PayloadAction<HintId>) {
      if (state.selected.includes(action.payload)) {
        state.selected = state.selected.filter(id => id !== action.payload)
      }
    },
    selectCategory(state, action: PayloadAction<HintCategory>) {
      state.selectedCategory = action.payload
    },
    markHintAsViewed(state, action: PayloadAction<HintId>) {
      if (!state.viewedObligatoryHints.includes(action.payload)) {
        state.viewedObligatoryHints.push(action.payload)
      }
    },
    setSkipObligatoryHints(state, action: PayloadAction<boolean>) {
      state.skipObligatoryHints = action.payload
    },
  },
})
