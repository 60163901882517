import {mapProps} from 'recompose'

import compose from 'lodash/flowRight'
import type {ComponentProps, ComponentType} from 'react'

import Redirect from '../components/App/Redirect/Redirect'
import RouterLink from '../components/common/RouterLink/RouterLink'
import type {RouterLinkProps} from '../components/common/RouterLink/RouterLink'
import {getOverviewHref, getHrefWithQueryParams, withLocation} from '../routes'
import type {Branch} from '../services/rest'
import {BuildTypePageTabNamesEnum, stringifyId} from '../types'
import type {ActiveEntityProps, BuildId, BuildTypeId, Id, ProjectId} from '../types'
import {stringifyBranch} from '../utils/branchNames'
import type {QueryParams} from '../utils/queryParams'

type EntityRedirectProps = ActiveEntityProps & {
  redirectedFromId?: Id
}
export const EntityRedirect: ComponentType<EntityRedirectProps> = compose(
  withLocation,
  mapProps(
    ({
      projectId,
      buildTypeId,
      buildId,
      location,
      redirectedFromId,
    }): ComponentProps<typeof Redirect> => ({
      to: getHrefWithQueryParams(
        location,
        getOverviewHref({
          projectId,
          buildTypeId,
          buildId,
        }),
        params => ({...params, redirectedFromId}),
      ),
      replace: true,
    }),
  ),
)(Redirect)
type EntityLinkProps = RouterLinkProps & {
  projectId?: ProjectId | null | undefined
  buildTypeId?: BuildTypeId | null | undefined
  buildId?: BuildId | null | undefined
  branch?: Branch | null | undefined
  isAllProjects?: boolean
}

export const getParams = ({branch}: QueryParams): QueryParams => ({
  branch,
  buildTypeTab:
    branch != null && branch.length > 0 ? stringifyId(BuildTypePageTabNamesEnum.OVERVIEW) : null,
})

export default mapProps(
  ({
    projectId,
    buildTypeId,
    buildId,
    branch,
    isAllProjects,
    ...restProps
  }: EntityLinkProps): RouterLinkProps => ({
    to: getOverviewHref({
      projectId,
      buildTypeId,
      buildId,
      isAllProjects,
    }),
    params: (params: QueryParams) =>
      getParams({...params, branch: stringifyBranch(branch) || params.branch}),
    ...restProps,
  }),
)(RouterLink)
