import {castDraft, original} from 'immer'

import type {PlaceId} from '@jetbrains/teamcity-api'
import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice, isAnyOf} from '@reduxjs/toolkit'

import deepEqual from 'fast-deep-equal'

import {receiveBuildAction, receiveBuildWSDataAction} from '../actions/builds'
import type {CollapsibleBlock} from '../actions/collapsibleBlockTypes'
import {
  getTriggerBuildKey,
  triggerBuildAction,
} from '../components/common/RunBuild/RunBuild.actions'
import type {CollapsibleBlockItems} from '../reducers/types'
import type {StatusKey} from '../rest/schemata'
import {getStatusKey} from '../rest/schemata'
import type {Branch} from '../services/rest'
import {restApi} from '../services/rest'
import type {
  AgentId,
  BuildId,
  BuildTypeId,
  Dialog,
  DialogType,
  Fetchable,
  Id,
  RouteAvailabilityResponse,
  ServerInfo,
  TabId,
  UrlExtension,
} from '../types'
import {getBuildTypeStatusRequest} from '../types'
import {internalProps} from '../types/BS_types'
import {emptyArrayFetchable} from '../utils/empty'
import {extractError} from '../utils/extractError'
import type {KeyValue} from '../utils/object'
import {objectEntries} from '../utils/object'

export const isSakuraUI = createSlice({
  name: 'isSakuraUI',
  initialState: false,
  reducers: {
    set: () => true,
  },
})

export const urlExtensions = createSlice({
  name: 'urlExtensions',
  initialState: [] as ReadonlyArray<UrlExtension<any>>,
  reducers: {
    set: (state, action: PayloadAction<readonly UrlExtension<{}>[]>) =>
      state.concat(action.payload),
  },
})

type RouteAvailabilityResponseMeta = {
  path: string
}
export const routeAvailabilityResponse = createSlice({
  name: 'routeAvailabilityResponse',
  initialState: {} as KeyValue<string, RouteAvailabilityResponse>,
  reducers: {
    set: {
      reducer(
        state,
        action: PayloadAction<RouteAvailabilityResponse, string, RouteAvailabilityResponseMeta>,
      ) {
        state[action.meta.path] = action.payload
      },
      prepare: (payload: RouteAvailabilityResponse, path: string) => ({
        payload,
        meta: {path},
      }),
    },
  },
})

export const showQueuedBuildsInBuildsList = createSlice({
  name: 'showQueuedBuildsInBuildsList',
  initialState: false,
  reducers: {
    toggle: state => !state,
  },
})

export const blocks = createSlice({
  name: 'blocks',
  initialState: {} as CollapsibleBlockItems,
  reducers: {
    add: {
      reducer(
        state,
        action: PayloadAction<ReadonlyArray<string | Id>, string, {block: CollapsibleBlock}>,
      ) {
        let prevState = state[action.meta.block]
        if (prevState == null) {
          prevState = state[action.meta.block] = {}
        }
        for (const id of action.payload) {
          prevState[id] = true
        }
      },
      prepare: (block: CollapsibleBlock, ids: ReadonlyArray<string | Id>) =>
        ({
          payload: ids,
          meta: {block},
        } as const),
    },
    remove: {
      reducer(
        state,
        action: PayloadAction<ReadonlyArray<string | Id>, string, {block: CollapsibleBlock}>,
      ) {
        let prevState = state[action.meta.block]
        if (prevState == null) {
          prevState = state[action.meta.block] = {}
        }
        for (const id of action.payload) {
          prevState[id] = false
        }
      },
      prepare: (block: CollapsibleBlock, ids: ReadonlyArray<string | Id>) =>
        ({
          payload: ids,
          meta: {block},
        } as const),
    },
  },
})

type OpenDialogPayload = {
  dialogId: string
  dialogType: DialogType
  data: unknown
}

export const dialog = createSlice({
  name: 'dialog',
  initialState: Object.freeze({}) as Dialog,
  reducers: {
    open: {
      reducer(state, action: PayloadAction<OpenDialogPayload>) {
        state.opened = true
        state.error = false
        state.id = action.payload.dialogId
        state.type = action.payload.dialogType
        state.data = action.payload.data
      },
      prepare: (dialogId: string, dialogType: DialogType, data?: unknown) => ({
        payload: {dialogId, dialogType, data},
      }),
    },
    close(state) {
      if (!state.processing) {
        state.opened = false
      }
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          restApi.endpoints.setBuildTags.matchPending,
          restApi.endpoints.setMultipleBuildComments.matchPending,
          restApi.endpoints.pinMultipleBuilds.matchPending,
          restApi.endpoints.addTagsToMultipleBuilds.matchPending,
        ),
        state => {
          state.processing = true
        },
      )
      .addMatcher(
        isAnyOf(
          restApi.endpoints.setBuildTags.matchFulfilled,
          restApi.endpoints.setMultipleBuildComments.matchFulfilled,
          restApi.endpoints.pinMultipleBuilds.matchFulfilled,
          restApi.endpoints.addTagsToMultipleBuilds.matchFulfilled,
        ),
        state => {
          state.processing = false
          state.opened = false
          state.error = false
        },
      )
      .addMatcher(
        isAnyOf(
          restApi.endpoints.setBuildTags.matchRejected,
          restApi.endpoints.setMultipleBuildComments.matchRejected,
          restApi.endpoints.pinMultipleBuilds.matchRejected,
          restApi.endpoints.addTagsToMultipleBuilds.matchRejected,
        ),
        state => {
          state.processing = false
          state.opened = true
          state.error = true
        },
      )
  },
})

const buildsInitialState: KeyValue<string, Fetchable<ReadonlyArray<BuildId>>> = {}

export const builds = createSlice({
  name: 'builds',
  initialState: buildsInitialState,
  reducers: {
    reset(state, action: PayloadAction<string>) {
      const prev = state[action.payload]
      if (prev != null) {
        prev.loading = false
        prev.ready = false
      }
    },
  },
  extraReducers: builder => {
    builder.addMatcher(restApi.endpoints.getAllBuildsNormalized.matchPending, (state, action) => {
      const {locator = ''} = action.meta.arg.originalArgs
      state[locator] ??= castDraft({...emptyArrayFetchable})
      state[locator]!.loading = true
      state[locator]!.inited = true
    })
    builder.addMatcher(restApi.endpoints.getAllBuildsNormalized.matchFulfilled, (state, action) => {
      const {locator = ''} = action.meta.arg.originalArgs
      state[locator] ??= castDraft({...emptyArrayFetchable})
      if (!deepEqual(state[locator]!.data, action.payload.result)) {
        state[locator]!.data = castDraft(action.payload.result)
      }
      state[locator]!.loading = false
      state[locator]!.error = null
      state[locator]!.ready = true
      state[locator]!.inited = true
    })
    builder.addMatcher(restApi.endpoints.getAllBuildsNormalized.matchRejected, (state, action) => {
      const {locator = ''} = action.meta.arg.originalArgs
      state[locator] ??= castDraft({...emptyArrayFetchable})
      state[locator]!.loading = false
      const extractedError = extractError(action.error)
      state[locator]!.error =
        typeof extractedError === 'string' ? new Error(extractedError) : extractedError
      state[locator]!.ready = true
      state[locator]!.inited = true
    })
  },
})

export const stoppingBuilds = createSlice({
  name: 'stoppingBuilds',
  initialState: {} as KeyValue<BuildId, boolean>,
  reducers: {
    add(state, action: PayloadAction<BuildId>) {
      state[action.payload] = true
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          restApi.endpoints.getAllAgentsNormalized.matchFulfilled,
          restApi.endpoints.getBuildNormalized.matchFulfilled,
          restApi.endpoints.getBuildNormalizedAsList.matchFulfilled,
          receiveBuildAction,
          restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        ),
        (state, action) => {
          const receivedBuilds = action.payload.entities.builds
          if (receivedBuilds != null) {
            objectEntries(receivedBuilds).forEach(([id, build]) => {
              if (build) {
                state[id] = false
              }
            })
          }
        },
      )
      .addMatcher(receiveBuildWSDataAction.match, (state, action) => {
        const receivedBuilds = action.payload
        if (receivedBuilds != null) {
          objectEntries(receivedBuilds).forEach(([id, build]) => {
            if (build) {
              state[id] = false
            }
          })
        }
      })
  },
})

export const sorting = createSlice({
  name: 'sorting',
  initialState: {dimension: '', descending: false},
  reducers: {
    changeDimension(state, action: PayloadAction<string>) {
      state.dimension = action.payload
    },
    changeDirection(state, action: PayloadAction<boolean>) {
      state.descending = action.payload
    },
  },
})

const agentsInCloudInitialState: Record<AgentId, boolean> = {}
export const agentsInCloud = createSlice({
  name: 'agentsInCloud',
  initialState: agentsInCloudInitialState,
  reducers: {
    receive: (_, action: PayloadAction<readonly AgentId[]>) =>
      Object.fromEntries(action.payload.map(id => [id, true])),
  },
})

export const haveDependants = createSlice({
  name: 'haveDependants',
  initialState: {} as KeyValue<BuildId, boolean>,
  reducers: {
    receive(state, action: PayloadAction<readonly [BuildId, boolean][]>) {
      action.payload.forEach(([buildId, hasDependants]) => {
        state[buildId] = hasDependants
      })
    },
  },
})

export const buildTypeTab = createSlice({
  name: 'buildTypeTab',
  initialState: null as TabId | null,
  reducers: {
    change: (_, action: PayloadAction<TabId | null>) => action.payload,
  },
})

export const buildTab = createSlice({
  name: 'buildTab',
  initialState: null as TabId | null,
  reducers: {
    change: (_, action: PayloadAction<TabId | null>) => action.payload,
  },
})

type ShowQueuedBuildsPerBranchActionPayload = {
  buildTypeId: BuildTypeId
  branch: Branch
}
export const showQueuedBuildsPerBranch = createSlice({
  name: 'showQueuedBuildsPerBranch',
  initialState: {} as KeyValue<StatusKey, boolean>,
  reducers: {
    hide(state, action: PayloadAction<ShowQueuedBuildsPerBranchActionPayload>) {
      const {buildTypeId, branch} = action.payload
      state[getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))] = false
    },
    show(state, action: PayloadAction<ShowQueuedBuildsPerBranchActionPayload>) {
      const {buildTypeId, branch} = action.payload
      state[getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))] = true
    },
  },
})

type SetShowQueuedBuildsCountPayload = {
  buildTypeId: BuildTypeId
  branch: Branch | null | undefined
  count: number
}
export const showQueuedBuildsCount = createSlice({
  name: 'showQueuedBuildsCount',
  initialState: {} as KeyValue<StatusKey, number>,
  reducers: {
    set(state, action: PayloadAction<SetShowQueuedBuildsCountPayload>) {
      const {buildTypeId, branch, count} = action.payload
      state[getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))] = count
    },
  },
})

export const showQueuedBuildsInProject = createSlice({
  name: 'showQueuedBuildsInProject',
  initialState: {} as KeyValue<BuildTypeId, boolean>,
  reducers: {
    hide(state, action: PayloadAction<BuildTypeId>) {
      state[action.payload] = false
    },
    show(state, action: PayloadAction<BuildTypeId>) {
      state[action.payload] = true
    },
  },
})

export const serverInfo = createSlice({
  name: 'serverInfo',
  initialState: null as ServerInfo | null,
  reducers: {
    receive: (state, action: PayloadAction<ServerInfo>) => ({...state, ...action.payload}),
  },
})

export const dummyCalls = createSlice({
  name: 'dummyCalls',
  initialState: 0,
  reducers: {
    increment: state => state + 1,
  },
})

type CachedPluginsActionPayload = {
  placeId: PlaceId
  name: string
}
export const cachedPlugins = createSlice({
  name: 'cachedPlugins',
  initialState: {} as KeyValue<PlaceId, readonly string[]>,
  reducers: {
    remove(state, action: PayloadAction<CachedPluginsActionPayload>) {
      const {name, placeId} = action.payload
      const currentState = original(state)![placeId]
      if (currentState != null) {
        state[placeId] = currentState.filter(pluginName => pluginName !== name)
      }
    },
    add(state, action: PayloadAction<CachedPluginsActionPayload>) {
      const {name, placeId} = action.payload
      const currentState = original(state)![placeId]
      if (currentState == null) {
        state[placeId] = [name]
      } else if (currentState.includes(name)) {
        // eslint-disable-next-line no-console
        console.warn(`Plugin ${name} has already been registered in Place ${placeId}`)
      } else {
        state[placeId]!.push(name)
      }
    },
  },
})

type SetSyncStorageValuePayload = {
  key: string
  value: string | null
}
export const syncStorageValues = createSlice({
  name: 'syncStorageValues',
  initialState: {} as KeyValue<string, string | null>,
  reducers: {
    set(state, action: PayloadAction<SetSyncStorageValuePayload>) {
      const {key, value} = action.payload
      state[key] = value
    },
  },
})

export const queuedTogglerAutoExpand = createSlice({
  name: 'queuedTogglerAutoExpand',
  initialState: {} as KeyValue<StatusKey, boolean | null>,
  reducers: {
    collapse(state, action: PayloadAction<StatusKey>) {
      state[action.payload] = false
    },
  },
  extraReducers: builder => {
    builder.addCase(triggerBuildAction.fulfilled, (state, action) => {
      if (action.payload.autoExpandQueued) {
        state[getTriggerBuildKey(action.meta.arg)] = true
      }
    })
  },
})

const INITIAL_BUILDTYPES_LIMIT = 200
const initialState =
  (internalProps['teamcity.overviewPage.buildTypes.limit'] as number) ?? INITIAL_BUILDTYPES_LIMIT
export const buildTypesLimit = createSlice({
  name: 'buildTypesLimit',
  initialState,
  reducers: {
    set: (_, action: PayloadAction<number>) => action.payload,
  },
})
