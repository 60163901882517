import {BS} from '../../../types/BS_types'

type TopNavHelperData = {
  adminSpaceAvailable: boolean
  agents: number
  queue: number
}
export const getTopNavHelperData = (): TopNavHelperData => ({
  agents: BS?.topNavPane?.helpers?.counters?.agents ?? 0,
  queue: BS?.topNavPane?.helpers?.counters?.queue ?? 0,
  adminSpaceAvailable: BS?.topNavPane?.helpers?.adminSpaceAvailable ?? false,
})
let sakuraHeaderUsed = false
export const setSakuraHeaderUsed = (): boolean => (sakuraHeaderUsed = true)
export const isSakuraHeaderUsed = (): boolean => sakuraHeaderUsed
