import RemoveIcon from '@jetbrains/icons/cancel.svg'
import StopIcon from '@jetbrains/icons/stop.svg'
import Button from '@jetbrains/ring-ui/components/button/button'
import classNames from 'classnames'
import * as React from 'react'

import type {BuildId} from '../../../types'
import {KillOperationKind} from '../../../types'
import {BS} from '../../../types/BS_types'
import IconButton from '../IconButton/IconButton'

import {stopButtonType} from './StopBuild.types'
import type {DefaultProps, Props} from './StopBuild.types'

import styles from './StopBuild.css'

const noop = () => Promise.resolve(false)

/**
 * BS.StopBuildDialog.showStopBuildDialog([21740961], '', 2, null)
 * Shows the dialog to one of the following 3 actions: remove a build from the queue, stop a running build, remove a finished build.
 *
 * @param promoIds        array of promotion identifiers. Mostly one identifier only.
 * @param defaultMessage
 * @param operationKind      See StopBuildAction.KillOperationKind:
 *                           1 - remove a queued build,
 *                           2 - stop a running build,
 *                           3 - remove a finished build.
 * @param reStopMessage
 */
type ShowDialogFn = (
  arg0: [BuildId],
  arg1: string,
  arg2: KillOperationKind,
  arg3: HTMLSpanElement | null | undefined,
) => Promise<boolean>
export default class StopBuild extends React.Component<Props> {
  static defaultProps: DefaultProps = {
    state: 'running',
    actualStopMessage: '',
    buttonType: stopButtonType.iconButton,
    onStop: noop,
    updateResults: noop,
  }

  commentRef: {
    current: null | HTMLSpanElement
  } = React.createRef()

  getTitle: () => string = (): string => {
    const {state, isStopping, isInterrupted, isCancellable, agentName} = this.props
    const agentMention = agentName != null ? ` on ${agentName}` : ''

    if (state === 'finished') {
      return ''
    }

    if (!isCancellable) {
      return isInterrupted ? 'Build is stopping' : ''
    }

    if (state === 'queued') {
      return 'Cancel build...'
    }

    if (isStopping) {
      return 'Build is stopping'
    }

    if (isInterrupted) {
      return `Build${agentMention} is stopping. Click to force stop`
    }

    return `Stop build${agentMention}...`
  }

  getButtonText: () => string = () => (this.props.state === 'running' ? 'Stop...' : 'Cancel...')
  getIcon = () => (this.props.state === 'running' ? StopIcon : RemoveIcon)

  getOperationKind: () => KillOperationKind = () =>
    this.props.state === 'running' ? KillOperationKind.STOP : KillOperationKind.CANCEL

  getShowHandler: () => ShowDialogFn = () => {
    if (BS?.StopBuildDialog) {
      return BS.StopBuildDialog.showStopBuildDialog.bind(BS.StopBuildDialog)
    } else {
      return noop
    }
  }

  handleShowStopDialog: () => Promise<void> = async () => {
    const {buildId, actualStopMessage, onStop, updateResults} = this.props
    const showDialog: ShowDialogFn = this.getShowHandler()
    const actionTriggered = await showDialog(
      [buildId],
      actualStopMessage,
      this.getOperationKind(),
      this.commentRef.current,
    )

    if (actionTriggered) {
      onStop(buildId)
      updateResults()
    }
  }

  render(): React.ReactNode {
    const {
      className,
      isCancellable,
      isStopping,
      isInterrupted,
      state,
      stoppedWithComment,
      stoppedBy,
      buttonType,
      buttonText,
      primary,
    } = this.props
    const classes = classNames(styles.stopBuild, className, {
      [styles.icon]: buttonType === stopButtonType.iconButton,
      [styles.invisible]: state === 'finished' || (!isCancellable && !isInterrupted),
    })
    const iconClasses = classNames({
      [styles.stopIcon]: state === 'running',
    })
    return (
      <>
        {stoppedWithComment != null && (
          <span hidden ref={this.commentRef}>
            {'This build has already been stopped'}
            {stoppedBy != null && (
              <>
                {' by '}
                <em>{stoppedBy}</em>
              </>
            )}
            {' with message '}
            <em>{stoppedWithComment}</em>
          </span>
        )}
        {buttonType === stopButtonType.iconButton ? (
          <IconButton
            disabled={!isCancellable}
            className={classes}
            iconWrapperClassName={iconClasses}
            title={this.getTitle()}
            onClick={this.handleShowStopDialog}
            loader={isStopping || (!isCancellable && isInterrupted)}
            icon={this.getIcon()}
            danger={isInterrupted && isCancellable}
            primary={primary}
          >
            {buttonText}
          </IconButton>
        ) : (
          <Button
            disabled={!isCancellable}
            className={classes}
            title={this.getTitle()}
            onClick={this.handleShowStopDialog}
            loader={isStopping || (!isCancellable && isInterrupted)}
            danger={isInterrupted && isCancellable}
          >
            {this.getButtonText()}
          </Button>
        )}
      </>
    )
  }
}
