import {combineReducers} from 'redux'

import {
  collapsedBlocks,
  hoveredJob,
  pipelineDraft,
  pipelineError,
} from './EditPipelinePage/slices/EditPipelinePage.slices'

export const pipelines = combineReducers({
  collapsedBlocks: collapsedBlocks.reducer,
  hoveredJob: hoveredJob.reducer,
  pipelineDraft: pipelineDraft.reducer,
  pipelineError: pipelineError.reducer,
})
