interface UserAgentData {
  brands: Array<{
    brand: string
    version: string
  }>
  mobile: boolean
  platform: string
}

declare global {
  interface NavigatorID {
    userAgentData: UserAgentData
  }
}

const userAgent =
  navigator.userAgentData && Array.isArray(navigator.userAgentData.brands)
    ? navigator.userAgentData.brands
        .map(brand => `${brand.brand.toLowerCase()} ${brand.version}`)
        .join(' ')
    : navigator.userAgent.toLowerCase()

const isChromium = userAgent.includes('chrome') || userAgent.includes('chromium')
export const isSafari = userAgent.includes('safari') && !isChromium
