import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {Job} from '../../types'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {deleteIfEmpty, getJob} from './EditPipelinePage.slices.utils'

export const setJobName: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    pipelineId: string
    value: string
    savedValue: string
  }>
> = (state, action) => {
  const {pipelineId, value, savedValue} = action.payload

  if (value !== savedValue) {
    state[pipelineId] ??= {}
    state[pipelineId]!.renamed ??= {}
    state[pipelineId]!.renamed!.jobs ??= {}
    state[pipelineId]!.renamed!.jobs![savedValue] = value
  } else if (state[pipelineId]?.renamed?.jobs?.[savedValue] != null) {
    delete state[pipelineId]!.renamed!.jobs![savedValue]
    deleteIfEmpty(state[pipelineId]!.renamed, 'jobs') && deleteIfEmpty(state[pipelineId], 'renamed')
  }
}

export const addJob: CaseReducer<
  PipelineDraftState,
  PayloadAction<{job: Job; id: string; jobId: string}>
> = (state, action) => {
  const {job, id, jobId} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.settings.jobs ??= {}
    draft.settings.jobs![jobId] = job
  }
}

export const deleteJob: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; jobId: string}>
> = (state, action) => {
  const {id, jobId} = action.payload
  state[id] ??= {}
  state[id]!.deleted ??= {}
  state[id]!.deleted!.jobs ??= []
  state[id]!.deleted!.jobs!.push(jobId)
}

export const restoreJob: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; jobId: string}>
> = (state, action) => {
  const {id, jobId} = action.payload
  const {deleted} = state[id] ?? {}
  if (deleted?.jobs?.includes(jobId)) {
    deleted.jobs = deleted.jobs.filter(otherId => otherId !== jobId)
    deleteIfEmpty(deleted, 'jobs') && deleteIfEmpty(state[id], 'deleted')
  }
}

export const toggleCheckoutWorkingDirectoriesOnly: CaseReducer<
  PipelineDraftState,
  PayloadAction<{value: boolean; id: string; jobId: string}>
> = (state, action) => {
  const {id, jobId, value} = action.payload
  const job = getJob(state, id, jobId)

  if (job != null) {
    if (value) {
      deleteIfEmpty(job, 'checkout-working-directories-only')
    } else {
      job['checkout-working-directories-only'] = false
    }
  }
}
