/* eslint-disable no-labels */
/* eslint-disable @typescript-eslint/no-magic-numbers */

import {darkThemeAdapterServicePrefix} from '../../defaults'
import {forEach} from '../../utils/array'
import {checkIfElementIgnoredByDarkThemeAdapter, iterateShadowHosts} from '../../utils/dom'
import {getStyleSheetJSSelector} from '../../utils/selectors'
import {getMatches} from '../../utils/text'
import {getAbsoluteURL} from '../../utils/url'
import {
  cssImportRegex,
  getCSSBaseBath,
  getCSSURLValue,
  removeCSSComments,
  replaceCSSFontFace,
  replaceCSSRelativeURLsWithAbsolute,
} from '../cssRules'

import type {StyleElement} from './styleManager.types'

export function containsCSSImport(element: StyleElement) {
  return element instanceof HTMLStyleElement && element.textContent!.trim().match(cssImportRegex)
}

export function hasImports(cssRules: CSSRuleList | null, checkCrossOrigin: boolean) {
  let result = false
  if (cssRules) {
    let rule: CSSRule

    cssRulesLoop: for (let i = 0, len = cssRules.length; i < len; i++) {
      rule = cssRules[i]
      if ((rule as CSSImportRule).href) {
        if (checkCrossOrigin) {
          if (
            (rule as CSSImportRule).href.startsWith('http') &&
            !(rule as CSSImportRule).href.startsWith(location.origin)
          ) {
            result = true
            break cssRulesLoop
          }
        } else {
          result = true
          break cssRulesLoop
        }
      }
    }
  }
  return result
}

export const rejectorsForLoadingLinks = new Map<number, (reason?: any) => void>()

export function cleanLoadingLinks() {
  rejectorsForLoadingLinks.clear()
}

export function shouldManageStyle(element: Node | null): boolean {
  const isHTMLStyle = element instanceof HTMLStyleElement
  const isSVGStyle = element instanceof SVGStyleElement
  const isStyleSheetValidLink =
    element instanceof HTMLLinkElement &&
    element?.rel?.toLowerCase()?.includes('stylesheet') &&
    Boolean(element.href) &&
    !checkIfElementIgnoredByDarkThemeAdapter(element) &&
    !element.disabled

  return (
    (isHTMLStyle || isSVGStyle || isStyleSheetValidLink) &&
    !element.classList.contains(darkThemeAdapterServicePrefix)
  )
}

const STYLE_SELECTOR = 'style, link[rel*="stylesheet" i]:not([disabled])'

export function getManageableStyles(
  node: Node | null,
  results = [] as StyleElement[],
  deep = true,
) {
  if (shouldManageStyle(node)) {
    results.push(node as StyleElement)
  } else if (node instanceof Element || node instanceof ShadowRoot || node === document) {
    forEach((node as Element).querySelectorAll(STYLE_SELECTOR), (style: Element) =>
      getManageableStyles(style, results, false),
    )
    if (deep) {
      iterateShadowHosts(node, host => getManageableStyles(host.shadowRoot, results, false))
    }
  }
  return results
}

function getCSSImportURL(importDeclaration: string) {
  return getCSSURLValue(
    importDeclaration
      .substring(7) // remove @import
      .trim()
      .replace(/;$/, '')
      .replace(/screen$/, ''),
  )
}

export const loadText = async (url: string) => await (await fetch(url)).text()

export async function replaceCSSImports(
  cssText: string,
  basePath: string,
  cache = new Map<string, string>(),
) {
  let result = removeCSSComments(cssText)
  result = replaceCSSFontFace(result)
  result = replaceCSSRelativeURLsWithAbsolute(result, basePath)

  const importMatches = getMatches(cssImportRegex, result)
  for (const match of importMatches) {
    const importURL = getCSSImportURL(match)
    const absoluteURL = getAbsoluteURL(basePath, importURL)
    let importedCSS: string
    if (cache.has(absoluteURL)) {
      importedCSS = cache.get(absoluteURL)!
    } else {
      try {
        importedCSS = await loadText(absoluteURL)
        cache.set(absoluteURL, importedCSS)
        importedCSS = await replaceCSSImports(importedCSS, getCSSBaseBath(absoluteURL), cache)
      } catch (err) {
        importedCSS = ''
      }
    }
    result = result.split(match).join(importedCSS)
  }

  result = result.trim()

  return result
}

export const syncStyleSet = new WeakSet<HTMLStyleElement | SVGStyleElement>()
export const corsStyleSet = new WeakSet<HTMLStyleElement>()

export function createCORSCopy(srcElement: StyleElement, cssText: string) {
  if (!cssText) {
    return null
  }

  const cors = document.createElement('style')
  cors.classList.add(darkThemeAdapterServicePrefix)
  cors.classList.add(getStyleSheetJSSelector('cors'))
  cors.media = 'screen'
  cors.textContent = cssText
  srcElement.parentNode!.insertBefore(cors, srcElement.nextSibling)
  cors.sheet!.disabled = true
  corsStyleSet.add(cors)
  return cors
}

export function linkLoading(link: HTMLLinkElement, loadingId: number) {
  return new Promise<void>((resolve, reject) => {
    const cleanUp = () => {
      link.removeEventListener('load', onLoad)
      link.removeEventListener('error', onError)
      rejectorsForLoadingLinks.delete(loadingId)
    }

    function onLoad() {
      cleanUp()
      resolve()
    }

    function onError() {
      cleanUp()
      reject(`Linkelement ${loadingId} couldn't be loaded. ${link.href}`)
    }

    rejectorsForLoadingLinks.set(loadingId, () => {
      cleanUp()
      reject()
    })
    link.addEventListener('load', onLoad)
    link.addEventListener('error', onError)
    if (!link.href) {
      onError()
    }
  })
}
