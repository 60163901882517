import type {State} from '../../../../../reducers/types'
import {getBuild} from '../../../../../selectors'
import type {BuildId, ChangeId, Fetchable, NormalizedChange} from '../../../../../types'
import {emptyNullFetchable} from '../../../../../utils/empty'
import {getBuildChanges} from '../../../../packages/Changes/Changes.selectors'

export const getChangeData = (state: State, id: ChangeId): Fetchable<NormalizedChange | null> =>
  state.buildStatusTooltip.changes[id] || emptyNullFetchable

export const getPersonalChangeId: (
  state: State,
  buildId: BuildId,
) => ChangeId | null | undefined = (state, buildId) => {
  const build = getBuild(state, buildId)

  if (build?.personal !== true) {
    return null
  }

  const [lastChange] = getBuildChanges(state, buildId)
  const {personal = false, id} = lastChange || {}
  return personal ? id : null
}
