import {debounce} from 'callbag-debounce'
import pipe from 'callbag-pipe'
import makeSubject from 'callbag-subject'
import subscribe from 'callbag-subscribe'

const DATA = 1
export type SimpleStream<T> = {
  fire: (value: T) => unknown
  subscribe: (handler: (value: T) => unknown) => () => void
}
export default function createSimpleStream<T = void>(debouncePeriod?: number): SimpleStream<T> {
  const subject = makeSubject<T>()
  const observable = debouncePeriod != null ? pipe(subject, debounce<T>(debouncePeriod)) : subject

  const fire = (value: T) => subject(DATA, value)

  return {
    fire,

    subscribe(listener) {
      return pipe(observable, subscribe(listener))
    },
  }
}
