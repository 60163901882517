import type {FC} from 'react'
import * as React from 'react'

import Link from '../../../common/Link/Link'
import SvgIcon from '../../../common/SvgIcon/SvgIcon'

import styles from './HeaderBanner.css'

type OwnProps = {
  href: string
}

const HeaderBannerFeedback: FC<OwnProps> = ({href}) => (
  <Link className={styles.feedback} href={href} target={'_blank'} rel="noreferrer">
    {WrapText => (
      <>
        <SvgIcon className={styles.feedbackIcon} icon={'comment'} />
        <WrapText>{'Share feedback...'}</WrapText>
      </>
    )}
  </Link>
)

export default HeaderBannerFeedback
