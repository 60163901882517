import * as Redux from 'redux'

import buildPager from './BuildPager/BuildPager.slice'
import changeLogPagerReducer from './ChangeLogPager/ChangeLogPager.reducer'
import changePagerReducer from './ChangePager/ChangePager.reducer'
import {PagerGroup} from './Pager.types'
import testPagerReducer from './TestPager/TestPager.reducer'
import testScopesPagerReducer from './TestScopePager/TestScopePager.reducer'

const pagerReducer = Redux.combineReducers({
  [PagerGroup.BUILD]: buildPager.reducer,
  [PagerGroup.TEST]: testPagerReducer,
  [PagerGroup.TEST_SCOPE]: testScopesPagerReducer,
  [PagerGroup.CHANGE]: changePagerReducer,
  [PagerGroup.CHANGE_LOG]: changeLogPagerReducer,
})

export default pagerReducer
