import {getBuildTypeAndBranchLocator} from '../rest/locators'
import type {BuildTypeId, SelectedBranch} from '../types'

import {defaultBranch} from './branchNames'
import type {KeyValue} from './object'

const locators: KeyValue<string, string> = {
  lastPinned: 'pinned:true',
  lastSuccessful: 'state:finished,status:SUCCESS',
  lastFinished: 'state:finished',
}
export function getPermalinkLocator(
  permalink: string,
  buildTypeId: BuildTypeId,
  branch?: SelectedBranch | null,
): string | null | undefined {
  const stateLocator = locators[permalink]
  return stateLocator != null
    ? `${stateLocator},${getBuildTypeAndBranchLocator(
        buildTypeId,
        branch == null || branch.wildcard === true || branch.groupFlag === true
          ? defaultBranch
          : branch,
      )}`
    : null
}
