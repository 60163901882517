import {castDraft} from 'immer'

import type {PayloadAction} from '@reduxjs/toolkit'
import {createReducer, isAnyOf} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {
  fetchArtifactsSize,
  fetchBuildStatsAction,
  fetchCurrentUserDataAction,
  fetchHtmlAction,
  fetchLicensingData,
  fetchPoolPermissions,
  fetchTabsAction,
  resetState,
  setUserPropertyAction,
} from '../actions'
import {fetchHasBuildsAction} from '../actions/builds'
import {fetchFederationServersAction} from '../actions/federation'
import {fetchBuildTypeTags, fetchDslOptionsAction} from '../actions/fetch'
import {toggleOverviewAction} from '../actions/overview'
import {
  allProjectsExpandState,
  searchProjectsExpandState,
  sidebar,
} from '../components/App/ProjectsSidebar/ProjectsSidebar.slices'
import {queueSidebar} from '../components/App/QueueSidebar/QueueSidebar.reducers'
import agentAuth from '../components/common/AgentAuth/AgentAuth.reducers'
import buildApprovalsSlice from '../components/common/BuildApproval/BuildApproval.slices'
import queueInfo from '../components/common/BuildQueueInfo/BuildQueueInfo.reducers'
import buildStatusTooltip from '../components/common/BuildStatus/BuildStatusLink/BuildStatusTooltip/BuildStatusTooltip.reducers'
import errorAlerts from '../components/common/ErrorAlerts/ErrorAlerts.reducers'
import pagerReducer from '../components/common/Pager/Pager.reducer'
import {
  getTriggerBuildKey,
  triggerBuildAction,
} from '../components/common/RunBuild/RunBuild.actions'
import userSelect from '../components/common/UserSelect/UserSelect.reducers'
import {buildLogReducers} from '../components/packages/BuildLog/BuildLog.reducers'
import buildProblems from '../components/packages/BuildProblems/BuildProblems.reducers'
import changeDetailsTabs from '../components/packages/Changes/ChangeDetailsTabs/ChangeDetailsTabs.reducers'
import changeProjectsSelect from '../components/packages/Changes/ChangeDetailsTabs/FilesTab/ChangeBuildTypeSelect/ChangeBuildTypeSelect.reducers'
import changeFiles from '../components/packages/Changes/ChangeFiles/ChangeFiles.reducers'
import changesDropdown from '../components/packages/Changes/ChangesDropdown/ChangesDropdown.reducers'
import type {DslActionPayload} from '../components/packages/Dsl/Dsl.slices'
import {
  dslFragment,
  dslOptions,
  dslPortable,
  dslVersion,
  toggleDsl,
} from '../components/packages/Dsl/Dsl.slices'
import {hints} from '../components/packages/Hints/Hints.slices'
import investigationHistory from '../components/packages/InvestigationHistory/InvestigationHistory.reducers'
import {parameterGroups} from '../components/packages/Parameters/Parameters.slice'
import {shortcuts} from '../components/packages/Shortcuts/Shortcuts.slices'
import tests from '../components/packages/Tests/Tests.reducers'
import type {TestFlaky} from '../components/packages/Tests/Tests.types'
import {agentsPagesReducers} from '../components/pages/AgentsPages/AgentsPages.reducers'
import buildSnippets from '../components/pages/BuildPage/BuildOverviewTab/BuildSnippets/BuildSnippets.reducer'
import deployments from '../components/pages/BuildPage/BuildOverviewTab/Deployments/Deployments.reducers'
import {getDeliveredArtifactsKey} from '../components/pages/BuildPage/DependenciesTab/DependenciesTab.utils'
import {dependenciesTimelineSearch} from '../components/pages/BuildPage/DependenciesTab/DependenciesTimeline/DependenciesTimeline.slice'
import cleanupPolicies from '../components/pages/CleanupProjectPage/CleanupProjectPage.reducers'
import cleanup from '../components/pages/CleanupProjectPage/Listings/Rules/Rules.reducers'
import {compareBuildsPageReducers} from '../components/pages/CompareBuildsPage/CompareBuildsPage.reducers'
import httpsConfigurationPageReducer from '../components/pages/HttpsConfigurationPage/HttpsConfigurationPage.reducers'
import {pipelines} from '../components/pages/PipelinesPages/PipelinesPages.reducers'
import projectInvestigationsReducers from '../components/pages/ProjectPage/ProjectInvestigationsTab/ProjectInvestigations.reducers'
import projectPage from '../components/pages/ProjectPage/ProjectPage.reducers'
import type {StatusKey} from '../rest/schemata'
import type {Property} from '../services/rest'
import {restApi} from '../services/rest'
import {
  agentsInCloud,
  blocks,
  builds as buildsSlice,
  buildTab,
  buildTypesLimit,
  buildTypeTab,
  cachedPlugins,
  dialog,
  dummyCalls,
  haveDependants,
  isSakuraUI,
  queuedTogglerAutoExpand,
  routeAvailabilityResponse,
  serverInfo,
  showQueuedBuildsCount,
  showQueuedBuildsInBuildsList,
  showQueuedBuildsInProject,
  showQueuedBuildsPerBranch,
  sorting,
  stoppingBuilds,
  syncStorageValues,
  urlExtensions,
} from '../slices'
import {buildSelections} from '../slices/buildSelections'
import buildsFilters from '../slices/buildsFilters'
import {theme} from '../slices/theme'
import type {
  BuildId,
  BuildTypeId,
  ProjectId,
  TestId,
  BuildArtifactsSize,
  CurrentUser,
  DslOptions,
  LicensingData,
  Permission,
} from '../types'
import {ALL_PROJECTS} from '../types'
import {emptyArray} from '../utils/empty'
import type {KeyValue} from '../utils/object'

import {combineReducers} from './combineReducers'

import entities from './entities'
import federationServersDataReducer from './federationServersData'
import {keyValueFetchable, fetchable} from './fetchable'
import type {PoolPermissionsState, ProjectsState, State, TogglingOverview} from './types'
import {clientId} from './types'
import {keyValueReducer} from './utils'

const currentUserReducer = fetchable(
  fetchCurrentUserDataAction,
  null as CurrentUser | null,
  (_, action) => action.payload,
)

const licensingDataReducer = fetchable(
  fetchLicensingData,
  null as LicensingData | null,
  (_, action) => action.payload,
)

const mainReducer = combineReducers({
  clientId: () => clientId,

  entities,

  buildTypeStatuses: createReducer({}, builder => {
    builder.addMatcher(restApi.endpoints.getStatuses.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload)
    })
  }),

  buildsDetailsLoading: createReducer<Record<string, boolean>>({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getAllBuildsDetailsNormalized.matchPending,
      (state, action) => {
        const {detailsLocatorToStore} = action.meta.arg.originalArgs
        if (detailsLocatorToStore != null) {
          state[detailsLocatorToStore] = true
        }
      },
    )
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllBuildsDetailsNormalized.matchFulfilled,
        restApi.endpoints.getAllBuildsDetailsNormalized.matchRejected,
      ),
      (state, action) => {
        const {detailsLocatorToStore} = action.meta.arg.originalArgs
        if (detailsLocatorToStore != null) {
          state[detailsLocatorToStore] = false
        }
      },
    )
  }),

  federationServersData: federationServersDataReducer,

  isSakuraUI: isSakuraUI.reducer,

  urlExtensions: urlExtensions.reducer,

  routeAvailabilityResponse: routeAvailabilityResponse.reducer,
  pager: pagerReducer,
  blocks: blocks.reducer,

  dialog: dialog.reducer,

  builds: buildsSlice.reducer,

  flakyTests: createReducer<KeyValue<BuildId, KeyValue<TestId, TestFlaky>>>({}, builder => {
    builder.addMatcher(restApi.endpoints.getFlakyTests.matchFulfilled, (state, action) => {
      action.payload.forEach(test => {
        const {buildId} = action.meta.arg.originalArgs
        state[buildId] ??= {}
        state[buildId]![test.id] = castDraft(test)
      })
    })
  }),
  projects: createReducer<KeyValue<ProjectId, ProjectsState>>({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      (state, action) => {
        const {projectId, projectReceiveMeta, archived} = action.meta.arg.originalArgs
        if ((archived == null || archived === 'false') && projectId != null) {
          state[projectId] = castDraft({
            data: action.payload.result,
            loaded: true,
            receiveMeta: {...state[projectId]?.receiveMeta, ...projectReceiveMeta},
          })
        }
      },
    )
  }),
  projectsWithArchived: createReducer<KeyValue<ProjectId, ProjectsState>>({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      (state, action) => {
        const {projectId, projectReceiveMeta, archived} = action.meta.arg.originalArgs
        if (archived === 'any' && projectId != null) {
          state[projectId] = castDraft({
            data: action.payload.result,
            loaded: true,
            receiveMeta: {...state[projectId]?.receiveMeta, ...projectReceiveMeta},
          })
        }
      },
    )
  }),

  permissions: createReducer<KeyValue<Permission, KeyValue<ProjectId, boolean>>>({}, builder => {
    builder.addMatcher(restApi.endpoints.getProjectPermissions.matchFulfilled, (state, action) => {
      const {projectId, permission} = action.meta.arg.originalArgs
      const data = action.payload.project
      if (data != null) {
        if (projectId === ALL_PROJECTS) {
          state[permission] = Object.fromEntries(data.map(item => [item.id, true]))
        } else {
          state[permission] ??= {}
          state[permission]![projectId] = data.length > 0
        }
      }
    })
  }),

  poolPermissions: createReducer<PoolPermissionsState>(
    {
      canChangeStatus: {},
      canAuthorize: {},
    },
    builder => {
      builder.addCase(fetchPoolPermissions.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
    },
  ),

  html: createReducer<KeyValue<string, string | null>>({}, builder => {
    builder.addCase(fetchHtmlAction.fulfilled, (state, action) => {
      state[action.meta.arg.path] = action.payload
    })
  }),

  stoppingBuilds: stoppingBuilds.reducer,

  startingBuilds: createReducer<Record<StatusKey, boolean>>({}, builder => {
    builder.addCase(triggerBuildAction.pending, (state, action) => {
      state[getTriggerBuildKey(action.meta.arg)] = true
    })
    builder.addCase(triggerBuildAction.fulfilled, (state, action) => {
      state[getTriggerBuildKey(action.meta.arg)] = false
    })
    builder.addCase(triggerBuildAction.rejected, (state, action) => {
      state[getTriggerBuildKey(action.meta.arg)] = false
    })
  }),

  togglingOverview: createReducer<TogglingOverview>(
    {
      bt: {},
      project: {},
    },
    builder => {
      builder.addCase(toggleOverviewAction.pending, (state, action) => {
        const {node, on} = action.meta.arg
        if (node.nodeType === 'bt' || node.nodeType === 'project') {
          state[node.nodeType][node.id!] = on
        }
      })
      builder.addMatcher(
        isAnyOf(toggleOverviewAction.fulfilled, toggleOverviewAction.rejected),
        (state, action) => {
          const {node} = action.meta.arg
          if (node.nodeType === 'bt' || node.nodeType === 'project') {
            state[node.nodeType][node.id!] = null
          }
        },
      )
    },
  ),

  buildsFilters: buildsFilters.reducer,
  currentUser: currentUserReducer,

  userProperties: createReducer<KeyValue<string, string>>({}, builder => {
    builder.addCase(fetchCurrentUserDataAction.fulfilled, (state, action) => {
      const properties = action.payload?.properties?.property

      if (properties == null) {
        return state
      }

      return Object.fromEntries(properties.map(({name, value}: Property) => [name, value]))
    })
    builder.addCase(setUserPropertyAction.pending, (state, action) => {
      const {name, value} = action.meta.arg
      state[name] = value
    })
  }),

  sorting: sorting.reducer,

  agentsInCloud: agentsInCloud.reducer,

  buildTypeTags: keyValueFetchable(
    arg => arg,
    fetchBuildTypeTags,
    emptyArray,
    (_, action) => action.payload,
  ),

  buildsStats: keyValueFetchable(
    arg => arg.locator,
    fetchBuildStatsAction,
    emptyArray,
    (_, action) => action.payload,
  ),

  haveDependants: haveDependants.reducer,

  buildTypeTab: buildTypeTab.reducer,

  buildTab: buildTab.reducer,

  projectPage,

  sidebar: sidebar.reducer,

  dslOptions: createReducer<KeyValue<string, DslOptions>>({}, builder => {
    builder.addDefaultCase(
      keyValueReducer(
        (action: PayloadAction<DslActionPayload>) => action.payload.controlId,
        dslOptions.reducer,
        [
          toggleDsl.actions.show,
          toggleDsl.actions.hide,
          dslVersion.actions.set,
          dslPortable.actions.set,
          dslOptions.actions.init,
        ],
      ),
    )
  }),

  dslFragment: dslFragment.reducer,

  availableDslOptions: fetchable(fetchDslOptionsAction, emptyArray, (_, action) => action.payload),
  showQueuedBuildsPerBranch: showQueuedBuildsPerBranch.reducer,
  showQueuedBuildsCount: showQueuedBuildsCount.reducer,
  showQueuedBuildsInProject: showQueuedBuildsInProject.reducer,

  showQueuedBuildsInBuildsList: showQueuedBuildsInBuildsList.reducer,

  federationServers: fetchable(
    fetchFederationServersAction,
    emptyArray,
    (_, action) => action.payload.result,
  ),
  hasBuilds: keyValueFetchable(
    arg => arg.locator,
    fetchHasBuildsAction,
    false,
    (_, action) => action.payload,
  ),
  tabs: keyValueFetchable(
    arg => arg,
    fetchTabsAction,
    emptyArray,
    (_, action) => action.payload,
  ),

  serverInfo: serverInfo.reducer,

  dummyCalls: dummyCalls.reducer,

  cachedPlugins: cachedPlugins.reducer,
  syncStorageValues: syncStorageValues.reducer,
  queuedToggler: Redux.combineReducers({
    autoExpand: queuedTogglerAutoExpand.reducer,
    hasTriggeredByMeBuilds: createReducer<KeyValue<BuildTypeId, boolean | null>>({}, builder => {
      builder.addCase(triggerBuildAction.fulfilled, (state, action) => {
        state[action.meta.arg.buildTypeId] = true
      })
    }),
  }),
  compareBuilds: compareBuildsPageReducers,
  cleanup,
  cleanupPolicies,
  buildLog: buildLogReducers,
  projectInvestigations: projectInvestigationsReducers,
  buildSnippets,
  agentsPage: agentsPagesReducers,
  queueInfo,
  deployments,
  changeDetailsTabs,
  changesDropdown,
  changeFiles,
  buildApprovals: buildApprovalsSlice.reducer,
  buildStatusTooltip,
  changeProjectsSelect,
  agentAuth,
  userSelect,
  errorAlerts,
  investigationHistory,
  tests,
  buildProblems,
  queueSidebar,
  hints: hints.reducer,
  shortcuts: shortcuts.reducer,
  https: httpsConfigurationPageReducer,
  licensingData: licensingDataReducer,

  buildTypesLimit: buildTypesLimit.reducer,

  artifactSizes: createReducer<KeyValue<BuildId, BuildArtifactsSize>>({}, builder => {
    builder.addCase(fetchArtifactsSize.fulfilled, (state, action) => {
      state[action.meta.arg] = action.payload
    })
  }),
  deliveredArtifacts: createReducer<KeyValue<string, readonly string[]>>({}, builder => {
    builder.addMatcher(restApi.endpoints.getAllBuildsNormalized.matchFulfilled, (state, action) => {
      const from = action.meta.arg.originalArgs.withDownloadedArtifactsFrom
      if (from != null) {
        for (const to of action.payload.result) {
          const download =
            action.payload.entities.builds?.[to]?.downloadedArtifacts?.downloadInfo[0]
          if (download != null) {
            state[getDeliveredArtifactsKey(from, to)] = download.artifactInfo?.map(
              item => item.path ?? '',
            )
          }
        }
      }
    })
  }),
  buildSelections: buildSelections.reducer,
  pipelines,
  [restApi.reducerPath]: restApi.reducer,
  dependenciesTimelineSearch: dependenciesTimelineSearch.reducer,
  theme: theme.reducer,

  allProjectsExpandState: allProjectsExpandState.reducer,

  searchProjectsExpandState: searchProjectsExpandState.reducer,

  parameterGroups: parameterGroups.reducer,
})
export type MainReducer = typeof mainReducer
export default (state: State | undefined, action: PayloadAction<unknown>): State =>
  resetState.match(action) ? action.payload : mainReducer(state, action)
