import {withHandlers} from 'recompose'

import compose from 'lodash/flowRight'
import {connect} from 'react-redux'

import {withFetchEffect} from '../../../../hocs/withEffect'
import type {State} from '../../../../reducers/types'
import {getIsAutodetectTimeZone} from '../../../../selectors'
import {subscribeOnProjectEvents} from '../../../../utils/subscriber'
import {
  BUILD_TYPE_RESPONSIBILITY_CHANGES,
  PROBLEM_RESPONSIBILITY_CHANGED,
  TEST_RESPONSIBILITY_CHANGED,
} from '../../../../utils/subscriptionEvents'

import {fetchInvestigationHistory} from '../InvestigationHistory.actions'
import {getInvestigationHistoryFetchable} from '../InvestigationHistory.selectors'

import InvestigationHistoryList from './InvestigationHistoryList'
import type {OwnProps, StateProps, ActionProps, Props} from './InvestigationHistoryList.types'

const actionCreators: ActionProps = {
  fetchInvestigationHistory,
}

const mapStateToProps = (state: State, {locator}: OwnProps): StateProps => {
  const investigationsFetchable = getInvestigationHistoryFetchable(state, locator)
  return {
    investigations: investigationsFetchable.data,
    isLoading: investigationsFetchable.loading,
    isBackgroundLoading: investigationsFetchable.backgroundLoading,
    isReady: investigationsFetchable.ready,
    isAutodetectTimeZone: getIsAutodetectTimeZone(state),
  }
}

export default compose(
  connect(mapStateToProps, actionCreators),
  withHandlers({
    fetchInvestigationHistoryHandler:
      ({
        fetchInvestigationHistory: fetch,
        isLoading,
        locator,
      }: OwnProps & StateProps & ActionProps) =>
      (isBackground?: boolean) => {
        if (!isLoading) {
          fetch(locator, isBackground)
        }
      },
  }),
  withFetchEffect(
    (props: Props) => props.projectInternalId,
    props => props.fetchInvestigationHistoryHandler,
    (projectInternalId, fetch) => {
      if (projectInternalId != null) {
        let isFirstCall = true
        return subscribeOnProjectEvents(
          projectInternalId,
          [
            TEST_RESPONSIBILITY_CHANGED,
            PROBLEM_RESPONSIBILITY_CHANGED,
            BUILD_TYPE_RESPONSIBILITY_CHANGES,
          ],
          () => {
            fetch(!isFirstCall)
            isFirstCall = false
          },
        )
      } else {
        fetch()
        return undefined
      }
    },
  ),
)(InvestigationHistoryList)
