import type {History} from 'history'
import * as React from 'react'
import {startTransition} from 'react'

import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom'

import {preloadRoute} from '../components/App/App.routes'

import {browserHistory, HistoryContext} from '.'

import {useAppDispatch} from 'src/hooks/react-redux'

type HistoryProviderProps = {
  history?: History
  children: React.ReactNode
}
export default function HistoryProvider({
  history = browserHistory,
  children,
}: HistoryProviderProps): React.ReactElement {
  const dispatch = useAppDispatch()
  const patchedHistory: History = {
    ...history,
    get action() {
      return history.action
    },
    get location() {
      return history.location
    },
    push(to, state) {
      if (!state?.isQuerySync) {
        dispatch(preloadRoute(to, history.location, history))
      }
      startTransition(() => history.push(to, state))
    },
    replace(to, state) {
      if (!state?.isQuerySync) {
        dispatch(preloadRoute(to, history.location, history))
      }
      startTransition(() => history.replace(to, state))
    },
  }
  return (
    <HistoryContext.Provider value={patchedHistory}>
      {/* TODO consider using data router, see https://github.com/remix-run/react-router/issues/9422 */}
      <HistoryRouter history={patchedHistory as any}>{children}</HistoryRouter>
    </HistoryContext.Provider>
  )
}
