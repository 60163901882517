import type {SWGeneratorReturn} from '../../types/generators'
import {UPDATE_MESSAGE_TYPE} from '../../workers/sw.constants'
import {requestSWUpdate} from '../../workers/sw.lifecycle'

export const subscribeOnServiceWorkerMessage = <ResultType, ResponseType = ResultType>({
  type = UPDATE_MESSAGE_TYPE,
  url,
  handler,
  resolver,
}: {
  type?: string
  url: string
  handler: (response: SWGeneratorReturn<ResultType>) => void
  resolver?: ((arg0: ResponseType) => ResultType) | null | undefined
}): (() => void) => {
  if (navigator.serviceWorker?.controller != null) {
    const wrappedHandler = ({data}: MessageEvent) => {
      if (data != null && data.type === type) {
        if (data.meta.url === url) {
          const {payload, meta} = data

          if (!meta || meta.version !== window.ReactUI.buildNumber) {
            requestSWUpdate()
          }

          if (meta.NO_NEW_DATA) {
            handler({meta})
            return
          }

          const result = typeof resolver === 'function' ? resolver(payload) : payload
          handler({payload: result, meta})
        }
      }
    }

    navigator.serviceWorker.addEventListener('message', wrappedHandler)
    return () => navigator.serviceWorker.removeEventListener('message', wrappedHandler)
  } else {
    handler({
      meta: {
        category: '',
        timeout: true,
        url,
      },
    })
    return () => {}
  }
}

export const subscribeOnServiceWorkerMessageOnce = <ResultType, ResponseType>({
  type = UPDATE_MESSAGE_TYPE,
  serverUrl,
  endpoint,
  resolver,
}: {
  type?: string
  serverUrl: string
  endpoint: string
  resolver?: ((arg0: ResponseType) => ResultType) | null | undefined
}): Promise<SWGeneratorReturn<ResultType>> =>
  new Promise(resolve => {
    let unsubscribe = () => {}
    unsubscribe = subscribeOnServiceWorkerMessage({
      type,
      url: `${serverUrl}/${endpoint}`,
      resolver,
      handler: response => {
        unsubscribe()
        resolve(response)
      },
    })
  })
