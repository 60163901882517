import type {Disposable, Environment} from 'react-relay'
import type {Variables, GraphQLTaggedNode} from 'relay-runtime'
import {createOperationDescriptor, fetchQuery, getRequest} from 'relay-runtime'

export default function preloadGraphQLQuery(
  environment: Environment,
  query: GraphQLTaggedNode,
  variables: Variables,
): Disposable {
  const operation = createOperationDescriptor(getRequest(query), variables)

  fetchQuery(environment, query, variables).toPromise()

  return environment.retain(operation)
}
