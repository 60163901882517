import {createFetchAction} from '../reducers/fetchable'
import {restRoot} from '../rest/consts'
import requestFederationServers from '../rest/federationServers'
import {normalizeFederationServer} from '../rest/schemata'
import type {FederationServer} from '../types'

import type {AppThunk} from './types'

export const fetchFederationServersAction = createFetchAction('fetchFederationServers', () =>
  requestFederationServers(restRoot).then(normalizeFederationServer),
)
export const fetchFederationServers =
  (force?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const {federationServers} = getState()
    if (force === true || (!federationServers.inited && !federationServers.loading)) {
      await dispatch(fetchFederationServersAction())
    }
  }
export const receiveFederationServers = (servers: ReadonlyArray<FederationServer>) => {
  const normalizedData = normalizeFederationServer(servers)
  return fetchFederationServersAction.fulfilled(normalizedData, '')
}
