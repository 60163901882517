import type {To, History, Location} from 'history'
import {parsePath} from 'history'
import * as React from 'react'

import {matchRoutes} from 'react-router'

import {
  fetchCurrentUserData,
  fetchPoolPermissions,
  fetchTabs,
  subscribeOnRemainingAgents,
} from '../../actions'
import {fetchBuildsData, fetchHasBuilds} from '../../actions/builds'
import {fetchProjectWithAllParentsData, fetchSingleProjectData} from '../../actions/projects'
import {subscribeOnBuildTypeStatus} from '../../actions/statuses'
import type {ActionCreator, AppThunk} from '../../actions/types'
import {getEvents} from '../../containers/BuildsFetcher'
import getEnvironment from '../../relay/getEnvironment'
import {getChangesArg} from '../../rest/arguments'
import {getBuildTypeHasNonDefaultBranchesArg} from '../../rest/branches'
import {getSingleBuildArg} from '../../rest/builds'
import {getBuildTypeArg} from '../../rest/buildTypes'
import {getBuildLocator} from '../../rest/locators'
import Routes, {getBaseRoute, getHref} from '../../routes'
import {
  getBuildsInited,
  getBuildType,
  getCurrentUserId,
  getCurrentUserLoaded,
  getHasBuildsInited,
  getIsAllTabsInited,
  getLocatorIfReady,
  getProject,
  getTabsFetchable,
  isBuildTypeLoaded,
} from '../../selectors'
import {restApi} from '../../services/rest'
import store from '../../store'
import type {
  BuildId,
  BuildTypeId,
  BuildTypeInternalId,
  ProjectId,
  ProjectInternalId,
} from '../../types'
import {
  AGENTS_TAB_PARAMS_KEY,
  BuildPageTabNamesEnum,
  BuildTypePageTabNamesEnum,
  ProjectPageTabNamesEnum,
  ROOT_PROJECT_ID,
  STARRED_LOCATOR,
  stringifyId,
  toAgentId,
  toAgentPoolId,
  toBuildId,
  toBuildTypeId,
  toChangeId,
  toProjectId,
} from '../../types'
import {internalProps} from '../../types/BS_types'
import {parseBranch} from '../../utils/branchNames'
import {emptyArray} from '../../utils/empty'
import {notNull} from '../../utils/guards'
import type {KeyValue} from '../../utils/object'
import {getPermalinkLocator} from '../../utils/permalinks'
import preloadGraphQLQuery from '../../utils/preloadGraphqlQuery'
import type {QueryParams} from '../../utils/queryParams'
import {queryToObject} from '../../utils/queryParams'
import {
  combineRefetchables,
  createRefetchableSubscription,
  unsubscribeOnSuccess,
} from '../../utils/refetchable'
import {
  getBuildTypeSuffix,
  getProjectSuffix,
  getTopic,
  getUserSuffix,
  subscribeOnBuildTypeEvents,
  subscribeOnOverallEvents,
} from '../../utils/subscriber'
import {
  BUILD_CHANGES_LOADED,
  BUILD_FINISHED,
  BUILD_INTERRUPTED,
  BUILD_TYPE_REMOVED_FROM_QUEUE,
  BUILD_TYPE_UPDATED,
  CHANGE_ADDED,
  PROJECT_UPDATED,
  USER_PERMISSIONS_CHANGED,
} from '../../utils/subscriptionEvents'
import {getTabParamsKey} from '../../utils/tabs'
import {submitPager} from '../common/Pager/Pager.actions.base'
import {getPager} from '../common/Pager/Pager.selectors'
import {PagerGroup} from '../common/Pager/Pager.types'
import {getChangeStatusArg} from '../packages/Changes/Changes.rest'
import {agentPageOptions} from '../pages/AgentsPages/AgentPage/AgentPage.rest'
import AgentsPages from '../pages/AgentsPages/AgentsPages'
import {
  REGULAR_OVERALL_AGENT_EVENT_TOPICS,
  subscribeOnAgent,
  subscribeOnAgentPools,
  subscribeOnAgents,
} from '../pages/AgentsPages/AgentsPages.actions'
import {agentTypesQuery} from '../pages/AgentsPages/AgentsPages.queries'
import {SnapshotDependenciesModes} from '../pages/BuildPage/DependenciesTab/DependenciesTab.modes'
import {getSnapshotDependenciesLocator} from '../pages/BuildPage/DependenciesTab/DependenciesTab.utils'
import {mapStateToBuildTypeHistoryProps} from '../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeHistory/BuildTypeHistory.selectors'
import {Modes as OverviewBuildTypeModes} from '../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeOverviewTab.modes'
import {fetchCompareBuildsList} from '../pages/CompareBuildsPage/CompareBuildsPage.actions'
import {pipelinesApi} from '../pages/PipelinesPages/services/pipelinesApi'
import {ParameterNames} from '../pages/PipelinesPages/types'
import {
  isPipelinesEnabled,
  isPipelinesEnabledInExclusiveMode,
} from '../pages/PipelinesPages/utils/featureToggles'
import {getBuildTypeLineLocator} from '../pages/ProjectPage/ProjectOverviewTab/BuildsByBuildType/BuildTypeLine/BuildTypeLine.selectors'
import {Modes as OverviewProjectModes} from '../pages/ProjectPage/ProjectOverviewTab/NestedProjectTrendsOrBuildsOverview.modes'
import {getQueuePageLocator} from '../pages/QueuePage/QueuePage.utils'

import {getIsAvailabilityError} from './App.selectors'
import {subscribeOnPoolCounters} from './QueueSidebar/QueueSidebar.actions'

const AgentsOverviewPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AgentsOverviewPage", webpackPrefetch: true */
      '../pages/AgentsPages/AgentsOverviewPage/AgentsOverviewPage'
    ),
)
const UnauthorizedAgentsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UnauthorizedAgentsPage", webpackPrefetch: true */
      '../pages/AgentsPages/UnauthorizedAgentsPage/UnauthorizedAgentsPage'
    ),
)
const AgentPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AgentPage", webpackPrefetch: true */
      '../pages/AgentsPages/AgentPage/AgentPage.container'
    ),
)
const AgentPoolPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AgentPoolPage", webpackPrefetch: true */
      '../pages/AgentsPages/AgentPoolPage/AgentPoolPage'
    ),
)
const CloudImagePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CloudImagePage", webpackPrefetch: true */
      '../pages/AgentsPages/AgentTypePage/AgentTypePage'
    ),
)
const ProjectPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectPage", webpackPrefetch: true */
      '../pages/ProjectPage/ProjectPage.container'
    ),
)
const FavoriteProjectsPage: React.ComponentType = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FavoriteProjectsPage", webpackPrefetch: true */
      '../pages/FavoriteProjectsPage/FavoriteProjectsPage.container'
    ),
)
const FavoriteBuildsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FavoriteBuildsPage", webpackPrefetch: true */
      '../pages/FavoriteBuildsPage/FavoriteBuildsPage'
    ),
)
const BuildTypePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BuildTypePage", webpackPrefetch: true */
      '../pages/BuildTypePage/BuildTypePage'
    ),
)
const BuildPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BuildPage", webpackPrefetch: true */
      '../pages/BuildPage/BuildPage.container'
    ),
)
const CompareBuildsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CompareBuildsPage", webpackPrefetch: true */
      '../pages/CompareBuildsPage/CompareBuildsPage.container'
    ),
)
const GuidesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "GuidesPage", webpackPrefetch: true */
      '../pages/GuidesPage/GuidesPage'
    ),
)
const TestHistoryPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TestHistoryPage", webpackPrefetch: true */
      '../pages/TestHistoryPage/TestHistoryPage.container'
    ),
)
const QueuePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "QueuePage", webpackPrefetch: true */
      '../pages/QueuePage/QueuePage'
    ),
)
const ChangePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangePage", webpackPrefetch: true */
      '../pages/ChangePage/ChangePage'
    ),
)
const ChangesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangesPage", webpackPrefetch: true */
      '../pages/ChangesPage/ChangesPage'
    ),
)
const PipelinesPages = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PipelinesPages", webpackPrefetch: true */
      '../pages/PipelinesPages/PipelinesPages'
    ),
)
const InvestigationsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InvestigationsPage", webpackPrefetch: true */
      '../pages/InvestigationsPage/InvestigationsPage'
    ),
)
type PreloadParams = Readonly<{
  params: KeyValue<string, string>
  queryParams: QueryParams
  prevParams: KeyValue<string, string> | null | undefined
  prevQueryParams: QueryParams | null | undefined
  path: string
  context: Symbol
  history?: History
}>
type PreloadableRoute = Readonly<{
  path: string
  Component: React.ComponentType<any>
  preload?: ActionCreator<PreloadParams>
}>
type Redirect = Readonly<{
  from: string
  to: string
}>
const USE_NEW_QUEUE_PAGE: boolean = internalProps['teamcity.ui.sakuraQueuePage.enabled']
const USE_NEW_INVESTIGATIONS_PAGE: boolean = internalProps['teamcity.ui.newInvestigationsPage']

type Subscriptions = {
  previous: Record<string, () => void>
  current: Record<string, () => void>
  currentContext: Symbol | null
  add(context: Symbol, key: string, subscribe: () => () => void): () => void
  clear(context: Symbol, isInterrupt?: boolean): void
}

const subscriptions: Subscriptions = {
  previous: {},
  current: {},
  currentContext: null,
  add(context, key, subscribe) {
    if (context !== this.currentContext) {
      return () => {}
    }
    if (this.current[key]) {
      throw new Error(
        `Subscription keys for preloaded route data must be unique, got duplicate key ${key}`,
      )
    }
    if (this.previous[key]) {
      this.current[key] = this.previous[key]
      delete this.previous[key]
    } else {
      this.current[key] = subscribe()
    }
    return this.current[key]
  },
  clear(context, isInterrupt) {
    if (context !== this.currentContext) {
      return
    }
    if (isInterrupt) {
      this.previous = {...this.previous, ...this.current}
    } else {
      Object.values(this.previous).forEach(fn => fn())
      this.previous = this.current
    }
    this.current = {}
    this.currentContext = null
  },
}

function subscribeOnAgentTypes() {
  let initial = true
  const disposable = preloadGraphQLQuery(getEnvironment(), agentTypesQuery, {})

  return subscribeOnOverallEvents(REGULAR_OVERALL_AGENT_EVENT_TOPICS, () => {
    if (!initial) {
      disposable.dispose()
    }

    initial = false
  })
}

const preloadAgentData =
  ({context}: PreloadParams): AppThunk =>
  dispatch => {
    subscriptions.add(context, 'agents', () => dispatch(subscribeOnAgents()))
    subscriptions.add(context, 'agentPools', () => dispatch(subscribeOnAgentPools()))
    subscriptions.add(context, 'agentTypes', () => subscribeOnAgentTypes())

    dispatch(fetchPoolPermissions(true))
  }

const preloadLicensingData =
  (context: Symbol): AppThunk =>
  dispatch =>
    subscriptions.add(context, 'licensingData', () => dispatch(subscribeOnRemainingAgents()))

const getProjectInternalId =
  (projectId: ProjectId, context: Symbol): AppThunk<Promise<ProjectInternalId | undefined>> =>
  async (dispatch, getState) => {
    const internalIdArg = {projectLocator: `id:${projectId}`, fields: 'internalId', essential: true}
    let internalIdSubscription
    subscriptions.add(context, `projectInternalIdCache:${projectId}`, () => {
      internalIdSubscription = dispatch(restApi.endpoints.getProject.initiate(internalIdArg))
      return internalIdSubscription.unsubscribe
    })
    await internalIdSubscription
    const {data} = restApi.endpoints.getProject.select(internalIdArg)(getState())
    return data?.internalId
  }

const getBuildTypeInternalId =
  (buildTypeId: BuildTypeId, context: Symbol): AppThunk<Promise<BuildTypeInternalId | undefined>> =>
  async (dispatch, getState) => {
    const internalIdArg = {btLocator: `id:${buildTypeId}`, fields: 'internalId', essential: true}
    let internalIdSubscription
    subscriptions.add(context, `buildTypeInternalIdCache:${buildTypeId}`, () => {
      internalIdSubscription = dispatch(restApi.endpoints.getBuildType.initiate(internalIdArg))
      return internalIdSubscription.unsubscribe
    })
    await internalIdSubscription
    const {data} = restApi.endpoints.getBuildType.select(internalIdArg)(getState())
    return data?.internalId
  }

const preloadProjectData =
  (context: Symbol, projectId: ProjectId, queryParams: QueryParams): AppThunk =>
  async (dispatch, getState) => {
    const {
      projectTab = ProjectPageTabNamesEnum.OVERVIEW,
      mode = OverviewProjectModes.BUILDS,
      branch,
    } = queryParams
    const internalId = await dispatch(getProjectInternalId(projectId, context))
    if (!getCurrentUserLoaded(getState())) {
      await fetchCurrentUserData()
    }
    const myId = getCurrentUserId(getState())
    let request
    if (internalId != null && myId != null) {
      subscriptions.add(context, `project:${projectId}`, () =>
        createRefetchableSubscription(
          () => {
            request = dispatch(fetchSingleProjectData(projectId))
            return combineRefetchables([
              request,
              dispatch(fetchProjectWithAllParentsData(projectId)),
            ])
          },
          handler =>
            subscribeOnOverallEvents(
              [
                getTopic(PROJECT_UPDATED, getProjectSuffix(internalId)),
                getTopic(USER_PERMISSIONS_CHANGED, getUserSuffix(myId)),
              ],
              handler,
            ),
        ),
      )
    }

    if (projectTab === ProjectPageTabNamesEnum.OVERVIEW && mode === OverviewProjectModes.BUILDS) {
      let project = getProject(getState(), projectId)
      if (project == null) {
        await request
        project = getProject(getState(), projectId)
      }
      const buildTypeIds = project?.buildTypes?.buildType ?? emptyArray

      for (const buildTypeId of buildTypeIds) {
        const locator = getBuildTypeLineLocator(getState(), {
          buildTypeId,
          branch: parseBranch(branch),
        })

        if (!getBuildsInited(getState(), locator)) {
          subscriptions.add(
            context,
            `projectBuilds:${locator}`,
            () =>
              dispatch(
                fetchBuildsData({
                  locator,
                  withPager: false,
                  requestOptions: {
                    withBuildTypeDetails: true,
                    withSnapshotDependencies: false,
                    withQueuedInfo: true,
                    withRunningInfo: true,
                    essential: true,
                  },
                }),
              ).unsubscribe,
          )
        }
      }
    }
  }

const preloadBuildTypeData =
  (
    context: Symbol,
    buildTypeId: BuildTypeId,
    internalId: BuildTypeInternalId | null | undefined,
  ): AppThunk =>
  async (dispatch, getState) => {
    let request
    if (!getCurrentUserLoaded(getState())) {
      await fetchCurrentUserData()
    }
    const myId = getCurrentUserId(getState())
    const buildTypeArg = getBuildTypeArg(buildTypeId)
    if (internalId != null && myId != null) {
      request = new Promise(resolve =>
        subscriptions.add(context, `buildType:${buildTypeId}`, () =>
          createRefetchableSubscription(
            () => {
              const result = dispatch(
                restApi.endpoints.getBuildTypeNormalized.initiate(buildTypeArg),
              )
              resolve(result)
              return result
            },
            handler =>
              subscribeOnOverallEvents(
                [
                  getTopic(BUILD_TYPE_UPDATED, getBuildTypeSuffix(internalId)),
                  getTopic(USER_PERMISSIONS_CHANGED, getUserSuffix(myId)),
                ],
                handler,
              ),
          ),
        ),
      )
    }

    if (!isBuildTypeLoaded(getState(), buildTypeId)) {
      const runningQueryThunk = await dispatch(
        restApi.util.getRunningQueryThunk('getBuildTypeNormalized', buildTypeArg),
      )
      if (runningQueryThunk === undefined) {
        await request
      }
    }

    const {projectId} = getBuildType(getState(), buildTypeId) ?? {}

    if (projectId != null) {
      subscriptions.add(
        context,
        `fetchProjectWithAllParentsData:${projectId}`,
        () => dispatch(fetchProjectWithAllParentsData(projectId)).unsubscribe,
      )
    }
  }

const preloadTabs =
  (tabParamsKey: string, cacheTabs = true): AppThunk =>
  (dispatch, getState) => {
    if (!getIsAllTabsInited(getState(), tabParamsKey)) {
      dispatch(fetchTabs(tabParamsKey, {essential: true}, cacheTabs))
    }
  }

export const redirects: ReadonlyArray<Redirect> = [
  {
    from: Routes.DEPRICATED_FAVORITE_BUILDS,
    to: Routes.FAVORITE_BUILDS,
  },
  {
    from: Routes.DEPRICATED_FAVORITE_PROJECTS,
    to: Routes.FAVORITE_PROJECTS,
  },
  {
    from: '*',
    to: isPipelinesEnabledInExclusiveMode
      ? Routes.PIPELINES.slice(0, -'/*'.length)
      : Routes.FAVORITE_PROJECTS,
  },
]

const favoriteProjectsRoute: PreloadableRoute = {
  path: Routes.FAVORITE_PROJECTS,
  Component: FavoriteProjectsPage,
  preload: ({queryParams, context}) => preloadProjectData(context, ROOT_PROJECT_ID, queryParams),
}

// https://reactjs.org/blog/2019/11/06/building-great-user-experiences-with-concurrent-mode-and-suspense.html#fetch-in-event-handlers
export const routes: ReadonlyArray<PreloadableRoute> = [
  favoriteProjectsRoute,
  {
    path: Routes.AGENTS,
    Component: AgentsPages,
    preload: preloadAgentData,
  },
  {
    path: Routes.AGENTS_OVERVIEW,
    Component: AgentsOverviewPage,
    preload:
      (params: PreloadParams): AppThunk =>
      dispatch => {
        dispatch(preloadTabs(AGENTS_TAB_PARAMS_KEY))
        dispatch(preloadAgentData(params))
        dispatch(preloadLicensingData(params.context))
      },
  },
  {
    path: Routes.DISCONNECTED_AGENTS_OVERVIEW,
    Component: AgentsOverviewPage,
    preload:
      (params: PreloadParams): AppThunk =>
      dispatch => {
        dispatch(preloadTabs(AGENTS_TAB_PARAMS_KEY))
        dispatch(preloadAgentData(params))
        dispatch(preloadLicensingData(params.context))
      },
  },
  {
    path: Routes.AGENTS_UNAUTHORIZED,
    Component: UnauthorizedAgentsPage,
    preload:
      (params: PreloadParams): AppThunk =>
      dispatch => {
        dispatch(preloadAgentData(params))
        dispatch(preloadLicensingData(params.context))
      },
  },
  {
    path: Routes.AGENT,
    Component: AgentPage,
    preload:
      (preloadParams: PreloadParams): AppThunk =>
      (dispatch, getState) => {
        const {params, path, context} = preloadParams
        if (!getIsAvailabilityError(getState(), path)) {
          const agentId = toAgentId(params.agentId ?? 0)
          subscriptions.add(context, `agent:${agentId}`, () =>
            dispatch(subscribeOnAgent(agentId, agentPageOptions)),
          )
        }

        dispatch(preloadAgentData(preloadParams))
        dispatch(preloadLicensingData(context))
      },
  },
  {
    path: Routes.AGENT_POOL,
    Component: AgentPoolPage,
    preload: preloadAgentData,
  },
  {
    path: Routes.CLOUD_IMAGE,
    Component: CloudImagePage,
    preload: preloadAgentData,
  },
  {
    path: Routes.PROJECT,
    Component: ProjectPage,
    preload:
      ({params, queryParams, path, context, history}: PreloadParams): AppThunk =>
      (dispatch, getState) => {
        if (getIsAvailabilityError(getState(), path)) {
          return null
        }

        const projectId = toProjectId(params.projectId ?? '')
        async function tryPipelinesRedirect() {
          const projectResult = await unsubscribeOnSuccess(
            dispatch(
              restApi.endpoints.getProject.initiate({
                projectLocator: `id:${projectId}`,
                fields: `internalId`,
                essential: true,
              }),
            ),
          )
          const {internalId} = projectResult.data ?? {}
          if (internalId == null) {
            return
          }
          const pipelineResult = await unsubscribeOnSuccess(
            dispatch(pipelinesApi.endpoints.getPipelineById.initiate(internalId)),
          )
          if (pipelineResult.data != null) {
            history?.replace(getHref(Routes.PIPELINE, {id: internalId}))
          }
        }
        if (isPipelinesEnabled) {
          tryPipelinesRedirect()
        }
        dispatch(preloadTabs(getTabParamsKey({projectId})))
        return dispatch(preloadProjectData(context, projectId, queryParams))
      },
  },
  {
    path: Routes.FAVORITE_BUILDS,
    Component: FavoriteBuildsPage,
    preload:
      ({context}: PreloadParams): AppThunk =>
      (dispatch, getState) => {
        let unsubscribeCache = () => {}
        const fetch = (locator: string | null | undefined) => {
          if (locator != null) {
            unsubscribeCache = dispatch(
              fetchBuildsData({
                locator,
                withPager: true,
                requestOptions: {
                  withBuildTypeDetails: true,
                  withSnapshotDependencies: false,
                  withQueuedInfo: true,
                  withRunningInfo: true,
                  essential: true,
                },
              }),
            ).unsubscribe
          }
        }

        const getLocator = () =>
          getLocatorIfReady(getState(), {
            withRunningAndQueued: true,
            baseLocator: STARRED_LOCATOR,
          })

        subscriptions.add(context, `favoriteBuildsLocator`, () => {
          let prevLocator = getLocator()
          const unsubscribeStore = store.subscribe(() => {
            const locator = getLocator()
            if (locator !== prevLocator) {
              prevLocator = locator
              unsubscribeCache()
              fetch(locator)
            }
          })
          return () => {
            unsubscribeStore()
            unsubscribeCache()
          }
        })
      },
  },
  {
    path: Routes.BUILD_TYPE,
    Component: BuildTypePage,
    preload:
      ({params, queryParams, path, context, history}: PreloadParams): AppThunk =>
      async (dispatch, getState) => {
        const {
          buildTypeTab = BuildTypePageTabNamesEnum.OVERVIEW,
          mode = OverviewBuildTypeModes.BUILDS,
          branch,
        } = queryParams
        const state = getState()

        if (getIsAvailabilityError(state, path)) {
          return
        }

        const pageSize = 50

        if (getPager(state, PagerGroup.BUILD).pageSize !== pageSize) {
          dispatch(
            submitPager({
              pageSize,
            }),
          )
        }

        const buildTypeId = toBuildTypeId(params.buildTypeId ?? '')

        async function tryPipelinesRedirect() {
          const buildTypeResult = await unsubscribeOnSuccess(
            dispatch(
              restApi.endpoints.getBuildType.initiate({
                btLocator: `id:${buildTypeId}`,
                fields: `project(internalId)`,
                essential: true,
              }),
            ),
          )
          const {internalId} = buildTypeResult.data?.project ?? {}
          if (internalId == null) {
            return
          }
          const pipelineResult = await unsubscribeOnSuccess(
            dispatch(pipelinesApi.endpoints.getPipelineById.initiate(internalId)),
          )
          if (pipelineResult.data != null) {
            history?.replace(getHref(Routes.PIPELINE, {id: internalId}))
          }
        }
        if (isPipelinesEnabled) {
          tryPipelinesRedirect()
        }

        const internalId = await dispatch(getBuildTypeInternalId(buildTypeId, context))

        const preloadBuildTypePromise = dispatch(
          preloadBuildTypeData(context, buildTypeId, internalId),
        )

        if (internalId != null) {
          const tabParamsKey = getTabParamsKey({buildTypeId, branch: parseBranch(branch)})
          subscriptions.add(context, `buildTypeTabs:${buildTypeId}:${branch}`, () =>
            subscribeOnBuildTypeEvents(
              internalId,
              [BUILD_CHANGES_LOADED, CHANGE_ADDED, BUILD_INTERRUPTED],
              () => {
                const tabsLoading = getTabsFetchable(getState(), tabParamsKey).loading
                if (!tabsLoading) {
                  dispatch(fetchTabs(tabParamsKey))
                }
              },
            ),
          )
          subscriptions.add(context, `buildTypeStatus:${buildTypeId}:${branch}`, () =>
            dispatch(subscribeOnBuildTypeStatus(buildTypeId, internalId, parseBranch(branch), 0)),
          )
        }

        if (
          buildTypeTab === BuildTypePageTabNamesEnum.OVERVIEW &&
          mode === OverviewBuildTypeModes.BUILDS
        ) {
          const getProps = () =>
            mapStateToBuildTypeHistoryProps(getState(), {
              buildTypeId,
              branch: parseBranch(branch),
              withCollapsedQueued: true,
              withRunningAndQueued: true,
              buildState: queryParams.state ?? undefined,
            })
          const {monitorLocator} = getProps()

          subscriptions.add(
            context,
            `hasNonDefaultBranches:${buildTypeId}`,
            () =>
              dispatch(
                restApi.endpoints.checkIfBranchExistsInBuildType.initiate(
                  getBuildTypeHasNonDefaultBranchesArg(buildTypeId),
                ),
              ).unsubscribe,
          )

          if (!getHasBuildsInited(state, monitorLocator)) {
            dispatch(
              fetchHasBuilds(monitorLocator, {
                essential: true,
              }),
            )
          }
          let refetch = () => {}
          let unsubscribeCache = () => {}
          const fetch = (locator: string | null | undefined) => {
            if (locator != null) {
              unsubscribeCache()
              const result = dispatch(
                fetchBuildsData({
                  locator,
                  withPager: true,
                  requestOptions: {
                    withBuildTypeDetails: true,
                    withSnapshotDependencies: false,
                    withQueuedInfo: true,
                    withRunningInfo: true,
                    essential: !getBuildsInited(state, locator),
                  },
                }),
              )
              unsubscribeCache = result.unsubscribe
              refetch = result.refetch
            }
          }

          subscriptions.add(
            context,
            `buildTypeBuildLocator:${buildTypeId}:${branch}:${queryParams.state}`,
            () => {
              let prevLocator = getProps().locator
              const unsubscribeStore = store.subscribe(() => {
                const {locator} = getProps()
                if (locator !== prevLocator) {
                  prevLocator = locator
                  fetch(locator)
                }
              })
              return () => {
                unsubscribeStore()
                unsubscribeCache()
              }
            },
          )

          if (internalId != null) {
            subscriptions.add(
              context,
              `buildTypeBuilds:${buildTypeId}:${branch}:${queryParams.state}`,
              () => {
                const unsubscribeEvents = subscribeOnBuildTypeEvents(
                  internalId,
                  getEvents(true, true),
                  () => refetch(),
                  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                  2000,
                )
                return () => {
                  unsubscribeEvents()
                  unsubscribeCache()
                }
              },
            )
          }
        }

        await preloadBuildTypePromise
      },
  },
  {
    path: Routes.BUILD,
    Component: BuildPage,
    preload:
      ({
        params,
        queryParams,
        prevParams,
        prevQueryParams,
        path,
        context,
        history,
      }: PreloadParams): AppThunk =>
      async (dispatch, getState) => {
        if (getIsAvailabilityError(getState(), path)) {
          return
        }

        const {
          buildTab = BuildPageTabNamesEnum.OVERVIEW,
          mode = SnapshotDependenciesModes.TIMELINE,
          branch,
        } = queryParams
        const buildTypeId = toBuildTypeId(params.buildTypeId ?? '')

        if (isPipelinesEnabled) {
          unsubscribeOnSuccess(
            dispatch(
              restApi.endpoints.getBuildType.initiate({
                btLocator: `id:${buildTypeId}`,
                fields: `project(internalId,buildTypes(buildType(id),$locator(parameter(${ParameterNames.PipelineHead}))))`,
                essential: true,
              }),
            ),
          ).then(({data}) => {
            const {internalId, buildTypes} = data?.project ?? {}
            const headId = buildTypes?.buildType?.[0]?.id
            const runId = params.buildId
            if (buildTypeId === headId) {
              history?.replace(getHref(Routes.PIPELINE_RUN, {id: internalId, runId}))
            } else if (headId != null) {
              unsubscribeOnSuccess(
                dispatch(
                  restApi.endpoints.getBuild.initiate({
                    buildLocator: `buildType:(id:${headId}),snapshotDependency(from:(id:${runId}))`,
                    fields: 'id',
                    essential: true,
                  }),
                ),
              ).then(result =>
                history?.replace(
                  `${getHref(Routes.PIPELINE_RUN, {
                    id: internalId,
                    runId: String(result.data?.id),
                  })}?job=${runId}`,
                ),
              )
            }
          })
        }

        const prevBuildTypeId = toBuildTypeId(prevParams?.buildTypeId ?? '')
        let buildId: BuildId | null | undefined
        const permalinkLocator = getPermalinkLocator(
          params.buildId ?? '',
          buildTypeId,
          parseBranch(branch),
        )

        if (permalinkLocator != null) {
          if (
            params.buildId !== prevParams?.buildId ||
            buildTypeId !== prevBuildTypeId ||
            branch !== prevQueryParams?.branch
          ) {
            const key = `fetchPermalinkBuild:${permalinkLocator}`
            const isSubscribed = subscriptions.previous[key] != null
            const result = dispatch(
              restApi.endpoints.getBuildNormalizedAsList.initiate(
                getSingleBuildArg(permalinkLocator, {
                  withBuildTypeDetails: true,
                }),
                {subscribe: !isSubscribed},
              ),
            )
            subscriptions.add(context, key, () => result.unsubscribe)
            const {data} = await result
            buildId = data?.result[0]
          }
        } else {
          buildId = toBuildId(params.buildId ?? 0)
        }

        const state = getState()

        if (buildTab === BuildPageTabNamesEnum.DEPENDENCIES) {
          const dependenciesLocator = getLocatorIfReady(state, {
            baseLocator: getSnapshotDependenciesLocator(buildId, mode),
            withRunningAndQueued: true,
            buildState: queryParams.state ?? undefined,
          })

          if (dependenciesLocator != null && !getBuildsInited(state, dependenciesLocator)) {
            subscriptions.add(
              context,
              `buildDependencies:${dependenciesLocator}`,
              () =>
                dispatch(
                  fetchBuildsData({
                    locator: dependenciesLocator,
                    requestOptions: {
                      withBuildTypeDetails: true,
                      withSnapshotDependencies: true,
                      withQueuedInfo: true,
                      withRunningInfo: true,
                      essential: true,
                    },
                  }),
                ).unsubscribe,
            )
          }
        }

        const internalId = await dispatch(getBuildTypeInternalId(buildTypeId, context))

        if (internalId != null) {
          const tabParamsKey = getTabParamsKey({buildId})
          subscriptions.add(context, `buildTabs:${buildId}`, () =>
            subscribeOnBuildTypeEvents(
              internalId,
              [BUILD_FINISHED, BUILD_INTERRUPTED, BUILD_TYPE_REMOVED_FROM_QUEUE],
              () => {
                const tabsLoading = getTabsFetchable(getState(), tabParamsKey).loading
                if (!tabsLoading) {
                  dispatch(fetchTabs(tabParamsKey, {essential: true}, false))
                }
              },
            ),
          )
        }

        if (buildId != null) {
          subscriptions.add(
            context,
            `fetchBuild:${buildId}`,
            () =>
              dispatch(
                restApi.endpoints.getBuildNormalizedAsList.initiate(
                  getSingleBuildArg(getBuildLocator(buildId!), {
                    withBuildTypeDetails: true,
                  }),
                ),
              ).unsubscribe,
          )
        }

        await dispatch(preloadBuildTypeData(context, buildTypeId, internalId))
      },
  },
  {
    path: Routes.BUILD_UNKNOWN_BUILDTYPE,
    Component: BuildPage,
  },
  {
    path: Routes.COMPARE_BUILDS,
    Component: CompareBuildsPage,
    preload:
      ({params, prevParams, queryParams, prevQueryParams, context}: PreloadParams): AppThunk =>
      dispatch => {
        if (params.buildIdSource !== prevParams?.buildIdSource) {
          const sourceId = toBuildId(params.buildIdSource!)
          subscriptions.add(
            context,
            `fetchSourceBuild:${sourceId}`,
            () =>
              dispatch(
                restApi.endpoints.getBuildNormalizedAsList.initiate(
                  getSingleBuildArg(getBuildLocator(sourceId)),
                ),
              ).unsubscribe,
          )
        }
        if (
          queryParams.withBuildId != null &&
          queryParams.withBuildId !== prevQueryParams?.withBuildId
        ) {
          const compareWithId = toBuildId(queryParams.withBuildId)
          subscriptions.add(
            context,
            `fetchCompareWithBuild:${compareWithId}`,
            () =>
              dispatch(
                restApi.endpoints.getBuildNormalizedAsList.initiate(
                  getSingleBuildArg(getBuildLocator(compareWithId)),
                ),
              ).unsubscribe,
          )
        }
        if (
          queryParams.withBuildId != null &&
          (params.buildIdSource !== prevParams?.buildIdSource ||
            queryParams.withBuildId !== prevQueryParams?.withBuildId)
        ) {
          const sourceId = toBuildId(params.buildIdSource!)
          const compareWithId = toBuildId(queryParams.withBuildId)
          dispatch(fetchCompareBuildsList(sourceId, compareWithId))
        }
      },
  },
  {
    path: Routes.TEST,
    Component: TestHistoryPage,
  },
  {
    path: Routes.CHANGE,
    Component: ChangePage,
    preload:
      ({params, queryParams, context}: PreloadParams): AppThunk =>
      dispatch => {
        const changeId = toChangeId(params.changeId ?? '')
        const personal = queryParams?.personal === 'true'
        const locator = `id:${stringifyId(changeId)},personal:${personal}`

        subscriptions.add(
          context,
          `fetchChanges:${locator}`,
          () =>
            combineRefetchables<unknown>([
              dispatch(restApi.endpoints.getAllChanges.initiate(getChangesArg(locator))),
              dispatch(restApi.endpoints.getAllChangesKeyed.initiate(getChangeStatusArg(locator))),
            ]).unsubscribe,
        )
        dispatch(fetchTabs(getTabParamsKey({changeId, personal}), {essential: true}))
      },
  },
  {
    path: Routes.CHANGES,
    Component: ChangesPage,
  },
  {
    path: Routes.GUIDES,
    Component: GuidesPage,
  },
  USE_NEW_QUEUE_PAGE
    ? {
        path: Routes.QUEUE,
        Component: QueuePage,
        preload:
          ({queryParams, context}: PreloadParams): AppThunk =>
          (dispatch, getState) => {
            const state = getState()

            subscriptions.add(context, 'queuePools', () => dispatch(subscribeOnPoolCounters()))

            const page = (queryParams.page && parseInt(queryParams.page, 10)) || 1
            dispatch(
              submitPager({
                currentPage: page,
                precountedPages: 3,
                pageSize: 50,
                lookupLimit: 10000,
                lookupDelta: 10000,
              }),
            )
            const activeAgentPoolIdString = queryParams.agentPoolId
            const activeAgentPoolId =
              activeAgentPoolIdString != null ? toAgentPoolId(activeAgentPoolIdString) : null
            const onlyMyPersonal = queryParams.onlyMyPersonal === 'true'
            const locator = getQueuePageLocator(activeAgentPoolId, onlyMyPersonal)

            if (!getBuildsInited(state, locator)) {
              subscriptions.add(
                context,
                `buildQueue:${locator}`,
                () =>
                  dispatch(
                    fetchBuildsData({
                      locator,
                      withPager: true,
                      requestOptions: {
                        customEndpoint: '/buildQueue',
                        withBuildTypeDetails: true,
                        withSnapshotDependencies: false,
                        withQueuedInfo: true,
                        essential: true,
                      },
                    }),
                  ).unsubscribe,
              )
            }
          },
      }
    : null,
  USE_NEW_INVESTIGATIONS_PAGE
    ? {
        path: Routes.INVESTIGATIONS,
        Component: InvestigationsPage,
      }
    : null,
  isPipelinesEnabled ? {path: Routes.PIPELINES, Component: PipelinesPages} : null,

  // !!! DON'T FORGET !!!
  // !!! 1) ADD the mapping to web-startup/WEB-INF/web.xml AND /react-ui/src/routes/shared-routes.json !!!
  // !!! 2) EXECUTE the prepareRingRouting(gradle task) !!!
].filter(notNull)
const baseRoutes = routes.map(route => ({...route, path: getBaseRoute(route.path)}))
export const preloadRoute =
  (to: To | null | undefined, prevLocation?: Location, history?: History): AppThunk =>
  async dispatch => {
    if (to == null) {
      return
    }

    const location = typeof to === 'string' ? parsePath(to) : to
    const path = location.pathname ?? prevLocation?.pathname ?? ''
    const match = matchRoutes(baseRoutes, path)?.[0]
    const route = match?.route ?? favoriteProjectsRoute
    const params = match?.params ?? {}
    const {preload} = route as PreloadableRoute

    if (subscriptions.currentContext != null) {
      subscriptions.clear(subscriptions.currentContext, true)
    }
    const context = Symbol()
    subscriptions.currentContext = context

    if (preload != null) {
      const prevMatch = prevLocation != null ? matchRoutes(baseRoutes, prevLocation)?.[0] : null
      const prevRoute = prevMatch?.route ?? favoriteProjectsRoute
      const isSameRoute = prevRoute === route
      await dispatch(
        preload({
          params,
          queryParams: queryToObject(location.search),
          prevParams: isSameRoute ? prevMatch?.params ?? {} : null,
          prevQueryParams:
            isSameRoute && prevLocation != null ? queryToObject(prevLocation.search) : null,
          path,
          context,
          history,
        }),
      )
    }
    subscriptions.clear(context)
  }
