import {original} from 'immer'

import type {AsyncThunk, Draft, AsyncThunkOptions, AsyncThunkPayloadCreator} from '@reduxjs/toolkit'
import {SHOULD_AUTOBATCH, createAsyncThunk, createReducer} from '@reduxjs/toolkit'
import type {
  AsyncThunkAction,
  AsyncThunkFulfilledActionCreator,
} from '@reduxjs/toolkit/dist/createAsyncThunk'
import deepEqual from 'fast-deep-equal'

import type {AppDispatch, FetchAction} from '../actions/types'
import type {Fetchable, SuccessReceiveMeta} from '../types'

import {BS} from '../types/BS_types'

import type {State} from './types'
import {keyValueReducer} from './utils'

type PendingMeta = {
  [SHOULD_AUTOBATCH]?: boolean
  invalidate?: boolean
  isBackground?: boolean
  request?: Promise<unknown>
}

type AsyncThunkConfig = {
  pendingMeta: PendingMeta
  serializedErrorType: Error | null
}

interface FulfilledMeta {
  fulfilledMeta: {
    [SHOULD_AUTOBATCH]: true
  }
}

type AppAsyncThunkConfig = {
  state: State
  dispatch: AppDispatch
}
// for usage with dispatch and getState
export const createAppAsyncThunk = <D, P = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<D, P, AppAsyncThunkConfig>,
  options?: Partial<AsyncThunkOptions<P, AppAsyncThunkConfig>>,
): AsyncThunk<D, P, AsyncThunkConfig> =>
  createAsyncThunk<unknown, P, AppAsyncThunkConfig & FulfilledMeta>(
    typePrefix,
    function innerPayloadCreator(args, innerOptions) {
      return Promise.resolve(payloadCreator(args, innerOptions)).then(result =>
        innerOptions.fulfillWithValue(result, {
          [SHOULD_AUTOBATCH]: true,
        }),
      )
    },
    {
      getPendingMeta: (...args) => ({
        [SHOULD_AUTOBATCH]: true,
        ...(options?.getPendingMeta?.(...args) as Object),
      }),
      ...options,
    },
  ) as AsyncThunk<D, P, AsyncThunkConfig>

// for usage without dispatch and getState
export const createFetchAction = <D, P = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<D, P, AsyncThunkConfig>,
  options?: Partial<AsyncThunkOptions<P, AsyncThunkConfig>>,
): AsyncThunk<D, P, AsyncThunkConfig> => {
  let resolveRequest: (value: unknown) => unknown
  const request = new Promise(resolve => {
    resolveRequest = resolve
  })
  const thunk = createAsyncThunk<unknown, P, AsyncThunkConfig & FulfilledMeta>(
    typePrefix,
    function innerPayloadCreator(args, innerOptions) {
      return Promise.resolve(payloadCreator(args, innerOptions)).then(result =>
        innerOptions.fulfillWithValue(result, {
          [SHOULD_AUTOBATCH]: true,
        }),
      )
    },
    {
      serializeError: e => (e instanceof Error ? e : null),
      getPendingMeta: (...args) => ({
        request,
        [SHOULD_AUTOBATCH]: true,
        ...options?.getPendingMeta?.(...args),
      }),
      ...options,
    },
  ) as AsyncThunk<D, P, AsyncThunkConfig>
  return Object.assign(
    (arg: P): AsyncThunkAction<D, P, AsyncThunkConfig> =>
      dispatch => {
        const result = dispatch(thunk(arg))
        resolveRequest(
          result.unwrap().catch(e => {
            BS?.Log?.error('Something went wrong: ', e)
          }),
        )
        return result
      },
    thunk,
  )
}

export const fetchable = <T, D, P>(
  thunk: AsyncThunk<D, P, AsyncThunkConfig>,
  defaultState: T,
  dataReducer: (
    prevState: T,
    action: ReturnType<AsyncThunkFulfilledActionCreator<D, P, AsyncThunkConfig>>,
  ) => T,
  getReceiveMeta: (arg: P) => SuccessReceiveMeta | undefined = () => undefined,
) =>
  createReducer(
    (): Fetchable<T> => ({
      data: defaultState,
      loading: false,
      backgroundLoading: false,
      error: null,
      ready: false,
      inited: false,
      receiveMeta: {},
      request: null,
    }),
    builder => {
      builder.addCase(thunk.pending, (state, action) => {
        const {invalidate, isBackground, request} = action.meta
        if (invalidate) {
          state.data = defaultState as Draft<T>
          state.ready = false
        }
        state.loading = true
        state.backgroundLoading = isBackground ?? false
        state.inited = true
        if (request != null) {
          state.request = request
        }
      })
      builder.addCase(thunk.fulfilled, (state, action) => {
        const originalData = original(state)!.data as T
        const data = dataReducer(originalData, action)
        if (!deepEqual(data, originalData)) {
          state.data = data as Draft<T>
        }
        state.loading = false
        state.backgroundLoading = false
        state.error = null
        state.ready = true
        state.inited = true
        const receiveMeta = getReceiveMeta(action.meta.arg)
        if (state.receiveMeta != null) {
          Object.assign(state.receiveMeta, receiveMeta)
        } else {
          state.receiveMeta = receiveMeta
        }
      })
      builder.addCase(thunk.rejected, (state, action) => {
        state.loading = false
        state.backgroundLoading = false
        state.error = action.error
        state.ready = true
        state.inited = true
      })
    },
  )

export const keyValueFetchable = <T, D, P>(
  getKey: (arg: P) => string,
  thunk: AsyncThunk<D, P, AsyncThunkConfig>,
  defaultState: T,
  dataReducer: (
    prevState: T,
    action: ReturnType<AsyncThunkFulfilledActionCreator<D, P, AsyncThunkConfig>>,
  ) => T,
  getReceiveMeta?: (arg: P) => SuccessReceiveMeta | undefined,
) =>
  keyValueReducer(
    (action: FetchAction<P>) => getKey(action.meta.arg),
    fetchable(thunk, defaultState, dataReducer, getReceiveMeta),
    [thunk.pending, thunk.fulfilled, thunk.rejected],
  )
