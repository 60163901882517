// ensures that the original order is kept if sortFn returns 0
export default <T>(
  array: ReadonlyArray<T>,
  sortFn: (arg0: T, arg1: T) => number,
): ReadonlyArray<T> =>
  process.env.NODE_ENV === 'production'
    ? [...array].sort(sortFn)
    : array
        .map((value, index) => ({
          value,
          index,
        }))
        .sort((a, b) => {
          const direct = sortFn(a.value, b.value)
          const reversed = sortFn(b.value, a.value)

          if (Math.sign(direct) !== -Math.sign(reversed)) {
            // eslint-disable-next-line no-console
            console.warn(
              `Inconsistent sortFn results: sortFn(a, b) === ${direct}, sortFn(b, a) === ${reversed}`,
            )
          }

          const balanced = direct - reversed
          return balanced || a.index - b.index
        })
        .map(({value}) => value)
