import type {Size} from '@jetbrains/ring-ui/components/avatar/avatar'
import RingAvatar from '@jetbrains/ring-ui/components/avatar/avatar'
import classNames from 'classnames'

import styles from './Avatar.css'

export function Avatar(props: {
  className: string | undefined
  withOutline: boolean
  mine: boolean
  highlightMyChanges: boolean
  size: Size
  avatarClassName: string | undefined
  avatar: string | undefined
  avatar2x: string | undefined
  username: string | undefined
}) {
  return (
    <span
      className={classNames(styles.wrapper, props.className, {
        [styles.withOutline]: props.withOutline,
        [styles.my]: props.mine && props.highlightMyChanges,
      })}
      style={{height: props.size, width: props.size}}
      data-ignore-dark-theme-adapter
    >
      <RingAvatar
        className={classNames(styles.avatar, props.avatarClassName)}
        url={props.avatar2x ?? props.avatar}
        srcSet={props.avatar && props.avatar2x && `${props.avatar}, ${props.avatar2x} 2x`}
        size={props.size}
        username={props.username}
        skipParams
      />
    </span>
  )
}
