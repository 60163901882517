import {castDraft} from 'immer'

import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice, isAnyOf} from '@reduxjs/toolkit'

import {restApi} from '../services/rest'
import type {BuildId} from '../types'
import type {KeyValue} from '../utils/object'

export const buildSelections = createSlice({
  name: 'buildSelections',
  initialState: {} as KeyValue<string, readonly BuildId[]>,
  reducers: {
    select: (state, action: PayloadAction<{selectionId: string; buildId: BuildId}>) => {
      const {selectionId, buildId} = action.payload
      state[selectionId] ??= []
      if (!state[selectionId]!.includes(buildId)) {
        state[selectionId]!.push(buildId)
      }
    },
    deselect: (state, action: PayloadAction<{selectionId: string; buildId: BuildId}>) => {
      const {selectionId, buildId} = action.payload
      state[selectionId] ??= []
      if (state[selectionId]!.includes(buildId)) {
        state[selectionId] = state[selectionId]!.filter(id => id !== buildId)
      }
    },
    setSelection: (
      state,
      action: PayloadAction<{selectionId: string; selection: readonly BuildId[]}>,
    ) => {
      const {selectionId, selection} = action.payload
      state[selectionId] = castDraft(selection)
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.setBuildTags.matchFulfilled,
        restApi.endpoints.setMultipleBuildComments.matchFulfilled,
        restApi.endpoints.pinMultipleBuilds.matchFulfilled,
        restApi.endpoints.addTagsToMultipleBuilds.matchFulfilled,
      ),
      () => ({}),
    )
  },
})
