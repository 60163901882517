import type {PlaceId, PluginContext} from '@jetbrains/teamcity-api'
import {useEffect} from 'react'
import type {$PropertyType} from 'utility-types'

import Plugin from '../../../plugins/plugins.prototype'
import pluginRegistry from '../../../plugins/plugins.registry'
import {PlaceIdList} from '../../../plugins/plugins.types'
import {base_uri, BS} from '../../../types/BS_types'
import type {WritableKeyValue} from '../../../utils/object'
import {getScripts, withoutScripts} from '../InjectHtml/InjectHtml.utils'

import {getPluginContent} from './Plugin.requests'
import type {DummyPlugin} from './Plugin.types'

type PluginLocationContext = $PropertyType<PluginContext, 'location'>
const cssFilesCache: WritableKeyValue<string, boolean> = {}

function noop() {}

const loadCssFiles = (
  paths: Array<string>,
  options: {
    name: string
  },
) => {
  const links = paths.map(path => {
    cssFilesCache[path] = true
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = `${base_uri}/${path}`
    link.dataset.plugin = options.name
    document.getElementsByTagName('HEAD')[0].appendChild(link)

    return link
  })

  return links.filter(Boolean)
}

const prepareExternalJS = (paths: Array<string>) =>
  paths.map(path => {
    const script = document.createElement('script')
    script.src = `${base_uri}/${path}`
    return script
  })

type OwnProps = {
  availablePlugins: ReadonlyArray<DummyPlugin>
  activeEntities: PluginLocationContext
  pluginDevelopmentMode: string | null | undefined
  availablePluginsLoading: boolean
  placeId: PlaceId
}
export const usePluginsLoader = ({
  availablePlugins,
  activeEntities,
  placeId,
  pluginDevelopmentMode,
  availablePluginsLoading,
}: OwnProps) => {
  useEffect(() => {
    if (availablePluginsLoading) {
      return noop
    }

    const updatePluginsCallbackList = availablePlugins.map(plugin => {
      let pluginInstance: Plugin | null = null
      let isCancelled = false
      let scriptsContainer: HTMLElement | null | undefined = null
      let cssLinks: Array<HTMLLinkElement | null> = []
      const cachedPlugin = pluginRegistry.searchByPlaceId(placeId, plugin.name)

      if (cachedPlugin instanceof Plugin && cachedPlugin.isControlled()) {
        return noop
      }

      const loadContent = async () => {
        const content = await getPluginContent(plugin.extensionUrl, activeEntities)

        if (!BS || isCancelled) {
          return
        }

        const scripts = getScripts(content)
        pluginInstance = new Plugin(placeId, {
          content: withoutScripts(content),
          name: plugin.name,
          options: {
            debug: pluginDevelopmentMode != null,
          },
          onMount: () => {
            cssLinks = loadCssFiles(plugin.css, {name: plugin.name})

            const js = prepareExternalJS(plugin.js)
            const range = document.createRange()
            scriptsContainer = document.createElement('div')
            scriptsContainer.dataset.tcPluginScripts = `${placeId}-${plugin.name}`
            range.selectNodeContents(scriptsContainer)
            range.deleteContents()
            range.setStart(scriptsContainer, 0)

            if (scripts != null) {
              scriptsContainer.appendChild(range.createContextualFragment(scripts))
            }

            js.map(script => scriptsContainer?.appendChild(script))
            document.body?.appendChild(scriptsContainer)
            return () => {}
          },
        })
      }

      loadContent()
      return () => {
        isCancelled = true

        if (pluginInstance == null) {
          scriptsContainer?.remove()
          return
        }

        if (!pluginInstance.isControlled() || placeId === PlaceIdList.TAB_PLUGIN_CONTAINER) {
          ;[...cssLinks].forEach(link => link?.remove())
          scriptsContainer?.remove()
          pluginInstance?.remove()
        }
      }
    })
    return () =>
      updatePluginsCallbackList.forEach(
        updatePlugin => typeof updatePlugin === 'function' && updatePlugin(),
      )
  }, [activeEntities, availablePlugins, availablePluginsLoading, placeId, pluginDevelopmentMode])
}
