import type {DslOption} from '../types'

import processResponse from './processResponse'
import request from './request'

const endpoint = 'dslApiVersions.html'
export default (serverUrl: string): Promise<ReadonlyArray<DslOption>> =>
  request(serverUrl, `${endpoint}`)
    .then<{kotlin: ReadonlyArray<DslOption>}>(processResponse)
    .then(data => data.kotlin)
