import isEmpty from 'lodash/isEmpty'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'

export function getJob(state: PipelineDraftState, id: string, jobId: string) {
  return state[id]?.draft?.settings.jobs?.[jobId]
}

export function deleteIfEmpty<T>(object: T | undefined, key: keyof T) {
  if (object != null && isEmpty(object[key])) {
    delete object[key]
    return true
  }
  return false
}
