const getRandomNumbers = (n: number) => {
  let array
  const cryptoObj = window.crypto

  if (cryptoObj) {
    array = new Uint32Array(n)
    cryptoObj.getRandomValues(array)
  } else {
    array = []

    for (let i = 0; i < n; i++) {
      array.push(Math.floor(Math.random() * 100000000)) // eslint-disable-line @typescript-eslint/no-magic-numbers
    }
  }

  return array
}

export const generateUID: (prefix?: string, count?: number) => string = (prefix, count) => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const array = getRandomNumbers(count ?? 3)
  return `${typeof prefix === 'string' && prefix.length ? `${prefix}:` : ''}${array.join(
    '_',
  )}_${Date.now()}`
}
