import ColorHash from 'color-hash'

export type ColorValueArray = [number, number, number]

const cyrb53Hash = (str = '', seed = 0) => {
  /*eslint-disable @typescript-eslint/no-magic-numbers*/
  let h1 = 0xdeadbeef ^ seed
  let h2 = 0x41c6ce57 ^ seed

  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)
  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
  /*eslint-enable @typescript-eslint/no-magic-numbers*/
}

const colorHash = new ColorHash({
  hash: cyrb53Hash,
})

export const getColorHexByString = (key?: string | null | undefined): string =>
  colorHash.hex(key ?? '')

export const getColorRgbByString = (key?: string | null | undefined): ColorValueArray =>
  colorHash.rgb(key ?? '')
