import {getWorkBoxInstance} from './setupServiceWorker'

let updateRequested = false

export const requestSWUpdate = () => {
  const workbox = getWorkBoxInstance()

  if (updateRequested || !navigator.serviceWorker || !workbox) {
    return
  }

  workbox?.update()
  updateRequested = true
}

export const getSWRegistration = async (): Promise<
  ServiceWorkerRegistration | null | undefined
> => {
  if (navigator.serviceWorker) {
    return await navigator.serviceWorker.getRegistration()
  } else {
    return Promise.resolve(null)
  }
}

export const requestSkipWaiting = () => {
  const wb = getWorkBoxInstance()

  if (wb) {
    wb.messageSkipWaiting()
  }
}
