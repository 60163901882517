import type {AppThunk} from '../../../actions/types'
import {getSingleBuildArg} from '../../../rest/builds'
import {getBuildLocator} from '../../../rest/locators'
import {restApi} from '../../../services/rest'
import {stoppingBuilds} from '../../../slices'
import type {BuildId} from '../../../types'

const markBuildAsStopping = stoppingBuilds.actions.add

export const refreshBuildAfterStopping =
  (id: BuildId): AppThunk<any> =>
  dispatch => {
    dispatch(markBuildAsStopping(id))
    return dispatch(
      restApi.endpoints.getBuildNormalizedAsList.initiate(getSingleBuildArg(getBuildLocator(id)), {
        subscribe: false,
        forceRefetch: true,
      }),
    )
  }
