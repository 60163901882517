import type {GetAllInvestigationsApiArg} from '../services/rest'
import {stringifyId} from '../types'
import type {BuildTypeId} from '../types'

export const investigation =
  'investigation(id,state,resolution(type),scope(project(id,name),buildTypes(buildType(id,name))),assignee(id,name,username),assignment(user(id,name,username),timestamp,text))'
export const getInvestigationArg = (buildTypeId: BuildTypeId): GetAllInvestigationsApiArg => ({
  locator: `buildType:(id:${encodeURIComponent(stringifyId(buildTypeId))}),count:1`,
  fields: investigation,
})
