import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import {stringifyId} from '../../../types'
import type {BuildId} from '../../../types'

import type {
  CompareBuildsList,
  CompareBuildsInfo,
  BuildForCompareData,
} from './CompareBuildsPage.types'

export const requestCompareBuildsList = (
  serverUrl: string,
  endpoint: string,
  sourceId: BuildId,
  compareWithId: BuildId,
): Promise<CompareBuildsList> =>
  request(
    serverUrl,
    `${endpoint}/list?build1=${stringifyId(sourceId)}&build2=${stringifyId(compareWithId)}`,
    {essential: true},
  ).then<CompareBuildsList>(processResponse)
export const requestCompareBuildsInfo = (
  serverUrl: string,
  endpoint: string,
  compareType: string,
  sourceId: BuildId,
  compareWithId: BuildId,
  mode: 'changed' | 'all',
  filterText?: string | null | undefined,
): Promise<CompareBuildsInfo> => {
  const params = new URLSearchParams()
  params.set('mode', mode)

  if (filterText != null) {
    params.set('text', filterText)
  }

  return request(
    serverUrl,
    `${endpoint}/${stringifyId(sourceId)}/to/${stringifyId(
      compareWithId,
    )}/with/${compareType}?${params.toString()}`,
  ).then<CompareBuildsInfo>(processResponse)
}
export const requestBuildsForCompare = (
  serverUrl: string,
  endpoint: string,
  sourceId: BuildId,
): Promise<BuildForCompareData> =>
  request(serverUrl, `${endpoint}/builds/for/${stringifyId(sourceId)}`).then<BuildForCompareData>(
    processResponse,
  )
