import type {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'

import type {KeyValue} from '../../../utils/object'

export const HINT_CATEGORY = {
  tabs: 'Tabs',
  buildLog: 'Build Log',
  sidebar: 'Sidebar',
  dependencyChain: 'Dependencies',
  header: 'Header',
  navigation: 'Navigation',
  miscellaneous: 'Miscellaneous',
  whatsNew: "What's new",
  projectOverview: 'Project',
  buildOverview: 'Build',
  agents: 'Agents',
  administration: 'Administration',
  guide: 'Guide',
} as const
type HintCategoryType = typeof HINT_CATEGORY
export type HintCategory = HintCategoryType[keyof HintCategoryType]
export type HintId = string
export const toHintId = (id: string): HintId => id
export const stringifyHintId = (id: HintId): string => (id != null ? String(id) : '')
type HintImage = {
  src: string
  alt: string
}
export type Hint = {
  id: HintId
  name: string
  text: string
  category?: HintCategory
  className?: string
  directions?: ReadonlyArray<Directions>
  offset?: number
  viewed?: boolean
  lessonId?: string
  helpLink?: string
  selector?: string
  image?: HintImage
  obligatory?: boolean
}
export type HintPopupProps = {
  id: HintId
  dark?: boolean
  btnPrevText?: string
  btnNextText?: string
  currentIndex?: number
  total?: number
  onClickPrev?: () => void
  onClickNext?: () => void
  onClose?: () => void
  btnPrevPrimary?: boolean
}
export type HintDataHook = {
  onSelectNext: () => unknown
  onSelectPrevious: () => unknown
  onSelectCategory: (category: HintCategory) => unknown
  currentCategoryHints: {
    category?: HintCategory | null
    collection: Array<Hint>
  }
  registeredCategories: HintCategory[]
  selectedHints: ReadonlyArray<HintId>
}
export type HintsState = {
  registrations: KeyValue<HintId, number>
  available: ReadonlyArray<Hint>
  selected: ReadonlyArray<HintId>
  selectedCategory: HintCategory | null
  viewedObligatoryHints: ReadonlyArray<HintId>
  skipObligatoryHints: boolean
}
