import type {TypedUseSelectorHook} from 'react-redux'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {useDispatch, useSelector} from 'react-redux'

import type {AppDispatch} from '../actions/types'
import type {State} from '../reducers/types'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<State> = useSelector
