import compose from 'lodash/flowRight'
import {memo} from 'react'
import {connect} from 'react-redux'

import {withUpdateResults} from '../../../contexts/update'
import withUserPermissions from '../../../hocs/withUserPermissions'
import type {State} from '../../../reducers/types'
import {getBuild, getBuildType, getIsStopping, getUserDisplayName} from '../../../selectors'

import {Permission} from '../../../types'

import StopBuild from './StopBuild'
import {refreshBuildAfterStopping} from './StopBuild.actions'
import {getIsCancellable, getIsInterrupted} from './StopBuild.selectors'
import type {ActionProps, OwnProps, StateProps} from './StopBuild.types'

const mapStateToProps = (state: State, {buildId, withAgent}: OwnProps): StateProps => {
  const {buildType, state: buildState, canceledInfo, agent} = getBuild(state, buildId) || {}
  const {projectId} = getBuildType(state, buildType) || {}
  return {
    projectId,
    isCancellable: getIsCancellable(state, buildId),
    agentName: withAgent === true ? agent?.name : null,
    state: buildState,
    isInterrupted: getIsInterrupted(state, buildId),
    isStopping: getIsStopping(state, buildId),
    stoppedWithComment: canceledInfo?.text,
    stoppedBy: getUserDisplayName(state, canceledInfo?.user),
  }
}

const actionCreators: ActionProps = {
  onStop: refreshBuildAfterStopping,
}
export default compose(
  connect(mapStateToProps, actionCreators),
  withUserPermissions(
    [Permission.CANCEL_BUILD, Permission.CANCEL_ANY_PERSONAL_BUILD],
    (props: OwnProps & StateProps & ActionProps) => props.projectId,
  ),
  memo,
  withUpdateResults(),
)(StopBuild)
