import CommentIcon from '@jetbrains/icons/comment.svg'
import EqualizerIcon from '@jetbrains/icons/filters.svg'
import MoreOptionsIcon from '@jetbrains/icons/more-options.svg'
import PinEmptyIcon from '@jetbrains/icons/pin-empty.svg'
import PinFilledIcon from '@jetbrains/icons/pin-filled.svg'
import TagIcon from '@jetbrains/icons/tag.svg'
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import {Type as ListItemType} from '@jetbrains/ring-ui/components/list/consts'
import classNames from 'classnames'
import * as React from 'react'

import {stringifyId} from '../../../../../types'
import {notNull} from '../../../../../utils/guards'
import IconButton from '../../../IconButton/IconButton'

import BuildActionsPopup from './BuildActions.popup.lazy'
import type {BuildActionsProps} from './BuildActions.types'

import styles from './BuildActions.css'

function noop() {}

export default function BuildActions({
  className,
  comment,
  buildId,
  buildTypeId,
  isPinned,
  pinComment,
  isStarred,
  isCustomizedParams,
  isCustomizedChanges,
  isCustomizedArtifacts,
  isCommentable = true,
  isPinnable = true,
  isStarrable = true,
  isTaggable = true,
  hasDependencies = false,
  joinedTags = '',
  onPin = noop,
  onTags = noop,
  onComment = noop,
  onStar = noop,
  onAction = noop,
}: BuildActionsProps) {
  const tagsContainerId = `buildTypeTags_${stringifyId(buildTypeId)}`

  async function handlePin() {
    if (buildId == null || buildTypeId == null) {
      return
    }

    await onPin({
      buildId,
      isPinned: !!isPinned,
      hasDependencies,
      pinComment,
      tagsContainerId,
      buildTypeId,
    })
    onAction()
  }

  async function handleTags() {
    if (buildId == null || buildTypeId == null) {
      return
    }

    await onTags({
      buildId,
      joinedTags,
      hasDependencies,
      tagsContainerId,
      buildTypeId,
    })
    onAction()
  }

  async function handleFavorite(newStatus: boolean) {
    if (buildId == null) {
      return
    }

    await onStar(buildId, newStatus)
    onAction()
  }

  async function handleComment() {
    if (buildId == null) {
      return
    }

    await onComment({
      buildId,
      comment,
    })
    onAction()
  }

  const classes = classNames(styles.buildActions, className)
  const items = [
    isPinnable
      ? {
          label: isPinned === true ? 'Unpin...' : 'Pin...',
          onClick: handlePin,
          glyph: isPinned === true ? PinFilledIcon : PinEmptyIcon,
        }
      : null,
    isTaggable
      ? {
          label: `${joinedTags.length ? 'Edit' : 'Add'} tags...`,
          onClick: handleTags,
          glyph: TagIcon,
        }
      : null,
    isCommentable === true
      ? {
          label: `${comment != null ? 'Edit' : 'Add'} comment...`,
          onClick: handleComment,
          glyph: CommentIcon,
        }
      : null,
    isStarrable
      ? {
          rgItemType: ListItemType.SEPARATOR,
        }
      : null,
    isStarrable
      ? {
          label: `${isStarred === true ? 'Remove from' : 'Add to'} favorites`,
          onClick: () => {
            handleFavorite(!isStarred)
          },
        }
      : null,
  ].filter(notNull)
  return items.length > 0 || isCustomizedParams || isCustomizedChanges || isCustomizedArtifacts ? (
    <Dropdown
      className={classes}
      hoverMode
      anchor={
        <IconButton
          title="Build Actions"
          icon={
            isCustomizedParams || isCustomizedChanges || isCustomizedArtifacts
              ? EqualizerIcon
              : MoreOptionsIcon
          }
        />
      }
    >
      <BuildActionsPopup items={items} />
    </Dropdown>
  ) : null
}
