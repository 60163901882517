const supportBaseUrl = 'https://teamcity-support.jetbrains.com/hc/en-us/requests/new'

export function getSupportUrl(
  licenseIsCloud: boolean | undefined,
  licenseIsEnterpise: boolean | undefined,
  version: string | undefined,
): string {
  const params = new URLSearchParams()
  if (licenseIsCloud) {
    params.set('ticket_form_id', '360003300299')
    params.set('teamcity_cloud_url', window.location.hostname)
  } else {
    params.set('ticket_form_id', '66621')
    if (licenseIsEnterpise) {
      params.set('license_type', 'enterprise')
    }
    if (version != null && version.length > 0) {
      const shortVersion = version.split(' ').find(i => i.length > 0)
      if (shortVersion != null) {
        params.set('version', shortVersion)
      }
    }
  }
  return `${supportBaseUrl}?${params.toString()}`
}
