export enum DependenciesTypes {
  SNAPSHOT = 'snapshot',
  DOWNLOADED_ARTIFACTS = 'downloaded-artifacts',
  DELIVERED_ARTIFACTS = 'delivered-artifacts',
}

export enum SnapshotDependenciesModes {
  TIMELINE = 'timeline',
  LIST = 'list',
  CHAIN = 'chain',
}
