import type {$Values} from 'utility-types'

import type {TestOccurrence} from '../../../services/rest'
import type {BuildTypeInternalId, TestId, TestOccurrenceId, TestsTreeNodeId} from '../../../types'

export const PAGER_QUERY_PARAM_NAME = 'pager.currentPage'
export const testStatuses = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  UNKNOWN: 'UNKNOWN',
} as const
export const OrderByFields = {
  status: 'status',
  name: 'name',
  duration: 'duration',
  runOrder: 'runOrder',
  newFailure: 'newFailure',
  count: 'count',
  success: 'success', // Sorting by these fields is not supported yet.
  failure: 'failure', // ...
  muted: 'muted', // ...
  ignored: 'ignored', // ...
  buildStartDate: 'buildStartDate',
} as const
export const OrderDirections = {
  asc: 'asc',
  desc: 'desc',
} as const
export const TestScopesKey = {
  suite: 'suite',
  package: 'package',
  class: 'class',
} as const
export const TreeEntityType = {
  PROJECT: 'PROJECT',
  BUILD_TYPE: 'BUILD_TYPE',
  BUILD: 'BUILD',
  SUITE: 'SUITE',
  PACKAGE: 'PACKAGE',
  CLASS: 'CLASS',
}
export type OrderByFieldsType = $Values<typeof OrderByFields>
export type OrderDirectionsType = $Values<typeof OrderDirections>
export type TestScopesKeyType = $Values<typeof TestScopesKey>
export type TreeEntityTypeType = $Values<typeof TreeEntityType>

export type RequestTestOccurrenceOptionsParams = {
  withNewFailure?: boolean
  withMetadataCount?: boolean
  withUpdatePager?: boolean
  withNextFixed?: boolean
  withFirstFailed?: boolean
  withRunOrder?: boolean
  withMuteInfo?: boolean
  withInvocationsCounters?: boolean
  withInvestigationInfo?: boolean
  withBuildInfo?: boolean
}
export type TestOccurrenceMetadataValues = {
  name: string
  type?: string
  value?: string
}
export type TestOccurrencesCounts = {
  all?: number
  failed?: number
  success?: number
  muted?: number
  ignored?: number
  newFailed?: number
  duration?: number
}
type BaseTestOccurrences<T> = {
  testCounters?: TestOccurrencesCounts
  testOccurrence?: ReadonlyArray<T>
}
type TestOccurrences = BaseTestOccurrences<TestOccurrence>
type NormalizedTestOccurrences = BaseTestOccurrences<TestOccurrenceId>
export type TestOccurrenceInvocations = {
  id: TestOccurrenceId
  invocations?: NormalizedTestOccurrences
}
type BaseTestOccurrencesTreeLeaf<T> = {
  nodeId: TestsTreeNodeId
  testOccurrences?: T
}
export type TestOccurrencesTreeLeaf = BaseTestOccurrencesTreeLeaf<TestOccurrences>
export type TestOccurrencesTreeNode = {
  id: TestsTreeNodeId
  parentId?: TestsTreeNodeId
  name: string
  childrenCount?: number
  testCounters?: TestOccurrencesCounts
  type: TreeEntityTypeType
}
type BaseTestOccurrencesTree<T> = {
  leaf?: ReadonlyArray<BaseTestOccurrencesTreeLeaf<T>>
  node?: ReadonlyArray<TestOccurrencesTreeNode>
}
export type TestOccurrencesTree = BaseTestOccurrencesTree<TestOccurrences>
export type TestScopeType = {
  suite?: string
  package?: string
  class?: string
  testOccurrences?: TestOccurrences
}
export type TestScope = {
  suite: string | null | undefined
  package: string | null | undefined
  class: string | null | undefined
}
export type TestFlaky = {
  id: TestId
  isFlaky: boolean
  title?: string
  descriptions?: ReadonlyArray<string>
}
export type RequestFlakyTests = ReadonlyArray<{
  testName: TestId | null | undefined
  buildTypeId: BuildTypeInternalId | null | undefined
}>
