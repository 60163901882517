import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import {pipelinesApi} from '../../services/pipelinesApi'

import * as pipelineDraftAgent from './EditPipelinePage.agent.slice'
import * as pipelineDraftBranch from './EditPipelinePage.branch.slice'
import * as pipelineDraftDependencies from './EditPipelinePage.dependencies.slice'
import * as pipelineErrorSlice from './EditPipelinePage.errors.slice'
import * as pipelineDraftFilesPublicationJob from './EditPipelinePage.filesPublicationJob.slice'
import * as pipelineDraftIntegration from './EditPipelinePage.integration.slice'
import * as pipelineDraftJob from './EditPipelinePage.job.slice'
import * as pipelineDraftParameter from './EditPipelinePage.parameter.slice'
import * as pipelineDraftMain from './EditPipelinePage.pipeline.slice'
import * as pipelineDraftSecret from './EditPipelinePage.secret.slice'
import {
  collapsedBlocksInitialState,
  initialState,
  pipelineErrorInitialState,
} from './EditPipelinePage.slices.consts'
import * as pipelineDraftSteps from './EditPipelinePage.steps.slice'
import * as pipelineDraftTrigger from './EditPipelinePage.trigger.slice'

export const pipelineDraft = createSlice({
  name: 'pipelineDraft',
  initialState,
  reducers: {
    ...pipelineDraftMain,
    ...pipelineDraftDependencies,
    ...pipelineDraftBranch,
    ...pipelineDraftSteps,
    ...pipelineDraftJob,
    ...pipelineDraftParameter,
    ...pipelineDraftIntegration,
    ...pipelineDraftSecret,
    ...pipelineDraftFilesPublicationJob,
    ...pipelineDraftTrigger,
    ...pipelineDraftAgent,
  },
  extraReducers: builder => {
    builder.addMatcher(pipelinesApi.endpoints.getPipelineById.matchFulfilled, (state, action) => {
      const {originalArgs: id, forceRefetch} = action.meta.arg
      const data = action.payload
      const apiPipelineVersion = data.pipelineVersion
      const originalPipelineVersion = state[id]?.original?.pipelineVersion
      const isCollisionApi = apiPipelineVersion !== originalPipelineVersion

      state[id] ??= {}

      if (!isCollisionApi) {
        state[id]!.original = data
        state[id]!.draft ??= data
      } else if (forceRefetch) {
        state[id]!.original = data
        state[id]!.draft = data
      }

      state[id]!.isCollisionApi = isCollisionApi && !forceRefetch
    })
  },
})

export const hoveredJob = createSlice({
  name: 'hoveredJob',
  initialState: null as string | null,
  reducers: {
    set: (_, action: PayloadAction<string | null>) => action.payload,
  },
})

export const pipelineError = createSlice({
  name: 'pipelineError',
  initialState: pipelineErrorInitialState,
  reducers: {
    ...pipelineErrorSlice,
  },
})

export const collapsedBlocks = createSlice({
  name: 'collapsedBlocks',
  initialState: collapsedBlocksInitialState,
  reducers: {
    toggle(state, action: PayloadAction<{id: string; collapsed: boolean}>) {
      const {id, collapsed} = action.payload
      state[id] = collapsed
    },
  },
})
