import type {$Values} from 'utility-types'

import type {Branch} from '../../../services/rest'
import type {BuildTypeId, ProjectId, ProjectOrBuildTypeNode} from '../../../types'

export const Sizes = {
  S: 14,
  M: 20,
}
export const types = {
  project: 'project',
  buildType: 'buildType',
  template: 'template',
  investigation: 'investigation',
  vcs_root: 'vcs-root',
  pausedOutline: 'paused-outline',
  paused: 'paused',
} as const
export type ProjectOrBuildTypeIconType = $Values<typeof types>
export type OverviewHeaderIconOwnProps = {
  showPausedInStatus?: boolean
  showInvestigationInStatus?: boolean
  ignoreStatus?: boolean
  size?: keyof typeof Sizes
  className?: string | null | undefined
  title?: string
  arrowClassName?: string | null | undefined
  projectOrBuildTypeNode?: ProjectOrBuildTypeNode | null | undefined
  withArrow?: boolean
}
export type OverviewHeaderIconAddProps =
  | {
      type: 'project'
      id?: ProjectId
      branch?: Branch
    }
  | {
      type: 'buildType'
      id?: BuildTypeId
      branch?: Branch
      showInfoStatus?: boolean
    }
  | {
      type?: Exclude<ProjectOrBuildTypeIconType, 'project' | 'buildType'> | null
    }
export type OverviewStatusIconOwnProps = OverviewHeaderIconOwnProps & OverviewHeaderIconAddProps
export type OverviewStatusIconStateProps = {
  title?: string | null | undefined
  status?: 'failed' | 'successful' | null
  type: ProjectOrBuildTypeIconType | null | undefined
  composite?: boolean
}
export type DefaultProps = {
  type: ProjectOrBuildTypeIconType
  size: keyof typeof Sizes
}
export type Props = OverviewStatusIconOwnProps & OverviewStatusIconStateProps & DefaultProps
