import type {AppThunk} from '../../../actions/types'
import {getAgentPool} from '../../../selectors'
import type {AgentPoolId} from '../../../types'
import {objectKeys} from '../../../utils/object'
import {createRefetchableSubscription} from '../../../utils/refetchable'
import {subscribeOnOverallEvents} from '../../../utils/subscriber'
import type {Unsubscribe} from '../../../utils/subscriber'
import {fetchAgentPoolsData} from '../../pages/AgentsPages/AgentsPages.actions'

import {counters} from './QueueSidebar.slices'
import type {QueueCountersByPool} from './QueueSidebar.types'

export const subscribeOnPoolCounters = (): AppThunk<Unsubscribe> => (dispatch, getState) => {
  let prevPoolIds: AgentPoolId[] = []
  return createRefetchableSubscription(
    () => dispatch(fetchAgentPoolsData()),
    fetchAgentPools =>
      subscribeOnOverallEvents(
        ['buildQueue/countersByPool'],
        (data: QueueCountersByPool | null | undefined) => {
          if (data == null) {
            return
          }

          dispatch(counters.actions.receive(data))
          const poolIds = objectKeys(data)
          const state = getState()

          if (poolIds.some(id => !prevPoolIds.includes(id) && getAgentPool(state, id) == null)) {
            fetchAgentPools()
          }

          prevPoolIds = poolIds
        },
      ),
  )
}
