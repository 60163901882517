import type {LinkType, WebLinks} from '../types'

import {resolveRelative} from './url'

const makeWebEntityLinkResolver =
  (targetType: LinkType) =>
  (entity: WebLinks | null | undefined): string | null | undefined => {
    const links = entity?.links
    const link = links?.link?.find(({type}) => type === targetType)
    return link?.relativeUrl ? resolveRelative(link.relativeUrl) : undefined
  }

export const resolveWebEntityLink: (
  entity: WebLinks | null | undefined,
) => string | null | undefined = makeWebEntityLinkResolver('webView')
export const resolveWebEntityEditLink: (
  entity: WebLinks | null | undefined,
) => string | null | undefined = makeWebEntityLinkResolver('webEdit')
export const resolveWebEntitySettingsLink: (
  entity: WebLinks | null | undefined,
) => string | null | undefined = makeWebEntityLinkResolver('webViewSettings')
