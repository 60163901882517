import {keyValueFetchable} from '../../../../../reducers/fetchable'

import {emptyArray} from '../../../../../utils/empty'

import {fetchBuildSnippetList} from './BuildSnippets.actions'
import {getBuildSnippetKey} from './BuildSnippets.selectors'

export default keyValueFetchable(
  getBuildSnippetKey,
  fetchBuildSnippetList,
  emptyArray,
  (_, action) => action.payload,
)
