const needsBase64 = /[()]/
const needsBase64InPath = /\//
export default (value: string, inPath?: boolean): string => {
  // eslint-disable-next-line  @typescript-eslint/prefer-includes
  if (needsBase64.test(value) || (inPath && needsBase64InPath.test(value))) {
    return `($base64:${btoa(unescape(encodeURIComponent(value)))})`
  }

  return `(${value})`
}
