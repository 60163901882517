import type {File} from '../types'

const archiveRE = /\.(zip|nupkg|sit|jar|war|ear|apk|tar\.gz|tgz|tar\.gzip|tar|7z)$/
export function getType({name, size}: File): string {
  if (size == null) {
    return 'folder'
  }

  if (archiveRE.test(name)) {
    return 'archive'
  }

  return 'file'
}
export const isOnlyFolder = (files: ReadonlyArray<File>): boolean =>
  files.length === 1 && getType(files[0]) === 'folder'
export const getChildPath = (path: string, name: string): string =>
  `${path}${archiveRE.test(path) ? '!' : ''}/${name}`
