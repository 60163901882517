import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {ErrorAlert} from './ErrorAlerts.types'

export const filedErrors = createSlice({
  name: 'errorAlerts.filedErrors',
  initialState: [] as ErrorAlert[],
  reducers: {
    close: (state, action: PayloadAction<string>) =>
      state.filter(item => item.key !== action.payload),
  },
  extraReducers: builder => {
    builder.addDefaultCase((_, __) => {
      // const error = 'error' in action && action.error != null ? action.error : null
      //
      // if (
      //   ('manualErrorHadling' in action && action.manualErrorHadling === true) ||
      //   error == null
      // ) {
      //   return state
      // }
      //
      // let key = error.message ?? error.toString()
      // let errorAlert: ErrorAlertType | null = null
      //
      // if (
      //   error instanceof HTTPError &&
      //   error.statusCode != null &&
      //   error.statusCode !== 0 &&
      //   error.statusCode !== HTTPCodesEnum.UNAUTHORIZED &&
      //   error.statusCode !== HTTPCodesEnum.FORBIDDEN
      // ) {
      //   if (key.length === 0) {
      //     key = `Network error: ${error.statusCode}`
      //   }
      //
      //   errorAlert = {
      //     type: 'http-error',
      //     statusCode: error.statusCode,
      //     showedDateTimeId: error.dateTimeId,
      //     actionType: action.type,
      //     message: error.message,
      //     url: error.url,
      //     key,
      //   }
      // }
      //
      // if (error instanceof GraphQLError) {
      //   errorAlert = {
      //     type: 'graphql-error',
      //     showedDateTimeId: error.dateTimeId,
      //     message: error.message,
      //     key: error.key || key,
      //   }
      // }
      //
      // if (errorAlert != null && !state.some(item => item.key === key)) {
      //   return [...state, errorAlert]
      // }
      // return state
    })
  },
})
