import type {AppThunk} from '../../../actions/types'
import {createFetchAction} from '../../../reducers/fetchable'
import {restRoot} from '../../../rest/consts'
import type {BuildId} from '../../../types'

import {requestWaitReasons} from './BuildQueueInfo.rest'
import {getWaitReasonsFetchable} from './BuildQueueInfo.selectors'
import type {WaitReasons} from './BuildQueueInfo.types'

export const fetchWaitReasonsAction = createFetchAction('fetchWaitReasons', (buildId: BuildId) =>
  requestWaitReasons(restRoot, buildId),
)
export const fetchWaitReasons =
  (buildId: BuildId): AppThunk =>
  (dispatch, getState) => {
    if (!getWaitReasonsFetchable(getState().queueInfo, buildId).loading) {
      dispatch(fetchWaitReasonsAction(buildId))
    }
  }
export const receiveWaitReasons = (buildId: BuildId, data: WaitReasons) =>
  fetchWaitReasonsAction.fulfilled(data, '', buildId)
