import type {$Values} from 'utility-types'

import type {BuildId, BuildState, ProjectId} from '../../../types'

export const stopButtonType = Object.freeze({
  defaultButton: 'defaultButton',
  iconButton: 'iconButton',
})
type StopButtonType = $Values<typeof stopButtonType>
export type OwnProps = {
  buildId: BuildId
  withAgent?: boolean
  className?: string
  buttonType?: StopButtonType
  primary?: boolean
  buttonText?: string
  actualStopMessage?: string
}
export type StateProps = {
  projectId?: ProjectId | null | undefined
  isCancellable: boolean
  agentName?: string | null | undefined
  state: BuildState | void
  isInterrupted: boolean
  isStopping: boolean
  stoppedWithComment?: string | null | undefined
  stoppedBy?: string | null | undefined
}
export type ActionProps = {
  onStop: (arg0: BuildId) => unknown
}
type UpdateProps = {
  updateResults: () => unknown
}
export type DefaultProps = UpdateProps &
  ActionProps & {
    buttonType: StopButtonType
    actualStopMessage: string
    state: BuildState
  }
export type Props = OwnProps & StateProps & DefaultProps
