export const INVESTIGATION_ACTION_TYPES = {
  // TEST INVESTIGATION ACTION TYPES
  TEST_INVESTIGATION_ASSIGN: 'TEST_INVESTIGATION_ASSIGN' as 'TEST_INVESTIGATION_ASSIGN',
  TEST_INVESTIGATION_REMOVE_NO_ASSIGNEE:
    'TEST_INVESTIGATION_REMOVE_NO_ASSIGNEE' as 'TEST_INVESTIGATION_REMOVE_NO_ASSIGNEE',
  TEST_MARK_AS_FIXED_NO_ASSIGNEE:
    'TEST_MARK_AS_FIXED_NO_ASSIGNEE' as 'TEST_MARK_AS_FIXED_NO_ASSIGNEE',
  TEST_INVESTIGATION_REMOVE: 'TEST_INVESTIGATION_REMOVE' as 'TEST_INVESTIGATION_REMOVE',
  TEST_MARK_AS_FIXED: 'TEST_MARK_AS_FIXED' as 'TEST_MARK_AS_FIXED',
  TEST_INVESTIGATION_ASSIGN_STICKY:
    'TEST_INVESTIGATION_ASSIGN_STICKY' as 'TEST_INVESTIGATION_ASSIGN_STICKY',
  TEST_INVESTIGATION_CHANGE: 'TEST_INVESTIGATION_CHANGE' as 'TEST_INVESTIGATION_CHANGE',
  TEST_INVESTIGATION_CHANGE_WITH_REASSIGN:
    'TEST_INVESTIGATION_CHANGE_WITH_REASSIGN' as 'TEST_INVESTIGATION_CHANGE_WITH_REASSIGN',
  TEST_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    'TEST_INVESTIGATION_ASSIGN_NO_ASSIGNEE' as 'TEST_INVESTIGATION_ASSIGN_NO_ASSIGNEE',
  TEST_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
    'TEST_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE' as 'TEST_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE',
  // BUILD TYPE INVESTIGATION ACTION TYPES
  BUILD_TYPE_INVESTIGATION_ASSIGN:
    'BUILD_TYPE_INVESTIGATION_ASSIGN' as 'BUILD_TYPE_INVESTIGATION_ASSIGN',
  BUILD_TYPE_INVESTIGATION_REMOVE_NO_ASSIGNEE:
    'BUILD_TYPE_INVESTIGATION_REMOVE_NO_ASSIGNEE' as 'BUILD_TYPE_INVESTIGATION_REMOVE_NO_ASSIGNEE',
  BUILD_TYPE_MARK_AS_FIXED_NO_ASSIGNEE:
    'BUILD_TYPE_MARK_AS_FIXED_NO_ASSIGNEE' as 'BUILD_TYPE_MARK_AS_FIXED_NO_ASSIGNEE',
  BUILD_TYPE_INVESTIGATION_REMOVE:
    'BUILD_TYPE_INVESTIGATION_REMOVE' as 'BUILD_TYPE_INVESTIGATION_REMOVE',
  BUILD_TYPE_MARK_AS_FIXED: 'BUILD_TYPE_MARK_AS_FIXED' as 'BUILD_TYPE_MARK_AS_FIXED',
  BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY:
    'BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY' as 'BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY',
  BUILD_TYPE_INVESTIGATION_CHANGE:
    'BUILD_TYPE_INVESTIGATION_CHANGE' as 'BUILD_TYPE_INVESTIGATION_CHANGE',
  BUILD_TYPE_INVESTIGATION_CHANGE_WITH_REASSIGN:
    'BUILD_TYPE_INVESTIGATION_CHANGE_WITH_REASSIGN' as 'BUILD_TYPE_INVESTIGATION_CHANGE_WITH_REASSIGN',
  BUILD_TYPE_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    'BUILD_TYPE_INVESTIGATION_ASSIGN_NO_ASSIGNEE' as 'BUILD_TYPE_INVESTIGATION_ASSIGN_NO_ASSIGNEE',
  BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
    'BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE' as 'BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE',
  // BUILD PROBLEM INVESTIGATION ACTION TYPES
  BUILD_PROBLEM_INVESTIGATION_ASSIGN:
    'BUILD_PROBLEM_INVESTIGATION_ASSIGN' as 'BUILD_PROBLEM_INVESTIGATION_ASSIGN',
  BUILD_PROBLEM_INVESTIGATION_REMOVE_NO_ASSIGNEE:
    'BUILD_PROBLEM_INVESTIGATION_REMOVE_NO_ASSIGNEE' as 'BUILD_PROBLEM_INVESTIGATION_REMOVE_NO_ASSIGNEE',
  BUILD_PROBLEM_MARK_AS_FIXED_NO_ASSIGNEE:
    'BUILD_PROBLEM_MARK_AS_FIXED_NO_ASSIGNEE' as 'BUILD_PROBLEM_MARK_AS_FIXED_NO_ASSIGNEE',
  BUILD_PROBLEM_INVESTIGATION_REMOVE:
    'BUILD_PROBLEM_INVESTIGATION_REMOVE' as 'BUILD_PROBLEM_INVESTIGATION_REMOVE',
  BUILD_PROBLEM_MARK_AS_FIXED: 'BUILD_PROBLEM_MARK_AS_FIXED' as 'BUILD_PROBLEM_MARK_AS_FIXED',
  BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY:
    'BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY' as 'BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY',
  BUILD_PROBLEM_INVESTIGATION_CHANGE:
    'BUILD_PROBLEM_INVESTIGATION_CHANGE' as 'BUILD_PROBLEM_INVESTIGATION_CHANGE',
  BUILD_PROBLEM_INVESTIGATION_CHANGE_WITH_REASSIGN:
    'BUILD_PROBLEM_INVESTIGATION_CHANGE_WITH_REASSIGN' as 'BUILD_PROBLEM_INVESTIGATION_CHANGE_WITH_REASSIGN',
  BUILD_PROBLEM_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    'BUILD_PROBLEM_INVESTIGATION_ASSIGN_NO_ASSIGNEE' as 'BUILD_PROBLEM_INVESTIGATION_ASSIGN_NO_ASSIGNEE',
  BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
    'BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE' as 'BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE',
}
