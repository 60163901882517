import type {BuildTypeId} from '../../../../../../types'

export enum BranchesToShow {
  DEFAULT,
  NON_DEFAULT,
  NON_BUILT,
}

type OwnProps = {
  branchesToShow: BranchesToShow
  withTitle?: boolean
}
export type ReduxOwnProps = OwnProps & {
  buildTypeId?: BuildTypeId
}
export type StateProps = {
  expanded: boolean
}
export type ActionProps = {
  onCollapse: (arg0: BuildTypeId, arg1: BranchesToShow) => unknown
  onExpand: (arg0: BuildTypeId, arg1: BranchesToShow) => unknown
}
export type Props = ReduxOwnProps & StateProps & ActionProps
