import {connect} from 'react-redux'

import {openDialog, closeDialog} from '../actions'
import type {State} from '../reducers/types'
import {isDialogShown, isDialogProcessing, dialogHasError} from '../selectors'
import {stringifyId} from '../types'
import type {BuildId, DialogType} from '../types'

export type DialogOwnProps<P = {}> = P &
  Readonly<{
    buildId?: BuildId | null | undefined
  }>
type StateProps = {
  isOpened: boolean
  isProcessing: boolean
  dialogHasError: boolean
}
type ActionProps = {
  openDialog: (dialogId: string, dialogType: DialogType) => unknown
  closeDialog: () => unknown
}
export type DialogProps<P = {}> = DialogOwnProps<P> & StateProps & ActionProps
const actionCreators: ActionProps = {
  openDialog,
  closeDialog,
}

const mapStateToProps =
  <P>(
    type: DialogType,
    idGenerator: ((arg0: State, arg1: DialogOwnProps<P>) => string) | null | undefined,
  ): ((arg0: State, arg1: DialogOwnProps<P>) => StateProps) =>
  (state, props) => {
    const dialogId =
      idGenerator && typeof idGenerator === 'function'
        ? idGenerator(state, props)
        : stringifyId(props.buildId)
    const isOpened = isDialogShown(state, dialogId, type)
    const isProcessing = isDialogProcessing(state)
    return {
      isOpened,
      isProcessing,
      dialogHasError: dialogHasError(state),
    }
  }

export default <P>(
  type: DialogType,
  idGenerator?: (state: State, props: DialogOwnProps<P>) => string,
) => connect(mapStateToProps(type, idGenerator), actionCreators)
