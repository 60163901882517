import {fetchBuildTypeTags} from '../../../../actions/fetch'
import type {AppThunk} from '../../../../actions/types'
import {getAddTagsArg, getAddTagsToDependenciesArg, getChangeTagsArg} from '../../../../rest/builds'
import {getAllTags, getIsStarred, getTags} from '../../../../selectors'
import {restApi} from '../../../../services/rest'
import {STAR_TAG} from '../../../../types'
import type {BuildId, BuildTypeId, Tag} from '../../../../types'

type ChangeBuildTagsArg = {
  buildId: BuildId
  tags: ReadonlyArray<Tag>
  buildTypeId?: BuildTypeId | undefined
  applyToChainBuilds: boolean
}
const changeBuildTagsAction =
  ({buildId, tags, buildTypeId, applyToChainBuilds}: ChangeBuildTagsArg): AppThunk =>
  (dispatch, getState) => {
    const state = getState()
    const allTags = getIsStarred(state, buildId)
      ? tags.concat({
          name: STAR_TAG,
          private: true,
        })
      : tags

    if (applyToChainBuilds && tags.length > 0) {
      dispatch(
        restApi.endpoints.addTagsToMultipleBuilds.initiate(
          getAddTagsToDependenciesArg(buildId, tags),
        ),
      )
    }

    dispatch(
      restApi.endpoints.setBuildTags.initiate(
        getChangeTagsArg(buildId, allTags, getAllTags(state, buildId)),
      ),
    ).then(() => {
      if (buildTypeId != null) {
        dispatch(fetchBuildTypeTags(buildTypeId))
      }
    })
  }

export const changeBuildTags = (
  buildIds: readonly BuildId[],
  tags: ReadonlyArray<Tag>,
  buildTypeId?: BuildTypeId | undefined,
  applyToChainBuilds: boolean = false,
): AppThunk =>
  buildIds.length > 1
    ? restApi.endpoints.addTagsToMultipleBuilds.initiate(
        getAddTagsArg(buildIds, tags, applyToChainBuilds),
      )
    : changeBuildTagsAction({buildId: buildIds[0], tags, buildTypeId, applyToChainBuilds})

export const removeBuildTag =
  (buildId: BuildId, name: string, buildTypeId?: BuildTypeId | undefined): AppThunk =>
  (dispatch, getState) =>
    dispatch(
      changeBuildTags(
        [buildId],
        getTags(getState(), buildId).filter(tag => tag.name !== name),
        buildTypeId,
      ),
    )
